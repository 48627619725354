import { combineReducers } from 'redux'
import { Actions as actions } from '../../actions/Types'
import { RootState } from '../../configureStore'
import {  DEPARTMENT_BASED_ORG_CHART_DATA, DESIGNATION_BASED_ORG_CHART_DATA, FAILURE, PEOPLE_BASED_ORG_CHART_DATA, REQUEST, SUCCESS } from '../../actions/actiontypes'

const ui = () => {

    const isGettingPeopleBasedOrgData = (state = false, action: actions) => {
        switch (action.type) {
          case PEOPLE_BASED_ORG_CHART_DATA[SUCCESS]:
            return false
            case PEOPLE_BASED_ORG_CHART_DATA[FAILURE]:
          case PEOPLE_BASED_ORG_CHART_DATA[REQUEST]:
            return true
          default:
            return state
        }
      }
      const isGettingDepartmentBasedOrgData = (state = false, action: actions) => {
        switch (action.type) {
          case DEPARTMENT_BASED_ORG_CHART_DATA[SUCCESS]:
            return false
            case DEPARTMENT_BASED_ORG_CHART_DATA[FAILURE]:
          case DEPARTMENT_BASED_ORG_CHART_DATA[REQUEST]:
            return true
          default:
            return state
        }
      }
      const isGettingDesignationBasedOrgChartData =(state = false, action: actions)=>{
        switch(action.type){
          case DESIGNATION_BASED_ORG_CHART_DATA[SUCCESS]:
             return false
          case DESIGNATION_BASED_ORG_CHART_DATA[REQUEST]:
             return true
            default:
              return state
        }
      }
      

    return combineReducers({
        isGettingPeopleBasedOrgData,
        isGettingDepartmentBasedOrgData, 
        isGettingDesignationBasedOrgChartData,
    })

}

export default ui

export const getAllOrgChartUI = (state: RootState) => state.ui.orgchartUIstate
