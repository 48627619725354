export const convertHtmlToString = (htmlContent: string): string => {
    // Create a temporary DOM element to parse the HTML string
    const tempElement = document.createElement('div');
    tempElement.innerHTML = htmlContent;
  
    // Function to handle <a> tags (links)
    const handleAnchorTags = (element: any) => {
      const links = element.querySelectorAll('a');
      links.forEach((link: any) => {
        const href = link.getAttribute('href');
        const text = link.textContent?.trim();
        // Replace link with clickable text (e.g., [link text](href))
        link.replaceWith(`[${text}](${href})`);
      });
    }; 
  
    // Function to handle <ul> and <ol> tags (unordered and ordered lists)
    const handleListTags = (element: any) => {
      const lists = element.querySelectorAll('ul, ol');
      lists.forEach((list: any) => {
        let formattedText = '';
        const items = list.querySelectorAll('li');
        items.forEach((item: any, index: number) => {
          // Extract text from list items and separate with commas
          formattedText += item.textContent?.trim();
          if (index < items.length - 1) {
            formattedText += ', '; // Add commas between list items
          }
        });
        // Replace the list with the comma-separated text
        list.replaceWith(formattedText);
      });
    };
  
    // Apply tag handling to the temporary element
    handleAnchorTags(tempElement);
    handleListTags(tempElement);
  
    // Return the plain text content with formatting applied, fallback to empty string if null
    return tempElement.textContent?.trim() || '';
  };
  