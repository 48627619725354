import jsPDF from 'jspdf'

export const exportAsPDF = () => {
  const heading = document.getElementsByClassName('heading')
  const cards = document.getElementById('card')
  const head = document.getElementById('head')
  const body = document.querySelectorAll('#body')

  if (body.length > 0 && head && heading) {
    const parent = document.createElement('div')
    for (let i = 0; i < 2; i++) {
      const temp = heading[i].cloneNode(true) as HTMLDivElement
      temp.style.width = '1280px'
      temp.style.fontFamily = 'Helvetica'
      temp.style.fontWeight = '800'
      parent.appendChild(temp)
    }

    if (cards) {
      const cardPar = cards.cloneNode(true) as HTMLDivElement
      cardPar.style.width = '1280px'
      cardPar.style.fontFamily = 'Symbol'
      parent.appendChild(cardPar)
    }
    const gapDiv = document.createElement('div')
    gapDiv.style.height = '1rem'
    parent.appendChild(gapDiv)

    const newHead = head.cloneNode(true) as HTMLDivElement
    newHead.style.fontFamily = 'Times'
    parent.appendChild(newHead)

    const bodyArray = Array.from(body)

    for (let element of bodyArray) {
      parent.appendChild(element.cloneNode(true))
    }

    let pdf = new jsPDF({
      orientation: 'l',
      putOnlyUsedFonts: false,
      unit: 'pt',
      format: [2500, 2500],
    })

    pdf.html(parent, {
      callback: (pdf) => pdf.save(`${(heading[1].cloneNode(true) as HTMLElement).innerText}.pdf`),
      x: 25,
      y: 25,
    })
  }
}