import {
  Autocomplete,
  Box,
  CircularProgress,
  Grid,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TextField,
  Typography,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import DateRangePicker from './DateRangePicker'
import dayjs from 'dayjs'
// import moment from 'moment'
import { StyledEditableTableCell, StyledTableCell, StyledTableRow } from '../../Common/CommonStyles'
import { style } from '../SheetStyles'
import AnalyticsBarGraph from './AnalyticsBarGraph'
import { convertHtmlToString } from '../ExportButton/ConvertHtmlToString'
import DelayedTaskRowData from '../DelayedTaskRowData'
// import { styles } from '../../OrgCharts/OrgStyles'

const getColumnData = [
  { id: 1, label: 'Name' },
  { id: 2, label: 'Project Name' },
  { id: 3, label: 'Mandate Type' },
  { id: 5, label: 'Date' },
]
const styles = {
  styledCells: {
    padding: '6px 6px',
  },
  typoIntablehead: {
    margin: '0',
    padding: '6px 2px',
    width: '160px',
  },
  border: {
    borderLeft: '1px solid #E9E9E9',
    borderRight: '1px solid #E9E9E9',
  },
}

const DelayedTasks = (props: any) => {
  const {
    fetchDelayedGraph,
    isDelayedTableData,
    fetchDelayedTable,
    setWorkingEmployee,
    workingEmployee,
    employeeRecords,
    delayedTableData,
  } = props
  const oneWeekAgo = dayjs().subtract(1, 'day')
  const [delayedTableStartDate, setDelayedTableStartDate] = useState(oneWeekAgo)
  const [delayedTableEndDate, setDelayedTableEndDate] = useState(dayjs())
  const [delayedTableShowResults, setDelayedTableShowResults] = useState(true)
  const [open, setOpen] = useState(false)
  const [rowData, setRowData] = useState()

  const [value, setValue] = useState<any>({})

  // useEffect(() => {
  //   if (workingEmployee) setValue(workingEmployee)
  // }, [workingEmployee])

  useEffect(() => {
    const data = {
      id: value?.userId,
      startDate: delayedTableStartDate.format('YYYY-MM-DD').toString(),
      endDate: delayedTableEndDate.format('YYYY-MM-DD').toString(),
    }

    fetchDelayedTable(data)
  }, [delayedTableShowResults, value?.userId])

  const limitContentLength = (content: any) => {
    if (content.length > 70) {
      return content.slice(0, 70) + '...' // Get first 100 characters and add ellipsis
    }
    return content
  }

  const handleDelayedRowData = (data: any) => {
    setRowData(data)
    setOpen(true)
  }

  return (
    <Box>
      <DelayedTaskRowData open={open} rowData={rowData} setOpen={setOpen} />
      <Grid container xs={12}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
            border: '2px solid #E0E0E0',
            padding: '0 20px',
            borderRadius: '5px',
          }}
        >
          <Autocomplete
            autoFocus={false}
            size='small'
            disablePortal
            id='select-working-employees'
            options={employeeRecords}
            getOptionLabel={(option: any) => option?.info ?? ''}
            sx={{
              width: '240px',
              '.MuiInputBase-root': {
                padding: '22px 11px',
                borderRadius: '20px',
                fontSize: '13px',
                fontFamily: 'Montserrat-Medium',
                height: '39px',
              },
              '& .MuiFormControl-root': {
                margin: '0',
                marginTop: '5px',
              },
              '& .MuiFormLabel-root ': {
                backgroundColor: 'white',
                width: '165px',
              },
              '.MuiFormLabel-asterisk': {
                color: 'white',
              },
            }}
            disableClearable
            clearOnEscape={false}
            clearIcon={null}
            value={value}
            renderInput={(params: any) => (
              <TextField {...params} label='Select Employee' variant='outlined' />
            )}
            onChange={(event: any, newValue: any) => {
              // Check if newValue exists and has an 'info' property before calling extractRole
              if (newValue?.userId) {
                // setWorkingEmployee(newValue)
                setValue(newValue)
              } else {
                // setWorkingEmployee('') // Clear or handle case when no employee is selected
                setValue({})
              }
            }}
            ListboxProps={{
              style: {
                maxHeight: '150px',
              },
            }}
          />
          <DateRangePicker
            setStartDate={setDelayedTableStartDate}
            setEndDate={setDelayedTableEndDate}
            startDate={delayedTableStartDate}
            endDate={delayedTableEndDate}
            setShowResults={setDelayedTableShowResults}
            delayTable='delayTable'
            fetchDelayedTable={fetchDelayedTable}
            fetchDelayedGraph={fetchDelayedGraph}
          />
        </Box>
        <TableContainer component={Paper} id='export' sx={{ marginTop: '15px' }}>
          {isDelayedTableData ? (
            <Box sx={style.loader}>
              <CircularProgress disableShrink />
            </Box>
          ) : (
            <>
              {delayedTableData?.length > 0 ? (
                <Table>
                  <TableHead id='head'>
                    <StyledTableRow sx={style.border}>
                      <StyledEditableTableCell> Emp Name</StyledEditableTableCell>
                      <StyledEditableTableCell> Mandate Name</StyledEditableTableCell>
                      <StyledEditableTableCell width='50'>Task Description</StyledEditableTableCell>
                      <StyledEditableTableCell>Date</StyledEditableTableCell>
                    </StyledTableRow>
                  </TableHead>
                  <TableBody>
                    {delayedTableData?.map((row: any, idx: number) => (
                      <StyledTableRow
                        id='body'
                        key={idx}
                        sx={style.border}
                        onClick={() => handleDelayedRowData(row)}
                      >
                        <StyledTableCell>{row.empName ? row.empName : 'NA'}</StyledTableCell>
                        <StyledTableCell>
                          {row.projectName ? row.projectName : 'NA'}
                        </StyledTableCell>
                        <StyledEditableTableCell width='150px'>
                          {row.taskDescription
                            ? limitContentLength(convertHtmlToString(row.taskDescription))
                            : 'NA'}
                        </StyledEditableTableCell>
                        <StyledTableCell>
                          {dayjs(row?.Date).format('DD/MM/YYYY').toString() ?? 'NA'}
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              ) : (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '300px',
                  }}
                >
                  <p>No Data Found</p>
                </div>
              )}
            </>
          )}
        </TableContainer>
      </Grid>
    </Box>
  )
}

export default DelayedTasks
