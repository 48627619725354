import React, { useEffect, useRef, useState } from 'react'
import { Box, Button, CircularProgress, Dialog, InputLabel, MenuItem } from '@mui/material'
import { Tree, TreeNode } from 'react-organizational-chart'
import { RootState } from '../../configureStore'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { designationBasedOrgChart } from '../../actions'
import { orgChartEntity, orgChartUI } from '../../reducers'
import { SelectField } from '../Common/ComponentCommonStyles'
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'
import { loaderProps } from '../Common/CommonStyles'
import {
  DesignationBasedChartsProps,
  DrsCount,
  Label,
  Name,
  NodeProps,
  StyledFormControl,
  StyledImage,
  StyledNode,
  StyledPaper,
  styles,
} from './OrgStyles'
import colors from './Colors'


const Node: React.FC<NodeProps> = ({ name, designation,imageURL,count,bgColor }) => (
  <StyledNode bgColor={bgColor}>
     <StyledImage
      src={imageURL}
      alt='image'
    />
    <Name>{name}</Name>
    <Label>{designation}</Label>
    {count ? <DrsCount>Total Reportees : {count}</DrsCount> : ''}

  </StyledNode>
)

const renderTreeNodes = (node: NodeProps, colorMap: { [key: string]: string }) => (
  <TreeNode key={node.emp_id} label={<Node {...node} bgColor={colorMap[node.emp_id]} />}>
    {node.drs?.map(child => renderTreeNodes(child, colorMap))}
  </TreeNode>
);

const generateColorMap = (
  nodes: NodeProps[],
  colorMap: { [key: string]: string },
  colorList: string[],
) => {
  const colorQueue = [...colorList]
  const assignColor = (node: NodeProps, parentColor?: string) => {
    if (!colorMap[node.emp_id]) {
      colorMap[node.emp_id] = parentColor || colorQueue.shift() || '#CCCCCC'
    }

    if (colorQueue.length === 0) {
      colorQueue.push(...colorList)
    }

    node.drs?.forEach((sub) => assignColor(sub, colorMap[node.emp_id]))
  }

  nodes.forEach((node) => assignColor(node))
  return colorMap
}

const DesignationBasedCharts: React.FC<DesignationBasedChartsProps> = (props) => {
  const {
    designationBasedOrgChartAPI,
    designationBasedOrgChartData,
    isGettingDesignationBasedOrgChartData,
  } = props

  const [level, setLevel] = useState<number>(1)
  const [colorMap, setColorMap] = useState<{ [key: string]: string }>({})


  const chartRef = useRef<HTMLDivElement>(null)

  const handleDepartment = (event: any) => {
    setLevel(event.target.value)
  }
  const predefinedColors = Object.values(colors)

  useEffect(() => {
    if (designationBasedOrgChartData && designationBasedOrgChartData.drs) {
      const initialColorMap = {}
      generateColorMap(designationBasedOrgChartData.drs, initialColorMap, predefinedColors)
      setColorMap(initialColorMap)
    }
  }, [designationBasedOrgChartData])

  useEffect(() => {
    designationBasedOrgChartAPI({ level: level })
  }, [level])


  const exportToPDF = () => {
    if (chartRef.current) {
      const chart = chartRef.current
      const originalWidth = chart.scrollWidth
      const originalHeight = chart.clientHeight
      const originalOverflow = chart.style.overflow

      chart.style.width = `${originalWidth}px`
      chart.style.overflow = 'visible'

      html2canvas(chart, {
        scrollX: 0,
        scrollY: 0,
        useCORS: true,
        width: originalWidth,
        height: originalHeight,
        scale: 1,
      }).then((canvas) => {
        const imgData = canvas.toDataURL('image/jpeg', 0.9)

        const pdf = new jsPDF({
          orientation: 'landscape',
          unit: 'px',
          format: [canvas.width, canvas.height],
        })

        const pdfWidth = pdf.internal.pageSize.getWidth()
        const pdfHeight = (canvas.height * pdfWidth) / canvas.width

        pdf.addImage(imgData, 'JPEG', 0, 0, pdfWidth, pdfHeight, '', 'FAST')
        pdf.save('org-chart.pdf')

        chart.style.width = ''
        chart.style.overflow = originalOverflow
      })
    }
  }
  return (
    <>
      <Box sx={styles.selectBox}>
        <StyledFormControl>
          <InputLabel id='demo-simple-select-readonly-label'>Select Level</InputLabel>
          <SelectField
            sx={styles.selectStyle}
            variant='outlined'
            type='small'
            label='Select Level'
            value={level}
            onChange={handleDepartment}
          >
            {Array.from({ length: 13 }, (_, index) => (
              <MenuItem key={index + 1} value={index + 1}>
                Level {index + 1}
              </MenuItem>
            ))}
          </SelectField>
        </StyledFormControl>
        <Button onClick={exportToPDF} sx={styles.buttonStyles} variant='contained' color='primary'>
          Export as PDF
        </Button>
      </Box>
      <StyledPaper>
        {isGettingDesignationBasedOrgChartData ? (
          <Dialog open={isGettingDesignationBasedOrgChartData} PaperProps={{ style: loaderProps }}>
            <CircularProgress color='secondary' />
          </Dialog>
        ) : (
          <div ref={chartRef}>
            <Tree
              lineWidth={'var(--line-width)'}
              lineColor={'var(--line-color)'}
              lineBorderRadius={'var(--line-border-radius)'}
              label={<Node {...designationBasedOrgChartData}  bgColor=""/>}
            >
              {designationBasedOrgChartData?.drs?.map((child: NodeProps) => renderTreeNodes(child,colorMap))}
            </Tree>
          </div>
        )}
      </StyledPaper>
    </>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    designationBasedOrgChartData:
      orgChartEntity.getAllOrgChartState(state).fetchDesignationBasedOrgChartState,
    isGettingDesignationBasedOrgChartData:
      orgChartUI.getAllOrgChartUI(state).isGettingDesignationBasedOrgChartData,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    designationBasedOrgChartAPI: (data: NodeProps) =>
      dispatch(designationBasedOrgChart.request(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DesignationBasedCharts)
