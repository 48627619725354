import { call, takeLatest } from 'redux-saga/effects'
import { actionTypes } from '../actions'
import * as ApiService from '../services'
import { sendPayload, sendPayloadFailure } from './helper'
import {
  ADD_NEW_COMMENT,
  FETCH_ASSIGNED_REQUEST,
  FETCH_DOWNLOADABLE_URL,
  FETCH_UPDATED_SERVICE_REQUEST,
} from '../actions/actiontypes'
const { REQUEST, LOGIN_USER, CREATE_SERVICE_REQUEST } = actionTypes

//write sagas function

function* handleCreateNewRequest(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.createNewRequest,
      data.data,
    )
    yield sendPayload(apiResponse, CREATE_SERVICE_REQUEST)
  } catch (e) {
    yield sendPayloadFailure(e, CREATE_SERVICE_REQUEST)
  }
}

function* handleAddNewComment(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.addNewComment,
      data.data,
    )
    yield sendPayload(apiResponse, ADD_NEW_COMMENT)
  } catch (e) {
    yield sendPayloadFailure(e, ADD_NEW_COMMENT)
  }
}
function* handleAssignedRequest(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.assignedRequest,
      data,
    )
    yield sendPayload(apiResponse, FETCH_ASSIGNED_REQUEST)
  } catch (e) {
    yield sendPayloadFailure(e, FETCH_ASSIGNED_REQUEST)
  }
}

function* handleUpdatedServiceRequest(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.updatedRequest,
      data,
    )
    yield sendPayload(apiResponse, FETCH_UPDATED_SERVICE_REQUEST)
  } catch (e) {
    yield sendPayloadFailure(e, FETCH_UPDATED_SERVICE_REQUEST)
  }
}

function* handleDownloadableURL(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.downloadableURL,
      data,
    )
    yield sendPayload(apiResponse, FETCH_DOWNLOADABLE_URL)
  } catch (e) {
    yield sendPayloadFailure(e, FETCH_DOWNLOADABLE_URL)
  }
}

export const sagas = {
  //watcher come here
  watchCreateSR: takeLatest(actionTypes.CREATE_SERVICE_REQUEST[REQUEST], handleCreateNewRequest),
  watchAddNewComment: takeLatest(actionTypes.ADD_NEW_COMMENT[REQUEST], handleAddNewComment),
  watchAssignedRequest: takeLatest(
    actionTypes.FETCH_ASSIGNED_REQUEST[REQUEST],
    handleAssignedRequest,
  ),
  watchUpdatedServiceRequest: takeLatest(
    actionTypes.FETCH_UPDATED_SERVICE_REQUEST[REQUEST],
    handleUpdatedServiceRequest,
  ),
  watchHandleDownloadableURL: takeLatest(
    actionTypes.FETCH_DOWNLOADABLE_URL[REQUEST],
    handleDownloadableURL,
  ),
}
