import { combineReducers } from 'redux'
import { actionTypes } from '../../actions'
import { Actions as actions } from '../../actions/Types'
import { RootState } from '../../configureStore'
import { REQUEST, SUCCESS } from '../../actions/actiontypes'

const { FETCH_EMPLOYEE_DETAILS } = actionTypes

const entity = () => {
  const getAllEmployeesDetails = (state = [], action: actions) => {
    switch (action.type) {
      case FETCH_EMPLOYEE_DETAILS[SUCCESS]:
        return action.payload
      case FETCH_EMPLOYEE_DETAILS[REQUEST]:
        return []

      default:
        return state
    }
  }

  return combineReducers({
    getAllEmployeesDetails,
  })
}

export default entity

export const getAllEmployeesList = (state: RootState) => state.entities.EmployeeDataRequest
