import { combineReducers } from 'redux'
import { Actions } from '../../actions/Types'
import { RootState } from '../../configureStore'
import { EMPLOYEE_HISTORY, FAILURE, GET_IDSR_RECENT, MANAGER_HISTORY, REQUEST, SUCCESS } from '../../actions/actiontypes'

const ui = () => {

  const isIdsrRecentData = (state = [], action: Actions) => {
    switch (action.type) {
      case GET_IDSR_RECENT[SUCCESS]:
          return false
        case GET_IDSR_RECENT[FAILURE]:
          return false
        case GET_IDSR_RECENT[REQUEST]:
          return true
      default:
        return state
    }
  }
    const isGettingEmpHistoryData = (state = false, action: Actions) => {
        switch (action.type) {
          case EMPLOYEE_HISTORY[SUCCESS]:
            return false
          case EMPLOYEE_HISTORY[REQUEST]:
            return true
          default:
            return state
        }
      }
      const isGettingmManagerHistoryData = (state = false, action: Actions) => {
        switch (action.type) {
          case MANAGER_HISTORY[SUCCESS]:
            return false
          case MANAGER_HISTORY[REQUEST]:
            return true
          default:
            return state
        }
      }

    return combineReducers({
        isGettingEmpHistoryData,
        isGettingmManagerHistoryData,
        isIdsrRecentData
      })
}
export default ui

export const getAllEmpPortalUI = (state: RootState) => state.ui.employeePortal
