import { call, takeLatest } from 'redux-saga/effects'
import { actionTypes } from '../actions'
import * as ApiService from '../services'
import { sendPayload, sendPayloadFailure } from './helper'
import {
  CREATE_USER,
  FETCH_ALL_COUNTRY,
  FETCH_ALL_FLOOR,
  FETCH_ALL_LOCATION,
  FETCH_ALL_ROLE,
  FETCH_ALL_STATE,
  FETCH_ALL_USER_LIST,
  FETCH_ALL_WORKSTATION,
  UPLOAD_USER_IMG,
  UPDATE_USER,
  FETCH_ALL_CLIENT_LOCATION,
  GET_USER_IMAGE,
  DELETE_USER,
} from '../actions/actiontypes'
const { REQUEST } = actionTypes

function* uploadUserImg(data:any){
  try{
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.uploadUserImg,
      data.data,
    )
    yield sendPayload(apiResponse, UPLOAD_USER_IMG)
  }catch(e){
    yield sendPayloadFailure(e, UPLOAD_USER_IMG)
  }
}

function* getUserImage(data: any){
  try{
    const apiResponse: Generator<string,number ,string> = yield call(
      ApiService.getUserImage,
      data,
    )
    yield sendPayload(apiResponse, GET_USER_IMAGE)
  } catch (e){
    yield sendPayloadFailure(e, GET_USER_IMAGE)
  }
  }

  function* deleteUser(data: any){
    try{
      const apiResponse: Generator<string,number ,string> = yield call(
        ApiService.deleteUser,
        data.userid,
      )
      yield sendPayload(apiResponse, DELETE_USER)
    } catch (e){
      yield sendPayloadFailure(e, DELETE_USER)
    }
    }

function* createUser(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.createUser,
      data.data,
    )
    yield sendPayload(apiResponse, CREATE_USER)
  } catch (e) {
    yield sendPayloadFailure(e, CREATE_USER)
  }
}

function* fetchAllRole() {
  try {
    const apiResponse: Generator<string, number, string> = yield call(ApiService.fetchAllRoles)
    yield sendPayload(apiResponse, FETCH_ALL_ROLE)
  } catch (e) {
    yield sendPayloadFailure(e, FETCH_ALL_ROLE)
  }
}

function* fetchAllState() {
  try {
    const apiResponse: Generator<string, number, string> = yield call(ApiService.fetchAllState)
    yield sendPayload(apiResponse, FETCH_ALL_STATE)
  } catch (e) {
    yield sendPayloadFailure(e, FETCH_ALL_STATE)
  }
}

function* fetchAllCountry() {
  try {
    const apiResponse: Generator<string, number, string> = yield call(ApiService.fetchAllCountry)
    yield sendPayload(apiResponse, FETCH_ALL_COUNTRY)
  } catch (e) {
    yield sendPayloadFailure(e, FETCH_ALL_COUNTRY)
  }
}

function* fetchAllFloor() {
  try {
    const apiResponse: Generator<string, number, string> = yield call(ApiService.fetchAllFloors)
    yield sendPayload(apiResponse, FETCH_ALL_FLOOR)
  } catch (e) {
    yield sendPayloadFailure(e, FETCH_ALL_FLOOR)
  }
}

function* fetchAllWorkstation() {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.fetchAllWorkstation,
    )
    yield sendPayload(apiResponse, FETCH_ALL_WORKSTATION)
  } catch (e) {
    yield sendPayloadFailure(e, FETCH_ALL_WORKSTATION)
  }
}

function* fetchAllLocation() {
  try {
    const apiResponse: Generator<string, number, string> = yield call(ApiService.fetchAllLocation)
    yield sendPayload(apiResponse, FETCH_ALL_LOCATION)
  } catch (e) {
    yield sendPayloadFailure(e, FETCH_ALL_LOCATION)
  }
}

function* fetchAllClientLocation() {
  try {
    const apiResponse: Generator<string, number, string> = yield call(ApiService.fetchAllClietLocation)
    yield sendPayload(apiResponse, FETCH_ALL_CLIENT_LOCATION)
  } catch (e) {
    yield sendPayloadFailure(e, FETCH_ALL_CLIENT_LOCATION)
  }
}

function* getAllUserList(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.getAllUserList,
      data.data,
    )
    yield sendPayload(apiResponse, FETCH_ALL_USER_LIST)
  } catch (e) {
    yield sendPayloadFailure(e, FETCH_ALL_USER_LIST)
  }
}

function* updateUser(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.updateUser,
      data.data,
    )
    yield sendPayload(apiResponse, UPDATE_USER)
  } catch (e) {
    yield sendPayloadFailure(e, UPDATE_USER)
  }
}

export const sagas = {
  watchUploadUserImg: takeLatest(actionTypes.UPLOAD_USER_IMG[REQUEST],uploadUserImg),
  watchgetUserImg: takeLatest(actionTypes.GET_USER_IMAGE[REQUEST],getUserImage),
  watchCreateUser: takeLatest(actionTypes.CREATE_USER[REQUEST], createUser),
  watchFetchUserRole: takeLatest(actionTypes.FETCH_ALL_ROLE[REQUEST], fetchAllRole),
  watchFetchUserState: takeLatest(actionTypes.FETCH_ALL_STATE[REQUEST], fetchAllState),
  watchFetchUserCountry: takeLatest(actionTypes.FETCH_ALL_COUNTRY[REQUEST], fetchAllCountry),
  watchFetchUserFloor: takeLatest(actionTypes.FETCH_ALL_FLOOR[REQUEST], fetchAllFloor),
  watchFetchUserWorkstation: takeLatest(
    actionTypes.FETCH_ALL_WORKSTATION[REQUEST],
    fetchAllWorkstation,
  ),
  watchFetchUserLocation: takeLatest(actionTypes.FETCH_ALL_LOCATION[REQUEST], fetchAllLocation),
  watchFetchUserClientLocation: takeLatest(actionTypes.FETCH_ALL_CLIENT_LOCATION[REQUEST], fetchAllClientLocation),
  watchFetchUserAllList: takeLatest(actionTypes.FETCH_ALL_USER_LIST[REQUEST], getAllUserList),
  watchUpdateUser: takeLatest(actionTypes.UPDATE_USER[REQUEST], updateUser),
  watchDeleteUser: takeLatest(actionTypes.DELETE_USER[REQUEST], deleteUser),
}
