import * as React from 'react'
import { useState } from 'react'
import {
  Card,
  CardActions,
  CardContent,
  Paper,
  Typography,
  Dialog,
  CircularProgress,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  styled,
  IconButton,
  DialogTitle,
  Divider,
  DialogContent,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  Checkbox,
} from '@mui/material'
import {
  HeaderHeading,
  StyledMenuItem,
  StyledTableCell,
  StyledTableCellForMyTeam,
  StyledTableRow,
} from '../Common/CommonStyles'
import DetailsIcon from '@mui/icons-material/Details'
import CloseIcon from '@mui/icons-material/Close'
import LightbulbIcon from '@mui/icons-material/Lightbulb'
import AccountTreeIcon from '@mui/icons-material/AccountTree'
import ImportantDevicesIcon from '@mui/icons-material/ImportantDevices'
import HolidayVillageIcon from '@mui/icons-material/HolidayVillage'
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted'
import MiscellaneousServicesIcon from '@mui/icons-material/MiscellaneousServices'
import HighlightIcon from '@mui/icons-material/Highlight'
import ContactsIcon from '@mui/icons-material/Contacts'
import { dashboardEntity, dashboardUI, financeEntity, financeUI } from '../../reducers'
import Tooltip from '@mui/material/Tooltip'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import { useNavigate } from 'react-router-dom'
import { DashboardIcon, OpenTabLogo, ProductivityMetricsGraph } from '../../utils/StaticData'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import { Primary } from '../../utils'
import { connect, useSelector } from 'react-redux'
import {
  downloadForm16,
  getAssignedTaskDrs,
  getFinancialYear,
  getHomePageDetails,
  getTodayTask,
} from '../../actions'
import { loaderProps } from '../Common/CommonStyles'
import { RootState } from '../../configureStore'
import { makeStyles } from '@mui/styles'
import CelebrationIcon from '@mui/icons-material/Celebration'
import CakeIcon from '@mui/icons-material/Cake'
import PersonAddIcon from '@mui/icons-material/PersonAdd'
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange'
import DescriptionIcon from '@mui/icons-material/Description'
import FilePresentIcon from '@mui/icons-material/FilePresent'
import FeedbackIcon from '@mui/icons-material/Feedback'
import FeedIcon from '@mui/icons-material/Feed'
import { Avatar } from '@material-ui/core'
import CloudDownloadIcon from '@mui/icons-material/CloudDownload'
import { toast } from 'react-toastify'
import TextSnippetIcon from '@mui/icons-material/TextSnippet'
import productivityGraph from '.'
import moment from 'moment'
import WorkingPercentage from './DashboardTiles/WorkingPercentage'

function MasterSetUp({
  downloadForm16Success,
  downloadForm16Reset,
  userDetails,
  downloadForm16LinkData,
  homePageDetails,
  assignedTaskDrs,
  getHomeDetails,
  getAssignedTaskDrs,
  loaderState,
  financialYearData,
  downloadForm16,
  getTodayTask,
  TodaysTasksDetails,
  markAsResolved,
  markAsResolvedData,
}: any) {
  const [yearData, setYearData] = useState({ file: false, year: '' })
  const [userId, setUserId] = useState(userDetails?.id ?? 0)
  const [assignedTaskDialog, setAssignedTaskDialog] = useState({ dialog: false, type: '' })
  const [taskTableName, setTasksTableName] = useState('')

  React.useEffect(() => {
    if (homePageDetails?.formSixteenUserData && homePageDetails?.formSixteenUserData?.length > 0)
      setYearData(homePageDetails?.formSixteenUserData[0])
  }, [homePageDetails])

  const StyledMenuItem = styled(MenuItem)({
    paddingRight: '50px',
    fontSize: '11px',
    fontWeight: 'bold',
    fontFamily: 'Montserrat-SemiBold',
  })

  const useStyles = makeStyles({
    DRsHeading: {
      marginBottom: '0 !important',
      position: 'relative',
      top: 4,
      lineHeight: '1 !important',
      color: '#193C6D !important',
      fontSize: '15px !important',
      cursor: 'pointer',
      textDecoration: 'none',
      width: '150px',
      transition: 'text-decoration 0.3s',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    taskHeading: {
      marginBottom: '0 !important',
      position: 'relative',
      top: 4,
      lineHeight: '1 !important',
      fontWeight: '600 !important',
      color: 'green !important',
      fontSize: '12px !important',
      cursor: 'pointer',
      textDecoration: 'none',
      width: '150px',
      transition: 'text-decoration 0.3s',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    taskFollowUpHeading: {
      marginBottom: '0 !important',
      position: 'relative',
      top: 4,
      lineHeight: '1 !important',
      fontWeight: '600 !important',
      color: 'red !important',
      fontSize: '12px !important',
      cursor: 'pointer',
      textDecoration: 'none',
      width: '150px',
      transition: 'text-decoration 0.3s',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    iconBillable: {
      color: 'green',
      position: 'relative',
      top: 5,
      fontSize: '18px !important',
    },
    iconAdditional: {
      color: 'brown',
      fontSize: '18px !important',
      position: 'relative',
      top: 5,
    },
    projectCard: {
      position: 'relative',
      bottom: 5,
    },
  })
  const classes = useStyles()
  const navigate = useNavigate()
  const [taskStatus, setTaskStatus] = useState(false)

  const userRole = useSelector(
    (state: { entities: { dashboard: { getUserDetails: { roles: string[] } } } }) =>
      state?.entities?.dashboard?.getUserDetails?.roles,
  )

  const setDownloadForm16Data = (id: number) => {
    downloadForm16({ id: id, currentYear: yearData.year })
  }

  const handleBasicInfo = (userId: number) => {
    if (userId) {
      navigate(`basic-info?id=${userId}`)
    }
  }
  const handleLeaveDetails = (userId: number) => {
    if (userId) {
      navigate(`leave-details?id=${userId}`, {
        state: { tabName: 'Leave Details', deptId: 3, issueId: 80, providedFeedback: false },
      })
    }
  }
  const handleDrsDetails = () => {
    navigate('drs')
  }
  const [isHovered, setIsHovered] = useState(false)

  const handleMouseEnter = () => {
    setIsHovered(true)
  }

  const handleMouseLeave = () => {
    setIsHovered(false)
  }

  const handleDrsLeave = () => {
    navigate('drs-list')
  }

  const handleAssignedTasks = (type: any, taskCount: number) => {
    if (taskCount !== 0) {
      getAssignedTaskDrs({ type: type !== 'assigned-task' ? 1 : 0 })
      setTasksTableName(type)
      setAssignedTaskDialog({ dialog: true, type: type })
    }
  }

  const handleAssignedTasksClose = () => {
    setAssignedTaskDialog({ dialog: false, type: '' })
  }

  const handleAttendanceList = () => {
    navigate('attendance-list')
  }

  const handleHolidayList = () => {
    navigate('holiday-list')
  }

  const handleTodaysHighlights = (type: string) => {
    navigate('todays-highlights', { state: { type: type } })
  }

  const handleProductivityRequest = (type: string) => {
    navigate('/home/dashboard/productivity-metrics', { state: { type: 'productivitymatrix' } })
  }

  const handleServiceRequest = (type: string) => {
    switch (type) {
      case 'reimbursement':
        return navigate('service-request', {
          state: {
            tabName: 'Reimbursement Details',
            deptId: 4,
            issueId: 34,
            providedFeedback: false,
          },
        })
      case 'form16':
        return navigate('service-request', {
          state: { tabName: 'Form 16 Details', deptId: 4, issueId: 96, providedFeedback: false },
        })
      case 'feedback':
        return navigate('service-request', {
          state: { tabName: 'Feedback Details', deptId: 19, issueId: 98, providedFeedback: false },
        })
      case 'plan-for-the-day':
        return navigate('/home/workinfo')
      default:
        return
    }
  }

  function htmlToText(html: string) {
    const temporaryElement = document.createElement('div')
    temporaryElement.innerHTML = html
    return temporaryElement.textContent || temporaryElement.innerText || ''
  }

  React.useEffect(() => {
    getHomeDetails()
    getTodayTask()
  }, [])

  React.useEffect(() => {
    if (downloadForm16Success) {
      const link = document.getElementById('download')
      link?.click()
      toast.success('Form 16 downloaded successfully')
      downloadForm16Reset()
    }
  }, [downloadForm16Success])

  React.useEffect(() => {
    if (userDetails) setUserId(userDetails?.id)
  }, [userDetails])

  const welcomeMessage = {
    fontFamily: 'Montserrat-SemiBold',
    letterSpacing: '0px',
    color: '#000000',
    opacity: '0.5',
    fontSize: '10px',
  }
  const DashboardText = {
    fontFamily: 'Montserrat-SemiBold',
    letterSpacing: '0px',
    opacity: '1',
    fontWeight: 'bold',
    fontSize: '14px',
  }

  const IconStyles: React.CSSProperties = {
    margin: '0px 10px 0 15px',
    width: '19px',
    position: 'relative',
    top: '3px',
  }

  const cardStyles = {
    marginLeft: '18px',
    marginBottom: '18px',
    marginTop: '8px',
    width: '23%',
  }

  const CommonCardStyle: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    height: '170px',
  }

  const CardHeadingText: React.CSSProperties = {
    position: 'relative',
    bottom: '8px',
    fontFamily: 'Montserrat-SemiBold',
  }

  const CardMidText = {
    lineHeight: '1.8',
    fontSize: '11px',
    fontFamily: 'Montserrat-Medium',
    letterSpacing: '0px',
    color: '#00000080',
    opacity: '1',
  }

  const CardMidTextPfd = {
    lineHeight: '1.8',
    fontSize: '11px',
    fontFamily: 'Montserrat-Medium',
    letterSpacing: '0px',
    color: '#00000080',
    opacity: '1',
  }

  const CardIconStyle = {
    fontSize: 28,
    color: Primary,
    marginRight: '4px',
    position: 'relative',
    right: '4px',
  }

  const CardStyle = {
    padding: '16px 13px 16px 16px',
  }

  const spanStyle = {
    cursor: 'pointer',
  }

  const TodaysHighlightCard = (
    <React.Fragment>
      <div style={CommonCardStyle}>
        <CardContent sx={CardStyle}>
          <Typography gutterBottom>
            <HighlightIcon sx={CardIconStyle} />
            <span style={CardHeadingText}>Highlights</span>
            <span style={{ float: 'right' }}>
              <img
                src={OpenTabLogo}
                style={{ cursor: 'pointer' }}
                alt=''
                onClick={() => handleTodaysHighlights('')}
              />
            </span>
          </Typography>

          <Typography sx={CardMidText} color='text.secondary' gutterBottom>
            Anniversaries Count:
            <span> {homePageDetails?.Highlights?.anniversariesCount}</span>
          </Typography>

          <Typography sx={CardMidText} color='text.secondary' gutterBottom>
            New Joinees Count:
            <span> {homePageDetails?.Highlights?.newJoineesCount}</span>
          </Typography>

          <Typography sx={CardMidText} color='text.secondary' gutterBottom>
            Birthday Count:
            <span> {homePageDetails?.Highlights?.birthdaysCount}</span>
          </Typography>
        </CardContent>
        <div style={{ flexGrow: 1 }} />
      </div>
    </React.Fragment>
  )

  const ProductivityMatrix = (
    <React.Fragment>
      <div style={CommonCardStyle}>
        <CardContent sx={CardStyle}>
          <Typography gutterBottom>
            <HighlightIcon sx={CardIconStyle} />
            <span style={CardHeadingText}>Productivity Metrics</span>
            <span style={{ float: 'right' }}>
              <img
                src={OpenTabLogo}
                style={{ cursor: 'pointer' }}
                alt=''
                onClick={() => handleProductivityRequest('productivity-matrix')}
              />
            </span>
          </Typography>
          <div
            style={{
              backgroundColor: 'white',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <img
              src={ProductivityMetricsGraph}
              alt='Productivity Metrics'
              style={{ height: '90px', width: '180px', display: 'block', marginLeft: '5px' }} // Use display: block to prevent any bottom space
            />
          </div>
        </CardContent>
        <div style={{ flexGrow: 1 }} />
      </div>
    </React.Fragment>
  )

  const demoTaskDescriptions = [
    'This is a short description.',

    'An even longer description that should be truncated if it exceeds the specified limit. It contains lots of information and should be tested.',
  ]

  // Mocking the homePageDetails object for testing

  // Example usage in your component

  const FeedBackCard = (
    <React.Fragment>
      <div style={CommonCardStyle}>
        <CardContent sx={CardStyle}>
          <Typography gutterBottom>
            <FeedbackIcon sx={CardIconStyle} />
            <span style={CardHeadingText}>Feedback</span>
            <span style={{ float: 'right' }}>
              <img
                src={OpenTabLogo}
                style={{ cursor: 'pointer' }}
                alt=''
                onClick={(e) => handleServiceRequest('feedback')}
              />
            </span>
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box sx={{ width: '50%' }}>
              <Typography sx={CardMidText} color='text.secondary' gutterBottom>
                Feedback Raised: <span> {homePageDetails?.raisedFeedbackCount} </span>
              </Typography>
              <Typography sx={CardMidText} color='text.secondary' gutterBottom>
                Feedback Provided: <span> {homePageDetails?.countForProvidedFeedback} </span>
              </Typography>
            </Box>
          </Box>
          <Typography
            onClick={handleAttendanceList}
            className={`${classes.DRsHeading} ${isHovered ? 'hovered' : ''}`}
            color='text.secondary'
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            gutterBottom
          >
            {/* Attendance Details  */}
          </Typography>
        </CardContent>
        <div style={{ flexGrow: 1 }} />
      </div>
    </React.Fragment>
  )

  const PlanForTheDayCard = (
    <React.Fragment>
      <div style={CommonCardStyle}>
        <CardContent sx={CardStyle}>
          <Typography gutterBottom>
            <TextSnippetIcon sx={CardIconStyle} />
            <span style={CardHeadingText}>Plan For The Day</span>
            <span style={{ float: 'right' }}>
              <img
                src={OpenTabLogo}
                style={{ cursor: 'pointer' }}
                alt=''
                onClick={(e) => handleServiceRequest('plan-for-the-day')}
              />
            </span>
          </Typography>
          <Box sx={{ width: '95%', overflow: 'auto', height: '80%', marginTop: '-10px' }}>
            <Typography sx={CardMidTextPfd} color='text.secondary' gutterBottom>
              {demoTaskDescriptions?.length > 0 ? (
                <ul>
                  {demoTaskDescriptions.map((description: any, index: any) => {
                    return (
                      <li key={index} style={{ marginLeft: '-15px' }}>
                        <Typography variant='body2' sx={CardMidText}>
                          {description}
                        </Typography>
                      </li>
                    )
                  })}
                </ul>
              ) : (
                ''
              )}
            </Typography>
          </Box>

          <Typography
            onClick={handleAttendanceList}
            className={`${classes.DRsHeading} ${isHovered ? 'hovered' : ''}`}
            color='text.secondary'
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            gutterBottom
          >
            {/* Attendance Details  */}
          </Typography>
        </CardContent>
        <div style={{ flexGrow: 1 }} />
      </div>
    </React.Fragment>
  )
  const BasicInfoCard = (
    <React.Fragment>
      <div style={CommonCardStyle}>
        <CardContent sx={CardStyle}>
          <Typography gutterBottom>
            <LightbulbIcon sx={CardIconStyle} />
            <span style={CardHeadingText}> Personal Info </span>
            <span style={{ float: 'right' }}>
              <img
                src={OpenTabLogo}
                style={{ cursor: 'pointer' }}
                alt=''
                onClick={(e) => handleBasicInfo(homePageDetails?.basicInfo?.userId)}
              />
            </span>
          </Typography>
          <Typography sx={CardMidText} color='text.secondary' gutterBottom>
            Name:
            <span>{` ${homePageDetails?.basicInfo?.name}`} </span>
          </Typography>

          <Typography sx={CardMidText} color='text.secondary' gutterBottom>
            Designation:
            <span> {homePageDetails?.basicInfo?.designation} </span>
          </Typography>

          <Typography sx={CardMidText} color='text.secondary' gutterBottom>
            Employee Id:
            <span> {homePageDetails?.basicInfo?.employeeId} </span>
          </Typography>

          <Typography sx={CardMidText} color='text.secondary' gutterBottom>
            Manager:
            <span> {homePageDetails?.basicInfo?.managerName} </span>
          </Typography>
          <Typography
            onClick={handleAttendanceList}
            className={`${classes.DRsHeading} ${isHovered ? 'hovered' : ''}`}
            color='text.secondary'
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            gutterBottom
          >
            {/* Attendance Details  */}
          </Typography>
        </CardContent>
        <div style={{ flexGrow: 1 }} />
      </div>
    </React.Fragment>
  )

  const DrsCard = (
    <React.Fragment>
      <div style={CommonCardStyle}>
        <CardContent sx={CardStyle}>
          <Typography gutterBottom>
            <ContactsIcon sx={CardIconStyle} />
            <span style={CardHeadingText}> DRs </span>
            <span style={{ float: 'right' }}>
              <img
                src={OpenTabLogo}
                style={{ cursor: 'pointer' }}
                alt=''
                onClick={handleDrsDetails}
              />
            </span>
          </Typography>

          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box sx={{ width: '50%' }}>
              <Typography sx={CardMidText} color='text.secondary' gutterBottom>
                <span style={{ color: 'red' }}> Free: </span>{' '}
                <span style={{ color: 'red' }}>{homePageDetails?.DRS?.free} </span>
              </Typography>
            </Box>
            <Box sx={{ width: '50%' }}>
              <Typography sx={CardMidText} color='text.secondary' gutterBottom>
                <span style={{ color: 'red' }}> Additional: </span>{' '}
                <span style={{ color: 'red' }}>{homePageDetails?.DRS?.additional} </span>
              </Typography>
            </Box>
          </Box>

          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box sx={{ width: '50%' }}>
              <Typography sx={CardMidText} color='text.secondary' gutterBottom>
                Billable: {homePageDetails?.DRS?.billable}
              </Typography>
            </Box>
            <Box sx={{ width: '50%' }}>
              <Typography sx={CardMidText} color='text.secondary' gutterBottom>
                Unbillable: {homePageDetails?.DRS?.nonBillable}
              </Typography>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box sx={{ width: '50%' }}>
              <Typography sx={CardMidText} color='text.secondary' gutterBottom>
                Total: {homePageDetails?.DRS?.count}
              </Typography>
            </Box>
          </Box>

          <Typography
            onClick={handleDrsLeave}
            className={`${classes.DRsHeading} ${isHovered ? 'hovered' : ''}`}
            color='text.secondary'
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            gutterBottom
          >
            DRs Leave Details
          </Typography>
        </CardContent>
        <div style={{ flexGrow: 1 }} />
      </div>
    </React.Fragment>
  )

  const LeaveDetailsCard = (
    <React.Fragment>
      <div style={CommonCardStyle}>
        <CardContent sx={CardStyle}>
          <Typography gutterBottom>
            <DetailsIcon sx={CardIconStyle} />
            <span style={CardHeadingText}> Leave Details </span>
            <span style={{ float: 'right' }}>
              <img
                src={OpenTabLogo}
                style={{ cursor: 'pointer' }}
                alt=''
                onClick={() => {
                  handleLeaveDetails(homePageDetails?.basicInfo?.userId)
                }}
              />
            </span>
          </Typography>

          <Typography sx={CardMidText} color='text.secondary' gutterBottom>
            Total Leaves Allocated:
            <span> {homePageDetails?.leaveDetails?.totalLeaveAllocated} </span>
          </Typography>

          <Typography sx={CardMidText} color='text.secondary' gutterBottom>
            Leaves Availed:
            <span> {homePageDetails?.leaveDetails?.leavesAvailed}</span>
          </Typography>

          <Typography sx={CardMidText} color='text.secondary' gutterBottom>
            Leave Balance:
            <span> {homePageDetails?.leaveDetails?.leaveBalance}</span>
          </Typography>
        </CardContent>
        <div style={{ flexGrow: 1 }} />
        <CardActions></CardActions>
      </div>
    </React.Fragment>
  )

  const HolidayListCard = (
    <React.Fragment>
      <div style={CommonCardStyle}>
        <CardContent sx={CardStyle}>
          <Typography gutterBottom>
            <HolidayVillageIcon sx={CardIconStyle} />
            <span style={CardHeadingText}> Holidays </span>
            <span style={{ float: 'right' }}>
              <img
                src={OpenTabLogo}
                style={{ cursor: 'pointer' }}
                alt=''
                onClick={handleHolidayList}
              />
            </span>
          </Typography>

          <Typography sx={CardMidText} color='text.secondary' gutterBottom>
            Upcoming Holidays:<span> {homePageDetails?.holidayList?.upcomingHoliday} </span>
          </Typography>

          <Typography sx={CardMidText} color='text.secondary' gutterBottom>
            Total Holidays:<span> {homePageDetails?.holidayList?.totalHolidays} </span>
          </Typography>
        </CardContent>
        <div style={{ flexGrow: 1 }} />
        <CardActions></CardActions>
      </div>
    </React.Fragment>
  )

  const ProjectDetailsCard = (
    <React.Fragment>
      <div style={CommonCardStyle}>
        <CardContent sx={CardStyle}>
          <Typography gutterBottom>
            <AccountTreeIcon sx={CardIconStyle} />
            <span style={CardHeadingText}> Projects</span>
            {/* <span style={{ float: 'right' }}>
              <img src={OpenTabLogo} style={{ cursor: 'pointer' }} alt='' onClick={handleProjectDetails} />
            </span> */}
          </Typography>

          <Typography
            className={classes.projectCard}
            sx={CardMidText}
            color='text.secondary'
            gutterBottom
          >
            {homePageDetails?.projectDetails?.projectInfo.map((data: any, index: number) => (
              <span key={data[index]}>
                {data.includes('Billable') ? (
                  <>
                    {data.replace('Billable', '')}
                    <Tooltip title='Billable' arrow>
                      <AttachMoneyIcon className={classes.iconBillable} />
                    </Tooltip>
                  </>
                ) : data.includes('Additional') ? (
                  <>
                    {data.replace('Additional', '')}
                    <Tooltip title='Additional' arrow>
                      <AddCircleIcon className={classes.iconAdditional} />
                    </Tooltip>
                  </>
                ) : (
                  data
                )}
                {index !== homePageDetails?.projectDetails?.projectInfo.length - 1 && (
                  <>
                    <br />
                  </>
                )}
              </span>
            ))}
          </Typography>

          <Typography
            className={classes.projectCard}
            sx={CardMidText}
            color='text.secondary'
            gutterBottom
          >
            Status: {homePageDetails?.projectDetails?.resourseStatus}
          </Typography>
        </CardContent>
        <div style={{ flexGrow: 1 }} />
      </div>
    </React.Fragment>
  )

  const TodaysTaskDetailsCard = (
    <React.Fragment>
      <div style={CommonCardStyle}>
        <CardContent sx={CardStyle}>
          <Typography gutterBottom>
            <FormatListBulletedIcon sx={CardIconStyle} />
            <span style={CardHeadingText}> Today's Plan</span>
          </Typography>
          <>
            <Box
              sx={{ overflowX: 'auto', maxHeight: '70px', paddingLeft: '5px', marginBottom: '8px' }}
            >
              {TodaysTasksDetails?.data?.length ? (
                <ol style={{ margin: 0, padding: '10px' }}>
                  {TodaysTasksDetails?.data?.map((data: any, index: number) => (
                    <Typography
                      className={classes.projectCard}
                      sx={CardMidText}
                      color='text.secondary'
                      gutterBottom
                    >
                      <li key={index} style={CardMidText}>
                        <Tooltip
                          title={data.taskDescription ? htmlToText(data.taskDescription) : 'NA'}
                          arrow
                        >
                          <span style={CardMidText}>
                            {data.taskDescription
                              ? htmlToText(data.taskDescription).length > 35
                                ? `${htmlToText(data.taskDescription).substring(0, 35)}...`
                                : htmlToText(data.taskDescription)
                              : 'NA'}
                          </span>
                        </Tooltip>
                      </li>
                    </Typography>
                  ))}
                </ol>
              ) : (
                <Typography
                  className={classes.projectCard}
                  sx={CardMidText}
                  color='text.secondary'
                  gutterBottom
                >
                  No plan found for today.
                </Typography>
              )}
            </Box>

            <Box
              sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                paddingBottom: '15px',
              }}
            >
              <Typography
                onClick={() => handleAssignedTasks('assigned-task', homePageDetails?.assignedTask)}
                className={`${classes.taskHeading} ${isHovered ? 'hovered' : ''}`}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                gutterBottom
                sx={{ opacity: homePageDetails?.assignedTask ? 1 : 0.5 }}
              >
                Assigned Tasks: {homePageDetails?.assignedTask ? homePageDetails?.assignedTask : 0}
              </Typography>

              <Typography
                onClick={() => handleAssignedTasks('followup-task', homePageDetails?.followUpTask)}
                className={`${classes.taskFollowUpHeading} ${isHovered ? 'hovered' : ''}`}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                gutterBottom
                sx={{ opacity: homePageDetails?.followUpTask ? 1 : 0.5 }}
              >
                Follow-up Tasks: {homePageDetails?.followUpTask ? homePageDetails?.followUpTask : 0}
              </Typography>
            </Box>
          </>
        </CardContent>
        <div style={{ flexGrow: 1 }} />
      </div>
    </React.Fragment>
  )

  const ReimbursementCard = (
    <React.Fragment>
      <div style={CommonCardStyle}>
        <CardContent sx={CardStyle}>
          <Typography gutterBottom>
            <CurrencyExchangeIcon sx={CardIconStyle} />
            <span style={CardHeadingText}> Reimbursement </span>
            <span style={{ float: 'right' }}>
              <img
                src={OpenTabLogo}
                style={{ cursor: 'pointer' }}
                alt=''
                onClick={() => handleServiceRequest('reimbursement')}
              />
            </span>
          </Typography>

          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box sx={{ width: '50%' }}>
              <Typography sx={CardMidText} color='text.secondary' gutterBottom>
                Open:{' '}
                <span> {homePageDetails?.serviceRequest?.reimbursement?.pendingRequest} </span>
              </Typography>
            </Box>
            <Box sx={{ width: '50%' }}>
              <Typography sx={CardMidText} color='text.secondary' gutterBottom>
                Closed:{' '}
                <span> {homePageDetails?.serviceRequest?.reimbursement?.closedRequest} </span>
              </Typography>
            </Box>
          </Box>

          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box sx={{ width: '50%' }}>
              <Typography sx={CardMidText} color='text.secondary' gutterBottom>
                In Progress:{' '}
                <span> {homePageDetails?.serviceRequest?.reimbursement?.inProgressRequest} </span>
              </Typography>
            </Box>
            <Box sx={{ width: '50%' }}>
              <Typography sx={CardMidText} color='text.secondary' gutterBottom>
                Reopen:{' '}
                <span> {homePageDetails?.serviceRequest?.reimbursement?.reOpenRequest} </span>
              </Typography>
            </Box>
          </Box>

          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box sx={{ width: '50%' }}>
              <Typography sx={CardMidText} color='text.secondary' gutterBottom>
                Won't Fixed:{' '}
                <span> {homePageDetails?.serviceRequest?.reimbursement?.wontFixedRequest} </span>
              </Typography>
            </Box>
            <Box sx={{ width: '50%' }}>
              <Typography sx={CardMidText} color='text.secondary' gutterBottom>
                Total: <span> {homePageDetails?.serviceRequest?.reimbursement?.totalRequest} </span>
              </Typography>
            </Box>
          </Box>
        </CardContent>
        <div style={{ flexGrow: 1 }} />
      </div>
    </React.Fragment>
  )

  const HeadingBar = {
    padding: '10px 0px',
    display: 'flex',
    background: '#F7F7F7 0% 0% no-repeat padding-box',
    boxShadow: '0px 2px 3px #00000029',
    opacity: 1,
  }

  return (
    <>
      {loaderState ? (
        <Dialog open={loaderState} PaperProps={{ style: loaderProps }}>
          <CircularProgress color='secondary' />
        </Dialog>
      ) : (
        <div style={{ width: '100%' }}>
          <Paper elevation={3} style={HeadingBar}>
            <span style={DashboardText}>
              {' '}
              <img style={IconStyles} src={DashboardIcon} />
              My Dashboard
            </span>
          </Paper>

          <div style={{ padding: '12px' }}>
            <Typography style={welcomeMessage} variant='body1'></Typography>
            <Paper
              elevation={3}
              style={{
                width: '100%',
                height: '100%',
                padding: '10px 0px 0px 0px',
                background: '#FFFFFF 0% 0% no-repeat padding-box',
                boxShadow: '0px 3px 6px #00000029',
                opacity: '1',
                marginTop: '10px',
                overflow: 'auto',
              }}
            >
              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                <Card sx={cardStyles} variant='outlined'>
                  {TodaysHighlightCard}
                </Card>

                <Card sx={cardStyles} variant='outlined'>
                  {ProjectDetailsCard}
                </Card>
                <Card sx={cardStyles} variant='outlined'>
                  {TodaysTaskDetailsCard}
                </Card>
                {/* <Card sx={cardStyles} variant='outlined'>
                  {SalarySlipCard}
                </Card> */}
                {homePageDetails?.DRS?.count > 0 ? (
                  <Card sx={cardStyles} variant='outlined'>
                    {DrsCard}
                  </Card>
                ) : null}
                <Card sx={cardStyles} variant='outlined'>
                  {ReimbursementCard}
                </Card>
                <Card sx={cardStyles} variant='outlined'>
                  {HolidayListCard}
                </Card>
                <Card sx={cardStyles} variant='outlined'>
                  {LeaveDetailsCard}
                </Card>
                <Card sx={cardStyles} variant='outlined'>
                  {BasicInfoCard}
                </Card>
                <Card sx={cardStyles} variant='outlined'>
                  {FeedBackCard}
                </Card>
                {/* <Card sx={cardStyles} variant='outlined'>
                  {PlanForTheDayCard}
                </Card> */}

                {userRole?.includes('Admin') && (
                  <Card sx={cardStyles} variant='outlined'>
                    {ProductivityMatrix}
                  </Card>
                )}
                {/* {userRole?.includes('Admin') && (
                  <Card sx={cardStyles} variant='outlined'>
                    <WorkingPercentage />
                  </Card>
                )} */}
              </div>
            </Paper>
          </div>
        </div>
      )}
      <Dialog
        open={assignedTaskDialog?.dialog}
        onClose={handleAssignedTasksClose}
        fullWidth
        maxWidth={false}
        PaperProps={{
          style: {
            width: '80%',
            maxWidth: '80%',
          },
        }}
      >
        <DialogTitle sx={{ display: 'flex', justifyContent: 'center', padding: '10px' }}>
          {taskTableName === 'followup-task' ? 'Follow-Up Tasks' : 'Assigned Tasks'}
          <IconButton
            aria-label='close'
            onClick={handleAssignedTasksClose}
            sx={{
              position: 'absolute !important',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Divider className='divider' />
        <DialogContent sx={{ padding: '10px 15px !important' }}>
          <>
            <TableContainer sx={{ margin: '1rem 0' }} component={Paper}>
              <Table aria-label='customized table'>
                <TableHead>
                  {taskTableName !== 'followup-task' ? (
                    <TableRow>
                      <StyledTableCell sx={{ padding: '5px 5px 5px 16px' }}>
                        PROJECT NAME
                      </StyledTableCell>
                      <StyledTableCell sx={{ padding: '5px 5px 5px 16px', minWidth: '100px' }}>
                        TASK DESCRIPTION
                      </StyledTableCell>
                      <StyledTableCell sx={{ padding: '5px 5px 5px 16px' }}>
                        COLLABORATE WITH
                      </StyledTableCell>
                      <StyledTableCell sx={{ padding: '5px 5px 5px 16px' }}>DATE</StyledTableCell>
                    </TableRow>
                  ) : (
                    <TableRow>
                      <StyledTableCell sx={{ padding: '5px 5px 5px 16px' }}>
                        PROJECT NAME
                      </StyledTableCell>
                      <StyledTableCell sx={{ padding: '5px 5px 5px 16px', minWidth: '100px' }}>
                        TASK DESCRIPTION
                      </StyledTableCell>
                      <StyledTableCell sx={{ padding: '5px 5px 5px 16px' }}>
                        FOLLOW UP DATE
                      </StyledTableCell>
                    </TableRow>
                  )}
                </TableHead>

                {assignedTaskDrs?.length ? (
                  <TableBody>
                    {assignedTaskDrs.map((data: any, index: number) => (
                      <TableRow key={index} sx={{ cursor: 'pointer' }}>
                        <StyledTableCellForMyTeam align='left' sx={{ minWidth: '60px' }}>
                          {data.projectName ? data.projectName : data.otherProjectName ?? 'NA'}
                        </StyledTableCellForMyTeam>
                        <StyledTableCellForMyTeam align='left' sx={{ minWidth: '120px' }}>
                          {htmlToText(data.taskDescription)}
                        </StyledTableCellForMyTeam>
                        {taskTableName !== 'followup-task' ? (
                          <>
                            <StyledTableCellForMyTeam align='left' sx={{ minWidth: '60px' }}>
                              {data.name ? data.name : 'NA'}
                            </StyledTableCellForMyTeam>
                            <StyledTableCellForMyTeam align='left' sx={{ minWidth: '60px' }}>
                              {data.submission_time
                                ? moment(data.submissionTime).format('MM-DD-YYYY')
                                : 'NA'}
                            </StyledTableCellForMyTeam>
                          </>
                        ) : (
                          <>
                            <StyledTableCellForMyTeam align='left' sx={{ minWidth: '60px' }}>
                              {data.submission_time
                                ? moment(data.submissionTime).format('MM-DD-YYYY')
                                : 'NA'}
                            </StyledTableCellForMyTeam>
                          </>
                        )}
                      </TableRow>
                    ))}
                  </TableBody>
                ) : (
                  <TableBody>
                    <StyledTableRow>
                      <StyledTableCellForMyTeam align='center' colSpan={10}>
                        <Typography
                          variant='subtitle1'
                          sx={{ color: '#483f3f', textAlign: 'center' }}
                        >
                          No matching records found.
                        </Typography>
                      </StyledTableCellForMyTeam>
                    </StyledTableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </>
        </DialogContent>
      </Dialog>
    </>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    markAsResolvedData: dashboardEntity.getDashboard(state).getMarkAsResolve,
    homePageDetails: dashboardEntity.getDashboard(state).getHomePageDetails,
    loaderState: dashboardUI.getDashboard(state).isHomePageData,
    isDownloadedForm16Loader: financeUI.getFinance(state).isDownloadedForm16Loader,
    downloadForm16LinkData: financeEntity.getFinance(state).getDownloadForm16Link,
    downloadForm16Success: financeUI.getFinance(state).isDownloadedForm16,
    isDownloadedFormSucesss: financeUI.getFinance(state).isDownloadedFormSucesss,
    userDetails: dashboardEntity.getDashboard(state).getUserDetails,
    assignedTaskDrs: dashboardEntity.getDashboard(state).getAssignedTaskDrs,
    TodaysTasksDetails: dashboardEntity.getDashboard(state).getTodayTask,
  }
}

const mapDispatchToProps = (dispatch: (arg: { type: string }) => void) => {
  return {
    getHomeDetails: () => {
      dispatch(getHomePageDetails.request())
    },
    getAssignedTaskDrs: (data: any) => {
      dispatch(getAssignedTaskDrs.request(data))
    },
    getTodayTask: () => {
      dispatch(getTodayTask.request())
    },
    downloadForm16: (data: any) => {
      dispatch(downloadForm16.request(data))
    },
    downloadForm16Reset: () => {
      dispatch(downloadForm16.reset())
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MasterSetUp)
