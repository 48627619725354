

const RcaFormat = () => {
  return (
    <>
      <div>
        <b>What happened?</b>
        <div>
          <b>What was the severity of the issue?</b>
        </div>
        <div>
          <b>Who all were affected?</b>
        </div>
        <div>
          <b>Who was responsible along with the degree of responsibility and the reason?</b>
          <ul>
            <li>Who?</li>
            <li>Responsibility Level (in percentage)</li>
            <li>Why do you think this is the level of responsibility?</li>
          </ul>
        </div>
        <div><b>What would you do differently to ensure this does not happen in the future?</b></div>
      </div>
    </>
  )
}
export default RcaFormat
