import { TableCell, TableRow, Typography, styled, tableCellClasses } from '@mui/material'
import { ReactComponent as NoItemFoundIcon } from '../../../assets/images/NoItemIcon.svg'
import { ProjectQARportPropType } from './projectQAReportTypes'
import { style } from './ projectQAReportStyles'

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  fontFamily: 'Montserrat-Regular',
  [`& .${tableCellClasses.root}`]: {
    border: 'none',
    padding: '0',
  },
}))

const StyledTypography = styled(Typography)(({ theme }) => ({
  fontSize: '0.9rem',
  padding: '13px 0',
  borderTop: '1px solid #EEEEEE',
  borderBottom: '1px solid #EEEEEE',
  margin: '16px 0 0',
  minHeight: '20px',
  textAlign: 'center',
}))

const NoQAProjectRow = ({ selectType }: ProjectQARportPropType) => {
  return (
    <>
      <StyledTableRow id='body'>
        <TableCell colSpan={5}>
          <StyledTypography sx={style.noQAProjectRowTypoStyle}>
            <NoItemFoundIcon />
            <Typography sx={style.noQAProjectRowTypographyStyle}>No Project Reports</Typography>
          </StyledTypography>
        </TableCell>
      </StyledTableRow>
    </>
  )
}

export default NoQAProjectRow