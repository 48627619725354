import { TableHead, TableRow, styled } from '@mui/material'
import { style } from './ProjectManagementReportStyle'
import data from './ProjectManagementReportData.json'
import { StyledTableCell } from '../../Common/CommonStyles'

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  left: '160px',
  width: '1719px',
  height: '60px',
  boxShadow: '0px 10px 3px #6c6c6c10',
  opacity: '1',
  // cursor: 'pointer',
}))

const TableHeadColumns = (props: any) => {
  const {tabView}=props
  const { getColumnData } = data
  return (
    <TableHead id='head'>
      <StyledTableRow sx={style.border}>
        {getColumnData.map((column) => (
          <StyledTableCell key={column.id} sx={style.cellWidth}>
           {tabView === 'free' && column.label === 'Project Details'
              ? 'Time Count'
              : column.label}
          </StyledTableCell>
        ))}
      </StyledTableRow>
    </TableHead>
  )
}

export default TableHeadColumns
