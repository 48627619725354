import html2pdf from 'html2pdf.js'
import { toast } from 'react-toastify'

export const exportAsPDF = () => {
    const heading = document.getElementsByClassName('heading')
    const cards = document.getElementById('card')
    const tile = document.getElementsByClassName('tile')
    const head = document.getElementById('head')
    const body = document.querySelectorAll('#body')

    if (body.length>0 && head && heading) {
      const parent = document.createElement('div')
      for (let i = 0; i < 2; i++) {
        parent.appendChild(heading[i].cloneNode(true))
      }

      if (cards) {
        const cardPar = cards.cloneNode(true) as HTMLDivElement
        cardPar.style.width = '1280px'
        parent.appendChild(cardPar)
      }

      if (tile.length > 0) {
        parent.appendChild(tile[0].cloneNode(true))
      }

      const gapDiv = document.createElement('div')
      gapDiv.style.height = '1rem'
      parent.appendChild(gapDiv)

      const newHead = head.cloneNode(true) as HTMLDivElement
      parent.appendChild(newHead)

      const bodyArray = Array.from(body)

      for (let element of bodyArray) {
          parent.appendChild(element.cloneNode(true))
      }

      const options = {
        margin: 10,
        filename: `${(heading[1].cloneNode(true) as HTMLElement).innerText}.pdf`,
        html2canvas: { dpi: 360, width: 900, scale:2 },
        jsPDF: { unit: 'pt', format: 'a4', orientation: 'landscape' },
      }

      html2pdf().set(options).from(parent).save()
    }else{
      toast.error("No data to export")
    }
  }