import { call, takeLatest } from 'redux-saga/effects'
import { actionTypes } from '../actions'
import * as ApiService from '../services'
import { sendPayload, sendPayloadFailure } from './helper'
import { FETCH_LOAN_DETAILS, FETCH_LOAN_INSTALLMENT_DATA } from '../actions/actiontypes'
const { REQUEST, SEND_LOAN_REQUEST } = actionTypes

//write sagas function

function* handleSendLoanRequestData(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.sendLoanRequest,
      data.data,
    )
    yield sendPayload(apiResponse, SEND_LOAN_REQUEST)
  } catch (e) {
    yield sendPayloadFailure(e, SEND_LOAN_REQUEST)
  }
}

function* getLoanInstallment(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.getLoanInstallmentHistoryEndPoint,
      data.data,
    )
    yield sendPayload(apiResponse, FETCH_LOAN_INSTALLMENT_DATA)
  } catch (e) {
    yield sendPayloadFailure(e, FETCH_LOAN_INSTALLMENT_DATA)
  }
}

function* getLoanDetails() {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.getLoanDetailsEndPoint,
    )
    yield sendPayload(apiResponse, FETCH_LOAN_DETAILS)
  } catch (e) {
    yield sendPayloadFailure(e, FETCH_LOAN_DETAILS)
  }
}

export const sagas = {
  //watcher come here
  watchLoanRequest: takeLatest(actionTypes.SEND_LOAN_REQUEST[REQUEST], handleSendLoanRequestData),
  watchLoanDetails: takeLatest(actionTypes.FETCH_LOAN_DETAILS[REQUEST], getLoanDetails),
  watchLoanInstallmentHistoryDetails: takeLatest(
    actionTypes.FETCH_LOAN_INSTALLMENT_DATA[REQUEST],
    getLoanInstallment,
  ),
}
