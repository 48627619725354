import { combineReducers } from 'redux'
import { actionTypes } from '../../actions'
import { Actions } from '../../actions/Types'
import { RootState } from '../../configureStore'
import { DELETE_USER, FETCH_ALL_CLIENT_LOCATION, FETCH_ALL_COUNTRY, FETCH_ALL_FLOOR, FETCH_ALL_LOCATION, FETCH_ALL_STATE, FETCH_ALL_USER_LIST, FETCH_ALL_WORKSTATION, GET_USER_IMAGE, RESET, UPDATE_USER, UPLOAD_USER_IMG } from '../../actions/actiontypes'
import { toast } from 'react-toastify'

const { SUCCESS, REQUEST, FAILURE, CREATE_USER, FETCH_ALL_ROLE } = actionTypes

const ui = () => {
  const isUserDataFetched = (state = false, action: Actions) => {
    switch (action.type) {
      case FETCH_ALL_USER_LIST[SUCCESS]:
        return false
      case FETCH_ALL_USER_LIST[FAILURE]:
        return false
      case FETCH_ALL_USER_LIST[REQUEST]:
        return true
      case FETCH_ALL_USER_LIST[RESET]:
        return false
      default:
        return state
    }
  }

  const isUserCreatedSuccess = (state = false, action: Actions) => {
    switch (action.type) {
      case CREATE_USER[SUCCESS]:
        return true
      case CREATE_USER[FAILURE]:
        return false
      case CREATE_USER[REQUEST]:
        return false
      case CREATE_USER[RESET]:
        return false
      default:
        return state
    }
  }

  const isUserCreating = (state = false, action: Actions) => {
    switch (action.type) {
      case CREATE_USER[SUCCESS]:
        return false
      case CREATE_USER[FAILURE]:
        return false
      case CREATE_USER[REQUEST]:
        return true
      case CREATE_USER[RESET]:
        return false
      default:
        return state
    }
  }

  const isUserCreateFailed = (state = false, action: Actions) => {
    switch (action.type) {
      case CREATE_USER[SUCCESS]:
      case CREATE_USER[REQUEST]:
        return false
      case CREATE_USER[FAILURE]:
        return true
      case CREATE_USER[RESET]:
        return false
      default:
        return state
    }
  }

  const isAllRoleFetched = (state = false, action: Actions) => {
    switch (action.type) {
      case FETCH_ALL_ROLE[SUCCESS]:
        return true
      case FETCH_ALL_ROLE[FAILURE]:
      case FETCH_ALL_ROLE[REQUEST]:
        return false
      case FETCH_ALL_ROLE[RESET]:
        return false
      default:
        return state
    }
  }

  const isAllRoleFetchedFailed = (state = false, action: Actions) => {
    switch (action.type) {
      case FETCH_ALL_ROLE[SUCCESS]:
      case FETCH_ALL_ROLE[REQUEST]:
        return false
      case FETCH_ALL_ROLE[FAILURE]:
        return true
      case FETCH_ALL_ROLE[RESET]:
        return false
      default:
        return state
    }
  }

  const isAllStateFetched = (state = false, action: Actions) => {
    switch (action.type) {
      case FETCH_ALL_STATE[SUCCESS]:
        return true
      case FETCH_ALL_STATE[FAILURE]:
      case FETCH_ALL_STATE[REQUEST]:
        return false
      case FETCH_ALL_STATE[RESET]:
        return false
      default:
        return state
    }
  }

  const isAllStateFetchedFailed = (state = false, action: Actions) => {
    switch (action.type) {
      case FETCH_ALL_STATE[SUCCESS]:
      case FETCH_ALL_STATE[REQUEST]:
        return false
      case FETCH_ALL_STATE[FAILURE]:
        return true
      case FETCH_ALL_STATE[RESET]:
        return false
      default:
        return state
    }
  }

  const isAllUserListFetched = (state = false, action: Actions) => {
    switch (action.type) {
      case FETCH_ALL_USER_LIST[SUCCESS]:
        return true
      case FETCH_ALL_USER_LIST[FAILURE]:
      case FETCH_ALL_USER_LIST[REQUEST]:
        return false
      case FETCH_ALL_USER_LIST[RESET]:
        return false
      default:
        return state
    }
  }

  const isAllUserListFetchedFailed = (state = false, action: Actions) => {
    switch (action.type) {
      case FETCH_ALL_USER_LIST[SUCCESS]:
      case FETCH_ALL_USER_LIST[REQUEST]:
        return false
      case FETCH_ALL_USER_LIST[FAILURE]:
        return true
      case FETCH_ALL_USER_LIST[RESET]:
        return false
      default:
        return state
    }
  }

  const isAllCountryFetched = (state = false, action: Actions) => {
    switch (action.type) {
      case FETCH_ALL_COUNTRY[SUCCESS]:
        return true
      case FETCH_ALL_COUNTRY[FAILURE]:
      case FETCH_ALL_COUNTRY[REQUEST]:
        return false
      case FETCH_ALL_COUNTRY[RESET]:
        return false
      default:
        return state
    }
  }

  const isAllCountryFetchedFailed = (state = false, action: Actions) => {
    switch (action.type) {
      case FETCH_ALL_COUNTRY[SUCCESS]:
      case FETCH_ALL_COUNTRY[REQUEST]:
        return false
      case FETCH_ALL_COUNTRY[FAILURE]:
        return true
      case FETCH_ALL_COUNTRY[RESET]:
        return false
      default:
        return state
    }
  }

  const isAllFloorFetched = (state = false, action: Actions) => {
    switch (action.type) {
      case FETCH_ALL_FLOOR[SUCCESS]:
        return true
      case FETCH_ALL_FLOOR[FAILURE]:
      case FETCH_ALL_FLOOR[REQUEST]:
        return false
      case FETCH_ALL_FLOOR[RESET]:
        return false
      default:
        return state
    }
  }

  const isAllFloorFetchedFailed = (state = false, action: Actions) => {
    switch (action.type) {
      case FETCH_ALL_FLOOR[SUCCESS]:
      case FETCH_ALL_FLOOR[REQUEST]:
        return false
      case FETCH_ALL_FLOOR[FAILURE]:
        return true
      case FETCH_ALL_FLOOR[RESET]:
        return false
      default:
        return state
    }
  }

  const isAllWorkstationFetched = (state = false, action: Actions) => {
    switch (action.type) {
      case FETCH_ALL_WORKSTATION[SUCCESS]:
        return true
      case FETCH_ALL_WORKSTATION[FAILURE]:
      case FETCH_ALL_WORKSTATION[REQUEST]:
        return false
      case FETCH_ALL_WORKSTATION[RESET]:
        return false
      default:
        return state
    }
  }

  const isAllWorkstationFetchedFailed = (state = false, action: Actions) => {
    switch (action.type) {
      case FETCH_ALL_WORKSTATION[SUCCESS]:
      case FETCH_ALL_WORKSTATION[REQUEST]:
        return false
      case FETCH_ALL_WORKSTATION[FAILURE]:
        return true
      case FETCH_ALL_WORKSTATION[RESET]:
        return false
      default:
        return state
    }
  }

  const isAllLocationFetched = (state = false, action: Actions) => {
    switch (action.type) {
      case FETCH_ALL_LOCATION[SUCCESS]:
        return true
      case FETCH_ALL_LOCATION[FAILURE]:
      case FETCH_ALL_LOCATION[REQUEST]:
        return false
      case FETCH_ALL_LOCATION[RESET]:
        return false
      default:
        return state
    }
  }

  const isAllLocationFetchedFailed = (state = false, action: Actions) => {
    switch (action.type) {
      case FETCH_ALL_LOCATION[SUCCESS]:
      case FETCH_ALL_LOCATION[REQUEST]:
        return false
      case FETCH_ALL_LOCATION[FAILURE]:
        return true
      case FETCH_ALL_LOCATION[RESET]:
        return false
      default:
        return state
    }
  }

  const isAllClientLocationFetched = (state = false, action: Actions) => {
    switch (action.type) {
      case FETCH_ALL_CLIENT_LOCATION[SUCCESS]:
        return true
      case FETCH_ALL_CLIENT_LOCATION[FAILURE]:
      case FETCH_ALL_CLIENT_LOCATION[REQUEST]:
        return false
      case FETCH_ALL_CLIENT_LOCATION[RESET]:
        return false
      default:
        return state
    }
  }

  const isAllClientLocationFetchedFailed = (state = false, action: Actions) => {
    switch (action.type) {
      case FETCH_ALL_CLIENT_LOCATION[SUCCESS]:
      case FETCH_ALL_CLIENT_LOCATION[REQUEST]:
        return false
      case FETCH_ALL_CLIENT_LOCATION[FAILURE]:
        return true
      case FETCH_ALL_CLIENT_LOCATION[RESET]:
        return false
      default:
        return state
    }
  }


  const isUserUpdated = (state = false, action:Actions) =>{
    switch (action.type) {
      case UPDATE_USER[SUCCESS]:
        return true
      case UPDATE_USER[FAILURE]:
        return false
      case UPDATE_USER[RESET]:
        return false
      case UPDATE_USER[REQUEST]:
        return false
      default:
        return state
    }
  }

  const isUserUpdating = (state = false, action:Actions) =>{
    switch (action.type) {
      case UPDATE_USER[SUCCESS]:
        return false
      case UPDATE_USER[FAILURE]:
        return false
      case UPDATE_USER[RESET]:
        return false
      case UPDATE_USER[REQUEST]:
        return true
      default:
        return state
    }
  }

  const isUserUpdateFailed = (state = false, action:Actions) =>{
    switch (action.type) {
      case UPDATE_USER[SUCCESS]:
        return false
      case UPDATE_USER[FAILURE]:
        return true
      case UPDATE_USER[RESET]:
        return false
      case UPDATE_USER[REQUEST]:
        return false
      default:
        return state
    }
  }

  const isImgUploaded = (state = false, action:Actions) =>{
    switch (action.type) {
      case UPLOAD_USER_IMG[SUCCESS]:
        return true
      case UPLOAD_USER_IMG[FAILURE]:
        return false
      case UPLOAD_USER_IMG[RESET]:
        return false
      case UPLOAD_USER_IMG[REQUEST]:
        return false
      default:
        return state
    }
  }

  const getUserImageSuccess = (state = false, action:Actions) =>{
    switch (action.type) {
      case GET_USER_IMAGE[SUCCESS]:
        return true
      case GET_USER_IMAGE[FAILURE]:
        return false
      case GET_USER_IMAGE[RESET]:
        return false
      case GET_USER_IMAGE[REQUEST]:
        return false
      default:
        return state
    }
  }

  const isUserDeleteSuccess = (state = false, action:Actions) =>{
    switch (action.type) {
      case DELETE_USER[SUCCESS]:
        return true
      case DELETE_USER[FAILURE]:
        return false
      case DELETE_USER[RESET]:
        return false
      case DELETE_USER[REQUEST]:
        return false
      default:
        return state
    }
  }

  const isUserDeleting = (state = false, action:Actions) =>{
    switch (action.type) {
      case DELETE_USER[SUCCESS]:
        return false
      case DELETE_USER[FAILURE]:
        return false
      case DELETE_USER[RESET]:
        return false
      case DELETE_USER[REQUEST]:
        return true
      default:
        return state
    }
  }

  const isUserDeleteFailed = (state = false, action:Actions) =>{
    switch (action.type) {
      case DELETE_USER[SUCCESS]:
        return false
      case DELETE_USER[FAILURE]:
        return true
      case DELETE_USER[RESET]:
        return false
      case DELETE_USER[REQUEST]:
        return false
      default:
        return state
    }
  }

  return combineReducers({
    isUserDataFetched,
    isUserCreateFailed,
    isAllRoleFetched,
    isAllRoleFetchedFailed,
    isAllStateFetched,
    isAllStateFetchedFailed,
    isAllUserListFetched,
    isAllUserListFetchedFailed,
    isAllCountryFetched,
    isAllCountryFetchedFailed,
    isAllFloorFetched,
    isAllFloorFetchedFailed,
    isAllWorkstationFetched,
    isAllWorkstationFetchedFailed,
    isAllLocationFetched,
    isAllLocationFetchedFailed,
    isUserUpdated,
    isAllClientLocationFetched,
    isAllClientLocationFetchedFailed,
    isUserCreatedSuccess,
    isImgUploaded,
    getUserImageSuccess,
    isUserDeleteSuccess,
    isUserDeleteFailed,
    isUserUpdateFailed,
    isUserDeleting,
    isUserCreating,
    isUserUpdating
  })
}

export default ui

export const fetchUserData = (state: RootState) => state.ui.user
