import {
  alpha,
  Box,
  Button,
  CircularProgress,
  Dialog,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  MenuProps,
  Pagination,
  Paper,
  Select,
  Stack,
  styled,
  Tab,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Tooltip,
  Typography,
} from '@mui/material'
import {
  HeaderHeading,
  loaderProps,
  StatusBoxCustom,
  StyledTableCell,
  StyledTableCellForMyTeam,
  StyledTableRow,
} from '../Common/CommonStyles'
import { style } from '../Report/SheetStyles'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import CloudDownloadIcon from '@mui/icons-material/CloudDownload'
import { useEffect, useState } from 'react'
import { RootState } from '../../configureStore'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import {
  downloadForm16,
  getFinancialYear,
  getPayrollForm16,
  sendMail,
  uploadForm16,
} from '../../actions'
import { dashboardEntity, financeEntity, financeUI } from '../../reducers'
import { toast } from 'react-toastify'
import { SelectField, StyledMenuItem } from '../UserDetails/UserDetailsStyle'
import ShowEnteries from './ShowEntries'
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox'

import * as React from 'react'
import { Card, CardActions, CardContent } from '@mui/material'

import { getHomePageDetails } from '../../actions'
import { makeStyles } from '@mui/styles'
import CelebrationIcon from '@mui/icons-material/Celebration'
import CakeIcon from '@mui/icons-material/Cake'
import PersonAddIcon from '@mui/icons-material/PersonAdd'
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange'
import DescriptionIcon from '@mui/icons-material/Description'
import FilePresentIcon from '@mui/icons-material/FilePresent'
import FeedbackIcon from '@mui/icons-material/Feedback'
import HistoryEduSharpIcon from '@mui/icons-material/HistoryEduSharp'
import { Avatar } from '@material-ui/core'
import backgroundImage from '../../assets/images/form16bg.jpg'
import formImg from '../../assets/images/form16Form.png'
import form16DownloadIcon from '../../assets/images/form16DownloadIcon.png'
import UserDetails from '../UserDetails/UserDetails'
import { ReactComponent as FormLogo } from '../../assets/images/formnewimage.svg'

const getColumnData = [
  // { id: "1", label: "EMPLOYEE ID" },
  { id: '1', label: 'FINANCIAL YEAR' },
  { id: '2', label: 'NAME (EMP ID)' },
  { id: '3', label: 'EMAIL' },
  { id: '4', label: 'PAN' },
  { id: '5', label: 'MOBILE' },
  { id: '6', label: 'DOWNLOAD' },
]

interface ICancelButton {
  fontSize?: string
  height?: string
}

const CancelButton = styled(Button)(({ fontSize = '13px', height = '42px' }: ICancelButton) => ({
  background: '#E2E2E2',
  color: '#000000',
  fontSize: `${fontSize}`,
  height: `${height}`,
  fontFamily: 'Montserrat-SemiBold',
  width: '100px',
  borderRadius: '20px',
  '&:hover': {
    background: '#E2E2E2',
    color: '#000000',
  },
}))

const Form16 = (props: any) => {
  const { financialYearData, userDetails, downloadForm16LinkData } = props

  const [yearData, setYearData] = useState(props?.financialYearData[0] ?? '')
  const [id, setId] = useState<Number>()
  let [empStatus, setEmpStatus] = useState(1)
  const [page, setPage] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState(100)
  const [userId, setUserId] = useState(userDetails?.id ?? 0)

  useEffect(() => {
    if (userDetails) {
      setUserId(userDetails?.id)
    }
  }, [userDetails])

  useEffect(() => {
    props?.fetchFinancialYear()
  }, [])

  useEffect(() => {
    if (props?.financialYearData) {
      setYearData(props?.financialYearData[0])
    }
  }, [props?.financialYearData])

  useEffect(() => {
    if (yearData)
      props?.fetchForm16Data({
        currentYear: yearData,
        status: empStatus,
        limit: rowsPerPage,
        page: page,
        user: 1,
      })
  }, [yearData, props?.isUploadedForm16Loader])

  useEffect(() => {
    if (props?.downloadForm16Success) {
      const link = document.getElementById('download')
      link?.click()
      toast.success('Form 16 downloaded successfully')
      props?.downloadForm16Reset()
    }
  }, [props?.downloadForm16Success])

  const setDownloadForm16Data = () => {
    props?.downloadForm16({ id: userId, currentYear: yearData })
  }

  return (
    <Paper
      sx={{
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        padding: '40px 0',
        height: '100%',
        backgroundColor: '#3FA9F5',
        margin: '15px auto 0',
        width: '90%',
        gap: '50px'

      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          marginLeft: '100px',
          width: '400px',
          borderRadius: '11px',
          height: '420px',
          backgroundColor: '#FFFFFF',
        }}
      >
        <Typography
          sx={{
            margin: '20px 0 0 0',
            height: '33px',
            lineHeight: '32.78px',
            fontSize: '24px',
            fontFamily: 'Montserrat-Semibold',
          }}
        >
          Form 16
        </Typography>
        <img src={formImg} style={{ width: '250px', height: '200px' }} />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
          width={'80%'}
        >
          <Typography
            sx={{
              fontWeight: '500',
              fontFamily: 'Montserrat-Medium',
              fontSize: '16px',
              textAlign: 'center',
            }}
          >
            Choose the financial year from below
          </Typography>

          <SelectField
            size='small'
            id='financialYear'
            name='financialYear'
            label='Financial Year'
            select
            required={false}
            value={yearData}
            onChange={(e: any) => {
              setYearData(e.target.value)
            }}
          >
            {props?.getAllForm16Data?.data?.map((data: any, index: number) => {
              return (
                <StyledMenuItem key={index} value={data.year}>
                  {data.year}
                </StyledMenuItem>
              )
            })}
          </SelectField>

          <a id='download' href={downloadForm16LinkData} download></a>

          <Button
            onClick={() => {
              if (
                props?.getAllForm16Data?.data?.find((value: any) => value.year === yearData)
                  ?.file === 'Uploaded'
              )
                setDownloadForm16Data()
            }}
            // disabled={props?.getAllForm16Data?.data?.find((value:any)=> value.year === yearData)?.file!=='Uploaded'}
            disableRipple={
              props?.getAllForm16Data?.data?.find((value: any) => value.year === yearData)?.file !==
              'Uploaded'
            }
            sx={{
              borderRadius: '23px',
              width: '100%',
              height: '40px',
              backgroundColor: '#193C6C',
              color: 'white',
              opacity:
                props?.getAllForm16Data?.data?.find((value: any) => value.year === yearData)
                  ?.file !== 'Uploaded'
                  ? 0.5
                  : 1,
              '&:hover': {
                cursor:
                  props?.getAllForm16Data?.data?.find((value: any) => value.year === yearData)
                    ?.file !== 'Uploaded'
                    ? 'not-allowed'
                    : 'pointer',
              },
            }}
          >
            <Typography
              sx={{
                fontWeight: '500',
                fontFamily: 'Montserrat-Medium',
                fontSize: '16px',
                textAlign: 'center',
              }}
            >
              Download
            </Typography>
            <img
              src={form16DownloadIcon}
              style={{ width: '22px', height: '22px', marginLeft: '5px' }}
            />
          </Button>
        </Box>
      </Box>
      <Box
        sx={{
          width: '45%',
          height: '350px',
          backgroundColor: '#3FA9F5',
        }}
      >
        <FormLogo style={{ width: '100%', height: '100%', backgroundColor: '#3FA9F5' }} />
      </Box>
    </Paper>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    userDetails: dashboardEntity.getDashboard(state).getUserDetails,
    getAllForm16Data: financeEntity.getFinance(state).getForm16Data,
    isForm16DataLoader: financeUI.getFinance(state).isForm16DataLoader,
    financialYearData: financeEntity.getFinance(state).getFinancialYearData,
    downloadForm16LinkData: financeEntity.getFinance(state).getDownloadForm16Link,
    downloadForm16Success: financeUI.getFinance(state).isDownloadedForm16,
    isDownloadedFormSucesss: financeUI.getFinance(state).isDownloadedFormSucesss,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    fetchForm16Data: (data: any) => {
      dispatch(getPayrollForm16.request(data))
    },

    fetchFinancialYear: () => {
      dispatch(getFinancialYear.request())
    },
    downloadForm16: (data: any) => {
      dispatch(downloadForm16.request(data))
    },
    downloadForm16Reset: () => {
      dispatch(downloadForm16.reset())
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form16)
