import { Box, IconButton, InputAdornment, styled } from '@mui/material'
import { Dispatch, SetStateAction, useState } from 'react'
import { style } from './SheetStyles'
import { SearchBoxCustom, SearchIconStyle } from '../../Common/CommonStyles'
import ClearIcon from '@mui/icons-material/Clear'

type SearchBoxType = {
  searchQuery: string
  setSearchQuery: Dispatch<SetStateAction<string>>
}

const CustomSearchBox = styled(SearchBoxCustom)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: '20px',
    '& fieldset': {
      borderColor: '#777777',
    },
  },
}))

const SearchBox = (props: SearchBoxType) => {
  const { setSearchQuery } = props
  const [value, setValue] = useState('')

  const handleClear = () => {
    setValue('')
    setSearchQuery('')
  }

  return (
    <Box width='240px' sx={style.serachBoxContainer}>
      <CustomSearchBox
        id='outlined-basic'
        placeholder='Search Project'
        variant='outlined'
        size='small'
        value={value}
        onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
          if (e.key === 'Enter') {
            setSearchQuery((e.target as HTMLInputElement).value)
            setValue((e.target as HTMLInputElement).value)
          }
        }}
        onChange={(e) => {
          if (e.target.value === '') {
            setSearchQuery('')
          }
          return setValue(e.target.value)
        }}
        width='100%'
        InputProps={{
          startAdornment: <SearchIconStyle />,
          endAdornment: (
            <InputAdornment position='end'>
              <IconButton aria-label='clear-icon' onClick={handleClear} edge='end'>
                {value && <ClearIcon />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </Box>
  )
}

export default SearchBox
