import { Select, TextField } from '@mui/material'
import styled from 'styled-components'

const InputField = styled(TextField)(({ theme }) => ({
  marginTop: '5px',
  marginBottom: '5px',
  '& .MuiOutlinedInput-input': {
    padding: '13.5px 14px', // Adjust the padding here
    fontSize: '13px', // Adjust the font size here
    fontFamily: 'Montserrat-Medium',
  },
  '& .MuiFormLabel-asterisk': {
    color: 'red',
  },
  '& .MuiInputBase-root.MuiOutlinedInput-root': {
    borderRadius: '20px',
  },
}))

const SelectField = styled(Select)(({ theme }) => ({
  borderRadius: '20px',
  '& .MuiSelect-select': {
    padding: '13.5px 14px',
    fontSize: '13px',
    fontFamily: 'Montserrat-Medium',
    borderRadius: '20px',
  },
  '&&.MuiInputBase-root.MuiOutlinedInput-root.MuiSelect-root': {
    borderRadius: '20px',
  },
}))
export { InputField, SelectField }
