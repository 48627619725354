import Button from '@mui/material/Button'
import { ReactComponent as ExportToPDFIcon } from '../../../assets/images/exportToPDF.svg'
import { ReactComponent as ExportToExcelIcon } from '../../../assets/images/exportToExcel.svg'
import { ReactComponent as ExportToPrintScreenIcon } from '../../../assets/images/printScreen.svg'
import { Box } from '@mui/material'
import { exportAsPDF } from './ExportAsPDF'
import { exportToExcel } from './ExportAsExcel'
import { style } from './ projectQAReportStyles'
import { ExportButtonPropType } from './projectQAReportTypes'

const ExportButton = ({ selectType, rowsToDisplay }: ExportButtonPropType) => {
  const printTable = () => {
    window.print()
  }

  return (
    <Box sx={style.exportBtnBoxStyle}>
      <Box>
        <Button
          onClick={() => {
            if (rowsToDisplay.length != 0) {
              exportAsPDF()
            }
          }}
          sx={style.exportButtonsStyle}
          variant='outlined'
        >
          <ExportToPDFIcon />
        </Button>
      </Box>
      <Box>
        <Button
          onClick={() => {
            if (rowsToDisplay.length != 0) {
              selectType === 'Project Wise' ? exportToExcel(5) : exportToExcel(4)
            }
          }}
          sx={style.exportButtonsStyle}
          variant='outlined'
        >
          <ExportToExcelIcon />
        </Button>
      </Box>
      <Box>
        <Button
          onClick={() => {
            printTable()
          }}
          sx={style.exportButtonsStyle}
          variant='outlined'
        >
          <ExportToPrintScreenIcon />
        </Button>
      </Box>
    </Box>
  )
}

export default ExportButton
