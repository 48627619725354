import { Box, Button, styled } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs, { Dayjs } from 'dayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { style } from './ projectQAReportStyles'
import { DateRangePickerPropType } from './projectQAReportTypes'
import { useState } from 'react'

const StyledDatePicker = styled(DatePicker)(({ theme }) => ({
  '*': {
    paddingLeft: '0',
    marginLeft: '0',
  },
  '& .MuiFormLabel-asterisk': {
    display: 'none'
  },
  '&': {
    width: '10rem',
    padding: '0',
    marginLeft: '10px',
  },
  '& .MuiDateCalendar-root': {
    width: '100px',
  },
  '& .MuiInputBase-root.MuiOutlinedInput-root': {
    borderRadius: '20px',
  },
  input: {
    fontSize: '14px',
    padding: '11.5px 14px',
    color: 'primary',
    fontFamily: 'Montserrat-Medium',
  },
  '@media only screen and (max-width:780px)': {
    '&': {
      width: '9rem',
    },
    input: {
      fontSize: '12px',
      padding: '9px 10px',
    },
  },
}))

const DateRangePicker = (props: DateRangePickerPropType) => {
  const { setStartDate, setEndDate, startDate, endDate, setShowResult } = props

  const [fromDate, setFromDate] = useState(dayjs(new Date(new Date().getFullYear(), new Date().getMonth(), 1)),);
  const [toDate, setToDate] = useState(dayjs(new Date()));
  const [openStartDatePicker, setOpenStartDatePicker] = useState<boolean>(false)
  const [openEndDatePicker, setOpenEndDatePicker] = useState<boolean>(false)


  const handlerDateResult = () =>{
    setStartDate(fromDate)
    setEndDate(toDate)
    setShowResult((prev:boolean)=>!prev)
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box sx={style.inDatePickerBoxStyle}>
        <StyledDatePicker
          label='From'
          value={startDate}
          onAccept={(newValue) => {
            if (fromDate.diff(toDate) > 0) setEndDate(newValue as Dayjs)
            return setFromDate(newValue as Dayjs)
          }}
          maxDate={toDate}
          disableFuture
          open={openStartDatePicker}
          slotProps={{
            textField: {
              onClick: () => setOpenStartDatePicker(true)
            }
          }}
          onClose={()=>setOpenStartDatePicker(false)}
        />
      </Box>
      <Box sx={style.inDatePickerBoxStyle}>
        <StyledDatePicker
          label='To'
          value={endDate}
          onAccept={(newValue) => setToDate(newValue as Dayjs)}
          minDate={fromDate}
          open={openEndDatePicker}
          slotProps={{
            textField: {
              onClick: () => setOpenEndDatePicker(true)
            }
          }}
          onClose={() => setOpenEndDatePicker(false)}
        />
      </Box>
      <Box sx={style.showReportBoxStyle}>
        <Button onClick={handlerDateResult} variant="outlined" sx={style.showReportBoxBtnStyle}>Show Result</Button>
      </Box>
    </LocalizationProvider>
  )
}

export default DateRangePicker