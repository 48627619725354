import { call, takeLatest } from 'redux-saga/effects'
import { actionTypes } from '../actions'
import * as ApiService from '../services'
import { sendPayload, sendPayloadFailure } from './helper'
import { FETCH_EMPLOYEE_DETAILS } from '../actions/actiontypes'
const { REQUEST } = actionTypes

//write sagas function

function* getEmployeeDetails() {
  try {
    const apiResponse: Generator<string, number, string> = yield call(ApiService.getAllEmployees)
    yield sendPayload(apiResponse, FETCH_EMPLOYEE_DETAILS)
  } catch (e) {
    yield sendPayloadFailure(e, FETCH_EMPLOYEE_DETAILS)
  }
}

export const sagas = {
  //watcher come here
  watchEmployeeDetails: takeLatest(actionTypes.FETCH_EMPLOYEE_DETAILS[REQUEST], getEmployeeDetails),
}
