import { Box, CircularProgress, Pagination, Paper, Stack, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import { Dispatch } from 'redux'
import { connect, useSelector } from 'react-redux'
import { fetchProjectReports } from '../../../actions'
import NoProjectRow from './NoProjectRow'
import ActionBar from './ActionBar'
import ShowEnteries from './ShowEntries'
import { GetDataType } from './ProjectSheetTypes'
import { style } from './SheetStyles'
import { RootState } from '../../../configureStore'
import { CardData } from './Cards/CardsData'
import { projectManagementEntity, projectManagementUI } from '../../../reducers'
import { ArrowBack } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import TableLayout from './TableLayout'


const stylesForProjectSheet ={
      backNavigave:{
        position: 'absolute',
        top: 80,
        right: 25,
        margin: '20px',
        cursor: 'pointer',
      }
}


const ProjectSheet = (props: any) => {
  const { fetchTableData, tableData, isGettingTableData } = props
  const [searchQuery, setSearchQuery] = useState('')
  const [page, setPage] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState(100)
  const [startDate, setStartDate] = useState(
    dayjs(new Date(new Date().getFullYear(), new Date().getMonth(), 1)),
  )
  const currentMonthLastDate = dayjs().endOf('month');
  
  const [endDate, setEndDate] = useState(currentMonthLastDate);  const [expand, setExpand] = useState<boolean>(false)
  const [cardData, setCardData] = useState<{ title: string; items: string[] }[]>([])
  const rowsToDisplay = tableData?.result
  const [showResults, setShowResults] = useState(true)

  useEffect(() => {
      fetchTableData({
        startDate:startDate.format('YYYY-MM-DD').toString(),
        endDate: endDate.format('YYYY-MM-DD').toString(),
        page: page.toString(),
        limit: rowsPerPage.toString(),
        search: searchQuery,
      })
  }, [showResults, page, searchQuery, rowsPerPage, fetchTableData])

  useEffect(() => {
    setCardData(CardData(tableData))
  }, [expand, startDate, endDate, searchQuery, page, rowsPerPage, tableData])

  useEffect(() => {
    setPage(1)
  }, [rowsPerPage, searchQuery, startDate, endDate])

  const handleChangePage = (event: React.ChangeEvent<unknown> | null, newPage: number) => {
    setPage(newPage)
  }
  const navigate = useNavigate()

  const CompanyName = useSelector((state:{entities:{dashboard:{getUserDetails:{company_name:string}}}})=> state?.entities?.dashboard?.getUserDetails?.company_name)


  return (
    <Paper sx={style.container}>
      <Box sx={style.pageHead}>
        <Box
          sx={stylesForProjectSheet.backNavigave}
          onClick={() => navigate(-1)}
        >
          <ArrowBack />
        </Box>
        <Box sx={style.headingBar}>
          <Typography width='fit-content' className='heading' sx={style.primaryHeading}>
           {CompanyName}
          </Typography>
          <Typography className='heading' sx={style.headerHeading}>
            Project Sheet Report from {startDate.format('MM/DD/YYYY')} to{' '}
            {endDate.format('MM/DD/YYYY')}
          </Typography>
        </Box>
      </Box>
      <ActionBar
        expand={expand}
        setExpand={setExpand}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        setPage={setPage}
        setRowsPerPage={setRowsPerPage}
        startDate={startDate}
        endDate={endDate}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        cardData={cardData}
        setShowResults={setShowResults}
        rowsPerPage={rowsPerPage}
      />
      {isGettingTableData && (
        <Box sx={style.loader}>
          <CircularProgress disableShrink />
        </Box>
      )}
      {!isGettingTableData && (
        <Box>
          <TableLayout rowsToDisplay={rowsToDisplay} />
          {rowsToDisplay?.length === 0 && <NoProjectRow />}
          {rowsToDisplay === undefined && <NoProjectRow />}
          {rowsToDisplay?.length > 0 && (
            <Stack
              width='100%'
              spacing={2}
              direction='row'
              justifyContent='flex-end'
              marginTop='1rem'
            >
              <ShowEnteries 
              setPage={setPage} 
              setRowsPerPage={setRowsPerPage} 
              rowsPerPage={rowsPerPage}
              />
              <Pagination
                count={Math.ceil(tableData?.count / rowsPerPage)}
                page={page}
                onChange={handleChangePage}
                color='primary'
              />
            </Stack>
          )}
        </Box>
      )}
    </Paper>
  )
}
const mapStateToProps = (state: RootState) => {
  return {
    tableData: projectManagementEntity.getAllProjectManagement(state).getProjectReports,
    isGettingTableData: projectManagementUI.getProjectManagemet(state).isGettingProjectReportsList,
  }
}
const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    fetchTableData: (data: GetDataType) => dispatch(fetchProjectReports.request(data)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ProjectSheet)
