import { combineReducers } from 'redux'
import * as dashboardState from './dashboard'
import * as userAuthenticationState from './userAuthentication'
import * as loaderState from './loaderState'
import * as loginUserState from './loginUser'
import * as employeeDataSate from './employeeList'
import * as financeState from './finance'
import * as servicepostState from './ServiceRequest'
import * as attendanceState from "./attendance"
import * as projectmanagement from "./projectGraph"
import * as getAllOrgChartUI from "./orgChart"
import * as getAllEmpPortalUI from "./employeeportal"
import * as fetchAllUsersUI from './user'


export {
  servicepostState,
  dashboardState,
  userAuthenticationState,
  loaderState,
  loginUserState,
  employeeDataSate,
  financeState,
  attendanceState,
  projectmanagement,
  getAllOrgChartUI,
  getAllEmpPortalUI,
  fetchAllUsersUI
  
}

export default combineReducers({
  dashboard: dashboardState.default(),
  userAuthentication: userAuthenticationState.default(),
  loaderState: loaderState.default(),
  loginUser: loginUserState.default(),
  employeeList: employeeDataSate.default(),
  finance: financeState.default(),
  servicepostState: servicepostState.default(),
  employeeListAttendance: attendanceState.default(),
  projectmanagement:projectmanagement.default(),
  orgchartUIstate: getAllOrgChartUI.default(),
  employeePortal: getAllEmpPortalUI.default(),
  user: fetchAllUsersUI.default(),
})
