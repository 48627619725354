import {
    Backdrop,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Paper,
    Typography,
    styled,
  } from '@mui/material'
  import CloseIcon from '@mui/icons-material/Close'
import { ConfieleteDiaglogPropType } from './Types'
import { style } from './ConfirmDeleteDialogStyles'
  
  const Heading = styled(Typography)(({ theme }) => ({
    fontSize: '20px',
    textAlign: 'center',
    fontFamily: 'Montserrat-SemiBold',
    letterSpacing: '0px',
    '@media (max-width: 500px)': {
      fontSize: '5vw',
    },
  }))
  
  const ActionButton = styled(Button)(({ theme }) => ({
    fontSize: '13px',
    height: '35px',
    fontFamily: 'Montserrat-SemiBold',
    width: '25%',
    borderRadius: '20px',
  }))
  
  const CancelButton = styled(Button)(({ theme }) => ({
    background: '#E2E2E2',
    color: '#000000',
    fontSize: '13px',
    height: '35px',
    fontFamily: 'Montserrat-SemiBold',
    width: '25%',
    borderRadius: '20px',
    '&:hover': {
      background: '#E2E2E2',
      color: '#000000',
    },
  }))

  
  
  const ConfirmDeleteDialog = ({
    actionDialog,
    setActionDialog,
    diaglogTitle,
    diaglogContent,
    deleteFunction,
  }: ConfieleteDiaglogPropType) => {
    const handleClickCloseDiaglogForm = () => {
      setActionDialog(false)
    }
    const DeleteProjectRow = () => {
      deleteFunction()
      setActionDialog(false)
    }
    return (
      <>
        <Paper sx={style.customBox}>
          <Box>
            <Dialog
              open={actionDialog}
              onClose={handleClickCloseDiaglogForm}
              aria-labelledby='alert-dialog-title'
              aria-describedby='alert-dialog-description'
              BackdropComponent={Backdrop}
              sx={style.BackdropDiaglogStyle}
            >
              <Box>
                <DialogTitle sx={style.dialogTitleStyle}>
                  <Heading>{diaglogTitle}</Heading>
                  <Box marginLeft='5px'>
                    <IconButton
                      aria-label='close'
                      onClick={handleClickCloseDiaglogForm}
                      sx={{
                        ...style.iconButtonStyle,
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Box>
                </DialogTitle>
                <DialogContent dividers>
                  <Box sx={style.dialogContentBoxStyle}>
                    <Box sx={style.dialogContentMsgBox}>
                      <Typography sx={style.secondTypographyInContent}>
                        Are you sure you want to delete the {diaglogContent} ?
                      </Typography>
                    </Box>
                  </Box>
                </DialogContent>
                <DialogActions sx={style.dialogActions}>
                  <CancelButton onClick={handleClickCloseDiaglogForm} autoFocus>
                    CANCEL
                  </CancelButton>
                  <ActionButton onClick={DeleteProjectRow}>DELETE</ActionButton>
                </DialogActions>
              </Box>
            </Dialog>
          </Box>
        </Paper>
      </>
    )
  }
  
  export default ConfirmDeleteDialog
  