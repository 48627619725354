const colors: { [key: string]: string } = {
    "1": "#E5DDC5",
    "2": "#EEEEEE",
    "3": "#F7DCB9",
    "4": "#FFF1DB",
    "5": "#F8EDE3",
    "6": "#ECFFE6",
    "7": "#FEFCF3",
    "8": "#F3F0D7",
    "9": "#FFF8F3",
    "10": "#EAECCC",
    "11": "#F8F6E3",
    "12": "#E7F0DC",
    "13": "#FFFED3",
    "14": "#F6E6CB",
    "15": "#F1EEDC",
    "16": "#E7D4B5",
    "17": "#D8EFD3",
    "18": "#F3F7EC",
    "19": "#F3F7EC",
    "20": "#FEFFD2",
    "21": "#EEEEEE",
    "22": "#FFE8C5",
    "23": "#F8F4E1",
    "24": "#EADBC8",
    "25": "#FFF9D0",
    "26": "#FBFADA",
    "27": "#EEEDEB",
    "28": "#E1F0DA",
    "29": "#F5EEE6",
    "30": "#F9EFDB"

  };

  export default colors