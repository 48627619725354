import { Box } from '@mui/material'
import { useEffect, useState } from 'react'
import { fetchProjectCustomersList } from '../../../actions/index'
import { connect } from 'react-redux'
import { style } from './projectCustomersStyle'
import DiaglogForm from './DiaglogForm'
import AddCustomerButton from './AddCustomerButton'
import SearchBox from './SearchBox'
import { Dispatch } from 'redux'
import { RootState } from '../../../configureStore'
import {
  FormValues,
  ProjectCustomersPropType,
  getProjectCustomersType,
} from './ProjectCustomersTypes'
import Cards from './Cards'
import { projectManagementEntity, projectManagementUI } from '../../../reducers'

const ProjectCustomers = ({
  getProjectCustomers,
  projectCustomersData,
  isProjectCustomersCreated,
  isProjectCustomerDeleted,
  isProjectCustomerUpdated,
}: ProjectCustomersPropType) => {
  const [searchQuery, setSearchQuery] = useState('')
  const [rowsPerPage, setRowsPerPage] = useState(100)
  const [page, setPage] = useState(1)
  const [openCustomerForm, setOpenCustomerForm] = useState(false)
  const [openCustomerEditForm, setopenCustomerEditForm] = useState(false)
  const [editCustomerData, setEditCustomerData] = useState<FormValues>({
    id: 0,
    customer_name: '',
  })

  useEffect(() => {
    getProjectCustomers({
      data: { pageSize: rowsPerPage, search: searchQuery, page: page },
    })
  }, [searchQuery, rowsPerPage, page,isProjectCustomersCreated, isProjectCustomerUpdated, isProjectCustomerDeleted])

  useEffect(() => {
    setPage(1)
  }, [rowsPerPage, searchQuery])

  
  return (
    <>
      <Box sx={style.customBox}>
        <Box>
          <SearchBox searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
        </Box>
        <Box >
          <AddCustomerButton setOpenCustomerForm={setOpenCustomerForm} />
        </Box>
      </Box>
      <DiaglogForm
        setOpenCustomerForm={setOpenCustomerForm}
        openCustomerForm={openCustomerForm}
        openCustomerEditForm={openCustomerEditForm}
        setopenCustomerEditForm={setopenCustomerEditForm}
        editCustomerData={editCustomerData}
      />
      <Box marginTop='5px'>
        <Cards
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          searchQuery={searchQuery}
          projectCustomersData={projectCustomersData}
          setopenCustomerEditForm={setopenCustomerEditForm}
          setEditCustomerData={setEditCustomerData}
        />
      </Box>
    </>
  )
}

const mapStateToProp = (state: RootState) => {
  return {
    projectCustomersData:
      projectManagementEntity.getAllProjectManagement(state).getProjectCustomersDetails,
    isProjectCustomersCreated:
    projectManagementUI.getProjectManagemet(state).isProjectCustomersCreated,
    isProjectCustomerDeleted:
    projectManagementUI.getProjectManagemet(state).isProjectCustomerDeleted,
    isProjectCustomerUpdated:
    projectManagementUI.getProjectManagemet(state).isProjectCustomerUpdated

  }
}
const mapDispatchToProp = (dispatch: Dispatch) => {
  return {
    getProjectCustomers: (data: getProjectCustomersType) =>
      dispatch(fetchProjectCustomersList.request(data)),
  }
}

export default connect(mapStateToProp, mapDispatchToProp)(ProjectCustomers)
