import {
  Box,
  CircularProgress,
  Divider,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
} from '@mui/material'
import { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import {
  addCommentPlanForTheDay,
  addCommentTimesheet,
  approvePlanForTheDay,
  editPlanForTheDay,
  fetchMandateType,
  fetchReports,
  fetchClientMember,
  getDrsData,
  fetchTeamMandates,
  fetchDelayedGraph,
  fetchDelayedTable,
} from '../../actions'
import React from 'react'
import { GetDataType } from './ProjectSheetTypes'
import { RootState } from '../../configureStore'
import ActionBar from './ActionBar'
import { style } from './SheetStyles'
import {
  attendanceUI,
  dashboardEntity,
  dashboardUI,
  projectManagementEntity,
  projectManagementUI,
} from '../../reducers'

import { Tab, Tabs } from '@mui/material'

import { useLocation, useNavigate } from 'react-router-dom'
import ProductivityMatixChart from './ProductivityMatixChart'
import { employeeDataRequest } from '../../reducers/entities'
import { FamilyDetailsHeading, StyledTableCell } from '../Common/CommonStyles'
import DelayedTasks from './ClientReport/DelayedTasks'
import AnalyticsPieChart from './AnalyticsBarGraph'
import ExportButton from './ExportButton'

const styles = {
  styledCells: {
    padding: '6px 6px',
  },
  typoIntablehead: {
    margin: '0',
    padding: '6px 2px',
    width: '160px',
  },
  border: {
    borderLeft: '1px solid #E9E9E9',
    borderRight: '1px solid #E9E9E9',
  },
}

const getColumnDataForMandate = [
  { id: 1, label: 'Emp Name' },
  { id: 2, label: 'Client(s)' },
  { id: 3, label: 'No Of Client(s)' },
  { id: 4, label: 'Mandate(s)' },
  { id: 5, label: 'No Of Mandate(s)' },
]

const getUniqueMembersColumns = [
  { id: 1, label: 'Client' },
  { id: 2, label: 'Project(s)' },
  { id: 3, label: 'Emp Name' },
  { id: 4, label: 'Total Member(s)' },
]

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  left: '160px',
  width: '1719px',
  height: '60px',
  boxShadow: '0px 10px 3px #6c6c6c10',
  opacity: '1',
}))
const ProductivityMatrixIndex = (props: any) => {
  const [workingEmployee, setWorkingEmployee] = useState<number | null>(null)

  const {
    fetchTableData,
    tableData,
    isGettingTableData,
    fetchDrsData,
    DrsData,
    fetchClientMembers,
    clientMemberData,
    isClientMemberData,
    isTeamMandateData,
    teamMandatesData,
    fetchTeamMandates,
    delayedGraphData,
    isDelayedGraphData,
    fetchDelayedGraph,
    isDelayedTableData,
    fetchDelayedTable,
    delayedTableData,
    employeeRecords,
  } = props
  const { state } = useLocation()
  const oneWeekAgo = dayjs().subtract(1, 'day') // name is for one week and i am using for one day

  const [startDate, setStartDate] = useState(oneWeekAgo)
  const [endDate, setEndDate] = useState(dayjs())
  const savedTab = parseInt(sessionStorage.getItem('activeTab') || '0', 10)

  const [selectedTab, setSelectedTab] = useState(savedTab || 1)
  const [selectedProductivityTab, setSelectedProductivityTab] = useState(1)

  const [showResults, setShowResults] = useState(true)

  const filteredRecords = employeeRecords.filter(
    (employee: any) => employee.userId !== 2787 && employee.userId !== 1,
  )

  useEffect(() => {
    fetchDrsData()
  }, [])

  useEffect(() => {
    if (savedTab === 3) fetchClientMembers()
    if (savedTab === 2) fetchTeamMandates()
  }, [savedTab])

  useEffect(() => {
    fetchTableData({
      startDate: startDate.format('YYYY-MM-DD').toString(),
      endDate: endDate.format('YYYY-MM-DD').toString(),
      search: workingEmployee,
      reportType: state?.type === 'productivitymatrix' ? selectedProductivityTab : selectedTab,
    })
  }, [showResults, workingEmployee])

  useEffect(() => {
    const graphData = {
      startDate: startDate.format('YYYY-MM-DD').toString(),
      endDate: endDate.format('YYYY-MM-DD').toString(),
    }
    fetchDelayedGraph(graphData)
  }, [showResults])

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue)
  }

  const handleSelectedTab = (data: any) => {
    sessionStorage.setItem('activeTab', data.toString()) // Store the selected tab in sessionStorage
    setSelectedTab(data)
    setStartDate(oneWeekAgo)
    setEndDate(dayjs())
  }

  const csvData = (savedTab: any) => {
    switch (savedTab) {
      case 2:
        return teamMandatesData?.data
      case 3:
        return clientMemberData?.data
      case 4: // Assuming you meant to check for savedTab 4
        return delayedTableData
      default:
        return [] // Default case if none match
    }
  }

  return (
    <Paper sx={style.container}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-start',
          justifyContent: 'space-between',
          marginTop: 0,
        }}
      >
        <Tabs
          value={selectedTab - 1}
          onChange={handleTabChange}
          aria-label='Tabs for different tables'
        >
          <Tab label='Productivity Metrics' onClick={() => handleSelectedTab(1)} />
          <Tab label='Client Details' onClick={() => handleSelectedTab(2)} />
          <Tab label='Unique Members' onClick={() => handleSelectedTab(3)} />
          <Tab label='Delayed Tasks' onClick={() => handleSelectedTab(4)} />
        </Tabs>
        {/* {savedTab !== 1 && (
          <ExportButton downLoadCsvData={csvData(savedTab)} savedTab={savedTab} state={state} />
        )} */}
      </Box>
      <Box sx={style.pageHead}></Box>
      {selectedTab === 1 && (
        <>
          <ActionBar
            employeeRecords={filteredRecords}
            workingEmployee={workingEmployee}
            setWorkingEmployee={setWorkingEmployee}
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            setShowResults={setShowResults}
            DrsData={DrsData}
          />
          <Box
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'flex-start',
              marginTop: '10px',
            }}
          >
            {/* Loader or Bar Chart */}
            {isGettingTableData ? (
              <Box
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '380px', // Adjust to match the table/chart height
                  width: '70%',
                }}
              >
                <CircularProgress disableShrink />
              </Box>
            ) : tableData?.users?.length > 0 ? (
              <Box style={{ width: '70%' }}>
                <FamilyDetailsHeading>Time Spent Analysis</FamilyDetailsHeading>
                <ProductivityMatixChart
                  chartData={tableData}
                  startDate={startDate}
                  endDate={endDate}
                  showResults={showResults}
                />
              </Box>
            ) : (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '430px',
                  width: '70%',
                }}
              >
                <p>No Data Found</p>
              </div>
            )}
            {/* Divider between Bar Chart and Pie Chart */}
            <Divider orientation='vertical' flexItem sx={{ mx: 2 }} /> {/* Vertical Divider */}
            {/* Loader or Pie Chart */}
            {isDelayedGraphData ? (
              <Box
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '380px', // Adjust to match the chart height
                  width: '30%',
                }}
              >
                <CircularProgress disableShrink />
              </Box>
            ) : delayedGraphData?.length > 0 ? (
              <Box style={{ width: '30%', marginLeft: '20px' }}>
                <FamilyDetailsHeading>Delayed Task Metrics</FamilyDetailsHeading>{' '}
                <AnalyticsPieChart myChartData={delayedGraphData} />
              </Box>
            ) : (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '430px',
                  width: '30%',
                }}
              >
                <p>No Data Found</p>
              </div>
            )}
          </Box>
        </>
      )}

      {selectedTab === 2 && (
        <>
          {isTeamMandateData ? (
            <Box sx={style.loader}>
              <CircularProgress disableShrink />
            </Box>
          ) : (
            teamMandatesData?.data?.length > 0 && (
              <Box>
                <TableContainer component={Paper} id='export' sx={style.tableContainer}>
                  <Table>
                    <TableHead id='head'>
                      <StyledTableRow sx={style.border}>
                        {getColumnDataForMandate?.map((column: any, index: number) => (
                          <StyledTableCell key={index} sx={styles.styledCells}>
                            <Typography
                              fontSize={'0.800rem'}
                              fontFamily={'Montserrat-Medium'}
                              sx={styles.typoIntablehead}
                            >
                              {column.label}
                            </Typography>
                          </StyledTableCell>
                        ))}
                      </StyledTableRow>
                    </TableHead>

                    <TableBody>
                      {teamMandatesData?.data?.map((teamData: any, index: any) => (
                        <StyledTableRow id='body' key={index} sx={styles.border}>
                          <StyledTableCell>{teamData?.employee_name}</StyledTableCell>

                          <StyledTableCell key={index}>
                            {teamData?.clients?.length
                              ? teamData?.clients?.map((data: any, index: any) => (
                                  <span key={index}>
                                    {data} {index !== teamData?.clients?.length - 1 && ', '}
                                  </span>
                                ))
                              : 'NA'}
                          </StyledTableCell>
                          <StyledTableCell>
                            {teamData?.number_of_clients ? teamData?.number_of_clients : 'NA'}
                          </StyledTableCell>

                          <StyledTableCell>
                            {' '}
                            {teamData?.mandates?.length > 0
                              ? teamData?.mandates?.map((data: any, index: any) => (
                                  <span key={index}>
                                    {data} {index !== teamData?.mandates?.length - 1 && ', '}
                                  </span>
                                ))
                              : 'NA'}
                          </StyledTableCell>
                          <StyledTableCell>
                            {teamData?.number_of_projects ? teamData?.number_of_projects : 'NA'}
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            )
          )}
        </>
      )}
      {selectedTab === 3 && (
        <>
          {isClientMemberData ? (
            <Box sx={style.loader}>
              <CircularProgress disableShrink />
            </Box>
          ) : (
            clientMemberData?.data?.length > 0 && (
              <Box>
                <TableContainer component={Paper} id='export' sx={style.tableContainer}>
                  <Table>
                    <TableHead id='head'>
                      <StyledTableRow sx={style.border}>
                        {getUniqueMembersColumns?.map((column: any, index: number) => (
                          <StyledTableCell key={index} sx={styles.styledCells}>
                            <Typography
                              fontSize={'0.800rem'}
                              fontFamily={'Montserrat-Medium'}
                              sx={styles.typoIntablehead}
                            >
                              {column.label}
                            </Typography>
                          </StyledTableCell>
                        ))}
                      </StyledTableRow>
                    </TableHead>

                    <TableBody>
                      {clientMemberData?.data?.map((clientData: any, index: any) => (
                        <StyledTableRow
                          id='body'
                          key={index}
                          sx={{
                            ...style.border,
                            backgroundColor:
                              clientData.total_members === 1
                                ? 'rgba(255, 227, 81, 0.8)'
                                : 'inherit', // Highlight if priority is high
                          }}
                        >
                          <StyledTableCell>{clientData?.client_name}</StyledTableCell>
                          <StyledTableCell>
                            {clientData?.mandates?.length
                              ? clientData?.mandates?.map((data: any, index: any) => (
                                  <span>
                                    {data?.project_name}{' '}
                                    {index !== clientData?.employee?.length - 1 && ', '}{' '}
                                  </span>
                                ))
                              : 'NA'}
                          </StyledTableCell>
                          <StyledTableCell key={index}>
                            {clientData?.employee?.length
                              ? clientData?.employee?.map((data: any, index: any) => (
                                  <span>
                                    {data} {index !== clientData?.employee?.length - 1 && ', '}
                                  </span>
                                ))
                              : 'NA'}
                          </StyledTableCell>
                          <StyledTableCell>
                            {clientData?.total_members ? clientData?.total_members : 'NA'}
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            )
          )}
        </>
      )}
      {selectedTab === 4 && (
        <>
          <DelayedTasks
            fetchDelayedGraph={fetchDelayedGraph}
            isDelayedTableData={isDelayedTableData}
            fetchDelayedTable={fetchDelayedTable}
            delayedTableData={delayedTableData}
            employeeRecords={filteredRecords}
            workingEmployee={workingEmployee}
            setWorkingEmployee={setWorkingEmployee}
          />
        </>
      )}
    </Paper>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    isDelayedTableData: dashboardUI.getDashboard(state).isDelayedTableData,
    delayedTableData: dashboardEntity.getDashboard(state).getDelayedTableData,
    isDelayedGraphData: dashboardUI.getDashboard(state).isDelayedGraphData,
    delayedGraphData: dashboardEntity.getDashboard(state).getDelayedGraphData,
    isTeamMandateData: dashboardUI.getDashboard(state).isTeamMandateData,
    teamMandatesData: dashboardEntity.getDashboard(state).getTeamMandatesData,
    isClientMemberData: dashboardUI.getDashboard(state).isClientMembersData,
    clientMemberData: dashboardEntity.getDashboard(state).getClientMembersData,
    employeeRecords: employeeDataRequest.getAllEmployeesList(state).getAllEmployeesDetails,
    DrsData: dashboardEntity.getDashboard(state).getDrsData,
    tableData: projectManagementEntity.getAllProjectManagement(state).getReports,
    isGettingTableData: projectManagementUI.getProjectManagemet(state).isGettingReportsList,
    mandateTypeData: projectManagementEntity.getAllProjectManagement(state).getAllMandate,
    getApproveSuccessStatus:
      attendanceUI.getEmpAttendanceList(state).isApprovingPlanForTheDaySuccess,
    getApproveLoader: attendanceUI.getEmpAttendanceList(state).isApprovingPlanForTheDay,
    getPlanForTheDayCommentSuccess:
      attendanceUI.getEmpAttendanceList(state).isCreateCommentForPlanForTheDaySuccess,
    getCommentSuccess: attendanceUI.getEmpAttendanceList(state).isCreateCommentForTimesheetSuccess,
    getLoaderState: attendanceUI.getEmpAttendanceList(state).isCreateCommentForTimesheetLoader,
    getPlanForTheDayLoaderState:
      attendanceUI.getEmpAttendanceList(state).isCreateCommentForPlanForTheDayLoader,
    isUpdatedPlanForTheDayList: attendanceUI.getEmpAttendanceList(state).isUpdatePlanForTheDayList,
  }
}
const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    fetchDelayedTable: (data: any) => dispatch(fetchDelayedTable.request(data)),
    fetchDelayedGraph: (data: any) => dispatch(fetchDelayedGraph.request(data)),
    fetchTeamMandates: () => dispatch(fetchTeamMandates.request()),
    fetchClientMembers: () => dispatch(fetchClientMember.request()),
    fetchDrsData: () => dispatch(getDrsData.request()),
    fetchTableData: (data: GetDataType) => dispatch(fetchReports.request(data)),
    getMandateType: (data: any) => dispatch(fetchMandateType.request(data)),
    approvePlanForTheDayDispatchCall: (data: any) => dispatch(approvePlanForTheDay.request(data)),
    resetPlanForTheDayApprove: () => dispatch(approvePlanForTheDay.reset()),
    createCommentPlanForTheDay: (data: any) => dispatch(addCommentPlanForTheDay.request(data)),
    createCommentTimesheet: (data: any) => dispatch(addCommentTimesheet.request(data)),
    resetTimesheetComment: () => dispatch(addCommentTimesheet.reset()),
    resetPlanForTheDayComment: () => dispatch(addCommentPlanForTheDay.reset()),
    resetEditPlanForTheDay: (data: any) => dispatch(editPlanForTheDay.reset()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductivityMatrixIndex)
