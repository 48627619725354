import React, { useEffect, useState } from 'react'
import ProjectQAReportHeading from './Heading'
import { style } from './ projectQAReportStyles'
import { Box, Button, CircularProgress, Collapse } from '@mui/material'
import DateRangePicker from './DateRangePicker'
import dayjs from 'dayjs'
import { ReactComponent as ExpandIcon } from '../../../assets/images/expand_content.svg'
import { ReactComponent as ShrinkIcon } from '../../../assets/images/shrink.svg'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { fetchProjectQAReport } from '../../../actions'
import { projectManagementEntity, projectManagementUI } from '../../../reducers'
import CollapseData from './CollapseData'
import ExportButton from './ExportButton'
import QATable from './QATable'
import SelectReportType from './SelectReportType'
import { RootState } from '../../../configureStore'
import { ArrowBack } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import SearchBox from './SearchBox'

const ProjectQAReports = ({
  getProjectQAReport,
  isGettingingProjectQAReport,
  projectQAReportData,
}: any) => {
  const [selectType, setSelectType] = useState('project-wise')
  const [count, setCount] = useState(0)
  const [startDate, setStartDate] = useState(
    dayjs(new Date(new Date().getFullYear(), new Date().getMonth(), 1)),
  )
  const currentDate = dayjs()
  const lastDateOfMonth = currentDate.endOf('month')
  const [endDate, setEndDate] = useState(lastDateOfMonth)
  const [filteredRows, setfilteredRows] = useState<[]>()
  const [page, setPage] = useState(1)
  const [rowPerPage, SetRowPerPage] = useState(100)
  const startIndex = (page - 1) * rowPerPage
  const endIndex = startIndex + rowPerPage
  const displayedRows = filteredRows ? filteredRows : projectQAReportData?.result
  const rowsToDisplay = displayedRows?.slice(startIndex, endIndex)
  const [showResult, setShowResult] = useState(false)
  const [expand, setExpand] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')

  useEffect(() => {
    if (projectQAReportData?.result?.length > 0) {
      setfilteredRows(projectQAReportData?.result)
      setCount(Math.ceil(projectQAReportData?.result?.length / rowPerPage))
    }
  }, [projectQAReportData])

  useEffect(() => {
    getProjectQAReport({
      data: {
        data: {
          startDate: startDate.format('YYYY-MM-DD').toString(),
          endDate: endDate.format('YYYY-MM-DD').toString(),
          type: selectType,
          page: page,
          pageSize: rowPerPage,
          search: searchQuery,
        },
      },
    })
  }, [showResult, rowPerPage, page, selectType, searchQuery])

  useEffect(() => {
    setfilteredRows(projectQAReportData?.result ?? [])
    setCount(Math.ceil(projectQAReportData?.result?.length / rowPerPage))
    setExpand(false)
  }, [selectType, rowPerPage, projectQAReportData])

  const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value)
  }

  const navigate = useNavigate()

  return (
    <Box sx={style.container}>
      <Box
        sx={{
          position: 'absolute',
          top: 80,
          right: 25,
          margin: '20px',
          cursor: 'pointer',
        }}
        onClick={() => navigate(-1)}
      >
        <ArrowBack />
      </Box>
      <Box sx={style.pageHead}>
        <ProjectQAReportHeading startDate={startDate} endDate={endDate} />
      </Box>
      <Box sx={style.actionBarConatiner}>
        <Box sx={style.actionBar}>
          <SearchBox searchQuery={searchQuery} setSearchQuery={setSearchQuery} selectType={selectType}/>
          <Box>
            <SelectReportType selectType={selectType} setSelectType={setSelectType} />
          </Box>
          <Box sx={style.datePickerBoxStyle}>
            <DateRangePicker
              startDate={startDate}
              endDate={endDate}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              setShowResult={setShowResult}
            />
          </Box>
          <Box sx={style.actionBarDownloadbtn}>
            <ExportButton selectType={selectType} rowsToDisplay={rowsToDisplay} />
          </Box>
          <Button size='small' onClick={() => setExpand((prev) => !prev)} sx={style.expandButton}>
            {expand ? <ShrinkIcon /> : <ExpandIcon />}
          </Button>
        </Box>
        <Collapse orientation='vertical' in={expand}>
          <CollapseData projectQAReportData={projectQAReportData} />
        </Collapse>
      </Box>
      {isGettingingProjectQAReport ? (
        <Box sx={style.boxCircularProgress}>
          <CircularProgress disableShrink />
        </Box>
      ) : (
        <QATable
          rowsToDisplay={rowsToDisplay}
          selectType={selectType}
          projectQAReportData={projectQAReportData}
          count={count}
          page={page}
          setPage={setPage}
          handleChangePage={handleChangePage}
          SetRowPerPage={SetRowPerPage}
          rowPerPage={rowPerPage}
        />
      )}
    </Box>
  )
}

const mapStateToProp = (state: RootState) => {
  return {
    projectQAReportData:
      projectManagementEntity.getAllProjectManagement(state).getProjectQAReportDetails,
    isGettingingProjectQAReport:
      projectManagementUI.getProjectManagemet(state).isGettingProjectQAReport,
  }
}
const mapDispatchToProp = (dispatch: Dispatch) => {
  return {
    getProjectQAReport: (data: any) => dispatch(fetchProjectQAReport.request(data?.data)),
  }
}
export default connect(mapStateToProp, mapDispatchToProp)(ProjectQAReports)
