import { Box, styled } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { Dayjs } from 'dayjs'
import { Dispatch, SetStateAction, useState } from 'react'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'

type PropType = {
  startDate: Dayjs
  setStartDate: Dispatch<SetStateAction<Dayjs>>
}
export const StyledDatePicker = styled(DatePicker)(({ theme }) => ({
  '*': {
    paddingLeft: '0',
    marginLeft: '0',
  },
  '& .MuiFormLabel-asterisk': {
    display: 'none',
  },
  '& .MuiDateCalendar-root': {
    width: '100px',
  },
  '& .MuiInputBase-root': {
    height: '40px',
    width: '160px',
  },
  '& .MuiInputBase-root.MuiOutlinedInput-root': {
    borderRadius: '20px',
  },
  input: {
    fontSize: '14px',
    padding: '11.5px 14px',
    color: '#353665',
    fontFamily: 'Montserrat-Medium',
  },
}))

const DateRangePicker = (props: PropType) => {
  const { setStartDate, startDate } = props
  const [open,setOpen]=useState(false)
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box display='flex' alignItems='center' justifyContent='center' margin='0' padding='0'>
        <StyledDatePicker
          label='Date'
          value={startDate}
          onAccept={(newValue) => {
            return setStartDate(newValue as Dayjs)
          }}
          open={open}
          onClose={() => setOpen(false)}
          slotProps={{
            textField: {
              onClick: () => setOpen(true),
            },
          }}
        />
      </Box>
    </LocalizationProvider>
  )
}

export default DateRangePicker
