import { Checkbox, FormGroup, FormControlLabel, Box, Typography, Grid } from '@mui/material'
import { style } from './NonBillabelStyle'
import { FilteredItemListPropsType, NonBillableDataType } from './NonBillableResourcesTypes'

const FilteredItemList = ({
  items,
  handleCheckboxChange,
  handleSelectAll,
}: FilteredItemListPropsType) => {
  
  return (
    <Box sx={style.mainCheckBoxContainter}>
      <FormGroup sx={style.formControl}>
        {items?.length !== 0 && (
          <FormControlLabel
            sx={style.selectAllStyle}
            control={
              <Checkbox
                checked={
                  items?.length > 0 && items.every((item: NonBillableDataType) => item.checked)
                }
                onChange={handleSelectAll}
              />
            }
            label={<Typography sx={style.checkBoxSelectAllLabelStyle}>Select All</Typography>}
          />
        )}
        <Box height={items?.length >= 24 ? '348px' : 'auto'} sx={style.checkBoxContainter}>
          {items?.length !== 0 ? (
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} >
                  <Box sx={style.firstColumnBox}>
                    {items?.map((item: NonBillableDataType) => (
                      <Box key={item.id}>
                        <FormControlLabel
                          sx={style.selectSingleStyle}
                          control={
                            <Checkbox
                              name={item.name}
                              checked={item.checked}
                              onChange={() => handleCheckboxChange(item.id)}
                            />
                          }
                          label={
                            <Typography sx={style.checkBoxLetterLabelStyle}>{item.name}</Typography>
                          }
                        />
                      </Box>
                    ))}
                  </Box>
                </Grid>
            </Grid>
          ) : (
            <Box sx={style.noContentCheckBox}>
              <Typography sx={style.noContentTypographyCheckBox}>
                Data for non-billable resources is not available.
              </Typography>
            </Box>
          )}
        </Box>
      </FormGroup>
    </Box>
  )
}

export default FilteredItemList
