import React, { useEffect, useRef } from 'react'
import * as echarts from 'echarts'
import { connect } from 'react-redux'
import { RootState } from '../../configureStore'
import { fetchEmpTenure } from '../../actions'
import { dashboardEntity, dashboardUI } from '../../reducers'
import { Box, CircularProgress, Paper, styled, Typography } from '@mui/material'
import { EmpTenureHeading } from '../Common/CommonStyles'
import { style } from '../Report/SheetStyles'
import { Primary } from '../../utils'

export const styles = {
  container: {
    fontFamily: 'Montserrat-Bold',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    background: '#FFFFFF',
    opacity: '1',
    textAlign: 'left',
    margin: '20px 20px 0 20px',
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: '10px 25px 0 25px',
    overflow: 'hidden',
    width: '93%',
    border: '1px solid #DDDDDD',
    height: '70vh',
    '@media print': {
      '@page': {
        size: '1280px',
      },
    },
  },
}

const HeaderHeading = styled(Typography)(({ theme }) => ({
  fontSize: '28px',
  textAlign: 'center',
  fontFamily: 'Montserrat-SemiBold',
  letterSpacing: '0px',
  color: Primary,
}))

const EmpTenturePer = (props: any) => {
  const { empTenureData, fetchEmpTenure, isGetEmpTenure } = props
  const chartRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    fetchEmpTenure()
  }, [fetchEmpTenure])

  useEffect(() => {
    if (empTenureData.length > 0) {
      const limitedData = empTenureData?.map((item: any) => ({
        name: item?.name,
        value: item?.value,
        year: item?.year,
      }))

      if (chartRef.current) {
        const chartInstance = echarts.init(chartRef.current)

        const options = {
          tooltip: {
            trigger: 'item',
            formatter: (params: any) => {
              const { name, value, data } = params
              return `
                  <strong>${name}</strong><br/>
                  <strong> Tenure: </strong> ${value}% (${data.year})
                `
            },
          },

          series: [
            {
              name: 'Funnel',
              type: 'funnel',
              left: '10%',
              top: 100,
              bottom: 60,
              width: '80%',
              min: 0,
              max: 100,
              minSize: '0%',
              maxSize: '100%',
              sort: 'descending',
              gap: 2,
              label: {
                show: true,
                position: 'inside',
              },
              labelLine: {
                length: 10,
                lineStyle: {
                  width: 1,
                  type: 'solid',
                },
              },
              itemStyle: {
                borderColor: '#fff',
                borderWidth: 1,
              },
              emphasis: {
                label: {
                  fontSize: 20,
                },
              },
              data: limitedData,
            },
          ],
        }

        chartInstance.setOption(options)

        return () => {
          chartInstance.dispose()
        }
      }
    }
  }, [empTenureData])

  return (
    <Paper sx={styles.container}>
      <HeaderHeading>Employment History</HeaderHeading>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          // marginTop: '-80px',
          marginBottom: '20px',
          width: '100%',
          height:'55vh'
        }}
      >
        {isGetEmpTenure ? (
          <Box
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '380px', // Adjust to match the chart height
              width: '100%',
            }}
          >
            <CircularProgress disableShrink />
          </Box>
        ) : empTenureData.length > 0 ? (
          <>
            <div
              ref={chartRef}
              style={{
                display: 'flex',
                justifyContent: 'center',
                width: '60%',
                height: '500px',
              }}
            />
          </>
        ) : (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '430px',
              width: '30%',
              fontFamily: 'sans-serif',
              fontWeight: 'bold',
            }}
          >
            <p>No Data Found</p>
          </div>
        )}
      </div>
    </Paper>
  )
}

const mapStateToProps = (state: RootState) => ({
  empTenureData: dashboardEntity.getDashboard(state).getEmpTenure,
  isGetEmpTenure: dashboardUI.getDashboard(state).isGetEmpTenure,
})

const mapDispatchToProps = (dispatch: any) => ({
  fetchEmpTenure: () => dispatch(fetchEmpTenure.request()),
})

export default connect(mapStateToProps, mapDispatchToProps)(EmpTenturePer)
