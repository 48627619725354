import { Box, Typography } from '@mui/material'
import { ReactComponent as NoItemFoundIcon } from '../../../assets/images/NoItemIcon.svg'

const NoProjectRow = () => {
  return (
    <Box
      border='1px solid #EEEEEE'
      width='97%'
      height='60px'
      borderRadius='5px 0px 0px 0px'
      display='flex'
      alignItems='center'
      justifyContent='center'
      marginTop='0.4rem'
      marginLeft='16px'
    >
      <NoItemFoundIcon />
      <Typography
        fontFamily='Montserrat-Regular'
        fontSize='0.9rem'
        color='#444444'
        marginLeft='0.5rem'
      >
        No Project Reports
      </Typography>
    </Box>
  )
}

export default NoProjectRow
