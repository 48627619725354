import { Box, FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import { useEffect, useState } from 'react'
import { formLabel, textFileds } from '../CommontStyleForResource'
import { Dispatch } from 'redux'
import { RootState } from '../../../../configureStore'
import { projectManagementEntity } from '../../../../reducers'
import {
  fetchProjectLocation,
  getAllResourceReport,
  getAllResourceReportDropdownData,
  projectSource,
} from '../../../../actions'
import { connect, useDispatch, useSelector } from 'react-redux'

export const styleForMenuItem = {
  fontSize: '0.950rem',
  fontFamily: 'Montserrat-Medium',
}

const mainContainer = {
  display: 'flex',
  width: '100%',
  height: 'fit-content',
  justifyContent: 'space-between',
  // '@media (max-width: 1067px)': {
  //   marginLeft: '0px',
  // },
  // backgroundColor:'#BBB'
}

const innerMainContainer = {
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  padding: '0 1%',
  gap: 0,
  '@media (max-width: 1067px)': {
    width: '60%',
    height: 'fit-content',
    gap: '15px',
  },
  '@media (max-width: 893px)': {
    width: '70%',
  },
  '@media (max-width: 770px)': {
    width: '80%',
  },
  '@media (max-width: 710px)': {
    width: '95%',
  },
}

interface PropValue {
  setProjectState: any
  setLocation: any
  setEmploymentType: any
  setPage: (data: number) => void
  selectData: any
  selectForSource: string
  projectState: string
  location: string
  employmentType: string
  setSelectForSource: any
  fetchProjectLocation: any
  projectLocation: any
  getAllProjectResourceReport: any
  fetchResourceReport: any
  projectResourceDropdownAPI: any
  resourceReportDropdownData: any
  fetchProjectSource: any
}
const ProjectsSelectTab = (props: PropValue) => {
  const {
    selectForSource,
    setSelectForSource,
    setProjectState,
    setPage,
    projectState,
    location,
    employmentType,
    setEmploymentType,
    setLocation,
    projectLocation,
    fetchProjectLocation,
    projectResourceDropdownAPI,
    resourceReportDropdownData,
    fetchProjectSource,
  } = props

  const [locationAnchor, setLocationAnchor] = useState(false)
  const [projectAnchor, setProjectAnchor] = useState(false)
  const [typeAnchor, setTypeAnchor] = useState(false)
  const [sourceAnchor, setSourceAnchor] = useState(false)

  const projectSourceList = useSelector(
    (state: any) => state.entities.projectManagement.fetchProjectSource,
  )
  useEffect(() => {
    fetchProjectLocation()
    projectResourceDropdownAPI()
    fetchProjectSource()
  }, [])

  const handleProjects = (event: { target: { value: string } }) => {
    setProjectState(event.target.value)
    setPage(1)
  }

  const handleSource = (event: { target: { value: string } }) => {
    setSelectForSource(event.target.value)
    setPage(1)
  }

  const handleEmpType = (event: { target: { value: string } }) => {
    setEmploymentType(event.target.value)
    setPage(1)
  }

  const handleLocation = (event: { target: { value: string } }) => {
    setLocation(event.target.value)
    setPage(1)
  }

  return (
    <Box sx={mainContainer}>
      <Box sx={innerMainContainer}>
        <Box
          width='auto'
          height='100%'
          display='flex'
          justifyContent='space-between'
          marginLeft='6px'
          alignItems='center'
        >
          <FormControl sx={textFileds}>
            <InputLabel sx={formLabel}>Projects</InputLabel>
            <Select
              value={projectState === '' ? 'all' : projectState}
              onChange={handleProjects}
              label='Projects'
              MenuProps={{
                PaperProps: {
                  sx: {
                    maxHeight: '150px',
                    overflowY: 'auto',
                  },
                },
              }}
              open={projectAnchor}
              onClick={() => setProjectAnchor((prev) => !prev)}
              onOpen={() => window.addEventListener('scroll', () => setProjectAnchor(false))}
              onClose={() => window.removeEventListener('scroll', () => setProjectAnchor(false))}
            >
              <MenuItem sx={styleForMenuItem} value='all'>
                All
              </MenuItem>
              {resourceReportDropdownData?.map((project_name: any) => (
                <MenuItem key={`${project_name?.id}`} value={project_name.id}>
                  {project_name.project_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        <Box
          width='auto'
          height='100%'
          display='flex'
          justifyContent='space-between'
          alignItems='center'
        >
          <FormControl sx={textFileds}>
            <InputLabel sx={formLabel}>Project Source</InputLabel>
            <Select
              label='Project Source'
              value={selectForSource === '' ? 'all' : selectForSource}
              onChange={handleSource}
              MenuProps={{ PaperProps: { sx: { maxHeight: '150px', overflowY: 'auto' } } }}
              open={sourceAnchor}
              onClick={() => setSourceAnchor((prev) => !prev)}
              onOpen={() => window.addEventListener('scroll', () => setSourceAnchor(false))}
              onClose={() => window.removeEventListener('scroll', () => setSourceAnchor(false))}
            >
              <MenuItem value='all'>{`All`}</MenuItem>
              {projectSourceList.map((source:{id:number, source_name:string})=>{
                return <MenuItem value={`${source.id}`}>{source.source_name}</MenuItem>
              })}
            </Select>
          </FormControl>
        </Box>

        <Box
          width='auto'
          height='100%'
          display='flex'
          justifyContent='space-between'
          alignItems='center'
        >
          <FormControl sx={textFileds}>
            <InputLabel sx={formLabel}>Employment Type</InputLabel>
            <Select
              value={employmentType === '' ? 'all' : employmentType}
              onChange={handleEmpType}
              label='Employment Type'
              MenuProps={{ PaperProps: { sx: { maxHeight: '150px', overflowY: 'auto' } } }}
              open={typeAnchor}
              onClick={() => setTypeAnchor((prev) => !prev)}
              onOpen={() => window.addEventListener('scroll', () => setTypeAnchor(false))}
              onClose={() => window.removeEventListener('scroll', () => setTypeAnchor(false))}
            >
              <MenuItem value='all'>All</MenuItem>
              <MenuItem value='Billable'>Billable</MenuItem>
              <MenuItem value='Non-Billable'>Non-Billable</MenuItem>
            </Select>
          </FormControl>
        </Box>

        <Box
          width='auto'
          height='100%'
          display='flex'
          justifyContent='space-between'
          alignItems='center'
          sx={{
            '@media (max-width: 1020px)': { marginTop: '10px' },
            '@media (max-width: 1010px)': { marginTop: '0px' },
          }}
        >
          <FormControl sx={textFileds}>
            <InputLabel sx={formLabel}>Project Location</InputLabel>
            <Select
              value={location === '' ? 'all' : location}
              onChange={handleLocation}
              label='Project Location'
              MenuProps={{ PaperProps: { sx: { maxHeight: '150px', overflowY: 'auto' } } }}
              open={locationAnchor}
              onClick={() => setLocationAnchor((prev) => !prev)}
              onOpen={() => window.addEventListener('scroll', () => setLocationAnchor(false))}
              onClose={() => window.removeEventListener('scroll', () => setLocationAnchor(false))}
            >
              <MenuItem value='all'>All</MenuItem>
              {projectLocation?.map((location: any) => (
                <MenuItem key={`${location?.id}`} value={location.id}>
                  {location.location}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Box>
    </Box>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    projectLocation: projectManagementEntity.getAllProjectManagement(state).fetchAllProjectLocation,
    fetchResourceReport:
      projectManagementEntity.getAllProjectManagement(state).getResourceReportData,
    resourceReportDropdownData:
      projectManagementEntity.getAllProjectManagement(state).getResourceReportDropdowndata,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    fetchProjectLocation: () => dispatch(fetchProjectLocation.request()),
    getAllProjectResourceReport: (data: {
      startDate: string
      endDate: string
      project: string
      projectSource: string
      location: string
      employmentType: string
    }) => dispatch(getAllResourceReport.request(data)),
    projectResourceDropdownAPI: () => dispatch(getAllResourceReportDropdownData.request()),
    fetchProjectSource: () => dispatch(projectSource.request()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectsSelectTab)
