import { Box, CircularProgress, Pagination, Paper, Stack, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import { Dispatch } from 'redux'
import { connect, useSelector } from 'react-redux'
import {
  addCommentPlanForTheDay,
  addCommentTimesheet,
  approvePlanForTheDay,
  assignedTaskMyTeam,
  assinedTask,
  editPlanForTheDay,
  fetchFollowUpTask,
  fetchMandateType,
  fetchReports,
  getDownLoadCsvForMyTeam,
  getTaskStatus,
} from '../../actions'
import React from 'react'
import { GetDataType } from './ProjectSheetTypes'
import { RootState } from '../../configureStore'
import NoProjectRow from './NoProjectRow'
import ActionBar from './ActionBar'
import ShowEnteries from './ShowEntries'
import { style } from './SheetStyles'
import {
  attendanceUI,
  dashboardEntity,
  dashboardUI,
  projectManagementEntity,
  projectManagementUI,
} from '../../reducers'
import TableLayout from './TableLayout'

import { Tab, Tabs } from '@mui/material'
import SheetOverview from './SheetOverview'
import { HeaderHeading } from '../Common/CommonStyles'
import CommonTableContainer from './AssignedTasks/table/CommonTableContainer'
import FollowUpTable from './FollowUpTable'
import { getFixedT } from 'i18next'
import { getFollowUpTask } from '../../services'

const stylesForProjectSheet = {
  backNavigave: {
    position: 'absolute',
    top: 80,
    right: 25,
    margin: '20px',
    cursor: 'pointer',
  },
}

const Reports = (props: any) => {
  const {
    fetchTableData,
    tableData,
    isGettingTableData,
    fetchTaskStatus,
    fetchAssignedTaskMyTask,
    fetchFollowUpTask,
    myTeamAssignedTaskData,
    isMyTeamAssignedTaskData,
    getFollowUpTaskData,
  } = props

  const [searchQuery, setSearchQuery] = useState('')
  const [page, setPage] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState(500)
  const [startDate, setStartDate] = useState(dayjs())
  const [endDate, setEndDate] = useState(dayjs())
  const [expand, setExpand] = useState<boolean>(false)
  const [cardData, setCardData] = useState<{ title: string; items: string[] }[]>([])
  const [selectedTab, setSelectedTab] = useState(0)
  const [showResults, setShowResults] = useState(true)

  const [selectedPlanForTheDay, setSelectedPlanForTheDay] = useState<any | null>(null)
  const [copyTooltipOpen, setCopyTooltipOpen] = useState(false)
  const [hoverTooltipOpen, setHoverTooltipOpen] = useState(false)
  const rowsToDisplay = selectedTab === 2 ? myTeamAssignedTaskData : tableData?.users

  const handleClosePlanModal = () => {
    setSelectedPlanForTheDay(null)
  }

  function convertTextToHTML(text: string) {
    let htmlText = text.replace(/\\n/g, '<br>')

    htmlText = htmlText.replace(/\*([^*]+)\*/g, '<strong>$1</strong>')

    return <div dangerouslySetInnerHTML={{ __html: htmlText }} />
  }
  const handlePlanRowClick = (data: any) => {
    setSelectedPlanForTheDay(data)
  }

  useEffect(() => {
    props.getMandateType({
      data: { pageSize: '', search: '', page: '' },
    })
    fetchTaskStatus()
    // fetchFollowUpTask({
    //   startDate: startDate.format('YYYY-MM-DD').toString(),
    //   endDate: endDate.format('YYYY-MM-DD').toString(),
    // })
  }, [])

  useEffect(() => {
    if (selectedTab === 3) {
      fetchFollowUpTask({
        startDate: startDate.format('YYYY-MM-DD').toString(),
        endDate: endDate.format('YYYY-MM-DD').toString(),
      })
    }
  }, [showResults, selectedTab])

  useEffect(() => {
    if (selectedTab === 2) {
      fetchAssignedTaskMyTask({
        startDate: startDate.format('YYYY-MM-DD').toString(),
        endDate: endDate.format('YYYY-MM-DD').toString(),
        page: page.toString(),
        limit: rowsPerPage.toString(),
        search: searchQuery,
      })
    }
  }, [showResults, selectedTab])

  useEffect(() => {
    if (selectedTab === 0 || selectedTab === 1) {
      fetchTableData({
        startDate: startDate.format('YYYY-MM-DD').toString(),
        endDate: endDate.format('YYYY-MM-DD').toString(),
        page: page.toString(),
        limit: rowsPerPage.toString(),
        search: searchQuery,
        reportType: selectedTab,
      })
    }
  }, [showResults, page, searchQuery, rowsPerPage, fetchTableData])

  useEffect(() => {
    setPage(1)
  }, [rowsPerPage, searchQuery, startDate, endDate])

  const handleChangePage = (event: React.ChangeEvent<unknown> | null, newPage: number) => {
    setPage(newPage)
  }

  const CompanyName = useSelector(
    (state: { entities: { dashboard: { getUserDetails: { company_name: string } } } }) =>
      state?.entities?.dashboard?.getUserDetails?.company_name,
  )

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue)
  }

  useEffect(() => {
    if (props.getApproveSuccessStatus) {
      fetchTableData({
        startDate: startDate.format('YYYY-MM-DD').toString(),
        endDate: endDate.format('YYYY-MM-DD').toString(),
        page: page.toString(),
        limit: rowsPerPage.toString(),
        search: searchQuery,
        reportType: selectedTab,
      })
    }
  }, [props.getApproveSuccessStatus])

  useEffect(() => {
    if (props.getCommentSuccess) {
      fetchTableData({
        startDate: startDate.format('YYYY-MM-DD').toString(),
        endDate: endDate.format('YYYY-MM-DD').toString(),
        page: page.toString(),
        limit: rowsPerPage.toString(),
        search: searchQuery,
        reportType: selectedTab,
      })
    }
  }, [props.getCommentSuccess])

  useEffect(() => {
    if (props.getPlanForTheDayCommentSuccess) {
      fetchTableData({
        startDate: startDate.format('YYYY-MM-DD').toString(),
        endDate: endDate.format('YYYY-MM-DD').toString(),
        page: page.toString(),
        limit: rowsPerPage.toString(),
        search: searchQuery,
        reportType: selectedTab,
      })
    }
  }, [props.getPlanForTheDayCommentSuccess])

  const handleSelectedTab = (data: any) => {
    setSelectedTab(data)
    if (data !== 2 && data !== 3) {
      fetchTableData({
        startDate: dayjs().format('YYYY-MM-DD').toString(),
        endDate: dayjs().format('YYYY-MM-DD').toString(),
        page: page.toString(),
        limit: rowsPerPage.toString(),
        search: searchQuery,
        reportType: data,
      })
    }
    setStartDate(dayjs())
    setEndDate(dayjs())
  }

  return (
    <Paper sx={style.container}>
      <HeaderHeading>My Team</HeaderHeading>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-start',
          justifyContent: 'flex-start',
          // marginTop: '-25px',
        }}
      >
        <Tabs value={selectedTab} onChange={handleTabChange} aria-label='Tabs for different tables'>
          <Tab label='Plan For The Day' onClick={() => handleSelectedTab(0)} />
          <Tab label='Timesheet' onClick={() => handleSelectedTab(1)} />
          <Tab label='Assigned Tasks' onClick={() => handleSelectedTab(2)} />
          <Tab label='Follow Up Tasks' onClick={() => handleSelectedTab(3)} />
          {/* <Tab label='MY DRs' onClick={() => handleSelectedTab(2)} sx={{textTransform:'none'}}/> */}
        </Tabs>
      </Box>
      <Box sx={style.pageHead}></Box>

      <ActionBar
        expand={expand}
        setExpand={setExpand}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        setPage={setPage}
        setRowsPerPage={setRowsPerPage}
        startDate={startDate}
        endDate={endDate}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        cardData={cardData}
        setShowResults={setShowResults}
        rowsPerPage={rowsPerPage}
        selectedTab={selectedTab}
        subTableData={rowsToDisplay}
      />

      {selectedTab !== 2 && selectedTab !== 3 && (
        <>
          {isGettingTableData && (
            <Box sx={style.loader}>
              <CircularProgress disableShrink />
            </Box>
          )}
          {!isGettingTableData && (
            <Box>
              <TableLayout
                rowsToDisplay={rowsToDisplay}
                onRowClick={handlePlanRowClick}
                selectedTab={selectedTab}
                mandateType={props.mandateTypeData}
              />
              {rowsToDisplay?.length === 0 && <NoProjectRow />}
              {rowsToDisplay === undefined && <NoProjectRow />}
              {rowsToDisplay?.length > 0 && (
                <Stack
                  width='100%'
                  spacing={2}
                  direction='row'
                  justifyContent='flex-end'
                  marginTop='1rem'
                >
                  <ShowEnteries
                    setPage={setPage}
                    setRowsPerPage={setRowsPerPage}
                    rowsPerPage={rowsPerPage}
                  />
                  <Pagination
                    count={Math.ceil(tableData?.count / rowsPerPage)}
                    page={page}
                    onChange={handleChangePage}
                    color='primary'
                  />
                </Stack>
              )}
            </Box>
          )}
        </>
      )}

      {selectedTab === 2 && <CommonTableContainer tableData={rowsToDisplay} />}

      {selectedTab === 3 && <FollowUpTable tableData={getFollowUpTaskData} />}

      <SheetOverview
        selectedPlanForTheDay={selectedPlanForTheDay}
        copyTooltipOpen={copyTooltipOpen}
        hoverTooltipOpen={hoverTooltipOpen}
        setCopyTooltipOpen={setCopyTooltipOpen}
        setHoverTooltipOpen={setHoverTooltipOpen}
        setSelectedPlanForTheDay={setSelectedPlanForTheDay}
        handleClosePlanModal={handleClosePlanModal}
        convertTextToHTML={convertTextToHTML}
        approvePlanForTheDayDispatchCall={props.approvePlanForTheDayDispatchCall}
        getApproveSuccessStatus={props.getApproveSuccessStatus}
        getApproveLoader={props.getApproveLoader}
        resetPlanForTheDayApprove={props.resetPlanForTheDayApprove}
        createCommentPlanForTheDay={props.createCommentPlanForTheDay}
        createCommentTimesheet={props.createCommentTimesheet}
        getLoaderState={props.getLoaderState}
        resetTimesheetComment={props.resetTimesheetComment}
        getCommentSuccess={props.getCommentSuccess}
        getPlanForTheDayCommentSuccess={props.getPlanForTheDayCommentSuccess}
        getPlanForTheDayLoaderState={props.getPlanForTheDayLoaderState}
        resetPlanForTheDayComment={props.resetPlanForTheDayComment}
      />
    </Paper>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    isMyTeamAssignedTaskData: dashboardUI.getDashboard(state).isAssignedTaskMyTeam,
    myTeamAssignedTaskData: dashboardEntity.getDashboard(state).getAssignedTaskMyTeam,
    getFollowUpTaskData: dashboardEntity.getDashboard(state).getFollowUpTask,
    tableData: projectManagementEntity.getAllProjectManagement(state).getReports,
    isGettingTableData: projectManagementUI.getProjectManagemet(state).isGettingReportsList,
    mandateTypeData: projectManagementEntity.getAllProjectManagement(state).getAllMandate,
    getApproveSuccessStatus:
      attendanceUI.getEmpAttendanceList(state).isApprovingPlanForTheDaySuccess,
    getApproveLoader: attendanceUI.getEmpAttendanceList(state).isApprovingPlanForTheDay,
    getPlanForTheDayCommentSuccess:
      attendanceUI.getEmpAttendanceList(state).isCreateCommentForPlanForTheDaySuccess,
    getCommentSuccess: attendanceUI.getEmpAttendanceList(state).isCreateCommentForTimesheetSuccess,
    getLoaderState: attendanceUI.getEmpAttendanceList(state).isCreateCommentForTimesheetLoader,
    getPlanForTheDayLoaderState:
      attendanceUI.getEmpAttendanceList(state).isCreateCommentForPlanForTheDayLoader,
    isUpdatedPlanForTheDayList: attendanceUI.getEmpAttendanceList(state).isUpdatePlanForTheDayList,
  }
}
const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    fetchAssignedTaskMyTask: (data: any) => dispatch(assignedTaskMyTeam.request(data)),
    fetchFollowUpTask: (data: any) => dispatch(fetchFollowUpTask.request(data)),
    fetchTableData: (data: GetDataType) => dispatch(fetchReports.request(data)),
    getMandateType: (data: any) => dispatch(fetchMandateType.request(data)),
    approvePlanForTheDayDispatchCall: (data: any) => dispatch(approvePlanForTheDay.request(data)),
    resetPlanForTheDayApprove: () => dispatch(approvePlanForTheDay.reset()),
    createCommentPlanForTheDay: (data: any) => dispatch(addCommentPlanForTheDay.request(data)),
    createCommentTimesheet: (data: any) => dispatch(addCommentTimesheet.request(data)),
    resetTimesheetComment: () => dispatch(addCommentTimesheet.reset()),
    resetPlanForTheDayComment: () => dispatch(addCommentPlanForTheDay.reset()),
    resetEditPlanForTheDay: (data: any) => dispatch(editPlanForTheDay.reset()),
    fetchTaskStatus: () => dispatch(getTaskStatus.request()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Reports)
