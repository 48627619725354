import useWindowSize from 'react-use/lib/useWindowSize'
import ReactConfetti from 'react-confetti'

const Confetti = ({ recycle = true }) => {
  const { width, height } = useWindowSize()

  return (
    <>
      <ReactConfetti width={width - 105} height={height} recycle={recycle} numberOfPieces={300} />
    </>
  )
}

export default Confetti
