import html2pdf from 'html2pdf.js'

export const exportAsPDF = () => {
  const heading = document.getElementsByClassName('heading')
  const cards = document.getElementById('card')
  const head = document.getElementById('head')
  const body = document.querySelectorAll('#body')

  if (body.length > 0 && head && heading) {
    const parent = document.createElement('div')
    for (let i = 0; i < 2; i++) {
      parent.appendChild(heading[i].cloneNode(true))
    }

    if (cards) {
      const cardPar = cards.cloneNode(true) as HTMLDivElement
      cardPar.style.width = '800px'
      parent.appendChild(cardPar)
    }

    const newHead = head.cloneNode(true) as HTMLDivElement
    parent.appendChild(newHead)

    const bodyArray = Array.from(body)

    for (let element of bodyArray) {
        parent.appendChild(element.cloneNode(true))
    }

    const options = {
      margin: 10,
      filename: `${(heading[1].cloneNode(true) as HTMLElement).innerText}.pdf`,
      html2canvas: { dpi: 480, width: 1000, scale: 2 },
      jsPDF: { unit: 'pt', format: 'a4', orientation: 'landscape' },
    }

    html2pdf().set(options).from(parent).save()
  }
}
