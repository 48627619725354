import { combineReducers } from 'redux'
import { actionTypes } from '../../actions'
import { Actions as actions } from '../../actions/Types'
import { RootState } from '../../configureStore'
import { STATUS_TYPE,TASK_STATUS, FETCH_PLANS_FOR_THE_DAY, FETCH_IDSR, FETCH_PROJECTS_NAME, REQUEST, SUCCESS } from '../../actions/actiontypes'

const { FETCH_RCA } = actionTypes

const entity = () => {
    const fetchRCAs = (state = [], action: actions) => {
        switch (action.type) {
            case FETCH_RCA[SUCCESS]:
                return action.payload
            case FETCH_RCA[REQUEST]:
                return []

            default:
                return state
        }
    }

    const fetchIDSRs = (state = [], action: actions) => {
        switch (action.type) {
            case FETCH_IDSR[SUCCESS]:
                return action.payload
            case FETCH_IDSR[REQUEST]:
                return []

            default:
                return state
        }
    }

    const fetchPlansForTheDay = (state = [], action: actions) => {
        switch (action.type) {
            case FETCH_PLANS_FOR_THE_DAY[SUCCESS]:
                return action.payload
            case FETCH_PLANS_FOR_THE_DAY[REQUEST]:
                return []

            default:
                return state
        }
    }

    const fetchStatusType = (state = [], action: actions) => {
        switch (action.type) {
            case STATUS_TYPE[SUCCESS]:
                return action.payload
            case STATUS_TYPE[REQUEST]:
                return []

            default:
                return state
        }
    }

    const fetchTaskStatus = (state = [], action: actions) => {
        switch (action.type) {
            case TASK_STATUS[SUCCESS]:
                return action.payload
            case TASK_STATUS[REQUEST]:
                return []

            default:
                return state
        }
    }
     
    const fetchProjects = (state = [], action: actions) => {
        switch (action.type) {
            case FETCH_PROJECTS_NAME[SUCCESS]:
                return action.payload
            case FETCH_PROJECTS_NAME[REQUEST]:
                return []

            default:
                return state
        }
    }


    return combineReducers({
        fetchRCAs,
        fetchIDSRs,
        fetchProjects,
        fetchPlansForTheDay,
        fetchStatusType,
        fetchTaskStatus
    })
}

export default entity

export const getAllRCAsList = (state: RootState) => state.entities.fetchRCAs
export const getAllIDSRList =(state: RootState) => state.entities.fetchIDSRs
export const getAllPlansList = (state : RootState) => state.entities.fetchPlans
export const getStatusTypeList = (state : RootState) => state.entities.fetchStatusType



 