import * as React from 'react'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { useNavigate } from 'react-router-dom'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props
  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

export default function SRTab(props: any) {

  const {fetchSRData1, state} = props
  const [value, setValue] = React.useState(0)
  const navigate = useNavigate()

  React.useEffect(() => {
    if (window.location.pathname === '/home/assigned-request') {
      setValue(1)
    } else if (window.location.pathname === '/home/service-request' && state?.providedFeedback) {
      // setValue(isAssignable === 'true' ? 2 : 1)
      setValue(1)
    }else {
      setValue(0)
    }
  }, [])

  // useEffect(() => {
  //   if (state?.providedFeedback)
  //     fetchSRData({
  //       page,
  //       pageSize,
  //       issueId: state?.issueId ?? 33,
  //       isFeedback: state?.providedFeedback,
  //     })
  // }, [state?.providedFeedback])

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    // fetchSRData1()
    setValue(newValue)
  }

  const handleClick = (type: string) => {
    switch (type) {
      case 'service-request':
        return navigate('/home/service-request', {
          state: {
            ...props.state,
            providedFeedback: false,
          },
        })
      case 'assigned-request':
        return navigate('/home/assigned-request', {
          state: {
            ...props.state,
            providedFeedback: false,
          },
        })
      case 'provided-feedback':
        return navigate('/home/service-request', {
          state: {
            ...props.state,
            providedFeedback: true,
          },
        })
    }
  }
  const isAssignable = localStorage.getItem('is_assignable')
  return (
    <Box sx={{ width: '100%', marginTop: props?.state ? '-15px' : '' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label='basic tabs example'>
          <Tab
            label={props.state.tabName === 'Feedback Details' ? 'Raised Feedback' : 'My Request'}
            {...a11yProps(0)}
            onClick={() => handleClick('service-request')}
          />
          {isAssignable === 'true' && props.state.tabName !== 'Feedback Details' && props.state.tabName !== 'Leave Details'? (
            <Tab
              label={'Assigned Request'}
              {...a11yProps(1)}
              onClick={() => handleClick('assigned-request')}
            />
          ) : null}
          {props.state.tabName === 'Feedback Details' && (
            <Tab
              label={'Provided Feedback'}
              {...a11yProps(2)}
              onClick={() => handleClick('provided-feedback')}
            />
          )}
        </Tabs>
      </Box>
      {/* <CustomTabPanel value={value} index={0}>
        My Request
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        Assigned Request
      </CustomTabPanel> */}
    </Box>
  )
}
