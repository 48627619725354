import { all } from 'redux-saga/effects'
import { sagas as dashboardSagas } from './dashboard'
import { sagas as userAuthentication } from './userAuthentication'
import { sagas as loginUser } from './loginUser'
import { sagas as employeePortalSaga } from './employeePortal'
import { sagas as financeSaga } from './finance'
import { sagas as loansSaga } from './loans'
import { sagas as employeeSaga } from './employeeList'
import { sagas as serviceRequestSaga } from './ServiceRequest'
import { sagas as statusSummarySaga } from './statusSummary'
import { sagas as attendanceSaga } from "./attendance"
import { sagas as fetchRCAsSaga } from "./fetchRCAs"
import { sagas as getGraphData} from "./projectGraph"
import { sagas as orgChartSagas} from "./orgChart"
import { sagas as createUserSagas } from './createUser'

export default function* rootSaga() {
  yield all({
    ...dashboardSagas,
    ...userAuthentication,
    ...loginUser,
    ...employeePortalSaga,
    ...financeSaga,
    ...loansSaga,
    ...employeeSaga,
    ...serviceRequestSaga,
    ...statusSummarySaga,
    ...attendanceSaga,
    ...fetchRCAsSaga,
    ...getGraphData,
    ...orgChartSagas,
    ...createUserSagas,
  })
}
