import { combineReducers } from 'redux'
import { actionTypes } from '../../actions'
import { Actions } from '../../actions/Types'
import { RootState } from '../../configureStore'
import {
  DOWNLOAD_FORM16,
  FETCH_BANKINFO,
  FETCH_COMPENSATION_DETAILS,
  GET_PAY_SLIP,
  GET_TIMESHEET,
  PAYROLL_FORM16,
  RESET,
  SEND_EMAIL,
  SEND_LOAN_REQUEST,
  UPLOAD_FORM16,
} from '../../actions/actiontypes'

const { SUCCESS, REQUEST, FAILURE } = actionTypes

const ui = () => {
  const isCompensationData = (state = false, action: Actions) => {
    switch (action.type) {
      case FETCH_COMPENSATION_DETAILS[SUCCESS]:
        return false
      case FETCH_COMPENSATION_DETAILS[FAILURE]:
        return false
      case FETCH_COMPENSATION_DETAILS[REQUEST]:
        return true
      default:
        return state
    }
  }

  const isLoanRequestSuccess = (state = false, action: Actions) => {
    switch (action.type) {
      case SEND_LOAN_REQUEST[SUCCESS]:
        return true
      case SEND_LOAN_REQUEST[FAILURE]:
      case SEND_LOAN_REQUEST[REQUEST]:
      case SEND_LOAN_REQUEST[RESET]:
        return false
      default:
        return state
    }
  }

  const isPayslipData = (state = false, action: Actions) => {
    switch (action.type) {
      case GET_PAY_SLIP[SUCCESS]:
        return false
      case GET_PAY_SLIP[FAILURE]:
        return false
      case GET_PAY_SLIP[REQUEST]:
        return true
      default:
        return state
    }
  }

  const isGettingBankInfo = (state = false, action: Actions) => {
    switch (action.type) {
      case FETCH_BANKINFO[SUCCESS]:
        return false
      case FETCH_BANKINFO[REQUEST]:
        return true
      default:
        return state
    }
  }

  const isTimesheetData = (state = false, action: Actions) => {
    switch (action.type) {
      case GET_TIMESHEET[SUCCESS]:
        return true
      case GET_TIMESHEET[FAILURE]:
      case GET_TIMESHEET[REQUEST]:
        return false
      default:
        return state
    }
  }

  const isUploadedForm16 = (state = false, action: Actions) => {
    switch (action.type) {
      case UPLOAD_FORM16[SUCCESS]:
      case UPLOAD_FORM16[FAILURE]:
        return false
      case UPLOAD_FORM16[REQUEST]:
        return true
      default:
        return state
    }
  }

  const isSendEmailLoader = (state = false, action: Actions) => {
    switch (action.type) {
      case SEND_EMAIL[SUCCESS]:
      case SEND_EMAIL[FAILURE]:
        return false
      case SEND_EMAIL[REQUEST]:
        return true
      default:
        return state
    }
  }

  const isSendEmailLoaderSuccess = (state = false, action: Actions) => {
    switch (action.type) {
      case SEND_EMAIL[SUCCESS]:
        return true
      case SEND_EMAIL[FAILURE]:
      case SEND_EMAIL[REQUEST]:
      case SEND_EMAIL[RESET]:
        return false
      default:
        return state
    }
  }

  const isUploadedForm16ToastSuccess = (state = false, action: Actions) => {
    switch (action.type) {
      case UPLOAD_FORM16[SUCCESS]:
        return true
      case UPLOAD_FORM16[FAILURE]:
      case UPLOAD_FORM16[REQUEST]:
      case UPLOAD_FORM16[RESET]:
        return false
      default:
        return state
    }
  }
  

  const isDownloadedForm16Loader = (state = false, action: Actions) => {
    switch (action.type) {
      case DOWNLOAD_FORM16[SUCCESS]:
        case DOWNLOAD_FORM16[FAILURE]:
        return false
      case DOWNLOAD_FORM16[REQUEST]:
        return true
      default:
        return state
    }
  }

  const isDownloadedFormSucesss = (state = false, action: Actions) => {
    switch (action.type) {
      case DOWNLOAD_FORM16[SUCCESS]:
        case DOWNLOAD_FORM16[FAILURE]:
        return true
      case DOWNLOAD_FORM16[REQUEST]:
        return false
      case DOWNLOAD_FORM16[RESET]:
        return false
      default:
        return state
    }
  }


  const isDownloadedForm16 = (state = false, action: Actions) => {
    switch (action.type) {
      case DOWNLOAD_FORM16[SUCCESS]:
        return true
      case DOWNLOAD_FORM16[FAILURE]:
      case DOWNLOAD_FORM16[REQUEST]:
      case DOWNLOAD_FORM16[RESET]:
        return false

      default:
        return state
    }
  }
  
  const isForm16DataLoader = (state = false, action: Actions) => {
    switch (action.type) {
      case PAYROLL_FORM16[SUCCESS]:
      case PAYROLL_FORM16[FAILURE]:
        return false
      case PAYROLL_FORM16[REQUEST]:
        return true
      default:
        return state
    }
  }

  return combineReducers({
    isCompensationData,
    isPayslipData,
    isTimesheetData,
    isLoanRequestSuccess,
    isGettingBankInfo,
    isUploadedForm16,
    isDownloadedForm16,
    isDownloadedForm16Loader,
    isDownloadedFormSucesss,
    isForm16DataLoader,
    isUploadedForm16ToastSuccess,
    isSendEmailLoader,
    isSendEmailLoaderSuccess,
  })
}

export default ui

export const getFinance = (state: RootState) => state.ui.finance
