// App Colors
export const Primary = '#193C6D'

export const Secondary = '#F2D5F2'

export const TextColor = '#0D0D0D'

export const SecondaryV2 = '#4E2973'

export const Background = '#F8F9FF'

export const SecondaryButtonColor = '#818E94'

// Auth
export const AuthObject = {
  username: '',
  password: '',
}

// Constant URLs
export const BaseURLAdmin = ``
export const BaseURLPlatform = ``
export const AuthBaseURL = ``
