import { Button, MenuItem, TextField } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { DatePicker } from '@mui/x-date-pickers'
import styled from 'styled-components'

export const InputField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-input': {
    padding: '13.5px 14px', // Adjust the padding here
    fontSize: '13px', // Adjust the font size here
    fontFamily: 'Montserrat-Medium',
  },
  '& .MuiFormLabel-asterisk': {
    color: 'red',
  },
  '& .MuiInputBase-root.MuiOutlinedInput-root': {
    fontFamily: 'Montserrat-Medium',
    borderRadius: '20px',
  },
  '& .MuiFormLabel-root.MuiInputLabel-root': {
    marginLeft:'-5px !important',
    fontSize: '15px',
    lineHeight: '1.8em',
    fontFamily: 'Montserrat-Medium',
    backgroundColor: 'white',
    padding: '0 7px'
  },

}))

export const SelectField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-input': {
    padding: '11px 14px', // Adjust the padding here
    fontSize: '15px', // Adjust the font size here
    fontFamily: 'Montserrat-Medium',
  },
  '& .MuiFormLabel-root': {
    marginTop: '2px',
    fontSize: '15px',
    fontFamily: 'Montserrat-Medium',
        backgroundColor: 'white',
    padding: '0 7px'
  },
  '& .MuiFormLabel-asterisk': {
    color: 'red',
  },
  '& .MuiInputBase-root.MuiOutlinedInput-root': {
    borderRadius: '20px',
  },
}))

export const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  fontSize: '15px !important',
  fontFamily: 'Montserrat-Medium !important',
}))

export const useStyles = makeStyles((theme: { spacing: (arg0: number) => any }) => ({
  root: {
    width: '100%',
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}))

export const CommonButton = styled(Button)(({ theme }) => ({
  fontSize: '16px !important',
  fontFamily: 'Montserrat-SemiBold !important',
  width: '20%',
  borderRadius: '20px !important',
}))

export const DateField = styled(DatePicker)(({ theme }) => ({
  marginTop: '5px',
  marginBottom: '5px',
  '& .MuiOutlinedInput-input': {
    padding: '13.5px 14px',
    fontSize: '13px',
    fontFamily: 'Montserrat-Medium',
  },
  '& .MuiFormLabel-asterisk': {
    color: 'red',
  },
  '& .MuiFormLabel-root, & .MuiInputLabel-root': {
    lineHeight: '1em',
  },
  '& .MuiInputBase-root.MuiOutlinedInput-root': {
    borderRadius: '20px',
  },
}))