import { Box, Typography } from '@mui/material'
import { style } from './ projectQAReportStyles'
import { HeadingPropType } from './projectQAReportTypes'
import { useSelector } from 'react-redux'

export const Heading = ({startDate,endDate}:HeadingPropType) => {
  const CompanyName = useSelector((state:{entities:{dashboard:{getUserDetails:{company_name:string}}}})=> state?.entities?.dashboard?.getUserDetails?.company_name)


  return (
      <Box sx={style.headingBar}>
          <Typography width='fit-content' className='heading' sx={style.primaryHeading}>
            {CompanyName}
          </Typography>
          <Typography className='heading' sx={style.headerHeading}>
            QA Report from {startDate.format('MM/DD/YYYY')} to{' '}
            {endDate.format('MM/DD/YYYY')}
          </Typography>
        </Box>
  )
}

export default Heading