export const style = {
    customBox: {
      '@media only screen and (max-width: 911px)': {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: '20px',
      },
      '@media only screen and (max-width: 650px )': {
        display: 'flex',
      },
    },
    dialogContentBoxStyle: {
      width: '100%',
    },
    dialogContentMsgBox: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
    },
    secondTypographyInContent: {
      fontSize: '16.5px',
      fontFamily: 'Montserrat-Medium',
      display: 'flex',
      justifyContent: 'center',
      '@media (max-width: 700px)': {
        fontSize: '0.875rem',
        paddingBottom: '10px',
      },
    },
    dialogActions: {
      display: 'flex',
      justifyContent: 'end',
      margin: '5px 0',
      gap: '5px',
    },
    iconButtonStyle: {
      position: 'absolute',
      right: 8,
      top: 8,
      color: '#9e9e9e',
    },
    BackdropDiaglogStyle: {
      backdropFilter: 'blur(1px)',
      padding: '50px',
      '& .MuiDialogContent-root': {
        flex: '1 1 auto',
        overflowY: 'auto',
        padding: '10px 5px',
        borderTop: '1px solid rgba(0, 0, 0, 0.12)',
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        width: '360px',
      },
      '& .MuiDialogTitle-root': {
        padding: '10px 0 5px 0',
      },
    },
    dialogTitleStyle: {
      display: 'flex',
      justifyContent: 'center',
    },
  }
  