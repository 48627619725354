import { Box, CircularProgress, Paper, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import { style } from './ProjectManagementReportStyle'
import SearchBox from './SearchBox'
import { Dispatch } from 'redux'
import { connect, useSelector } from 'react-redux'
import DateRangePicker from './DateRangePicker'
import TableLayout from './TableLayout'
import ExportButtons from './ExportButtons'
import SelectResources from './SelectResources'
import CardSection from './CardSection'
import { RootState } from '../../../configureStore'
import { projectManagementEntity, projectManagementUI } from '../../../reducers'
import { useNavigate } from 'react-router-dom'
import { ArrowBack } from '@mui/icons-material'
import { fetchProjectManagementReports } from '../../../actions'


const styles = {
  backNavigate:{
  
      position: 'absolute',
      top: 80,
      right: 25,
      margin: '20px',
      cursor: 'pointer',
  
  },
}

const ProjectManagementReport = (props: any) => {

  const { tableData, fetchTableData, isGettingTableData } = props
  const [startDate, setStartDate] = useState(dayjs(new Date()))
  const [searchQuery, setSearchQuery] = useState('')
  const [page, setPage] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState(100)
  const [tabView, setTabView] = useState('billable')

  

  const handleChangePage = (event: React.ChangeEvent<unknown> | null, newPage: number) => {
    setPage(newPage)
  }

  useEffect(() => {
    fetchTableData({
       date: startDate.format('YYYY-MM-DD').toString(),
       type: tabView,
       page: page,
       limit: rowsPerPage,
       search: searchQuery,
      });
  }, [startDate,tabView,searchQuery,rowsPerPage,page])

  const rowsToDisplay = tableData?.resources

  const navigate = useNavigate()
  
  const CompanyName = useSelector((state:{entities:{dashboard:{getUserDetails:{company_name:string}}}})=> state?.entities?.dashboard?.getUserDetails?.company_name)


  return (
    <Paper sx={style.container}>
      <Box
        sx={styles.backNavigate}
        onClick={() => navigate(-1)}
      >
        <ArrowBack />
      </Box>
      <Box sx={style.pageHead}>
        <Box sx={style.headings}>
          <Typography className='heading' width='fit-content' sx={style.primaryHeading}>
            {CompanyName}
          </Typography>
          <Typography className='heading' width='fit-content' sx={style.headerHeading}>
            Project Management Report As On {startDate.format('MM/DD/YYYY')}
          </Typography>
        </Box>
      </Box>
      <Box sx={style.actionBarConatiner}>
        <Box sx={style.actionBar}>
          <SearchBox searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
          <CardSection tabView={tabView} tableData={tableData}/>
          <Box sx={style.rightGroup}>
            <DateRangePicker startDate={startDate} setStartDate={setStartDate} />
            <SelectResources setPage={setPage} setTabView={setTabView} />
          </Box>
          <ExportButtons />
        </Box>
      </Box>
      {isGettingTableData ? (
        <Box sx={style.loader}>
          <CircularProgress disableShrink />
        </Box>
      ) : (
        <TableLayout
          tableData={tableData}
          tabView={tabView}
          rowsToDisplay={rowsToDisplay}
          page={page}
          handleChangePage={handleChangePage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          setPage={setPage}
        />
      )}
    </Paper>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    tableData:
    projectManagementEntity.getAllProjectManagement(state).getProjectManagementReports,
    isGettingTableData:
    projectManagementUI.getProjectManagemet(state)
        .isGettingProjectManagementReportsList,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    fetchTableData: (data: any) => dispatch(fetchProjectManagementReports.request(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectManagementReport)
