import styled from '@emotion/styled'
import Select from '@mui/material/Select'
import {
  Breadcrumbs,
  Button,
  Card,
  IconButton,
  Grid,
  InputLabel,
  ListItemIcon,
  Pagination,
  Paper,
  Stack,
  StepConnector,
  StepLabel,
  Stepper,
  Tab,
  TableCell,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Typography,
  MenuItem,
  createTheme,
  Toolbar,
  Icon,
  tableCellClasses,
  Divider,
} from '@mui/material'
import { Link, NavLink } from 'react-router-dom'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import AddToPhotosTwoToneIcon from '@mui/icons-material/AddToPhotosTwoTone'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import { Primary, SecondaryButtonColor } from '../../utils'
import SearchIcon from '@mui/icons-material/Search'

interface IStyledNormalText {
  fontvalue?: string
  lineheight?: string
  color?: string
  applydarktheme?: number
  letterSpacing?: string
  textAlign?: string
  opacityValue?: string
  fontWeight?: string
  darkColor?: string
}

interface ISearchBoxCustom {
  width?: string
}

const HeaderHeading = styled(Typography)(({ theme }) => ({
  fontSize: '28px',
  textAlign: 'center',
  fontFamily: 'Montserrat-SemiBold',
  letterSpacing: '0px',
  color: Primary,
}))

const HeaderHeadingDelayedTask = styled(Typography)(({ theme }) => ({
  fontSize: '20px !important',
  textAlign: 'center',
  fontFamily: 'sans-serif',
  letterSpacing: '0px',
  fontWeight:'bold'
}))

export const FamilyDetailsHeading = styled(Typography)(({ theme }) => ({
  fontSize: '25px',
  textAlign: 'center',
  fontFamily: 'Montserrat-SemiBold',
  letterSpacing: '0px',
  color: Primary,
}))

export const EmpTenureHeading = styled(Typography)(({ theme }) => ({
  fontSize: '25px',
  textAlign: 'center',
  fontFamily: 'Montserrat-SemiBold',
  letterSpacing: '0px',
  color: Primary,
}))


const SearchBoxCustom = styled(TextField)<ISearchBoxCustom>(({ width = '250px' }) => ({
  float: 'left',
  '& .MuiOutlinedInput-root': {
    width: `${width}`,
    borderRadius: '20px',
    '& fieldset': {
      borderColor: Primary,
    },
  },
}))

export const StatusBoxCustom = styled(TextField)<ISearchBoxCustom>(({ width = '250px' }) => ({
  float: 'left',
  '& .MuiOutlinedInput-root': {
    width: `200px`,
    borderRadius: '20px',
    '& fieldset': {
      borderColor: Primary,
    },
  },
}))


const SearchIconStyle = styled(SearchIcon)({
  color: Primary,
  paddingRight: '8px',
})

const FlexClass = {
  justifyContent: 'center',
  alignItems: 'center',
  display: 'flex',
}

const FullSize = {
  width: '100%',
  height: '100%',
}

const TableProp = {
  minWidth: 400,
  width: '100%',
}

const DataTableProp = {
  minWidth: 200,
  width: '100%',
}

const TableContainerProp = (styles?: { height: string | number } | undefined) => {
  return { height: styles?.height ? styles.height : '100%', overScroll: 'auto' }
}

const SmallArrow = {
  fontSize: '15px',
  cursor: 'pointer',
}

const RadioProps = {
  display: 'flex',
  flexDirection: 'row',
}

const BoldIcon = { color: 'black', height: '20px', cursor: 'pointer' }
const RedIcon = { color: 'red', height: '20px', cursor: 'pointer' }
const ListButton = { height: '25px', marginLeft: '-4px', cursor: 'pointer' }

const DialogTitleSx = { margin: '15px 20px 0px 20px' }

const DialogContentSx = { margin: '0px 20px 0px 20px', justifyContent: 'center', display: 'flex' }

const DialogActionSx = { justifyContent: 'center', margin: '0px 20px 15px 20px', gap: '20%' }

const loaderProps = {
  backgroundColor: 'transparent',
  boxShadow: 'none',
  height: '50px',
  width: '50px',
}

const BreadCrumbContainer = styled(Stack)({
  width: '100%',
  height: '60px',
  flexDirection: 'row',
  padding: '0px 0px',
})

const SmallIconButton = styled(Button) <{ Color?: string }>`
  height: 35px;
  width: 35px;
  min-width: 50px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  background-color: ${(props) => (props.Color ? props.Color : '#FFFFFF')};
  color: ${(props) => (props.Color ? '#FFFFFF' : Primary)};
  border-color: ${Primary} !important;
  '& svg' {
    font-size: 30px;
  }
`
const StyledBreadCrumbs = styled(Breadcrumbs)({
  marginTop: '10px',
  '& a': {
    textDecoration: 'none',
    color: Primary,
    p: {
      fontWeight: 'bold',
    },
  },
  '& li:not(:last-child)': {
    opacity: 0.9,
    '& a': {
      textDecoration: 'none',
      color: 'inherit',
      p: {
        fontWeight: 'normal',
      },
    },
  },
  '& .MuiBreadcrumbs-separator': {
    margin: '0px 10px',
    fontSize: '28px',
    fontWeight: 'bold',
  },
})

const StyledLink = styled(Link)({
  textDecoration: 'none',
  color: 'black',
  fontWeight: '450',
})

const StyledSelect = styled(Select)({
  height: '30px',
  width: '80px',
  margin: '0px 10px 0px 10px',
})

const StyledPagination = styled(Pagination)({
  paddingRight: '1.5%',
  '& .Mui-selected': {
    color: '#fff',
    backgroundColor: `${Primary} !important`,
    borderRadius: '4px',
    '&:hover': {
      backgroundColor: Primary,
      color: '#fff',
    },
    '&:active': {
      backgroundColor: Primary,
      color: '#fff',
    },
  },
  '& .MuiPaginationItem-icon': {
    backgroundColor: '#ffff',
    borderRadius: '50%',
  },
  '.css-yuzg60-MuiButtonBase-root-MuiPaginationItem-root': {
    fontWeight: '1000',
    fontSize: '11px !important',
  },
})

const StyledButton = styled(Button)({
  height: '35px',
  fontSize: '13px',
  lineHeight: 1,
  fontWeight: 450,
  borderRadius: '4px',
  padding: '0px 15px',
  background: 'none',
  color: Primary,
  border: `1px solid ${Primary} `,
  img: {
    height: '20px',
  },
})

const PopupButton = styled(StyledButton) <{ bgcolor?: string }>`
  min-width: 140px;
  background-color: ${(props) => props.bgcolor || 'defaultBgColor'};
  color: ${(props) => (props.bgcolor ? 'white' : 'defaultTextColor')};
  font-size: 14px;
`

const StyledTableHead = styled(TableHead)({
  position: 'sticky',
  top: 0,
  background: Primary,
  height: '20px',
  padding: '4px',
  borderRadius: '10px',
  '& .MuiTableCell-root': {
    color: '#fff',
  },
})

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: Primary,
    color: 'White',
    fontFamily: 'Montserrat-Medium',
    textAlign: 'left',
    fontSize: '13px',
    letterSpacing: '0px',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
    fontFamily: 'Montserrat-Medium',
    textAlign: 'left',
    color: '#483f3f',
    letterSpacing: '0px',
    paddingLeft: '16px',
    widtgh: 'fit-content'
  },
}))

const StyledTableCellEmp = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: Primary,
    color: 'White',
    fontFamily: 'Montserrat-Medium',
    textAlign: 'left',
    fontSize: '13px',
    letterSpacing: '0px',
    width: '200px', // Fixed width for header
  },
  [`&.${tableCellClasses.body}`]: {
    width: '200px', // Fixed width for body
    fontSize: 12,
    fontFamily: 'Montserrat-Medium',
    textAlign: 'left',
    color: '#483f3f',
    letterSpacing: '0px',
    paddingLeft: '16px',
  },
  // Ensure fixed width at all breakpoints
  width: '200px',
  maxWidth: '200px',
  minWidth: '150px',
}))

const StyledEditableTableCell = styled(TableCell, {
  shouldForwardProp: (prop) => prop !== 'width', // Ensure width prop is passed correctly
})(({ theme, width }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: Primary,
    color: 'White',
    fontFamily: 'Montserrat-Medium',
    textAlign: 'left',
    fontSize: '13px',
    letterSpacing: '0px',
    textTransform:  'capitalize'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
    fontFamily: 'Montserrat-Medium',
    textAlign: 'left',
    color: '#483f3f',
    letterSpacing: '0px',
    paddingLeft: '16px',
    maxWidth: width || '50px',
    minWidth: width || '50px',
    overflow: 'hidden',                // Hide overflow text
    whiteSpace: 'nowrap',              // Prevent line breaks
    textOverflow: 'ellipsis',           // Add ellipsis for overflow text
  },
}));



export const StyledTableCellProjectName = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: Primary,
    color: 'White',
    fontFamily: 'Montserrat-Medium',
    textAlign: 'left',
    fontSize: '13px',
    letterSpacing: '0px',
    width: '200px', // Fixed width for header
  },
  [`&.${tableCellClasses.body}`]: {
    width: '200px', // Fixed width for body
    fontSize: 12,
    fontFamily: 'Montserrat-Medium',
    textAlign: 'left',
    color: '#483f3f',
    letterSpacing: '0px',
    paddingLeft: '16px',
  },
  // Ensure fixed width at all breakpoints
  width: '200px',
  maxWidth: '200px',
  minWidth: '130px',
}))



const StyledTableCellForMyTeam = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: Primary,
    color: 'White',
    fontFamily: 'Montserrat-Medium',
    textAlign: 'left',
    fontSize: '13px',
    letterSpacing: '0px',
    '& .MuiTableCell-root':{padding: "6px"}
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
    fontFamily: 'Montserrat-Medium',
    textAlign: 'left',
    color: '#483f3f',
    letterSpacing: '0px',
    padding: '6px 6px 6px 16px',
  },
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  left: '160px',
  width: '1719px',
  height: '60px',
  boxShadow: '0px 10px 3px #6c6c6c10',
  opacity: '1',
  cursor: 'pointer',
}))

const ModalContainer = styled(Paper)({
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  padding: '20px 30px',
  minWidth: '400px',
  display: 'flex',
  justifyContent: 'center',
})

const StyledSelectInput = styled(Select)<{ width?: string }>(({ width }) => ({
  height: '40px',
  width: width ? width : '300px',
  borderRadius: '10px',
  color: '#000',
  '& svg': {
    height: '22px',
    fill: Primary,
  },
  '& .MuiListItemIcon-root': {
    minWidth: '0px',
  },
  ' & .MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input.MuiSelect-select': {
    display: 'flex',
  },
  zIndex: 99999,
  opacity: 100,
}))

const StyledSelectInputReports = styled(Select)<{ width?: string }>(({ width }) => ({
  height: '40px',
  width: width ? width : '300px',
  borderRadius: '5px',
  color: '#000',
  background: '#fff',
  '& svg': {
    height: '22px',
    fill: Primary,
  },
  '& .MuiListItemIcon-root': {
    minWidth: '0px',
  },
  ' & .MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input.MuiSelect-select': {
    height: '33px',
    display: 'flex',
  },
  zIndex: 99999,
  opacity: 100,
}))
const StyledLable = styled(InputLabel)({
  fontSize: '13px',
  fontWeight: '450',
  color: 'black',
  marginLeft: '2px',
})

const InputContainer = styled(Stack)({
  flexDirection: 'column',
  alignItems: 'flex-start',
})

const PaginationContainer = styled(Stack)({
  width: '500px',
  height: '40px',
  flexDirection: 'row',
  justifyContent: 'flex-start',
})

const FullWidthStack = styled(Stack)({
  width: '100%',
})
const FlexendStack = styled(Stack)({
  justifyContent: 'flex-end',
})

const WhiteMoreHorizIcon = styled(MoreHorizIcon)({
  color: '#fff',
  fontSize: '25px',
})

const PopperContainer = styled(Card)({
  borderRadius: '10px',
  width: '90px',
  '& .MuiTypography-root': {
    fontSize: '0.8rem',
  },
})

const StyledListItemIcon = styled(ListItemIcon)({
  minWidth: '30px',
})

const StyledTextField = styled(TextField)({
  minWidth: '200px',
  margin: '0px 0px',
  borderRadius: '8px',
  backgroundColor: 'white',
  '& input': {
    padding: '5px 10px',
  },
})

const StyledTextFieldReports = styled(TextField)({
  minWidth: '200px',
  margin: '0px 0px',
  height: '37px',
  borderRadius: '8px',
  backgroundColor: 'white',
  '& div': {
    height: '100%',
  },
  '& input': {
    height: '100%',
    padding: '5px 10px',
  },
})

const EllipsisTypography = styled(Typography)`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  line-height: 1em;
  max-height: 3em;
  color: white;
`

const FullSizeColumnStack = styled(Stack)({
  height: '100%',
  width: '100%',
  justifyContent: 'flex-start',
  flexDirection: 'column',
  overflow: 'hidden',
})

const FullSizeRowStack = styled(FullSizeColumnStack)({
  flexDirection: 'row',
})

const StepperContainerInner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
`

const StyledStepLabel = styled(StepLabel)`
  .MuiStepLabel-labelContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`

const StyledConnector = styled(StepConnector)({})

const CustomStepper = styled(Stepper)({
  width: '90%',
  '& .MuiStepLabel-label': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontSize: '14px',
  },
  '& .MuiStepLabel-label.MuiStepLabel-alternativeLabel': {
    marginTop: '0px',
  },
  '& .MuiStepConnector-root': {
    marginTop: '13px',
  },
})

const SvgImage = styled.svg<{ isActive?: boolean; isComing?: boolean }>`
  width: 100%;
  height: 100%;
  fill: red;
`

const FullHeightColumnStack = styled(Stack)({
  height: '100%',
  flexDirection: 'column',
  justifyContent: 'flex-start',
})

const CustomTabs = styled(Tabs)({
  width: '100%',
})
const CustomTab = styled(Tab)({
  width: '30%',
  textTransform: 'none',
  fontSize: '13.5px',
  ' &.Mui-selected': {
    fontWeight: 'bold',
    color: 'black',
  },
})

const ColumnStack = styled(Stack)({
  flexDirection: 'column',
})

const FullWidthEndStack = styled(FullWidthStack)({
  justifyContent: 'flex-end',
})

const MandatoryMark = styled.span`
  font-weight: bold;
  font-size: 14px;
  color: #ff0000;
`

const FullWidthSpaceStack = styled(FullWidthStack)({
  justifyContent: 'space-between',
})

const FullWidthStartStack = styled(FullWidthStack)({
  justifyContent: 'flex-start',
})

const UploadButton = styled(InputLabel)({
  width: '60%',
  minWidth: '80px',
  height: '30px',
  color: '#fff',
  borderRadius: '4px',
  backgroundColor: Primary,
  fontSize: '13px',
  padding: '10px 10px 0px 10px',
  textAlign: 'center',
  cursor: 'pointer',
})

const SmallUploadButton = styled(UploadButton)({
  minWidth: '40px',
  width: '50px',
  height: '25px',
  fontSize: '12px',
})

const ReplaceButton = styled(UploadButton)({
  width: '80px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  padding: '4px 10px',
  fontSize: '15px',
  fontWeight: '500',
  gap: '2%',
})

const DropZoneContainer = styled(Stack) <{ height?: string }>`
  border: 2px dashed gray;
  border-radius: 10px;
  padding: 15px;
  textalign: center;
  fontsize: 1.2rem;
  height: ${(props) => (props.height ? props.height : '75px')};
  flex-direction: row;
  width: 100%;
  gap: 10px;
  justifycontent: space-between;
  overflow: hidden;
  max-width: 380px;
`

const DropFiled = styled(Stack)<{ height?: string; width?: string }>(({ height, width }) => ({
  height: height ?? '100%',
  width: width ?? '100%',
  overflow: 'hidden',
  justifyContent: 'space-between',
  boxSizing: 'border-box',
  padding: '4px',
  maxWidth: '300px',
  maxHeight: '90px',
  border: '1px solid rgba(128, 128, 128, 0.5)',
  borderRadius: '4px',
  '& span': {
    fontSize: '10px',
    lineHeight: 1,
  },
  '& svg': {
    fill: 'red',
  },
}))

const CenterStack = styled(Stack)({
  justifyContent: 'center',
})

const DropZoneLogo = styled(ColumnStack)({
  width: '45%',
  textAlign: 'center',
})

const SpaceBetweenStack = styled(Stack)({
  justifyContent: 'space-between',
})

const StyledSubModuleIconButton = styled(IconButton)({
  backgroundColor: 'white',
  '&:hover': {
    backgroundColor: 'white',
  },
})
const StyledSubModuleIcon = styled(AddToPhotosTwoToneIcon)({
  color: Primary,
})
const StyledCrossIcon = styled(CloseRoundedIcon)({
  color: 'white',
})

const StyledCard = styled(Card)<{
  image?: string
  isactive?: boolean
  height?: string
  cursorpointer?: boolean
}>((props) => ({
  background: props.isactive
    ? `linear-gradient(180deg, rgba(43, 3, 3, 0) 58%, rgba(0, 0, 0, 0.7371323529411764) 80%), url(${props.image})`
    : `linear-gradient(180deg, rgba(15,15,15,0.7791491596638656) 24%, rgba(56,47,47,0.8519782913165266) 76%), url(${props.image})`,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  height: props.height ? props.height : '120px',
  width: '16.5%',
  borderRadius: '15px', // Changed from 'border-radius' to 'borderRadius'
  padding: '10px',
  minWidth: '160px',
  cursor: props.cursorpointer ? 'pointer' : 'default',
  '&.MuiBox-root.dot-box': {
    borderRadius: '50%',
    background: 'red',
    height: '10px',
    width: '10px',
  },
}))

const TypeToSearch = styled(Card)<{ width?: string }>(({ width }) => ({
  backgroundColor: '#F3F3F3',
  width: width,
  height: 'fit-content',
  padding: '5px 0px 5px 10px',
  borderRadius: '8px',
  display: 'flex',
  boxShadow: 'none',
  alignItems: 'center',
}))

const StyledSortButton = styled(Button)(() => ({
  backgroundColor: '#F6F7F7',
  fontSize: '14px',
  color: '#000000',
  borderRadius: '8px',
  borderColor: '#86868633',
  height: '39px',
  padding: '5px 10px',
  lineHeight: 1,
  '&:hover': {
    color: '#000000',
    borderColor: '#86868633',
    backgroundColor: '#F6F7F7',
  },
}))

const SubModuleBoxSx = { p: 0.5, border: '1.5px solid #A90076', borderRadius: '15px' }

const RightContainer = styled(Grid)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  flex: 1;
`
const StyledHTMLImg = styled('img')<{
  width?: string
  height?: string
  display?: string
  margin?: string
}>(({ width, height, display, margin }) => ({
  width: width,
  height: height,
  display: display,
  margin: margin,
}))

const AddSubModuleCard = styled(Stack)({
  height: '130px',
  width: '18.5%',
  minWidth: '180px',
  borderRadius: '10px',
  boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
  flexDirection: 'column',
  justifyContent: 'center',
  textDecorationColor: 'red',
  backgroundColor: 'white',
  cursor: 'pointer',
  alignItems: 'center',
  '& .MuiTypography-root': {
    color: Primary,
    fontWeight: '500',
  },
  '& .MuiSvgIcon-root': {
    color: Primary,
    fontWeight: '500',
  },
})

const FullWidthCenterStack = styled(FullWidthStack)({
  justifyContent: 'center',
})

const StyledMenuItem = styled(MenuItem)({
  ' & svg': {
    height: '22px',
    fill: Primary,
  },
  '& .MuiButtonBase-root-MuiMenuItem-root': {
    display: 'flex',
  },
})

const AdministrationContainer = styled(Stack)({
  borderRadius: '10px',
  backgroundColor: '#fff',
})

const DeletePopupButton = styled(Button)({
  borderRadius: '8px',
  fontSize: '14px',
  height: '35px',
  width: '100px',
  marginLeft: '10px',
})

const DeleteCancelPopupButton = styled(Button)({
  color: '#B80078',
  borderRadius: '8px',
  fontSize: '14px',
  height: '35px',
  backgroundColor: 'white',
  '&:hover': {
    backgroundColor: 'white',
    color: '#B80078',
  },
  width: '100px',
  marginRight: '10px',
})

const FullSizeStack = styled(FullWidthStack)({
  height: '100%',
  '&::-webkit-scrollbar': {
    width: '4px',
    height: '4px',
    borderRadius: '4px',
  },
  '&::-webkit-scrollbar-track': {
    background: 'lightgray',
    borderRadius: '4px',
  },
  '&::-webkit-scrollbar-thumb': {
    background: 'gray',
    borderRadius: '4px',
  },
  '&::-webkit-scrollbar-corner': {
    background: 'lightgray',
  },
  '&::-webkit-scrollbar-thumb:hover': {
    background: Primary,
  },
})

const SearchContainer = styled(Stack)<{ width?: string }>(({ width }) => ({
  width: width ? width : '200px',
  maxWidth: width ? width : '200px',
  transition: 'width 0.5s ease-in',
  overflow: 'hidden',
  border: `1px solid ${SecondaryButtonColor}`,
  borderRadius: '5px',
  paddingLeft: '5px',
  boxSizing: 'border-box',
  '& svg': {
    fill: SecondaryButtonColor,
  },
}))

// New styles

const CustomSelect = styled(Select)(() => ({
  padding: '0',
  height: '35px',
}))

const ProtectedStack = styled(Stack)({
  height: '100%',
  width: '100%',
  flexDirection: 'column',
  justifyContent: 'flex-start',
})

const StyledNavLink = styled(NavLink)(({ theme }) => ({
  color: '#C11828',
  textDecoration: 'none',
  '&.active': {
    color: 'red',
    fontWeight: 'bold',
  },
}))

const StyledCopyrightIcon = styled(Icon)(({ theme }) => ({
  color: '#193C6C',
  fontSize: '1rem',
  verticalAlign: 'middle',
  marginRight: '2px',
}))

const StyledIcon = styled('span')(({ theme }) => ({
  color: '#193C6C',
  fontSize: '1rem',
  verticalAlign: 'middle',
  marginRight: '2px',
}))

const AppBarTheme = createTheme({
  palette: {
    primary: {
      main: '#ffffff', // Replace with your desired color
    },
  },
})

const HeaderLogo = styled('img')({
  height: '55px',
})

const HeaderProfileName = {
  marginRight: '10px',
  fontFamily: 'Montserrat-Medium',
}

const HeaderToolbar = styled(Toolbar)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  '@media all': {
    minHeight: 75,
    padding: 0,
  },
}))

const SearchBoxContainer = styled(Paper)({
  background: '#FFFFFF',
  boxShadow: '0px 2px 8px #00000022',
  borderRadius: '20px',
})

const HeaderRightContent = styled(Paper)({
  background: '#FFFFFF',
  boxShadow: '0px 2px 8px #00000022',
  borderRadius: '8px',
  padding: '5px 15px 5px 15px',
})

const StyledDivider = styled(Divider)({
  height: '0px',
  border: '1px solid #000',
  opacity: '0.3',
  width: '100%',
})

const StyledNormalText = styled(Typography)(
  ({
    fontvalue = '1.2rem',
    lineheight = '1.6rem',
    color = '#3E3168',
    letterSpacing = '0px',
    opacityValue = '1',
    fontWeight = 'normal',
  }: IStyledNormalText) => ({
    margin: 'unset',
    color: `${color}`,
    fontSize: `${fontvalue}`,
    lineHeight: `${lineheight}`,
    fontWeight: `${fontWeight}`,
    letterSpacing: `${letterSpacing}`,
    opacity: `${opacityValue}`,
  }),
)


export {
  CustomSelect,
  FlexClass,
  FullSize,
  StyledLink,
  StyledBreadCrumbs,
  SmallIconButton,
  BreadCrumbContainer,
  StyledSelect,
  StyledPagination,
  StyledButton,
  StyledTableHead,
  StyledTableRow,
  StyledTableCell,
  ModalContainer,
  StyledSelectInput,
  PopupButton,
  StyledLable,
  InputContainer,
  RedIcon,
  TableProp,
  PaginationContainer,
  FullWidthStack,
  FlexendStack,
  WhiteMoreHorizIcon,
  PopperContainer,
  BoldIcon,
  ListButton,
  StyledListItemIcon,
  TableContainerProp,
  SmallArrow,
  StyledTextField,
  EllipsisTypography,
  ProtectedStack,
  FullSizeColumnStack,
  FullSizeRowStack,
  StyledIcon,
  StyledStepLabel,
  StepperContainerInner,
  StyledConnector,
  CustomStepper,
  FullHeightColumnStack,
  CustomTab,
  CustomTabs,
  FullWidthEndStack,
  FullWidthSpaceStack,
  FullWidthStartStack,
  UploadButton,
  DropZoneContainer,
  ReplaceButton,
  CenterStack,
  DropZoneLogo,
  RightContainer,
  ColumnStack,
  SpaceBetweenStack,
  StyledSubModuleIcon,
  StyledSubModuleIconButton,
  StyledCrossIcon,
  StyledCard,
  TypeToSearch,
  StyledSortButton,
  StyledHTMLImg,
  MandatoryMark,
  AddSubModuleCard,
  SubModuleBoxSx,
  // CustomDatePicker,
  FullWidthCenterStack,
  StyledMenuItem,
  RadioProps,
  AdministrationContainer,
  DeletePopupButton,
  DeleteCancelPopupButton,
  DialogTitleSx,
  DialogContentSx,
  DialogActionSx,
  DataTableProp,
  FullSizeStack,
  SearchContainer,
  DropFiled,
  SmallUploadButton,
  SvgImage,
  StyledSelectInputReports,
  StyledTextFieldReports,
  loaderProps,
  StyledNavLink,
  StyledCopyrightIcon,
  AppBarTheme,
  HeaderLogo,
  HeaderToolbar,
  HeaderRightContent,
  SearchBoxContainer,
  HeaderProfileName,
  HeaderHeading,
  SearchIconStyle,
  SearchBoxCustom,
  StyledDivider,
  StyledNormalText,
  StyledTableCellForMyTeam,
  StyledTableCellEmp,
  StyledEditableTableCell,
  HeaderHeadingDelayedTask
}
