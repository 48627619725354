import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Paper,
  Typography,
  styled,
} from '@mui/material'
import React, { useState } from 'react'
import { RootState } from '../../configureStore'
import { Dispatch } from 'redux'
import { fetchDocURL, managerViewData } from '../../actions'
import { dashboardEntity, dashboardUI } from '../../reducers'
import { connect } from 'react-redux'
import { InputField } from '../Common/ComponentCommonStyles'
import { useLocation } from 'react-router-dom'
import { OpenTabLogo } from '../../utils/StaticData'

const StyledPaper = styled(Paper)(() => ({
  width: '93%',
  padding: '14px  25px 14px 25px',
  background: '#FFFFFF',
  opacity: '1',
  margin: '20px',
  border: '1px solid #DDDDDD',
  minHeight: '350px',
  marginTop: '10px',
}))
const URLDialogBox = styled(Dialog)(() => ({
  '& .MuiDialogContent-root': {
    padding: '30px 40px',
    borderRadius: '25px',
    marginBottom: '-21px'
  },
  '& .MuiDialog-paper': {
    width: '80%',
  },
  '& .MuiButton-root': {},
}))

const CancelButton = styled(Button)(() => ({
  background: '#E2E2E2',
  color: '#000000',
  fontSize: '13px',
  height: '35px',
  fontFamily: 'Montserrat-SemiBold',
  width: '15%',
  borderRadius: '20px',
  '&:hover': {
    background: '#E2E2E2',
    color: '#000000',
  },
}))

const OneToOneDocument = (props: any) => {
  const { ManagerViewData, fetcDocumentURL } = props

  const [open, setOpen] = useState(false)
  const [urlInput, setUrlInput] = useState('')

  const location = useLocation()
  const userId = new URLSearchParams(location.search).get('id')

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleUrlInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault()
    const inputValue = event.target.value

    setUrlInput(inputValue)
  }

  const handleUrlSubmit = () => {
    const url = urlInput

    const data = {
      userId: userId,
      url: url,
    }

    fetcDocumentURL(data)
    setOpen(false)
  }

  const handleOpenSheet = () => {
    const docUrl = ManagerViewData?.userDetails?.userGoalInfo

    if (docUrl) {
      window.open(docUrl, '_blank')
    }
  }

  return (
    <>
      <StyledPaper>
        <Button
          sx={{
            fontSize: '15px',
            height: '42px',
            float: 'right',
            letterSpacing: '0px',
            borderRadius: '20px',
            padding: '5px 20px',
            marginBottom: '12px',
            marginLeft: '8px',
            width: '100px',
          }}
          onClick={handleClickOpen}
        >
          Upload
        </Button>
        <Button
          sx={{
            fontSize: '15px',
            height: '42px',
            float: 'right',
            letterSpacing: '0px',
            borderRadius: '20px',
            marginBottom: '12px',
            width: '168px',
            '&.Mui-disabled': {
              opacity: 0.6,
              color: '#ffffff',
              cursor: 'not-allowed',
            },
          }}
          onClick={handleOpenSheet}
          disabled={!ManagerViewData?.userDetails?.userGoalInfo}
        >
          Open In New Tab
          <span style={{ float: 'right' }}>
            <img
              src={OpenTabLogo}
              style={{
                cursor: 'pointer',
                marginTop: '8px',
                marginLeft: '6px',
                height: '21px',
                filter: 'brightness(0) invert(1)',
              }}
              alt=''
            />
          </span>
        </Button>
        <>
          <URLDialogBox open={open} onClose={handleClose} key={1}>
            <DialogContent>
              <DialogContentText style={{ textAlign: 'center' }}>
                Enter Google Document
              </DialogContentText>
              <InputField
                autoFocus
                id='url'
                label='URL'
                type='url'
                size='small'
                fullWidth
                sx={{ marginBottom: '-10px' }}
                value={urlInput}
                onChange={handleUrlInputChange}
              />
            </DialogContent>
            <DialogActions style={{ marginBottom: '10px' }}>
              <CancelButton onClick={handleClose}>Cancel</CancelButton>
              <Button
                sx={{
                  fontSize: '15px',
                  letterSpacing: '0px',
                  borderRadius: '20px',
                  padding: '5px 20px',
                  width: '15%',
                  height: '35px',
                  marginRight: '32px',
                  '&.Mui-disabled': {
                    opacity: 0.5,
                    color: '#ffffff',
                    cursor: 'not-allowed',
                  },
                }}
                onClick={handleUrlSubmit}
                color='primary'
                disabled={!urlInput.trim()}
              >
                Submit
              </Button>
            </DialogActions>
          </URLDialogBox>
        </>
        {ManagerViewData?.userDetails?.userGoalInfo ? (
          <iframe
            src={ManagerViewData?.userDetails?.userGoalInfo}
            title='ONE TO ONE DOCUMENT'
            style={{ width: '100%', height: '500px', marginTop: '5px' }}
          ></iframe>
        ) : (
          <Typography
            variant='subtitle1'
            align='center'
            sx={{ color: '#483f3f', marginTop: '14%', marginRight: '60px' }}
          >
            No document available
          </Typography>
        )}
      </StyledPaper>
    </>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    ManagerViewData: dashboardEntity.getDashboard(state).getManagerViewData,
    DocURLData: dashboardEntity.getDashboard(state).getDocURL,
    IsUrlSave: dashboardUI.getDashboard(state).isURLSave,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    fetchManagerViewData: (data: any) => dispatch(managerViewData.request(data)),
    fetcDocumentURL: (data: any) => dispatch(fetchDocURL.request(data)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(OneToOneDocument)
