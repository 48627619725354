import Button from '@mui/material/Button'
import { ReactComponent as ExportToPDFIcon } from '../../../../assets/images/exportToPDF.svg'
import { ReactComponent as ExportToExcelIcon } from '../../../../assets/images/exportToExcel.svg'
import { ReactComponent as ExportToPrintScreenIcon } from '../../../../assets/images/printScreen.svg'
import { Box } from '@mui/material'
import { exportAsPDF } from './ExportAsPDF'
import { exportToExcel } from './ExportAsExcel'

const style = {
  buttonStyle: {
    fontFamily: 'Montserrat-Regular',
    fontSize: '1rem',
    padding: '0',
    width: '40px',
    height: '40px',
    minWidth: 'fit-content',
    borderRadius: '50%',
    backgroundColor: 'white',
    '&:hover': {
      backgroundColor: 'white',
    },
    '@media only screen and (max-width:780px)': {
      fontSize: '0.9rem',
      marginLeft: '20px',
    },
  },
  container:{
    '@media only screen and (max-width:1230px)': {
      width:"200px",
      // border:'1px solid black'
    },
    '@media only screen and (max-width:1200px)': {
      marginTop: '-8px'
    },
    '@media only screen and (max-width:700px)': {
      marginTop: '-3px'
    },
  }
}

const ExportButton = () => {
  
  const printTable = () => {
    window.print()
  }

  return (
    <Box display='flex' justifyContent='space-evenly' alignItems='center' width='160px' sx={style.container}>
      <Box>
        <Button
          onClick={() => {
            exportAsPDF()
          }}
          sx={style.buttonStyle}
          variant='outlined'
        >
          <ExportToPDFIcon />
        </Button>
      </Box>
      <Box>
        <Button
          onClick={() => {
            exportToExcel()
          }}
          sx={style.buttonStyle}
          variant='outlined'
        >
          <ExportToExcelIcon />
        </Button>
      </Box>
      <Box>
        <Button
          onClick={() => {
            printTable()
          }}
          sx={style.buttonStyle}
          variant='outlined'
        >
          <ExportToPrintScreenIcon />
        </Button>
      </Box>
    </Box>
  )
}

export default ExportButton
