import { FormControl, Select, MenuItem, SelectChangeEvent, Typography, Box } from '@mui/material'
import { style } from './projectCustomersStyle'
import { ProjectCustomersEntriesProp } from './ProjectCustomersTypes'

const ShowEntries = ({ rowsPerPage, setRowsPerPage }: ProjectCustomersEntriesProp) => {
  
  const handleChange = (event: SelectChangeEvent) => {
    const newValue = parseInt(event.target.value, 10)
    setRowsPerPage(newValue)
  }

  return (
    <Box sx={style.showEntriesInBoxStyle}>
      <Typography sx={style.showEntriesTypographyCustom}>Show entries : </Typography>
      <Box sx={style.selectComponent}>
        <FormControl sx={style.showEntriesFormControlStyle}>
          <Select
            value={`${rowsPerPage}`}
            onChange={handleChange}
            sx={style.showEntriesSelectCustomStyle}
          >
            <MenuItem value={100}>100</MenuItem>  
            <MenuItem value={200}>200</MenuItem>
            <MenuItem value={300}>300</MenuItem>
            <MenuItem value={400}>400</MenuItem>
            <MenuItem value={500}>500</MenuItem>
          </Select>
        </FormControl>
      </Box>
    </Box>
  )
}
export default ShowEntries
