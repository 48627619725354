import { CompanyIdTypes } from '../actions'
import { GetAccessToken, LoginAuthData, designationGraphData } from '../components/Types'
import { NodeProps } from '../sagas/orgChart'
import { ProjectCustomersType } from '../sagas/projectGraph'
import * as RestAPI from './rest'

export const loginUserEndpoint = (data: LoginAuthData) =>
  RestAPI.POST(`/google-authentication`, data)

export const getUserDataEndPoint = () => RestAPI.GET(`/users`)

export const getBackInfoDataEndPoint = () => RestAPI.GET(`/users/background-info`)

export const getClientMembers = () => RestAPI.GET(`/users/clientsmembers`)

export const getTeamMandates = () => RestAPI.GET(`/users/teamMandates`)

export const getDelayedGraps = (data: any) => {
  return RestAPI.GET(`/idsr/delay-graph-data?formDate=${data?.startDate}&toDate=${data?.endDate}`)
}

export const getDelayedTableEndPoint = (data: any) =>
  RestAPI.GET(
    `/idsr/delay-table-data?id=${data?.id ? data?.id : ''}&formDate=${data?.startDate}&toDate=${
      data?.endDate
    }`,
  )

export const getRecentEndPoint = () => RestAPI.GET(`/idsr/recent`)

export const getHolidaysEndPoint = () => RestAPI.GET(`/holidays`)

export const getHighlightsEndPoint = () => RestAPI.GET(`/highlights`)

export const getAssetsEndPoint = () => RestAPI.GET(`/assets`)

export const getEmpInfoDataEndPoint = () => RestAPI.GET(`/users/emp-info`)

export const getDesignationEndPoint = () => RestAPI.GET(`/users/designation`)

export const getDesignationBandEndPoint = () => RestAPI.GET(`/users/designation-band`)

export const getEmployementTypeEndPoint = () => RestAPI.GET(`/users/employeement-type`)

export const getSAPeriodEndPoint = () => RestAPI.GET(`/users/service-agreement`)

export const getTimingEndPoint = () => RestAPI.GET(`/users/office-time`)

export const getBasicInfoPoint = (data: any) =>
  RestAPI.GET(`/users/basic-info?userId=${data.userId}&isGlobal=${data.isGlobal}`)

export const getSREndPoint = (data: any) =>
  RestAPI.GET(
    `/service-requests?page=${data.page}&pageSize=${data.pageSize}&issueId=${
      data.issueId
    }&is_feedback=${data.isFeedback ?? false}`,
  )

export const getAssignedSREndPoint = (data: any) =>
  RestAPI.GET(`/assigned-requests?page=${data.page}&pageSize=${data.pageSize}`)

export const getBankInfoEndPoint = () => RestAPI.GET(`/bank-info`)

export const getDrsEndPoint = () => RestAPI.GET(`users/drs-list`)

export const getAssignedTaskDrs = (data: any) => {
  return RestAPI.GET(`onholdplanfortheday/report?followup=${data.type}`)
}

export const getTodayTask = () => RestAPI.GET(`planForTheDay/recent`)

export const getSubDrsEndPoint = (data: { id: number }) =>
  RestAPI.GET(`/api1/Drscount?userId=${data.id}`)

export const getCountryEndPoint = () => RestAPI.GET(`/users/countries`)

export const getTimesheetEndPoint = () => RestAPI.GET(`/timesheets`)

export const getLeaveTimesheetEndPoint = () => RestAPI.GET(`leaves/leaveTimesheets`)

export const getPaySlipEndPoint = (data: any) => RestAPI.GET(`/payslips?tID=${data}`)

export const getLeaveDataEndPoint = (data: any) =>
  RestAPI.GET(`leaves/underlings?status=${data.status}&tID=${data.timeSheetId}`)

export const getAcceptLeaveDataEndPoint = (data: any) =>
  RestAPI.POST(`leaves/statusUpdate`, { ...data })

export const getRejectLeaveDataEndPoint = (data: any) =>
  RestAPI.GET(`leaves/underlings?tID=${data}`)

export const getProjectDetailsEndPoint = () => RestAPI.GET(`users/projects`)

export const getStateEndPoint = () => RestAPI.GET(`/users/states`)

export const getAccountTypeEndPoint = () => RestAPI.GET(`/bank-info/account-type`)

export const getMaritalStatusEndPoint = () => RestAPI.GET(`/users/marital-status`)

export const getLeaveDetailsEndPoint = (data: any) => RestAPI.GET(`/leaves`, data)

export const getDepartmentListEndPoint = () => RestAPI.GET(`/department`)

export const getUserDetails = () => RestAPI.GET(`users/user-info`)

export const getUserByIdEndPoint = (data: any) => RestAPI.GET(`users/user-by-id/${data.id}`)

export const getReferredByIdEndPoint = (data: any) => RestAPI.GET(`users/user-by-id/${data.id}`)

export const getHomePageDetails = () => RestAPI.GET(`users/home-page`)

// export const getIssueTypeListEndPoint = (deptId: any) => RestAPI.GET('/type', { dept_id: deptId })

export const getIssueTypeListEndPoint = (data: any) => RestAPI.GET('type', data)

export const getQualificationEndPoint = () => RestAPI.GET(`/users/qualification-set`)

export const getQualificationSkillEndPoint = () => RestAPI.GET(`/users/qualification-skill-set`)

export const getAccessTokenEndPoint = (data: GetAccessToken) =>
  RestAPI.OAuthPOST(`oauth/token`, data)

export const logoutUserEndpoint = () => RestAPI.OAuthLogout(`oauth/logout`)

export const getLoginUser = () => RestAPI.GET('login')

export const createNewRequest = (data: any) => RestAPI.POST1('service-requests', data)

export const getCompensationDetailsEndPoint = (data: any) =>
  RestAPI.GET(`compensations?compensationString=${data}`)

export const sendLoanRequest = (data: any) => RestAPI.POST('loans', data)

export const getLoanDetailsEndPoint = () => RestAPI.GET('loans')

export const getLoanInstallmentHistoryEndPoint = (data: any) =>
  RestAPI.GET(`loans/loanDetailsById?loanId=${data}`)

export const getAllEmployees = () => RestAPI.GET('/users/getAllEmployees')

export const addNewComment = (data: any) => RestAPI.POST('/comments', data)

export const assignedRequest = (data: any) =>
  RestAPI.GET(
    `/assigned-requests?page=${data.page}&pageSize=${data.pageSize}&issueId=${data.issueId}`,
  )

export const updatedRequest = (data: any) =>
  RestAPI.PUT(`/service-requests/update?srId=${data.srId}`, data)

export const getStatusSummary = (data: any) =>
  RestAPI.GET(`/idsr-summary?userId=${data.userId}&tID=${data.tID}`)

export const getAttendance = (data: any) =>
  RestAPI.GET(`/attendance?userId=${data.userId}&tID=${data.tId}`)

export const getTaskStatusApiCall = (data: any) =>
  RestAPI.GET(`/attendance?userId=${data.userId}&tID=${data.tId}`)

export const getPlanForTheDayApiCall = (data: any) =>
  RestAPI.GET(`/planfortheday/report?planforthedayId=${data.planforthedayId}`)

export const updatePlanForTheDayApiCall = (data: any) =>
  RestAPI.PATCH(`/planfortheday?planforthedayId=${data.planforthedayId}`, data.data)

export const getSingleIdsrApiCall = (data: any) => RestAPI.GET(`/idsr/report?idsrId=${data.idsrId}`)

export const updateSingleIdsrApiCall = (data: any) =>
  RestAPI.PATCH(`/idsr?idsrId=${data.idsrId}`, data.data)

export const downloadCsvForMyTeamReport = (data: any) =>
  RestAPI.DOWNLOADCSV(
    `/downloads?startDate=${data.startDate}&endDate=${data.endDate}&type=${data.reportType}&searchTerm=${data.search}`,
  )

export const getAttendanceTimesheet = (userId: string) =>
  RestAPI.GET(`/attendance-timesheets?userId=${userId}`)

export const getRCAsList = (userId: string) => RestAPI.GET(`/rca?userId=${userId}`)

export const createRCA = (data: any) => RestAPI.POST(`/rca`, data)

export const getIDSRsList = (data: any) =>
  RestAPI.GET(`/idsr?userId=${data.userId}&tId=${data.tId}`)

export const getPlansList = (data: any) =>
  RestAPI.GET(`/planforday?userId=${data.userId}&tId=${data.tId}`)

export const getStatusType = () => RestAPI.GET('/statusType')

export const getTaskStatus = () => RestAPI.GET('/status')

export const createIDSR = (data: any) => RestAPI.POST(`/idsr`, data)

export const createPlanForTheDay = (data: any) => RestAPI.POST(`/planfortheday`, data)

export const downloadableURL = (data: any) => RestAPI.GET(`/Downloadable-file?srId=${data.srId}`)

export const getManagerViewData = (data: any) =>
  RestAPI.GET(`/api1/Managerview?userId=${data.userId}&tID=${data.tID}`)

export const getDocURL = (data: any) =>
  RestAPI.PATCH(`/api1/url?userId=${data.userId}&url=${data.url}`, data)

export const getAllProjects = (data: any) => RestAPI.GET(`/project?userId=${data.userId}`)

export const getAllGraphData = (data: any) =>
  RestAPI.GET(
    `/projects/userId?userId=${data.userId}&start_date=${data.filterDataByDate.start_date}&end_date=${data.filterDataByDate.end_date}`,
  )

export const getAllProjectTypes = (data: any) =>
  RestAPI.GET(
    `/projects/project/index?status=${data.status}&limit=${data.limit}&page=${data.page}&search=${data.search}`,
  )

export const getProjectDomain = () => RestAPI.GET('/projects/projectDomains')

export const getProjectSource = () => RestAPI.GET('/projects/projectsource')

export const getDesignationGraphData = (data: designationGraphData) =>
  RestAPI.GET(
    `/projects/percentage?desgination=${data.designation}&start_date=${data.startDate}&end_date=${data.endDate}`,
  )

export const getDesignationGraphList = () => RestAPI.GET('users/allDesginations')

export const getAllTechnologies = () => RestAPI.GET(`/projects/technologies`)

export const getAllProjectLocation = () => RestAPI.GET(`/projects/locations`)

export const getNewProjectInfo = (data: any) => RestAPI.POST(`/projects/project/create`, data)

export const getDeletingProject = (data: any) =>
  RestAPI.PUT(`/projects/Project/delete?id=${data.id}`, data)

export const getMandateType = (data: any) =>
  RestAPI.GET(
    `/projects/mandatetype/index?page=${data.page ? data.page : 1}&pageSize=${
      data.pageSize ? data.pageSize : 100
    }&search=${data.search}`,
  )

// export const getFollowUpTask = (data: any) =>
//   RestAPI.GET(
//     `/myTeam/followup?page==${data.page}&limit=${data.pageSize}&startDate=${data.startDate}&endDate=${data.endDate}&searchTerm=${data.search}&type=0`,
//   )

export const getProjectCustomers = () => RestAPI.GET(`/projects/Customer/index`)

export const getRestartedProject = (data: any) =>
  RestAPI.PUT(`/projects/restart?id=${data.id}`, data)

export const getprojectDetails = (data: any) =>
  RestAPI.GET(`/projects/projectDetails?id=${data.id}`)

export const getAllEmployeesList = () => RestAPI.GET(`/projects/employees`)

export const AddEmpBasedOnRoles = (data: any) => RestAPI.POST(`/projects/developer/create`, data)

export const UpdateEmpBasedOnRoles = (data: any) =>
  RestAPI.PUT(`/projects/developer/update?id=${data.id}`, data)

export const DeleteEmpBasedOnRoles = (data: any) =>
  RestAPI.PUT(
    `/projects/developer/delete?id_users=${data.id_users}&id_project=${data.id_project}`,
    data,
  )

export const GetAllEditedProjects = (data: any) =>
  RestAPI.PUT(`/projects/project/update?id=${data.id}`, data)

export const getDesignationList = () => RestAPI.GET('/api1/Allcount')

export const getDesignationDataList = (data: { hierarchyName: string }) =>
  RestAPI.GET(`/projects/desginationss?hierarchyName=${data.hierarchyName}`)

export const getProjectReportsSheetURL = (data: any) =>
  RestAPI.GET(
    `/projects/reports/projectSheet?page=${data.page}&limit=${data.limit}&startDate=${data.startDate}&endDate=${data.endDate}&search=${data.search}`,
  )
export const getReportsSheetURL = (data: any) =>
  RestAPI.GET(
    `/download?page=${data.page ? data.page : 1}&limit=${
      data.limit ? data.limit : 1000
    }&startDate=${data.startDate}&endDate=${data.endDate}&searchTerm=${
      data.search ? data.search : ''
    }&type=${data.reportType}`,
  )

export const getAllResourceReportData = (data: any) =>
  RestAPI.GET(
    `/projects/reports/projectResourcesReport?project=${data.project}&projectSource=${data.projectSource}&employmentType=${data.employmentType}&startDate=${data.startDate}&endDate=${data.endDate}&location=${data.location}`,
  )

export const getProjectResourceDropdownData = () => RestAPI.GET(`/projects/AllProjectDetails`)

export const getProjectManagementReportData = (data: any) =>
  RestAPI.GET(
    `/projects/projectmanagmentreport?date=${data.date}&type=${data.type}&page=${data.page}&limit=${data.limit}&search=${data.search}`,
  )

export const getAllProjectQAReport = (data: any) =>
  RestAPI.GET(
    `/projects/projectqareport?startDate=${data.startDate}&endDate=${data.endDate}&type=${data.type}&page=${data.page}&pageSize=${data.pageSize}&search=${data.search}`,
  )

export const getNonBillableResources = () => RestAPI.GET('/projects/listNonBillableResource')

export const updateNonBillable = (data: { Employee_id: number[] }) =>
  RestAPI.PUT(`/projects/createNonBillResource`, data)

export const getAllProjectCustomers = (data: any) =>
  RestAPI.GET(
    `/projects/projectCustomer/index?page=${data.page}&pageSize=${data.pageSize}&search=${data.search}`,
  )

export const createProjectCustomers = (data: ProjectCustomersType) =>
  RestAPI.POST(`/projects/projectCustomer/create`, data.data)

export const createMandateTypes = (data: any) => RestAPI.POST(`/projects/mandate/create`, data.data)

export const updateMandateType = (data: any) =>
  RestAPI.PATCH(
    `/projects/mandate/update?id=${data.id}&mandate_name=${data.mandateName}`,
    data.data,
  )

export const deleteOneMandateType = (id: number) =>
  RestAPI.PATCH(`/projects/mandate/delete?id=${id}`, { id })

export const deleteOneProjectCustomers = (id: number) =>
  RestAPI.PUT(`/projects/projectCustomer/delete?id=${id}`, { id })

export const updateProjectCustomer = (data: any) =>
  RestAPI.PUT(
    `/projects/projectcustomer/update?id=${data.id}&CustomerName=${data.CustomerName}`,
    data.data,
  )

export const getpeopleBasedOrgChart = (data: NodeProps) => RestAPI.GET(`/projects/Peoplechart`)

export const geteDepartmentBasedOrgChart = (data: any) =>
  RestAPI.GET(`/projects/departmentcharts?department=${data.department}`)

export const getDesignationBasedOrgChart = (data: NodeProps) =>
  RestAPI.GET(`/projects/designationcharts?level=${data.level}`)

export const getEmpHistory = () => RestAPI.GET(`/users/desginationhistory`)

export const getManagerHistoryData = () => RestAPI.GET(`/users/managerhistory`)

export const getCompanyLogo = (data: any) => RestAPI.GET(`/users/image?id=${data.id}`)

export const getCompanyId = () => RestAPI.GET(`/users/companiesId`)

export const createUser = (data: any) => RestAPI.POST1(`/users/createUser`, data.data)

export const uploadUserImg = (data: any) =>
  RestAPI.POST(`/users/imageUpload?id=${data.data.id}`, data.data)

export const updateUser = (data: any) => RestAPI.PATCH1(`/users/updateUser`, data)

export const fetchAllRoles = () => RestAPI.GET(`/users/alluserroles`)

export const fetchAllState = () => RestAPI.GET(`/users/states`)

export const fetchAllCountry = () => RestAPI.GET(`/users/countries`)

export const fetchAllFloors = () => RestAPI.GET(`/users/floors`)

export const fetchAllWorkstation = () => RestAPI.GET(`/users/workstations`)

export const fetchAllLocation = () => RestAPI.GET(`/projects/locations`)

export const fetchAllClietLocation = () => RestAPI.GET(`/users/client_location`)

export const getAllUserList = (data: any) =>
  RestAPI.GET(
    `/users/admin/users?page=${data.page}&pageSize=${data.pageSize}&search=${data.search}&type=${data.type}`,
  )
export const getUserImage = (data: any) => RestAPI.GET(`/users/alluserImages?id=${data?.userid}`)

export const deleteUser = (userid: any) =>
  RestAPI.PATCH(`/users/deleteUser?id=${userid}`, { userid })

export const createCommentPlanForTheDay = (data: any) =>
  RestAPI.PATCH(`/comment/planfortheday?planforthedayId=${data.id}`, data.data)

export const createCommentTimesheet = (data: any) =>
  RestAPI.PATCH(`/comment/idsr?idsrId=${data.id}`, data.data)
export const approvePlanForTheDayAPI = (data: any) =>
  RestAPI.PATCH(`/approve?planforthedayId=${data.id}`, {})

export const getCommentTimesheet = (data: any) => RestAPI.GET(`/comment/idsr?idsrId=${data.id}`)
export const getCommentPlanForTheday = (data: any) =>
  RestAPI.GET(`/comment/planfortheday?planforthedayId=${data.id}`)

export const uploadForm16 = (data: any) =>
  RestAPI.POST1(`/form16`, {
    id_user: data.id_user,
    file: data.file,
    currentYear: data.currentYear,
  })

export const sendEmailRequest = (data: any) =>
  RestAPI.POST1(`/mailServiceFrom16`, {
    userId: data.userId,
    accountsDepartmentId: data.accountsDepartmentId,
    currentYear: data.currentYear,
  })

export const getForm16Data = (data: any) =>
  RestAPI.GET(
    `/form16?currentYear=${data.currentYear}&status=${data.status}&limit=${data.limit}&page=${data.page}&user=${data.user}`,
  )

export const getFinancialYearData = () => RestAPI.GET(`/financialByHireDate`)

export const getDownloadForm16 = (data: any) =>
  RestAPI.GET(`/downlodable-form16?userId=${data.id}&currentYear=${data.currentYear}`)

export const getAssigned = (data: any) => RestAPI.GET(`/downlodable-form16?userId=${data.id}`)

export const getAssignedMyTeam = (data: any) =>
  RestAPI.GET(
    `/assigned/task?page=${data.page || 1}&limit=${data.limit || 500}&startDate=${
      data.startDate
    }&endDate=${data.endDate}`,
  )

export const getFollowUpTask = (data: any) =>
  RestAPI.GET(
    `/myTeam/followup?page=1&limit=500&startDate=${data.startDate}&endDate=${data.endDate}`,
  )

export const getProjectStatus = (data: any) =>
  RestAPI.GET(
    `/projectStatus?startDate=${data.startDate}&endDate=${data.endDate}&taskFilter=${data.taskFilter}`,
  )
export const getMarkAsApiCALL = (data: any) =>
  RestAPI.GET(
    `/projectStatus?startDate=${data.startDate}&endDate=${data.endDate}&taskFilter=${data.taskFilter}`,
  )

  export const fetchEmpTenure = () => RestAPI.GET(`/tenure/percentages`)
