import { combineReducers } from 'redux'
import { actionTypes } from '../../actions'
import { Actions as actions } from '../../actions/Types'
import { RootState } from '../../configureStore'
import { FETCH_LOAN_INSTALLMENT_DATA } from '../../actions/actiontypes'

const { SUCCESS, REQUEST, SEND_LOAN_REQUEST, FETCH_LOAN_DETAILS } = actionTypes

const entity = () => {
  const sendLoanRequest = (state = [], action: actions) => {
    switch (action.type) {
      case SEND_LOAN_REQUEST[SUCCESS]:
        return action.payload
      case SEND_LOAN_REQUEST[REQUEST]:
        return []
      default:
        return state
    }
  }

  const getLoanDetails = (state = [], action: actions) => {
    switch (action.type) {
      case FETCH_LOAN_DETAILS[SUCCESS]:
        return action.payload
      case FETCH_LOAN_DETAILS[REQUEST]:
        return []
      default:
        return state
    }
  }

  const getLoanInstallment = (state = [], action: actions) => {
    switch (action.type) {
      case FETCH_LOAN_INSTALLMENT_DATA[SUCCESS]:
        return action.payload
      case FETCH_LOAN_INSTALLMENT_DATA[REQUEST]:
        return []
      default:
        return state
    }
  }

  return combineReducers({
    sendLoanRequest,
    getLoanDetails,
    getLoanInstallment,
  })
}

export default entity

export const sendLoanRequestData = (state: RootState) => state.entities.LoanRequest
