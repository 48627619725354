import { Primary } from '../../../utils'

export const style = {
  headerHeading: {
    fontSize: '1.1rem',
    textAlign: 'center',
    fontFamily: 'Montserrat-Bold',
    letterSpacing: '0px',
    color: Primary,
    lineHeight: '27px',
    '@media only screen and (max-width:860px)': {
      textAlign: 'center',
    },
  },
  styledPaper: {
    fontFamily: 'Montserrat-Bold',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    background: '#FFFFFF',
    opacity: '1',
    textAlign: 'left',
    margin: '10px',
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: '25px',
    width: '93%',
    border: '1px solid #DDDDDD',
  },
}
