import { FormControl, Paper } from '@mui/material'
import styled from 'styled-components'

export const StyledPaper = styled(Paper)(({ theme }) => ({
  width: 'auto',
  padding: '25px 25px',
  background: '#FFFFFF',
  opacity: '1',
  marginTop: '10px',
  marginLeft: 'auto',
  marginRight: 'auto',
  marginBottom: '10px',
  justifyContent: 'center',
  alignItems: 'center',
  border: '1px solid #DDDDDD',
  overflowX: 'scroll',
  overflowY: 'scroll',
}))

export const IndexStyledPaper = styled(Paper)(() => ({
  width: '93%',
  padding: '25px 25px',
  background: '#FFFFFF',
  opacity: '1',
  marginTop: '10px',
  marginLeft: 'auto',
  marginRight: 'auto',
  marginBottom: '10px',
  border: '1px solid #DDDDDD',
}))

export interface NodeProps {
  name: string
  emp_id: string
  designation: string
  level: number
  count: number
  imageURL: string
  drs?: NodeProps[]
  bgColor?: string;

}

export interface PeopleBasedChartsProps {
  peopleBasedOrgChartAPI: any; 
  peopleBasedOrgChartData: NodeProps; 
  isGettingPeopleBasedOrgData: boolean;
}

export interface DesignationBasedChartsProps {
  designationBasedOrgChartAPI: any; 
  designationBasedOrgChartData: NodeProps; 
  isGettingDesignationBasedOrgChartData: boolean;
}

export const StyledNode = styled.div<{ bgColor?: string }>`
  background-color: ${({ bgColor }) => bgColor || ''};
  padding: 8px;
  border-radius: 8px;
  display: inline-block;
  border: 1px solid gray;
  text-align: center;
  &:hover {
    background-color: #d3d3d3;
  }

  @media (max-width: 1200px) {
    padding: 8px;
  }

  @media (max-width: 992px) {
    padding: 6px;
  }

  @media (max-width: 768px) {
    padding: 5px;
  }

  @media (max-width: 576px) {
    padding: 4px;
    font-size: 12px;
  }

  @media (max-width: 400px) {
    padding: 3px;
    font-size: 10px;
  }
`
export const Name = styled.span`
  position:relative;
  display: block;
  color: rgb(25, 60, 109);
  font-family: 'Montserrat-SemiBold';
  white-space: nowrap;
  font-size: small;
`

export const Label = styled.span`
  position:relative;
  display: block;
  font-family: 'Montserrat-Medium';
  color: #193c6d;
  font-size: small;
`
export const DrsCount = styled.span`
  position:relative;
  display: block;
  font-family: 'Montserrat-Medium';
  font-size: small;
`
export const StyledImage = styled.img`
  width: 50px;
  border-radius: 50%;
  height: 50px;
  object-fit: cover;
`;
export const StyledFormControl = styled(FormControl)(() => ({
  width: '15%!important',
}))

export const styles = {
  selectStyle: {
    borderRadius: '20px',
    width: '150px',
    height: '38px',
    marginRight: '-90px',
  },
  selectBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginTop: '-40px',
  },
  buttonStyles: {
    whiteSpace: 'nowrap',
    borderRadius: '20px',
    height: '38px',
    fontFamily: 'Montserrat-Medium', 
  },
 
}
