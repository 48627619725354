import {
  Box,
  TableContainer,
  Paper,
  TableBody,
  Pagination,
  Table,
  FormControl,
  MenuItem,
  Select,
  Typography,
  SelectChangeEvent,
} from '@mui/material'
import { style } from './ projectQAReportStyles'
import NoQAProjectRow from './NoQAProjectRow'
import TableBodyRow from './TableBodyRow'
import TableHeadColumns from './TableHeadColumns'

export type QATablePropType = {
  projectQAReportData:any
  rowsToDisplay:any
  selectType:string
  count:number
  page:number
  handleChangePage:any
  SetRowPerPage:React.Dispatch<React.SetStateAction<number>>
  rowPerPage:number
}

const QATable = ({
  projectQAReportData,
  selectType,
  page,
  handleChangePage,
  SetRowPerPage,
  rowPerPage,
  setPage,
}: any) => {

  const handleSelectEntry = (event: SelectChangeEvent<string>) => {
    const value = parseInt(event.target.value, 10)
    SetRowPerPage(value)
    setPage(1)
  }

  return (
    <Box id='export' sx={style.tableBoxStyle}>
      <Box sx={style.tableContainer}>
        <TableContainer component={Paper}>
          <Table sx={style.styleOfTableBox}>
            <TableHeadColumns selectType={selectType} />
            <TableBody id='body'>
              {projectQAReportData?.result?.length ? (
                projectQAReportData?.result?.map((row: any) => (
                  <TableBodyRow 
                  row={row} 
                  selectType={selectType}  
                  key={`${row?.project_id}`}
                  />
                ))
              ) : (
                <NoQAProjectRow selectType={selectType} />
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {projectQAReportData?.total_projects  ? (
          <Box sx={style.selectAndPaginationBox}>
            <Box sx={style.BoxSecondCustomStyle}>
              <Typography sx={style.typographyEntriesNameSecondStyle}>Show Entries : </Typography>
              <FormControl sx={style.FormSecondControlStyle}>
                <Select
                  value={rowPerPage.toString()}
                  onChange={handleSelectEntry}
                  sx={style.selectSecondTypeStyle}
                >
                  <MenuItem value={100}>100</MenuItem>
                  <MenuItem value={200}>200</MenuItem>
                  <MenuItem value={300}>300</MenuItem>
                  <MenuItem value={400}>400</MenuItem>
                  <MenuItem value={500}>500</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Pagination 
            count={Math.ceil(projectQAReportData?.count / rowPerPage)} 
            page={page} 
            onChange={handleChangePage} 
            color='primary'
            />
          </Box>
        ) : (
          ''
        )}
      </Box>
    </Box>
  )
}
export default QATable
