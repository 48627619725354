import { styled } from '@mui/material/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import Paper from '@mui/material/Paper'
import './IDSR.scss'
import PendingIcon from '@mui/icons-material/Pending'
import CheckIcon from '@mui/icons-material/Check'
import priority from './constant'
import { ReactComponent as EditIcon } from '../../assets/images/editIconTimesheet.svg'
import { ReactComponent as CheckedApprovedIcon } from '../../assets/images/checkApprovedIcon.svg'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'

import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Tab,
  Tabs,
  TextField,
  Typography,
  Modal,
  Tooltip,
  InputAdornment,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@mui/material'
import { createStyles, makeStyles } from '@material-ui/core'
import {
  StyledMenuItem,
  StyledTableCell,
  StyledTableCellForMyTeam,
  StyledTableRow,
  loaderProps,
} from '../Common/CommonStyles'
import { Dispatch } from 'redux'
import { RootState } from '../../configureStore'
import { connect } from 'react-redux'
import { useEffect, useState, useCallback } from 'react'
import {
  createNewPlanForTheDay,
  createNewIDSR,
  fetchProjectsName,
  getAttendanceTimesheet,
  getIDSRs,
  getPlans,
  getStatusType,
  getTaskStatus,
  fetchMandateType,
  getPlanForTheDay,
  editPlanForTheDay,
  getSingleIdsr,
  editSingleIdsr,
  approvePlanForTheDay,
} from '../../actions'
import {
  attendanceEntity,
  attendanceUI,
  dashboardEntity,
  projectManagementEntity,
} from '../../reducers'
import { fetchPlans, fetchIDSRs, fetchRCAs, fetchStatusType } from '../../reducers/entities'
import { Heading } from '../Pages/Styles'
import CloseIcon from '@mui/icons-material/Close'
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone'
import 'react-quill/dist/quill.snow.css'
import { toast } from 'react-toastify'
import moment from 'moment'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import Grow from '@mui/material/Grow'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import CommentIcon from '@mui/icons-material/Comment'
import { Primary } from '../../utils'

interface IFormValues {
  projectName: string
  otherProjectName: string
  timeSpend: string
  taskDescription: string
  mandateId: string
  no_of_pr_raised?: number | null
  no_of_pr_approved?: number | null
  no_of_reworked_pr?: number | null
  isYesterdayTask?: boolean
  priority?: string
  withManager: boolean
  withSuperManager: boolean
  statusId: string
}
const StyledPaper = styled(Paper)(() => ({
  width: '93%',
  padding: '25px 25px',
  background: '#FFFFFF',
  opacity: '1',
  marginTop: '25px',
  marginLeft: 'auto',
  marginRight: 'auto',
  marginBottom: '70px',
  border: '1px solid #DDDDDD',
}))

const ActionButton = styled(Button)(() => ({
  fontSize: '13px',
  height: '42px',
  borderRadius: '20px',
  padding: '5px 20px',
  fontFamily: 'Montserrat-Medium',
  marginLeft: '1rem',
}))

const CustomFormControll = styled(FormControl)(() => ({
  marginLeft: '1px',
  marginTop: '5px',
}))

const InputField = styled(TextField)(() => ({
  marginTop: '5px',
  marginBottom: '5px',
  '& .MuiOutlinedInput-input': {
    padding: '11px 14px',
    fontSize: '13px',
    fontFamily: 'Montserrat-Medium',
  },
  '& .MuiFormLabel-asterisk': {
    color: 'red',
  },
  '& .MuiInputBase-root.MuiOutlinedInput-root': {
    borderRadius: '20px',
  },
  '& .MuiFormLabel-root.MuiInputLabel-root': {
    fontSize: '15px',
    lineHeight: '1.8em',
  },
}))
const InputField1 = styled(TextField)(() => ({
  marginTop: '5px',
  marginBottom: '5px',
  '& .MuiOutlinedInput-input': {
    padding: '11px 14px',
    fontSize: '13px',
    fontFamily: 'Montserrat-Medium',
  },
  '& .MuiFormLabel-asterisk': {
    color: 'white',
  },

  '& .MuiInputBase-root.MuiOutlinedInput-root': {
    borderRadius: '20px',
  },
  '& .MuiFormLabel-root.MuiInputLabel-root': {
    fontSize: '15px',
    lineHeight: '1.8em',
  },
}))

export interface DialogTitleProps {
  id: string
  children?: React.ReactNode
  onClose: () => void
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  )
}

interface ICancelButton {
  fontSize?: string
  height?: string
}

const CancelButton = styled(Button)(({ fontSize = '13px', height = '42px' }: ICancelButton) => ({
  background: '#E2E2E2',
  color: '#000000',
  fontSize: `${fontSize}`,
  height: `${height}`,
  fontFamily: 'Montserrat-SemiBold',
  width: '100px',
  borderRadius: '20px',
  '&:hover': {
    background: '#E2E2E2',
    color: '#000000',
  },
}))

const SelectField = styled(Select)(() => ({
  borderRadius: '20px',
  '& .MuiSelect-select': {
    padding: '8px 14px',
    fontSize: '13px',
    fontFamily: 'Montserrat-Medium',
    borderRadius: '20px',
  },
  '&&.MuiInputBase-root.MuiOutlinedInput-root.MuiSelect-root': {
    borderRadius: '20px',
  },
}))
const StyledSelectField = styled(Select)(({ theme }) => ({
  borderRadius: '20px',
  '& .MuiSelect-select': {
    padding: '9px 11px',
    fontSize: '13px',
    fontFamily: 'Montserrat-Medium',
    borderRadius: '20px',
  },
  '&&.MuiInputBase-root.MuiOutlinedInput-root.MuiSelect-root': {
    borderRadius: '20px',
    padding: '9px 11px',
    marginLeft: '-1px',
  },
}))

const EditPlanForTheDay = (props: any) => {
  const {
    onClose,
    AllProjectsName,
    getPlanForTheDayData,
    getPfpId,
    getPlanForTheDay,
    resetGetPlanForTheDay,
    editPlanForTheDay,
    isGetPlanForTheDaisGetPlanForTheDayLoaderyLoader,
    isNewPlanDialogOpen,
    getUserDetails,
    mandateTypeData,
    isUpdatedPlanForTheDayList,
    approvePlanForTheDayDispatchCall,
    resetEditPlanForTheDay,
    isUpdateLoaderPlanForTheDayList,
    getpfpIdForMngEdit,
    fetchProjectsName,
    getUserById,
    getTaskStatusData,
  } = props
  const [to, setTo] = useState('')
  const [toError, setToError] = useState(false)
  const [ccError, setCcError] = useState(false)
  const [cc, setCc] = useState('')
  const regex = /(\d+)\smins/
  const [formValues, setFormValues] = useState<IFormValues[]>([
    {
      projectName: '',
      otherProjectName: '',
      timeSpend: '',
      taskDescription: '',
      statusId: '2',
      withManager: false,
      withSuperManager: false,
      priority: 'High',
      mandateId: String(mandateTypeData?.data?.[0]?.id),
    },
  ])

  useEffect(() => {
    if (getpfpIdForMngEdit.sender) {
      setTo(getpfpIdForMngEdit.sender)
      setCc(getpfpIdForMngEdit.cc)
    }
  }, [getpfpIdForMngEdit.sender])

  useEffect(() => {
    if (getPlanForTheDayData && getPlanForTheDayData.length > 0 && AllProjectsName) {
      setCc(getpfpIdForMngEdit.cc)
      const updatedFormValues = getPlanForTheDayData.map((item: any) => ({
        projectName: AllProjectsName?.includes(item.projectName) ? item.projectName : 'Other',
        otherProjectName: item.otherProjectName || '',
        timeSpend:
          item.timeSpend?.length > 3
            ? item.timeSpend.match(regex)
              ? parseInt(item.timeSpend.match(regex)[1], 10)
              : ''
            : item.timeSpend,
        taskDescription: convertHTMLToPlainText(item.taskDescription) || '',
        withManager: JSON.parse(item.withManager ? item.withManager : 'false'),
        withSuperManager: JSON.parse(item?.withSuperManager ? item.withSuperManager : 'false'),
        statusId: item.statusId || '2',
        priority: item.priority || 'High',
        mandateId: item.mandateId ? String(item.mandateId) : String(mandateTypeData?.data?.[0]?.id),
      }))

      setFormValues(updatedFormValues)
    }
  }, [getPlanForTheDayData, mandateTypeData, AllProjectsName])

  useEffect(() => {
    setTo(getpfpIdForMngEdit.sender || '')
    setCc(getpfpIdForMngEdit.cc)
    setFormValues((prevFormValues) => {
      return prevFormValues.map((formValue: any) => ({
        ...formValue,
        mandateId: String(mandateTypeData?.data?.[0]?.id),
      }))
    })
  }, [getUserDetails?.manager, mandateTypeData])

  useEffect(() => {
    if (isUpdatedPlanForTheDayList) {
      toast.success('Plan for the day updated successfully')
      resetEditPlanForTheDay()
    }
  }, [isUpdatedPlanForTheDayList])

  const addFormFields = () => {
    setFormValues([
      ...formValues,
      {
        projectName: '',
        otherProjectName: '',
        timeSpend: '',
        taskDescription: '',
        priority: 'High',
        statusId: '2',
        withManager: false,
        withSuperManager: false,
        mandateId: String(mandateTypeData?.data?.[0]?.id),
      },
    ])
  }

  const convertHTMLToPlainText = (html: string): string => {
    // Create a DOM parser to handle HTML strings
    const parser = new DOMParser()
    const doc = parser.parseFromString(html, 'text/html')

    let result = ''

    // Traverse through the child nodes
    doc.body.childNodes.forEach((node) => {
      if (node.nodeName === 'P') {
        // If it's a paragraph, get its text content
        result += `${(node as HTMLElement).innerText}\n`
      } else if (node.nodeName === 'UL') {
        // If it's an unordered list, process the list items
        const listItems = node.childNodes
        listItems.forEach((li) => {
          if (li.nodeName === 'LI') {
            result += `- ${(li as HTMLElement).innerText}\n`
          }
        })
      }
    })

    return result.trim() // Remove any trailing newlines
  }

  const convertToUnorderedList = (content: string) => {
    // Split the input by newlines
    const lines = content.split('\n')

    let isInList = false
    let result = ''

    lines.forEach((line: string) => {
      const trimmedLine = line.trim()

      if (trimmedLine.startsWith('-')) {
        // If we're not already inside a list, start a new unordered list
        if (!isInList) {
          result += '<ul>'
          isInList = true
        }
        // Add list item by trimming the starting '-' and any extra whitespace
        result += `<li>${trimmedLine.substring(1).trim()}</li>`
      } else {
        // If we were inside a list and encounter a non-list item, close the unordered list
        if (isInList) {
          result += '</ul>'
          isInList = false
        }
        // Add the non-list text as a regular paragraph or line
        if (trimmedLine) {
          result += `<p>${trimmedLine}</p>`
        }
      }
    })

    // If the last line was a list, close the unordered list
    if (isInList) {
      result += '</ul>'
    }

    return result
  }
  const deleteFormFields = (indexToDelete: number) => {
    const newFormValues = [
      ...formValues.slice(0, indexToDelete),
      ...formValues.slice(indexToDelete + 1),
    ]
    setFormValues(newFormValues)
  }

  const handleProjectNameChange = (index: number, value: any) => {
    setFormValues((prevData) => {
      const newData = [...prevData]
      newData[index] = {
        ...newData[index],
        projectName: value,
        otherProjectName: value === 'Other' ? newData[index].otherProjectName : '',
      }
      return newData
    })
  }

  const handleProjectTypeChanges = (index: number, value: string) => {
    setFormValues((prevData) => {
      const newData = [...prevData]
      newData[index] = {
        ...newData[index],
        mandateId: String(value),
      }
      return newData
    })
  }

  const handleOtherProjectNameChange = (index: number, value: any) => {
    setFormValues((prevData) => {
      const newData = [...prevData]
      newData[index] = {
        ...newData[index],
        otherProjectName: value,
      }
      return newData
    })
  }

  const handleTimeSpentChange = (index: number, value: string) => {
    const numericValue = Number(value)

    if (!isNaN(numericValue) && numericValue <= 240) {
      setFormValues((prevData) => {
        const newData = [...prevData]
        newData[index] = {
          ...newData[index],
          timeSpend: value,
        }
        return newData
      })
    }
  }
  const handleTaskDescriptionChange = (index: number, content: string) => {
    setFormValues((prevData) => {
      const newData = [...prevData]
      newData[index] = {
        ...newData[index],
        taskDescription: content,
      }
      return newData
    })
  }

  const handleCheckError = () => {
    const hasError =
      formValues.some((value) => {
        return (
          value.projectName === '' ||
          value.timeSpend === '' ||
          value.taskDescription === '' ||
          (value.projectName === 'Other' && value.otherProjectName === '') ||
          value.mandateId === '' ||
          value.priority === ''
        )
      }) ||
      toError ||
      !to ||
      (cc !== '' && ccError)
    return hasError
  }

  const handleCreatePlanForTheDay = () => {
    const updateText = formValues.map((value) => {
      return {
        ...value,
        taskDescription: convertToUnorderedList(value?.taskDescription),
      }
    })
    handleClose()
    if (handleCheckError()) {
      toast.error('Please fill in valid data in all the fields.')
    } else {
      const data = {
        to: to,
        cc: cc,
        planfordayData: updateText,
      }
      editPlanForTheDay({ data, planforthedayId: getPfpId })
      approvePlanForTheDayDispatchCall({ id: getPfpId })
      resetGetPlanForTheDay()
      resetForm()
    }
  }

  const resetForm = () => {
    setTo(getpfpIdForMngEdit.sender || '')
    setToError(false)
    setCcError(false)
    setCc(getpfpIdForMngEdit.cc)
    setFormValues([
      {
        projectName: '',
        otherProjectName: '',
        timeSpend: '',
        taskDescription: '',
        statusId: '2',
        withManager: false,
        withSuperManager: false,
        priority: 'High',
        mandateId: String(mandateTypeData?.data?.[0]?.id) ?? '',
      },
    ])
  }

  const handleClose = () => {
    onClose()
    resetForm()
  }

  const emailListRegex = /^$|^[\w\.-]+@[\w\.-]+(?:,\s*[\w\.-]+@[\w\.-]+)*$/

  const handleTo = (value: string) => {
    setToError(!emailListRegex.test(value))
    setTo(value)
  }

  const handleCc = (value: string) => {
    setCcError(!emailListRegex.test(value))
    setCc(value)
  }

  const handlePriorityChanges = (index: number, value: string) => {
    setFormValues((prevData) => {
      const newData = [...prevData]
      newData[index] = {
        ...newData[index],
        priority: value,
      }
      return newData
    })
  }

  const convertMinutesToHours = (minutes: number) => {
    const hours = (minutes / 60).toFixed(1)
    return `${hours} hrs`
  }

  const handleProjectStatusChanges = (index: number, value: string) => {
    setFormValues((prevData) => {
      const newData = [...prevData]
      newData[index] = {
        ...newData[index],
        statusId: String(value),
      }
      return newData
    })
  }

  const handleOnHoldChange = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement>,
    type: 'withManager' | 'withSuperManager',
  ) => {
    const { checked } = event.target
    setFormValues((prevData) => {
      const newData = [...prevData]
      newData[index] = {
        ...newData[index],
        withManager: type === 'withManager' ? checked : false,
        withSuperManager: type === 'withSuperManager' ? checked : false,
      }

      return newData
    })
  }

  useEffect(() => {
    if (isNewPlanDialogOpen) {
      getPlanForTheDay({ planforthedayId: getPfpId })
      fetchProjectsName(getUserById.id)
    }
  }, [isNewPlanDialogOpen, getUserDetails.id])

  return (
    <>
      <Dialog open={isUpdateLoaderPlanForTheDayList} PaperProps={{ style: loaderProps }}>
        <CircularProgress color='secondary' />
      </Dialog>
      <Dialog open={isNewPlanDialogOpen} maxWidth='lg' className='create_IDSR_wrapper'>
        <BootstrapDialogTitle id='customized-dialog-title' onClose={handleClose}>
          <Typography variant='h5' sx={{ textAlign: 'center', marginTop: '-10px' }}>
            Edit Plan For The Day
          </Typography>
        </BootstrapDialogTitle>
        <Box sx={{ padding: '0 16px', marginTop: '-8px' }}>
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
            <Grid item xs={6} sm={6} sx={{ marginBottom: '15px' }}>
              <InputField
                id='outlined-required'
                label='To'
                size='small'
                fullWidth
                value={to}
                onChange={(e) => handleTo(e.target.value)}
                margin='normal'
                placeholder='Please enter email addresses separated by commas'
                error={toError}
                helperText={toError ? 'Please enter email separated by commas' : ''}
              />
            </Grid>
            <Grid
              item
              xs={6}
              sm={6}
              sx={{ display: 'flex', gap: '8px', alignItems: 'center', marginTop: '-13px' }}
            >
              <InputField1
                id='outlined-required'
                label='Cc'
                size='small'
                fullWidth
                value={cc}
                onChange={(e) => handleCc(e.target.value)}
                margin='normal'
                placeholder='Please enter email addresses separated by commas'
                error={ccError}
                helperText={cc && ccError ? 'Please enter email separated by commas' : ''}
              />
              <Tooltip title='Add Task' arrow>
                <AddCircleOutlineIcon
                  onClick={addFormFields}
                  fontSize='large'
                  sx={{
                    cursor: 'pointer',
                    color: 'green',
                  }}
                />
              </Tooltip>
            </Grid>

            {formValues.map((element, index) => (
              <Grid
                item
                xs={12}
                sm={12}
                key={index}
                container
                spacing={0.8}
                className='project_wrapper'
                sx={{
                  height: 'auto',
                  overflow: 'hidden',
                  display: 'flex', // Flex layout to align items in one line
                  justifyContent: 'space-between',
                  paddingTop: '0px !important',
                  marginTop: '10px',
                }}
              >
                {/* Left section with select fields (60% width) */}
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={element.projectName === 'Other' ? 7 : 7}
                  container
                  spacing={1.5}
                  sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    alignItems: 'center',
                    '& .MuiGrid-container': { paddingTop: '0px' },
                  }}
                >
                  <Grid item xs={element.projectName === 'Other' ? 1.8 : 2.55}>
                    <CustomFormControll>
                      <InputLabel
                        id='demo-simple-select-readonly-label'
                        sx={{ marginTop: -0.3, fontFamily: 'Montserrat-Medium', fontSize: '13px' }}
                      >
                        Select Project <span style={{ color: 'red' }}>*</span>
                      </InputLabel>
                      <StyledSelectField
                        labelId='select-project-label'
                        variant='outlined'
                        label='Select Project:'
                        type='small'
                        fullWidth
                        value={formValues[index]?.projectName || ''}
                        onChange={(e) => handleProjectNameChange(index, e.target.value)}
                      >
                        {AllProjectsName.map((project: string, index: number) => (
                          <MenuItem key={project} value={project}>
                            {project}
                          </MenuItem>
                        ))}
                        <MenuItem value='Other'>Other</MenuItem>
                      </StyledSelectField>
                    </CustomFormControll>
                  </Grid>

                  {element.projectName === 'Other' && (
                    <Grow in={element.projectName === 'Other'} timeout={500}>
                      <Grid item xs={1.8}>
                        <InputField
                          id='outlined-required'
                          label='Project Name'
                          size='small'
                          fullWidth
                          autoFocus
                          value={element.otherProjectName || ''}
                          onChange={(e) => handleOtherProjectNameChange(index, e.target.value)}
                          margin='normal'
                        />
                      </Grid>
                    </Grow>
                  )}

                  <Grid item xs={element.projectName === 'Other' ? 2.2 : 3}>
                    <CustomFormControll>
                      <InputLabel id='demo-simple-select-readonly-label' sx={{ marginTop: -0.7 }}>
                        Mandate Type <span style={{ color: 'red' }}>*</span>
                      </InputLabel>
                      <StyledSelectField
                        labelId='select-type-label'
                        variant='outlined'
                        label='Mandate Type:'
                        type='small'
                        fullWidth
                        value={formValues[index]?.mandateId}
                        onChange={(e) => handleProjectTypeChanges(index, e.target.value as string)}
                      >
                        {mandateTypeData?.data?.map((type: any) => (
                          <MenuItem key={`${type.id}${type.id}`} value={String(type.id)}>
                            {type.mandate_name}
                          </MenuItem>
                        ))}
                      </StyledSelectField>
                    </CustomFormControll>
                  </Grid>

                  <Grid item xs={element.projectName === 'Other' ? 2 : 2.2}>
                    <CustomFormControll>
                      <InputLabel
                        id='demo-simple-select-readonly-label'
                        sx={{ marginTop: -0.3, fontFamily: 'Montserrat-Medium', fontSize: '13px' }}
                      >
                        Task Status <span style={{ color: 'red' }}>*</span>
                      </InputLabel>
                      <StyledSelectField
                        labelId='select-type-label'
                        variant='outlined'
                        label='Task Status:'
                        type='small'
                        fullWidth
                        value={String(formValues[index]?.statusId) || '2'}
                        onChange={(e) =>
                          handleProjectStatusChanges(index, e.target.value as string)
                        }
                      >
                        {getTaskStatusData?.map((type: any, index: any) => (
                          <MenuItem key={`${type.id}${type.status_name}`} value={String(type.id)}>
                            {type.status_name}
                          </MenuItem>
                        ))}
                      </StyledSelectField>
                    </CustomFormControll>
                  </Grid>

                  <Grid item xs={element.projectName === 'Other' ? 1.8 : 1.8}>
                    <CustomFormControll>
                      <InputLabel
                        id='demo-simple-select-readonly-label'
                        sx={{ marginTop: -0.3, fontFamily: 'Montserrat-Medium', fontSize: '13px' }}
                      >
                        Priority <span style={{ color: 'red' }}>*</span>
                      </InputLabel>
                      <StyledSelectField
                        labelId='select-type-label'
                        variant='outlined'
                        label='Priority:'
                        type='small'
                        fullWidth
                        value={formValues[index]?.priority}
                        onChange={(e) => handlePriorityChanges(index, e.target.value as string)}
                      >
                        {priority?.map((type: any, index: number) => (
                          <MenuItem key={`${type}`} value={type}>
                            {type}
                          </MenuItem>
                        ))}
                      </StyledSelectField>
                    </CustomFormControll>
                  </Grid>

                  <Grid
                    item
                    xs={element.projectName === 'Other' ? 2.3 : 2.3}
                    sx={{ marginTop: '5px' }}
                  >
                    <InputField
                      id='outlined-required'
                      label='Est Time (Mins)'
                      size='small'
                      fullWidth
                      value={formValues[index]?.timeSpend || ''}
                      onChange={(e) => handleTimeSpentChange(index, e.target.value)}
                      margin='normal'
                      placeholder='Max. 240 minutes'
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position='end'>
                            <span style={{ fontSize: '14px' }}>
                              {formValues[index]?.timeSpend
                                ? convertMinutesToHours(Number(formValues[index].timeSpend))
                                : ''}
                            </span>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </Grid>

                <Grid
                  item
                  container
                  xs={12}
                  sm={12}
                  md={5}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                >
                  <Grid
                    item
                    xs={11}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                    }}
                  >
                    <TextField
                      id='editor'
                      label='Task Description'
                      multiline
                      fullWidth
                      variant='outlined'
                      value={formValues[index]?.taskDescription || ''}
                      onChange={(e) => handleTaskDescriptionChange(index, e.target.value)}
                      sx={{
                        marginTop: '20px',
                        '& .MuiInputBase-inputMultiline': {
                          position: 'relative',
                          left: '12px',
                          top: '6px !important',
                        },
                        '& .MuiOutlinedInput-root': {
                          width: '101%',
                          fontSize: '12px',
                          fontFamily: 'Montserrat-Medium',
                          borderRadius: '30px',
                          minHeight: '30px', // Set the minimum height to 35px
                          padding: '6px 8px', // Reduce the padding to make the field smaller
                          alignItems: 'center', // Ensure vertical alignment of text
                        },
                        '& .MuiOutlinedInput-input': {
                          fontSize: '12px',
                          fontFamily: 'Montserrat-Medium',
                          minHeight: '30px', // Set minimum height to 35px
                          padding: '0', // Adjust padding to minimize height
                          boxSizing: 'border-box', // Ensure padding and borders are calculated correctly
                          lineHeight: '1.2', // Adjust the line height to control the text appearance
                          '& .MuiFormLabel-asterisk': {
                            color: 'red',
                          },
                        },
                        '& .MuiInputLabel-root': {
                          marginTop: '-4px',
                          fontSize: '13px !important',
                          fontFamily: 'Montserrat-Medium !important',
                          '& .MuiFormLabel-asterisk': {
                            color: 'red',
                          },
                        },
                        '& textarea': {
                          fontSize: '12px',
                          fontFamily: 'Montserrat-Medium',
                          width: '95%',
                          height: 'auto', // Set the height to auto, so it grows with content
                          minHeight: '35px', // Ensure a minimum height of 35px
                          overflowY: 'auto', // Enable scrolling for content that exceeds height
                        },
                      }}
                    />

                    <FormGroup row sx={{ marginRight: '-15px' }}>
                      <FormControlLabel
                        key={`onHold-${index}`}
                        control={
                          <Tooltip title='Collaborate With Manager' arrow>
                            <Checkbox
                              checked={formValues[index].withManager}
                              onChange={(event) => handleOnHoldChange(index, event, 'withManager')}
                            />
                          </Tooltip>
                        }
                        label=''
                        sx={{
                          marginRight: '5px',
                          marginLeft: '5px',
                          '& .MuiFormControlLabel-label': {
                            fontSize: '13.7px',
                            fontFamily: 'Montserrat-Medium',
                          },
                        }}
                      />
                    </FormGroup>

                    <FormGroup row sx={{ marginRight: '-15px' }}>
                      <FormControlLabel
                        key={`withSuperManager-${index}`}
                        control={
                          <Tooltip title='Collaborate With Priyanka' arrow>
                            <Checkbox
                              checked={formValues[index].withSuperManager}
                              onChange={(event) =>
                                handleOnHoldChange(index, event, 'withSuperManager')
                              }
                            />
                          </Tooltip>
                        }
                        label=''
                        sx={{
                          marginRight: '5px',
                          marginLeft: '5px',
                          '& .MuiFormControlLabel-label': {
                            fontSize: '13.7px',
                            fontFamily: 'Montserrat-Medium',
                          },
                        }}
                      />
                    </FormGroup>
                  </Grid>
                  {index !== 0 && (
                    <Tooltip title='Remove Task' arrow>
                      <HighlightOffIcon
                        onClick={() => deleteFormFields(index)}
                        fontSize='large'
                        sx={{
                          marginRight: '-4px',
                          cursor: 'pointer',
                          color: 'red', // Set the color here
                        }}
                      />
                    </Tooltip>
                  )}
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Box>
        <DialogActions className='button_wrapper'>
          <CancelButton onClick={handleClose}>CANCEL</CancelButton>
          <Button
            sx={{
              borderRadius: '20px',
              fontSize: '13px',
              height: '42px',
              fontFamily: 'Montserrat-SemiBold',
              width: '100px',

              '&.Mui-disabled': {
                opacity: 0.5,
                color: '#ffffff',
                cursor: 'not-allowed',
              },
            }}
            onClick={handleCreatePlanForTheDay}
            disabled={handleCheckError()}
          >
            SUBMIT
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    getUserDetails: dashboardEntity.getDashboard(state).getUserDetails,
    isCreatingIDSR: attendanceUI.getEmpAttendanceList(state).isCreatingIDSR,
    isFetchingIDSRs: attendanceUI.getEmpAttendanceList(state).isFetchingIDSRs,
    isIDSRCreated: attendanceUI.getEmpAttendanceList(state).isIDSRCreated,
    getAllIDSRsList: fetchIDSRs.getAllIDSRList(state).fetchIDSRs,
    getUserById: dashboardEntity.getDashboard(state).getUserDetails,
    AttendanceTimesheet: attendanceEntity.getAllAttendance(state).getAttendanceTimesheet,
    AllProjectsName: fetchRCAs.getAllIDSRList(state).fetchProjects,
    getAllPlansList: fetchPlans.getAllPlansList(state).fetchPlansForTheDay,
    getStatusType: fetchStatusType.getStatusTypeList(state).fetchStatusType,
    getTaskStatusData: fetchStatusType.getStatusTypeList(state).fetchTaskStatus,
    isCreatingPlanForTheDay: attendanceUI.getEmpAttendanceList(state).isCreatingPlanForTheDay,
    isPlanForTheDayCreated: attendanceUI.getEmpAttendanceList(state).isPlanForTheDayCreated,
    isFetchingPlans: attendanceUI.getEmpAttendanceList(state).isFetchingPlanForTheDay,
    mandateTypeData: projectManagementEntity.getAllProjectManagement(state).getAllMandate,
    getPlanForTheDayData: attendanceEntity.getAllAttendance(state).getPlanForTheDayData,
    isGetPlanForTheDayList: attendanceUI.getEmpAttendanceList(state).isGetPlanForTheDayList,
    isGetPlanForTheDaisGetPlanForTheDayLoaderyLoader:
      attendanceUI.getEmpAttendanceList(state).isGetPlanForTheDayLoader,

    isUpdatedPlanForTheDayList: attendanceUI.getEmpAttendanceList(state).isUpdatePlanForTheDayList,
    isUpdateLoaderPlanForTheDayList:
      attendanceUI.getEmpAttendanceList(state).isUpdateLoaderPlanForTheDayList,

    getSingleIdsrData: attendanceEntity.getAllAttendance(state).getSingleIdsrData,
    isGetSingleIdsrList: attendanceUI.getEmpAttendanceList(state).isGetSingleIdsrList,
    isUpdatedSingleIdsrList: attendanceUI.getEmpAttendanceList(state).isUpdateSingleList,
    isUpdateLoaderSingleIdsrList: attendanceUI.getEmpAttendanceList(state).isUpdateLoaderIdsrList,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    createIDSR: (data: any) => dispatch(createNewIDSR.request({ data })),
    resetIDSR: () => dispatch(createNewIDSR.reset()),
    getAllIDSRs: (userId: any) => dispatch(getIDSRs.request({ userId })),
    fetchAttendanceData: (data: any) => dispatch(getIDSRs.request(data)),
    fetchAttendanceTimesheet: (userId: any) => dispatch(getAttendanceTimesheet.request({ userId })),
    fetchProjectsName: (userId: any) => dispatch(fetchProjectsName.request({ userId })),
    fetchPlansForTheDay: (data: any) => dispatch(getPlans.request(data)),
    fetchStatusType: () => dispatch(getStatusType.request()),
    fetchTaskStatus: () => dispatch(getTaskStatus.request()),
    createPlanForTheDay: (data: any) => dispatch(createNewPlanForTheDay.request({ data })),
    resetPlanForTheDay: () => dispatch(createNewPlanForTheDay.reset()),
    getMandateType: (data: any) => dispatch(fetchMandateType.request(data)),
    getPlanForTheDay: (data: any) => dispatch(getPlanForTheDay.request(data)),
    editPlanForTheDay: (data: any) => dispatch(editPlanForTheDay.request(data)),
    resetEditPlanForTheDay: (data: any) => dispatch(editPlanForTheDay.reset()),
    resetGetPlanForTheDay: () => dispatch(getPlanForTheDay.reset()),
    approvePlanForTheDayDispatchCall: (data: any) => dispatch(approvePlanForTheDay.request(data)),
    getSingleIdsr: (data: any) => dispatch(getSingleIdsr.request(data)),
    editSingleIdsr: (data: any) => dispatch(editSingleIdsr.request(data)),
    resetEditSingleIdsr: (data: any) => dispatch(editSingleIdsr.reset()),
    resetGetSingleIdsr: () => dispatch(getSingleIdsr.reset()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditPlanForTheDay)
