import React, { useState } from 'react'
import styled from 'styled-components'
import { Box, Paper, Tab, Tabs } from '@mui/material'
import Projects from './Projects/Projects'
import ProjectReports from './ProjectSheet/ProjectSheet'
import NonBillableResources from './NonBillableResources'
import ProjectCustomers from './ProjectCustomers'
import EmployeeCountGraph from './ProjectGraphs/EmployeeCountGraph'
import DesignationWiseBarGraph from './ProjectGraphs/DesignationWiseBarGraph'
import Mandate from './mandate'

const StyledPaper = styled(Paper)(() => ({
  width: '93%',
  padding: '25px 25px',
  background: '#FFFFFF',
  opacity: '1',
  marginTop: '10px',
  marginLeft: 'auto',
  marginRight: 'auto',
  marginBottom: '10px',
  border: '1px solid #DDDDDD',
}))

const ProjectGraph = (props: any) => {
  
  const [selectedTab, setSelectedTab] = useState(() => {
    const savedTab = localStorage.getItem('selectedTab')
    return savedTab ? parseInt(savedTab, 10) : 0
  })

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue)
    localStorage.setItem('selectedTab', newValue.toString())
  }

  return (
    <StyledPaper>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-start',
          justifyContent: 'flex-start',
          marginTop: '-25px',
        }}
      >
        <Tabs value={selectedTab} onChange={handleTabChange} aria-label='Tabs for different tables'>
          <Tab label='PROJECTS' onClick={() => setSelectedTab(0)} />
          <Tab label='CLIENTS' onClick={() => setSelectedTab(1)} />
          <Tab label='NON-BILLABLE RESOURCES' onClick={() => setSelectedTab(2)} />
          <Tab label='PROJECT REPORTS' onClick={() => setSelectedTab(3)} />
          <Tab label='PROJECT GRAPHS' onClick={() => setSelectedTab(4)} />
          <Tab label='Mandate' onClick={() => setSelectedTab(5)} />
        </Tabs>
      </Box>
      {selectedTab === 0 && (
        <>
          <Projects />
        </>
      )}
      {selectedTab === 4 && (
        <Box paddingTop='20px'>
          <EmployeeCountGraph selectedTab={selectedTab} />
          {/* <DesignationWiseBarGraph selectedTab={selectedTab} /> */}
        </Box>
      )}

      {selectedTab === 3 && <ProjectReports />}

      {selectedTab === 2 && (
        <StyledPaper>
          <NonBillableResources />
        </StyledPaper>
      )}

      {selectedTab === 1 && (
        <StyledPaper>
          <ProjectCustomers />
        </StyledPaper>
      )}

      {selectedTab === 5 && (
        <StyledPaper>
          <Mandate />
        </StyledPaper>
      )}
    </StyledPaper>
  )
}

export default ProjectGraph
