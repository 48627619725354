export const CardData = (tableData: any) => {
  return [
    {
      title: 'Project Details',
      items: [`Total Projects - ${tableData?.overall?.count ? tableData?.overall?.count  : 0}`],
    },
    {
      title: 'Total Member',
      items: [
        `Fixed - ${tableData?.overall?.stats?.all_member_fixed  ? tableData?.overall?.stats?.all_member_fixed : 0}`,
        `Monthly - ${tableData?.overall?.stats?.all_member_monthly  ? tableData?.overall?.stats?.all_member_monthly  : 0}`,
        `Hours - ${tableData?.overall?.stats?.all_first_reviewer_hourly ? tableData?.overall?.stats?.all_memebr_hourly  : 0}`,
      ],
    },
    {
      title: 'Total Reviewer 1',
      items: [
        `Fixed - ${tableData?.overall?.stats?.all_first_reviewer_fixed  ? tableData?.overall?.stats?.all_first_reviewer_fixed : 0}`,
        `Monthly - ${tableData?.overall?.stats?.all_first_reviewer_monthly  ? tableData?.overall?.stats?.all_first_reviewer_monthly  : 0}`,
        `Hours - ${tableData?.overall?.stats?.all_memebr_hourly ? tableData?.overall?.stats?.all_first_reviewer_hourly  : 0}`,
      ],
    },
    {
      title: 'Total Reviewer 2',
      items: [
        `Fixed - ${tableData?.overall?.stats?.all_second_reviewer_fixed  ? tableData?.overall?.stats?.all_second_reviewer_fixed : 0}`,
        `Monthly - ${tableData?.overall?.stats?.all_second_reviewer_monthly  ? tableData?.overall?.stats?.all_second_reviewer_monthly  : 0}`,
        `Hours - ${tableData?.overall?.stats?.all_memebr_hourly ? tableData?.overall?.stats?.all_second_reviewer_hourly  : 0}`,
      ],
    },
    // {
    //   title: 'Total Billable Designer',
    //   items: [
    //     `Fixed - ${tableData?.overall?.stats?.all_designer_fixed  ? tableData?.overall?.stats?.all_designer_fixed : 0}`,
    //     `Monthly - ${tableData?.overall?.stats?.all_designer_monthly  ? tableData?.overall?.stats?.all_designer_monthly  : 0}`,
    //     `Hours - ${tableData?.overall?.stats?.all_memebr_hourly ? tableData?.overall?.stats?.all_designer_hourly  : 0}`,
    //   ],
    // },
    // {
    //   title: 'Total Billable Lead',
    //   items: [
    //     `Fixed - ${tableData?.overall?.stats?.all_lead_fixed  ? tableData?.overall?.stats?.all_lead_fixed : 0}`,
    //     `Monthly - ${tableData?.overall?.stats?.all_lead_monthly  ? tableData?.overall?.stats?.all_lead_monthly  : 0}`,
    //     `Hours - ${tableData?.overall?.stats?.all_lead_hourly ? tableData?.overall?.stats?.all_lead_hourly  : 0}`,
    //   ],
    // },
    // {
    //   title: 'Total Billable Reviewer',
    //   items: [
    //     `Fixed - ${tableData?.overall?.stats?.all_reviewer_fixed  ? tableData?.overall?.stats?.all_reviewer_fixed : 0}`,
    //     `Monthly - ${tableData?.overall?.stats?.all_reviewer_monthly  ? tableData?.overall?.stats?.all_reviewer_monthly  : 0}`,
    //     `Hours - ${tableData?.overall?.stats?.all_reviewer_hourly ? tableData?.overall?.stats?.all_reviewer_hourly  : 0}`,
    //   ],
    // },
  ]
}
