import {
  Avatar,
  Box,
  CircularProgress,
  Dialog,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  Paper,
  Tab,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  Tabs,
  Tooltip,
  Typography,
  styled,
} from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import {
  HeaderRightContent,
  StyledMenuItem,
  StyledTableCell,
  StyledTableRow,
  loaderProps,
} from '../Common/CommonStyles'
import {
  addCommentPlanForTheDay,
  addCommentTimesheet,
  editPlanForTheDay,
  fetchBasicInfo,
  fetchDocURL,
  fetchMandateType,
  getAttendanceDetail,
  getAttendanceTimesheet,
  getStatusType,
  managerViewData,
} from '../../actions'
import { RootState } from '../../configureStore'
import { attendanceEntity, attendanceUI, dashboardEntity, dashboardUI, projectManagementEntity } from '../../reducers'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { SelectField } from '../Common/ComponentCommonStyles'
import moment from 'moment'
import { useLocation, useNavigate } from 'react-router-dom'
import { CurrentLoginUserProfile } from '../../utils/StaticData'
import DrsRcaTable from './RCAs'
import DrsIDSRsTable from './IDSRs'
import LeaveDetails from '../Dashboard/LeaveDetails'
import { toast } from 'react-toastify'
import OneToOneDocument from './OneToOneDocument'
import { ArrowBack } from '@mui/icons-material'
import PlanForTheDayList from './PlanForTheDayList'
import { fetchStatusType } from '../../reducers/entities'

const ManagerView = (props: any) => {
  const {
    fetchManagerViewData,
    ManagerViewData,
    AttendanceTimesheet,
    fetchAttendanceTimesheet,
    fetchBasicInfoData,
    BasicInfoData,
    IsUrlSave,
    resetSheet,
    isGettingManagerViewData,
    fetchStatusType,
    getMandateType,
    getStatusType,
    mandateTypeData,
    createCommentPlanForTheDay,
    createCommentTimesheet,
    getLoaderStateTimesheet,
    getLoaderStatePlanForTheDay,
    isUpdatedPlanForTheDayList, 
    resetEditPlanForTheDay
  } = props
  const [selectedTab, setSelectedTab] = useState(0)
  const [selectedMonth, setSelectedMonth] = useState('')

  const location = useLocation()
  const userId = new URLSearchParams(location.search).get('id')
  const navigate = useNavigate()

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue)
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location])

  useEffect(() => {
    fetchStatusType()
    getMandateType({
      data: { pageSize: '', search: '', page: '' },
    })
  }, [])

  useEffect(() => {
    if (userId) {
      fetchAttendanceTimesheet(userId)
    }
  }, [userId])

  useEffect(() => {
    if (userId) {
      fetchBasicInfoData({ userId, isGlobal: true })
    }
  }, [userId])

  useEffect(() => {
    if (AttendanceTimesheet?.length) {
      let data = {
        userId: userId,
        tID: AttendanceTimesheet[0]?.id,
      }
      setSelectedMonth(AttendanceTimesheet[0]?.id)
      fetchManagerViewData(data)
    }
  }, [AttendanceTimesheet, getLoaderStateTimesheet, getLoaderStatePlanForTheDay])

  useEffect(() => {
    if (IsUrlSave) {
      toast.success('Document URL saved successfully.')
      let data = {
        userId: userId,
        tID: AttendanceTimesheet[0]?.id,
      }
      setSelectedMonth(AttendanceTimesheet[0]?.id)
      fetchManagerViewData(data)
      resetSheet()
    }
  }, [IsUrlSave, getLoaderStateTimesheet, getLoaderStatePlanForTheDay])

  const handleSelectMonth = useCallback(
    (event: any) => {
      const selectedMonthId = event.target.value
      setSelectedMonth(selectedMonthId)
      fetchManagerViewData({
        userId: userId,
        tID: selectedMonthId,
      })
    },
    [userId, fetchManagerViewData, getLoaderStateTimesheet, getLoaderStatePlanForTheDay],
  )


  useEffect(() => {
    if (AttendanceTimesheet?.length) {
      let data = {
        userId: userId,
        tID: AttendanceTimesheet?.[0]?.id,
      }
      fetchManagerViewData(data);
    }
  }, [props.getApproveSuccessStatus])

  useEffect(()=>{
    if(isUpdatedPlanForTheDayList && AttendanceTimesheet?.length){
        let data = {
          userId: userId,
          tID: AttendanceTimesheet?.[0]?.id,
        }
        fetchManagerViewData(data);
     resetEditPlanForTheDay()
    }
 },[isUpdatedPlanForTheDayList])

  const SubHeading = styled(Typography)(({ theme }) => ({
    fontSize: '20px',
    fontFamily: 'Montserrat-Medium',
    textAlign: 'center',
    letterSpacing: '0px',
  }))

  const HeaderProfileName = {
    marginRight: '10px',
    fontFamily: 'Montserrat-Medium',
    fontSize: '14px',
  }

  const StyledPaper = styled(Paper)(({ theme }) => ({
    width: '93%',
    padding: '25px 25px',
    background: '#FFFFFF',
    opacity: '1',
    margin: '20px',
    border: '1px solid #DDDDDD',
    minHeight: '350px',
    marginTop: '10px',
  }))

  const StyledFormControl = styled(FormControl)(() => ({
    marginLeft: '-38px',
  }))

  const convertTime = (date: any) => {
    const originalDate = new Date(date)

    const modifiedDate = new Date(originalDate)
    modifiedDate.setHours(originalDate.getHours() - 5)
    modifiedDate.setMinutes(originalDate.getMinutes() - 30)
    return modifiedDate.toISOString()
  }

  const formatTime = (timeString: any) => {
    const [hourString, minute] = timeString.split(':')
    const hour = +hourString % 24
    return (hour % 12 || 12) + ':' + minute + (hour < 12 ? ' AM' : ' PM')
  }

  const handleOnClick = (userId: number) => {
    navigate(`/home/basic-info-on-search?id=${userId}`)
  }

  const UserFullName = BasicInfoData?.first_name + ' ' + BasicInfoData?.last_name

  return (
    <StyledPaper>
      <Box
        onClick={() => navigate(-1)}
        sx={{
          pr: '50px',
          mt: '20px',
          cursor: 'pointer',
          marginTop: '-5px',
          marginRight: '-24px',
          float: 'right',
          position: 'relative',
        }}
      >
        <ArrowBack />
      </Box>

      <Grid
        container
        sm={12}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '90px',
          padding: '20px',
          paddingTop: '0',
          marginTop: '-16px',
        }}
      >
        <Grid sm={12} sx={{ display: 'flex', flexDirection: 'row', marginTop: '-32px' }}>
          <Grid sm={1.5}>
            <img
              src={BasicInfoData?.image_path ? BasicInfoData?.image_path : CurrentLoginUserProfile}
              style={{
                width: '100px',
                borderRadius: '50%',
                height: '100px',
                objectFit: 'cover',
              }}
              alt=''
            />
          </Grid>
          <Grid
            sm={10.5}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'left',
              marginLeft: '24px',
              justifyContent: 'center',
            }}
          >
            <Typography
              variant='h6'
              component='h6'
              sx={{
                fontSize: '20px',
                textAlign: 'left',
                marginTop: '-14px',
                fontFamily: 'Montserrat-SemiBold',
                letterSpacing: '0px',
                color: '#193C6D',
              }}
            >
              {BasicInfoData?.first_name ? UserFullName : ''} {BasicInfoData?.employee_id ? `|` : ''} {BasicInfoData?.first_name ? BasicInfoData?.employee_id ?? 'NA' : ''}
            </Typography>
            <Typography
              variant='h6'
              component='h6'
              sx={{
                fontSize: '13px',
                textAlign: 'left',
                marginTop: '8px',
                fontFamily: 'Montserrat-Medium',
                fontWeight: '100',
                letterSpacing: '0px',
              }}
            >
              {BasicInfoData?.first_name ?BasicInfoData?.Desgination?.desc:''}
            </Typography>
            <Typography
              variant='h6'
              component='h6'
              sx={{
                fontSize: '13px',
                textAlign: 'left',
                marginTop: '8px',
                fontFamily: 'Montserrat-Medium',
                fontWeight: '100',
                letterSpacing: '0px',
              }}
            >
              {BasicInfoData?.first_name ? BasicInfoData?.username ?? 'NA':''}
            </Typography>
          </Grid>
          <Grid
            sm={10.5}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'left',
              justifyContent: 'center',
              marginTop: '15px',
            }}
          >
            <Typography
              variant='h6'
              component='h6'
              sx={{
                fontSize: '13px',
                textAlign: 'left',
                marginTop: '8px',
                fontFamily: 'Montserrat-Medium',
                fontWeight: '100',
                letterSpacing: '0px',
              }}
            >
              Workstation : {BasicInfoData?.first_name ? BasicInfoData?.workStation ?? 'NA':''}
            </Typography>
            <Typography
              variant='h6'
              component='h6'
              sx={{
                fontSize: '13px',
                textAlign: 'left',
                marginTop: '8px',
                fontFamily: 'Montserrat-Medium',
                fontWeight: '100',
                letterSpacing: '0px',
              }}
            >
              Phone : {BasicInfoData?.first_name ? BasicInfoData?.mobile_no ?? 'NA':''}
            </Typography>
          </Grid>
          <Grid
            sm={10.5}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'left',
              justifyContent: 'center',
              marginTop: '15px',
            }}
          >
            <Typography
              variant='h6'
              component='h6'
              sx={{
                fontSize: '13px',
                textAlign: 'left',
                marginTop: '8px',
                fontFamily: 'Montserrat-Medium',
                fontWeight: '100',
                letterSpacing: '0px',
              }}
            >
              Upcoming Leave : {''}
              {BasicInfoData?.upcomingPlannedLeave?.leave_start_date &&
                BasicInfoData?.upcomingPlannedLeave?.leave_end_date
                ? `${new Date(
                  BasicInfoData.upcomingPlannedLeave.leave_start_date,
                ).toLocaleDateString('en-US', {
                  month: '2-digit',
                  day: '2-digit',
                  year: 'numeric',
                })} - ${new Date(
                  BasicInfoData.upcomingPlannedLeave.leave_end_date,
                ).toLocaleDateString('en-US', {
                  month: '2-digit',
                  day: '2-digit',
                  year: 'numeric',
                })}`
                : ' NA'}
            </Typography>
            <Typography
              variant='h6'
              component='h6'
              sx={{
                fontSize: '13px',
                textAlign: 'left',
                marginTop: '8px',
                fontFamily: 'Montserrat-Medium',
                fontWeight: '100',
                letterSpacing: '0px',
              }}
            >
              Current Project : {BasicInfoData?.first_name ? BasicInfoData?.projectName:''}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Divider orientation='horizontal' sx={{ marginTop: 'auto' }} flexItem></Divider>
      {BasicInfoData?.DRS && BasicInfoData.DRS.length > 0 && (
        <>
          <Box style={{ marginTop: '10px', overflowX: 'auto', whiteSpace: 'nowrap' }}>
            <Box style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
              {BasicInfoData.DRS.map((data: any) => (
                <HeaderRightContent
                  key={`${data.userId}${data.employeeName}`}
                  sx={{ marginRight: '5px', cursor: 'pointer', marginLeft: '6px' }}
                  onClick={(e: any) => handleOnClick(data.userId)}
                >
                  <Tooltip title='' arrow>
                    <IconButton sx={{ p: 0 }}>
                      <Avatar alt={data.employeeName} src={data.imagePath} />
                    </IconButton>
                  </Tooltip>
                  <span style={{ ...HeaderProfileName, marginLeft: '7px' }}>
                    {data.employeeName}
                  </span>
                </HeaderRightContent>
              ))}
            </Box>
          </Box>

          <Divider orientation='horizontal' sx={{ marginTop: '9px' }} flexItem />
        </>
      )}

      {ManagerViewData && (
        <>
          {ManagerViewData?.message ? (
            <Typography
              variant='subtitle1'
              align='center'
              sx={{ color: '#483f3f', marginTop: '20px', marginRight: '65px' }}
            >
              {ManagerViewData.message}
            </Typography>
          ) : (
            <>

              <Tabs
                value={selectedTab}
                onChange={handleTabChange}
                aria-label='Tabs for different tables'
              >
                <Tab label='Plan For The Day' onClick={() => setSelectedTab(0)} />
                <Tab label='TIMESHEET' onClick={() => setSelectedTab(1)} />
                {/* <Tab label='Attendance' onClick={() => setSelectedTab(2)} /> */}
                <Tab label='RCA' onClick={() => setSelectedTab(2)} />
                <Tab label='Leaves' onClick={() => setSelectedTab(3)} />
                <Tab label='1-1 Document' onClick={() => setSelectedTab(4)} />
              </Tabs>
              <Box
                sx={{
                  justifyContent: 'flex-end',
                  display: 'flex',
                  alignItems: 'flex-start',
                  '& > div:nth-of-type(1), & > div:nth-of-type(2)': {
                    marginTop: '-32px',
                  },
                }}
              >
                {selectedTab !== 3 && selectedTab !== 5 && selectedTab !== 4 && (
                  <Box
                    sx={{
                      width: '160px',
                      display: 'flex',
                      alignItems: 'left',
                      justifyContent: 'flex-end',
                      marginRight: '8px',
                    }}
                  >
                    <StyledFormControl>
                      <InputLabel id='demo-simple-select-readonly-label'>Select Month</InputLabel>
                      <SelectField
                        sx={{ borderRadius: '22px', height: '42px' }}
                        variant='outlined'
                        label='Select Month:'
                        value={selectedMonth}
                        onChange={handleSelectMonth}
                      >
                        {AttendanceTimesheet.map((option: any) => (
                          <StyledMenuItem key={`${option?.start_date}${option?.id}`} value={option?.id}>
                            {moment(option?.start_date).format('MMMM YYYY')}
                          </StyledMenuItem>
                        ))}
                      </SelectField>
                    </StyledFormControl>
                  </Box>
                )}
              </Box>
              {/* {selectedTab === 2 && (
                <>
                  <Dialog open={isGettingManagerViewData} PaperProps={{ style: loaderProps }}>
                    <CircularProgress color='secondary' />
                  </Dialog>
                  <StyledPaper>
                    <div>
                      <Typography
                        align='center'
                        sx={{ position: 'relative', top: '0px', fontFamily: 'Montserrat-Medium' }}
                      >
                        Reporting time: {formatTime(BasicInfoData?.officeTime || '')}
                      </Typography>
                    </div>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-evenly',
                        alignItems: 'baseline',
                      }}
                    >
                      <Box sx={{ flex: '1', marginRight: '20px' }}>
                        <Typography>
                          <SubHeading>Late Attendance</SubHeading>
                        </Typography>
                        <TableContainer
                          sx={{
                            margin: '20px 0',
                            position:
                              ManagerViewData.lateAttendanceDetails?.length > 0
                                ? 'relative'
                                : 'static',
                            maxHeight: '300px',
                          }}
                          component={Paper}
                        >
                          <Table
                            sx={{
                              minWidth: 640,
                              minHeight:
                                ManagerViewData.lateAttendanceDetails?.length > 0 ? 115 : 50,
                            }}
                            aria-label='customized table'
                          >
                            <TableHead>
                              <StyledTableRow>
                                <StyledTableCell style={{ textAlign: 'left', marginLeft: '8px' }}>
                                  REPORTING TIME
                                </StyledTableCell>
                                <StyledTableCell style={{ textAlign: 'left' }}>
                                  ACTUAL REPORTING TIME
                                </StyledTableCell>
                                <StyledTableCell style={{ textAlign: 'left' }}>
                                  LATE BY
                                </StyledTableCell>
                                <StyledTableCell style={{ textAlign: 'left' }}>DAY</StyledTableCell>
                                <StyledTableCell style={{ textAlign: 'left' }}>
                                  DATE
                                </StyledTableCell>
                              </StyledTableRow>
                            </TableHead>
                            <TableBody>
                              {ManagerViewData?.lateAttendanceDetails?.length > 0 ? (
                                ManagerViewData?.lateAttendanceDetails.map(
                                  (data: any) => (
                                    <StyledTableRow key={`${data?.actual_scan_time}${data.date}`}>
                                      <StyledTableCell
                                        component='th'
                                        scope='row'
                                        style={{ verticalAlign: 'top', margin: '25px' }}
                                      >
                                        {moment(
                                          new Date(convertTime(data.expected_scan_time)),
                                          'HH:mm',
                                        ).format('h:mm A')}
                                      </StyledTableCell>
                                      <StyledTableCell
                                        component='th'
                                        scope='row'
                                        style={{ verticalAlign: 'top' }}
                                      >
                                        {moment(
                                          new Date(convertTime(data.actual_scan_time)),
                                          'HH:mm',
                                        ).format('h:mm A')}
                                      </StyledTableCell>
                                      <StyledTableCell
                                        align='left'
                                        style={{ verticalAlign: 'top' }}
                                      >
                                        {data.late_by}
                                      </StyledTableCell>
                                      <StyledTableCell
                                        align='left'
                                        style={{ verticalAlign: 'top' }}
                                      >
                                        {moment(
                                          new Date(data.expected_scan_time),
                                          'YYYY-MM-DD',
                                        ).format('dddd')}
                                      </StyledTableCell>
                                      <StyledTableCell
                                        align='left'
                                        style={{ verticalAlign: 'top' }}
                                      >
                                        {data.date}
                                      </StyledTableCell>
                                    </StyledTableRow>
                                  ),
                                )
                              ) : (
                                <StyledTableRow>
                                  <StyledTableCell align='center' colSpan={10}>
                                    <Typography
                                      variant='subtitle1'
                                      align='center'
                                      sx={{ color: '#483f3f' }}
                                    >
                                      No data found
                                    </Typography>
                                  </StyledTableCell>
                                </StyledTableRow>
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Box>
                      <Box sx={{ flex: '1' }}>
                        <Typography>
                          <SubHeading>No Attendance</SubHeading>
                        </Typography>

                        <TableContainer
                          sx={{
                            margin: '24px 0',
                            position: 'relative',
                            bottom: '5px',
                            maxHeight: '300px',
                          }}
                          component={Paper}
                        >
                          <Table
                            sx={{
                              minHeight: 105,
                            }}
                            aria-label='customized table'
                          >
                            <TableHead>
                              <StyledTableRow>
                                <StyledTableCell style={{ textAlign: 'left', marginLeft: '8px' }}>
                                  REPORTING TIME
                                </StyledTableCell>
                                <StyledTableCell style={{ textAlign: 'left' }}>DAY</StyledTableCell>{' '}
                                <StyledTableCell style={{ textAlign: 'left' }}>
                                  DATE
                                </StyledTableCell>
                              </StyledTableRow>
                            </TableHead>
                            {ManagerViewData?.noAttendanceDetails?.length > 0 ? (
                              <TableBody>
                                {ManagerViewData?.noAttendanceDetails.map(
                                  (data: any) => (
                                    <StyledTableRow key={`${data?.expected_scan_time}${data?.date}`}>
                                      <StyledTableCell component='th' scope='row'>
                                        {moment(
                                          new Date(convertTime(data.expected_scan_time)),
                                          'HH:mm',
                                        ).format('h:mm A')}
                                      </StyledTableCell>
                                      <StyledTableCell align='left'>
                                        {moment(
                                          new Date(convertTime(data.expected_scan_time)),
                                          'YYYY-MM-DD',
                                        ).format('dddd')}
                                      </StyledTableCell>
                                      <StyledTableCell align='left'>{data.date}</StyledTableCell>
                                    </StyledTableRow>
                                  ),
                                )}
                              </TableBody>
                            ) : (
                              <TableBody>
                                <StyledTableRow>
                                  <StyledTableCell align='center' colSpan={10}>
                                    <Typography
                                      variant='subtitle1'
                                      align='center'
                                      sx={{ color: '#483f3f' }}
                                    >
                                      No data found
                                    </Typography>
                                  </StyledTableCell>
                                </StyledTableRow>
                              </TableBody>
                            )}
                          </Table>
                        </TableContainer>
                      </Box>
                    </Box>
                  </StyledPaper>
                </>
              )} */}
              {selectedTab === 1 && (
                <>
                  <Dialog open={isGettingManagerViewData} PaperProps={{ style: loaderProps }}>
                    <CircularProgress color='secondary' />
                  </Dialog>
                  <DrsIDSRsTable mandateTypeData={mandateTypeData} createCommentTimesheet={createCommentTimesheet} resetTimesheetComment={props.resetTimesheetComment} />
                </>
              )}
              {selectedTab === 2 && (
                <>
                  <Dialog open={isGettingManagerViewData} PaperProps={{ style: loaderProps }}>
                    <CircularProgress color='secondary' />
                  </Dialog>
                  <DrsRcaTable />
                </>
              )}
              {selectedTab === 3 && (
                <>
                  <StyledPaper>
                    <LeaveDetails showBackArrow={false} />
                  </StyledPaper>
                </>
              )}
              {selectedTab === 4 && (
                <>
                  <OneToOneDocument />
                </>
              )}
              {selectedTab === 0 && (
                <>
                  <Dialog open={isGettingManagerViewData} PaperProps={{ style: loaderProps }}>
                    <CircularProgress color='secondary' />
                  </Dialog>
                  <PlanForTheDayList mandateTypeData={mandateTypeData} createCommentPlanForTheDay={createCommentPlanForTheDay} createCommentTimesheet={createCommentTimesheet} resetPlanForTheDayComment={props.resetPlanForTheDayComment} />
                </>
              )}
            </>
          )}
        </>
      )}
    </StyledPaper>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    ManagerViewData: dashboardEntity.getDashboard(state).getManagerViewData,
    isGettingManagerViewData: dashboardUI.getDashboard(state).isGettingManagerViewData,
    AttendanceTimesheet: attendanceEntity.getAllAttendance(state).getAttendanceTimesheet,
    getUserById: dashboardEntity.getDashboard(state).getUserDetails,
    BasicInfoData: dashboardEntity.getDashboard(state).getBasicInfoData,
    IsUrlSave: dashboardUI.getDashboard(state).isURLSave,
    getStatusType: fetchStatusType.getStatusTypeList(state).fetchStatusType,
    mandateTypeData: projectManagementEntity.getAllProjectManagement(state).getAllMandate,
    getLoaderStateTimesheet: attendanceUI.getEmpAttendanceList(state).isCreateCommentForTimesheetLoader,
    getLoaderStatePlanForTheDay: attendanceUI.getEmpAttendanceList(state).isCreateCommentForPlanForTheDayLoader,
    getCommentSuccess: attendanceUI.getEmpAttendanceList(state).isCreateCommentForPlanForTheDaySuccess,
    getApproveSuccessStatus: attendanceUI.getEmpAttendanceList(state).isApprovingPlanForTheDaySuccess,
    isUpdatedPlanForTheDayList: attendanceUI.getEmpAttendanceList(state).isUpdatePlanForTheDayList,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    fetchManagerViewData: (data: any) => dispatch(managerViewData.request(data)),
    resetSheet: () => dispatch(fetchDocURL.reset()),
    fetchAttendanceTimesheet: (userId: any) => dispatch(getAttendanceTimesheet.request({ userId })),
    fetchAttendanceData: (data: any) => dispatch(getAttendanceDetail.request(data)),
    fetchBasicInfoData: (data: any) => dispatch(fetchBasicInfo.request(data)),
    fetchStatusType: () => dispatch(getStatusType.request()),
    getMandateType: (data: any) => dispatch(fetchMandateType.request(data)),
    createCommentPlanForTheDay: (data: any) => dispatch(addCommentPlanForTheDay.request(data)),
    createCommentTimesheet: (data: any) => dispatch(addCommentTimesheet.request(data)),
    resetPlanForTheDayComment: () => dispatch(addCommentPlanForTheDay.reset()),
    resetTimesheetComment: () => dispatch(addCommentTimesheet.reset()),
    resetEditPlanForTheDay: (data: any) => dispatch(editPlanForTheDay.reset()),

  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ManagerView)
