import { IFinancialLeave } from './financial-leave.model'

export interface ILeavesAccrualReport {
  employeeId?: string
  employeeName?: string
  designation?: string
  totalLeaves?: number
  prevYearTotalLeaves?: number
  availedLeaves?: number
  prevAvailedLeaves?: number
  currentYearLeaves?: number
  previousYearLeaves?: number
  futureYearTotalLeaves?: number
  encashedLeaves: number
  balanceLeaves?: number
  accrualType?: string
  leaveAccrualFiles?: Array<any>
  leaveAccrualList?: IFinancialLeave[]
}

export const defaultValue: Readonly<ILeavesAccrualReport> = {
  encashedLeaves: 0,
}
