import { createTheme } from '@mui/material'
import { Primary } from './utils'

export const themeBreakpoint = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      // small
      sm: 600,
      // medium
      md: 900,
      // large
      lg: 1200,
      // extra-large
      xl: 1536,
    },
  },
})

const AppTheme = createTheme({
  palette: {
    primary: {
      main: Primary,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          backgroundColor: Primary,
          color: '#FFF',
          fontWeight: 'bold',
          fontSize: 'large',
          textTransform: 'none',
          '&:hover': {
            backgroundColor: Primary,
            color: '#fff',
          },
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'outlined',
        margin: 'normal',
        required: true,
        fullWidth: true,
      },
      styleOverrides: {
        root: {
          [themeBreakpoint.breakpoints.down('xl')]: {
            width: '100%',
          },
          [themeBreakpoint.breakpoints.up('xl')]: {
            width: '100%',
          },
          marginBottom: '16px',
          '& .MuiFormLabel-root.Mui-focused': {
            color: `${Primary} !important`,
          },
          '& .MuiFormLabel-root.Mui-error': {
            color: '#d32f2f !important',
          },
          '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: `${Primary} !important`,
          },
          '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
            borderColor: '#d32f2f !important',
          },
          '&:hover fieldset': {
            borderColor: 'grey',
          },
          '& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input:-webkit-autofill': {
            'box-shadow': '0 0 0 30px white inset !important',
          },
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          [themeBreakpoint.breakpoints.down('xl')]: {
            width: '100%',
          },
          [themeBreakpoint.breakpoints.up('xl')]: {
            width: '100%',
          },
        },
      },
    },
    MuiLink: {
      defaultProps: {
        textAlign: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        target: '_blank',
      },
    },
    MuiStack: {
      defaultProps: {
        direction: 'row',
        alignItems: 'center',
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          '&.Mui-checked': {
            color: Primary,
          },
        },
      },
    },
  },
})

export default AppTheme
