import { Table, TableBody, TableHead } from '@mui/material'
import React from 'react'
// import { StyledEditableTableCell, StyledTableRow } from '../CommonStyles'
// import { style } from '../../Report/SheetStyles'
import NoDataFound from '../Common/table/NoDataFound'
import { StyledEditableTableCell, StyledTableRow } from '../Common/CommonStyles'
import { style } from '../Report/SheetStyles'
import moment from 'moment'

const FollowUpTable = (props: any) => {
  const { tableData, width } = props
  const handleRowData = (rowData: any) => {}

  function htmlToText(html: string) {
    const temporaryElement = document.createElement('div')
    temporaryElement.innerHTML = html
    return temporaryElement.textContent || temporaryElement.innerText || ''
  }

  const tableHeaderForAssignedTask = [
    'EMP NAME',
    'PROJECT NAME',
    'TASK DESCRIPTION',
    'FOLLOW UP DATE',
  ]

  return (
    <>
      <Table style={{ marginTop: '20px' }}>
        <TableHead id='head'>
          <StyledTableRow sx={style.border}>
            {tableHeaderForAssignedTask?.length &&
              tableHeaderForAssignedTask?.map((header: any, index: any) => (
                <StyledEditableTableCell key={index} width={width ? width : '100px'}>
                  {header}
                </StyledEditableTableCell>
              ))}
          </StyledTableRow>
        </TableHead>
        <TableBody>
          {tableData?.length > 0 ? (
            tableData?.map((row: any, index: any) => (
              <StyledTableRow
                id='body'
                key={index}
                sx={style.border}
                onClick={() => handleRowData(row)}
              >
                <StyledEditableTableCell width='150px'>{row.name}</StyledEditableTableCell>
                <StyledEditableTableCell width='150px'>{row.projectName}</StyledEditableTableCell>
                <StyledEditableTableCell width='250px'>
                  {htmlToText(row.taskDescription)}
                </StyledEditableTableCell>
                <StyledEditableTableCell width='100px'>
                  {row.submission_time ? moment(row.submission_time).format('MM-DD-YYYY') : 'NA'}
                </StyledEditableTableCell>
              </StyledTableRow>
            ))
          ) : (
            <NoDataFound />
          )}
        </TableBody>
      </Table>
    </>
  )
}

export default FollowUpTable
