import { Box, Button, styled } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs, { Dayjs } from 'dayjs'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'

type PropType = {
  startDate: Dayjs
  endDate: Dayjs
  setStartDate: Dispatch<SetStateAction<Dayjs>>
  setEndDate: Dispatch<SetStateAction<Dayjs>>
  setShowResults: Dispatch<SetStateAction<boolean>>
  searchQuery: string
  selectedTab: any
  width: any
}

const style = {
  showResults: {
    padding: '3px 14px',
    borderRadius: '20px',
    fontFamily: 'Montserrat-Medium',
    fontSize: '0.9rem',
    height: '40px',
    '@media only screen and (max-width:650px)': {
      width: '7rem',
      fontSize: '0.75rem',
    },
    '&.Mui-disabled': {
      color: '#BEBEBE',
      backgroundColor: 'transparent',
    },
  },
}

const StyledDatePicker = styled(DatePicker)(({ theme }) => ({
  '*': {
    paddingLeft: '0',
    marginLeft: '0',
  },
  '&': {
    width: '10rem',
    padding: '0',
    marginLeft: '10px',
    '@media only screen and (max-width:460px)': {
      margin: '7px',
    },
  },
  '& .MuiInputBase-root': {
    height: '40px',
  },
  '& .MuiFormLabel-asterisk': {
    display: 'none',
  },
  '& .MuiDateCalendar-root': {
    width: '100px',
  },
  '& .MuiInputBase-root.MuiOutlinedInput-root': {
    borderRadius: '20px',
  },
  input: {
    fontSize: '14px',
    padding: '11.5px 14px',
    color: 'primary',
    fontFamily: 'Montserrat-Medium',
  },
  '@media only screen and (max-width:650px)': {
    '&': {
      width: '6rem',
    },
    input: {
      fontSize: '12px',
      padding: '9px 10px',
    },
  },
}))

const DateRangePicker = (props: PropType) => {
  const { setStartDate, setEndDate, setShowResults, startDate, endDate, selectedTab, width } = props
  const [startDateOpen, setStartDateOpen] = useState(false)
  const [endDateOpen, setEndDateOpen] = useState(false)
  const [fromDate, setFromDate] = useState(startDate)
  const [toDate, setToDate] = useState(endDate)
  const dayBeforeFirstWeek = fromDate
  const currentDate = endDate

  const setMonthRange = () => {
    setFromDate(dayBeforeFirstWeek)
    setToDate(currentDate)
  }

  useEffect(() => {
    setMonthRange()
  }, [])

  useEffect(() => {
    setFromDate(startDate)
    setToDate(dayjs())
  }, [selectedTab])

  const handlerDateResult = () => {
    setStartDate(fromDate)
    setEndDate(toDate)
    setShowResults((prev: boolean) => !prev)
  }

  useEffect(() => {
    setStartDate(fromDate)
    setEndDate(toDate)
  }, [fromDate, toDate])

  return (
    <Box sx={{ width: { width }, display: 'flex', alignItems: 'center' }}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Box display='flex' alignItems='center' justifyContent='center' margin='0' padding='0'>
          <StyledDatePicker
            label='Start Date'
            value={fromDate}
            onAccept={(newValue: any) => {
              if (fromDate.diff(toDate) > 0) setToDate(newValue as Dayjs)
              return setFromDate(newValue as Dayjs)
            }}
            disableFuture
            open={startDateOpen}
            onClose={() => setStartDateOpen(false)}
            slotProps={{
              textField: {
                onClick: () => setStartDateOpen(true),
              },
            }}
          />
          <Box display='flex' alignItems='center' justifyContent='center' marginLeft='1rem'>
            <StyledDatePicker
              label='End Date'
              value={toDate}
              onAccept={(newValue: any) => setToDate(newValue as Dayjs)}
              minDate={fromDate}
              open={endDateOpen}
              onClose={() => setEndDateOpen(false)}
              slotProps={{
                textField: {
                  onClick: () => setEndDateOpen(true),
                },
              }}
            />
          </Box>
        </Box>
        <Box display='flex' alignItems='center' justifyContent='center' sx={{width: '150PX'}}>
          <Button onClick={handlerDateResult} variant='outlined' sx={style.showResults}>
            Show Result
          </Button>
        </Box>
      </LocalizationProvider>
    </Box>
  )
}

export default DateRangePicker
