import React, { useEffect, useState } from 'react'
import ApexCharts from 'apexcharts'

// Predefined color palette for the first 10 colors
const predefinedColors = [
  '#FF5733',
  '#33FF57',
  '#3357FF',
  '#FF33A8',
  '#FF8F33',
  '#33FFF3',
  '#33A8FF',
  '#D433FF',
  '#FF5733',
  '#57FF33',
]

// Function to generate distinct colors using RGB
const generateColors = (count: number) => {
  const colors = [...predefinedColors] // Start with predefined colors

  // Generate more colors if needed
  if (count > predefinedColors.length) {
    const step = Math.floor(255 / (count - predefinedColors.length)) // Calculate step size for RGB
    for (let i = predefinedColors.length; i < count; i++) {
      const r = (i * step) % 256 // Cycle through red values
      const g = (128 + i * step) % 256 // Cycle through green values
      const b = (64 + i * step) % 256 // Cycle through blue values
      colors.push(`rgb(${r}, ${g}, ${b})`) // Add new RGB color
    }
  }

  return colors.slice(0, count) // Return only the required number of colors
}

const AnalyticsPieChart = (props: any) => {
  const { myChartData } = props

  const [chartData, setChartData] = useState<{ name: string; count: number }[]>([])

  useEffect(() => {
    if (myChartData && myChartData) {
      const newData = myChartData.map((user: any) => ({
        name: user.name,
        count: user.count || 0, // Ensure we have the count field
      }))
      setChartData(newData)
    }
  }, [myChartData])

  useEffect(() => {
    if (chartData.length > 0) {
      const names = chartData.map((item) => item.name)
      const counts = chartData.map((item) => item.count)
      const colors = generateColors(chartData.length) // Dynamically generate colors based on data length

      const options = {
        series: counts, // Pie chart uses the counts as the series
        chart: {
          height: 320,
          type: 'donut',
        },
        plotOptions: {
          pie: {
            startAngle: -90,
            endAngle: 270,
          },
        },
        fill: {
          type: 'gradient',
        },
        labels: names, // Labels for the pie slices
        colors: colors, // Use the generated colors
        tooltip: {
          y: {
            formatter: function (val: any) {
              return val
            },
          },
        },
        legend: {
          position: 'bottom',
        },
      }

      const chart = new ApexCharts(document.querySelector('#piechart'), options)
      chart.render()

      return () => {
        chart.destroy()
      }
    }
  }, [chartData])

  return (
    <div
      id='piechart'
      style={{ display: 'flex', justifyContent: 'center', width: '100%', height: '300px', marginTop:'20px' }}
    />
  )
}

export default AnalyticsPieChart
