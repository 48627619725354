import * as XLSX from 'xlsx-js-style'

export const exportToExcel = () => {
  if (document.querySelectorAll('#body').length == 0) {
    return
  }
  const newtable = document.getElementById('export') as HTMLTableElement

  const table = newtable.cloneNode(true) as HTMLTableElement

  const headingRow = document.createElement('tr')
  const headingCell = document.createElement('th')
  headingCell.colSpan = 13
  headingCell.textContent = document.getElementsByClassName('heading')[0].innerHTML
  headingRow.appendChild(headingCell)
  const subHeadingRow = document.createElement('tr')
  const subHeadingCell = document.createElement('th')
  subHeadingCell.colSpan = 13
  subHeadingCell.textContent = document.getElementsByClassName('heading')[1].innerHTML
  subHeadingRow.appendChild(subHeadingCell)

  const thead = document.createElement('thead')
  thead.appendChild(headingRow)
  thead.appendChild(subHeadingRow)

  const existingThead = table.querySelector('thead')
  if (existingThead) {
    existingThead.parentNode?.insertBefore(thead, existingThead)
  } else {
    table.insertBefore(thead, table.firstChild)
  }
  for (let i = 0; i < 2; i++) {
    const placeholderRow = document.createElement('tr')
    const placeholderCell = document.createElement('td')
    placeholderCell.colSpan = 13
    placeholderRow.appendChild(placeholderCell)
    table.appendChild(placeholderRow)
  }

  const wb = XLSX.utils.table_to_book(table)
  const sheetName = Object.keys(wb.Sheets)[0]
  const sheet = wb.Sheets[sheetName]
  sheet['A1'].s = {
    alignment: {
      vertical: 'center',
      horizontal: 'center',
    },
    font: {
      sz: 15,
      bold: true,
    },
  }

  sheet['A2'].s = {
    alignment: {
      vertical: 'center',
      horizontal: 'center',
    },
    font: {
      sz: 13,
      bold: true,
    },
  }

  for (let i = 0; i < 13; i++) {
    const char = `${String.fromCharCode(65 + i) + 3}`
    sheet[char].s = {
      alignment: {
        vertical: 'center',
        horizontal: 'center',
      },
      font: {
        bold: true,
      },
    }
  }

  const cols = []
  for (let i = 0; i < 13; i++) {
    cols.push({ wch: 20 })
    sheet['!rows'] = sheet['!rows'] || []
    sheet['!rows'][i] = { hpx: 25 }
  }

  sheet['!cols'] = cols

  XLSX.writeFile(wb, `${subHeadingCell.textContent}.xlsx`)
}
