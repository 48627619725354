import { Box, Button, styled } from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs, { Dayjs } from 'dayjs'
import { Dispatch, SetStateAction, useState } from 'react'
import { dateSubmitButtonForReport } from '../CommontStyleForResource'
import { datePickerStyle } from './StyleForDatePicker'

type PropType = {
  startDate: Dayjs
  endDate: Dayjs
  setStartDate: Dispatch<SetStateAction<Dayjs>>
  setEndDate: Dispatch<SetStateAction<Dayjs>>
}

export const StyledDatePicker = styled(DatePicker)(({ theme }) => ({
  '*': {
    paddingLeft: '0',
    marginLeft: '0',
  },
  '& .MuiFormLabel-asterisk': {
    display: 'none',
  },
  '&': {
    width: '10rem',
    padding: '0',
  },
  '& .MuiDateCalendar-root': {
    width: '100px',
  },
  '& .MuiInputBase-root': {
    height: '40px',
  },
  '& .MuiInputBase-root.MuiOutlinedInput-root': {
    borderRadius: '20px',
  },
  label: { fontFamily: 'Montserrat-Medium', backgroundColor: '#ffffff', paddingRight: '5px' },
  input: {
    fontSize: '14px',
    padding: '11.5px 14px',
    color: 'primary',
    fontFamily: 'Montserrat-Medium',
  },
  '@media only screen and (max-width:780px)': {
    '&': {
      width: '8rem',
    },
    input: {
      fontSize: '12px',
      padding: '9px 10px',
    },
  },
}))

const DateRangePicker = (props: PropType) => {
  const { setStartDate, setEndDate, startDate, endDate } = props
  const [fromDate, setFromDate] = useState(
    dayjs(new Date(new Date().getFullYear(), new Date().getMonth(), 1)),
  )
  const [toDate, setToDate] = useState(dayjs(new Date()))
  const [openStartDatePicker, setOpenStartDatePicker] = useState<boolean>(false)
  const [openEndDatePicker, setOpenEndDatePicker] = useState<boolean>(false)
  const handlerDateResult = () => {
    setStartDate(fromDate)
    setEndDate(toDate)
  }
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box
        sx={datePickerStyle}
        display='flex'
        alignItems='center'
        justifyContent='center'
        marginLeft='-2px'
        padding='0'
      >
        <Box display='flex' alignItems='center' justifyContent='center' margin='0' padding='0'>
          <Box display='flex' alignItems='center' justifyContent='center' margin='0' padding='0'>
            <StyledDatePicker
              label='From'
              value={startDate}
              onAccept={(newValue) => {
                if (startDate.diff(endDate) > 0) {
                  setEndDate(newValue as Dayjs)
                }
                return setFromDate(newValue as Dayjs)
              }}
              open={openStartDatePicker}
              maxDate={toDate}
              slotProps={{
                textField: {
                  onClick: () => setOpenStartDatePicker(true),
                },
              }}
              onClose={() => setOpenStartDatePicker(false)}
              disableFuture
            />
          </Box>
          <Box display='flex' alignItems='center' justifyContent='center' marginLeft='1rem'>
            <StyledDatePicker
              label='To'
              value={endDate}
              open={openEndDatePicker}
              onAccept={(newValue) => setToDate(newValue as Dayjs)}
              minDate={fromDate}
              slotProps={{
                textField: {
                  onClick: () => setOpenEndDatePicker(true),
                },
              }}
              onClose={() => setOpenEndDatePicker(false)}
            />
          </Box>
        </Box>
        <Box display='flex' alignItems='center' justifyContent='center' marginLeft='1.5rem'>
          <Button onClick={handlerDateResult} variant='outlined' sx={dateSubmitButtonForReport}>
            Show Result
          </Button>
        </Box>
      </Box>
    </LocalizationProvider>
  )
}
export default DateRangePicker
