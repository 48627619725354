import React from 'react';
import { StyledTableCell } from '../../Common/CommonStyles';
import { Box, TableRow, styled } from '@mui/material';

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  left: '160px',
  width: '1719px',
  height: '60px',
  boxShadow: '0px 10px 3px #6c6c6c10',
  opacity: '1',
}))

const TableBodyRow = (Props: { row: any }) => {
  const { row } = Props
  const renderProjectDetails = (projectDetails: any[]) => {
    if (projectDetails.length === 0) {
      return 'NA'
    }
    return projectDetails.map((project, index) => (
      <Box key={index}>
        {project.project ?? 'NA'} | {project.type ?? 'NA'} | {project.role ?? 'NA'}{project.hours ? ` (${project.hours})` : ''}
      </Box>
    )) 
    
  };

  const technologies = row.technologies.join(', ');

  return (
    <StyledTableRow key={row.emp_id} id='body'>
      <StyledTableCell>{row.emp_id ?? 'NA'}</StyledTableCell>
      <StyledTableCell>{row.name ?? 'NA'}</StyledTableCell>
      <StyledTableCell>{row.manager ?? 'NA'}</StyledTableCell>
     {row?.time_count || row?.time_count===0 ? <StyledTableCell>{row?.time_count ? `Hours left: ${row.time_count}` : 'NA'}
     </StyledTableCell>: 
      <StyledTableCell>{renderProjectDetails(row.project_details)}</StyledTableCell>
  }

      <StyledTableCell>{technologies ? technologies : 'NA'}</StyledTableCell>
    </StyledTableRow>
  );
};

export default TableBodyRow;
