import { Primary } from "../../../utils";

export const style = {
  container: {
    margin: '20px',
    padding: '10px',
    backgroundColor: '#FFFFFF',
    overflow: 'hidden',
    '@media print': {
      body: { '-webkit-print-color-adjust': 'exact' },
      '@page': {
        size: '1250px',
        margin: '0',
        '-webkit-print-color-adjust': 'exact',
      },
    },
  },
  pageHead: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0 0 1rem 0',
    '@media only screen and (max-width:860px)': {
      flexDirection: 'column',
    },
  },
  headingBar: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
  },
  primaryHeading: {
    fontSize: '1.3rem',
    fontFamily: 'Montserrat-Bold',
    color: Primary,
    '@media only screen and (max-width:480px)': {
      textAlign: 'center',
    },
  },
  headerHeading: {
    fontSize: '0.9rem',
    textAlign: 'left',
    fontFamily: 'Montserrat-Regular',
    color: Primary,
    '@media only screen and (max-width:780px)': {
      fontSize: '0.7rem',
      textAlign: 'center',
    },
    width: 'fit-content',
  },

  tableBoxStyle: {
    '@media only screen and (max-width:780px)': {
      minWidth: '70%',
      overflow: 'scroll',
    },
  },
  tableContainer: {
    margin: '1rem 1rem',
    minWidth: '70vw',
    overflow: 'scroll',
    '*::-webkit-scrollbar ': { display: 'none' },
    '@media only screen and (max-width:650px)': {
      margin: '15px 25px',
    },
    '@media only screen and (max-width:500px)': {
      margin: '15px 35px',
    },
  },
  border: {
    borderLeft: '1px solid #E9E9E9',
    borderRight: '1px solid #E9E9E9',
  },
  tableHeadCell: {
    color: 'white',
  },
  rowBorder: {
    borderTop: '1px solid #EEEEEE',
    borderBottom: '1px solid #EEEEEE',
    minHeight: '60px',
    margin: '16px 0 0',
  },
  rowInnerItem: {
    fontFamily: 'Montserrat-Regular',
    fontSize: '0.8rem',
    padding: '0',
  },
  actionBarConatiner: {
    margin: 'auto',
    position: 'relative',
    border: '2px solid #E0E0E0',
    borderRadius: '5px',
    width: '97%',
    '@media only screen and (max-width:1290px)': {
      width: '94%',
    },
    '@media only screen and (max-width:1000px)': {
      width: '90%',
    },
    '@media only screen and (max-width:750px)': {
      width: '85%',
    },
    '@media only screen and (max-width:600px)': {
      width: '80%',
    },
  },
  actionBar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: '22px',
    width: '93%',
    '@media only screen and (max-width:1290px)': {
      paddingLeft: '0',
      width: '100%',
      marginBottom: '10px',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  searchBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '40%',
    '@media only screen and (max-width:1160px)': {
      width: '100%',
      justifyContent: 'center',
    },
    '@media only screen and (max-width:600px)': {
      flexDirection: 'column',
    },
  },
  actionBarDownloadbtn: {
    backgroundColor: 'black',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '5px',
    zIndex: 1,
  },
  expandButton: {
    backgroundColor: 'transparent',
    position: 'absolute',
    right: '0',
    top: '0.5rem',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  cardSection: {
    height: '40px',
    width: '310px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0.5rem',
    color: 'primary',
    backgroundColor: '#c1c1c1',
    textTransform: 'uppercase',
    borderRadius: '20px',
    padding: '0,20px,0,20px',
    '@media only screen and (max-width:1125px)': {
      display: 'flex',
    },
  },
  cardText: {
    color: '#353665',
    fontFamily: 'Montserrat-Medium',
    padding: '20px',
    fontSize: '0.9rem',
  },
  loader: { backgroundColor: '#FFFFFF' },
  collapseBoxStyle: {
    display: 'flex',
    justifyContent: 'center',
    width: 'fit-content',
    margin: '0rem 0rem 1rem 1rem',
    '@media only screen and (max-width:1025px)': {
      flexWrap: 'wrap',
    },
    '@media only screen and (max-width:1000px)': {
      margin: '0rem 0rem 1rem 0',
    },
  },
  styleOfTableBox: {
    minWidth: '700px',
  },
  tableHead: {
    '& th:first-of-type': {
      borderRadius: '0 0 0 0',
      border: 'none',
    },
    '& th:last-of-type': {
      borderRadius: '0 0 0 0',
      border: 'none',
    },
    '& th': {
      fontFamily: 'Montserrat-Medium',
      fontSize: '1rem',
      padding: '15px 0 15px 0',
      border: 'none',
    },
    backgroundColor: Primary,
  },
  boxCircularProgress: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '100px',
  },
  selectAndPaginationBox: {
    display: 'flex',
    float: 'right',
    alignItems: 'center',
  },
  SelectReportTypeBoxStyle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    // width: '160px',
    '@media only screen and (max-width:1290px)': {
      margin: '15px 0',
    },
  },
  entriesAndDataPickerStyle: {
    display: 'flex',
    alignItems: 'center',
    '@media only screen and (max-width:900px)': {
      flexDirection: 'column',
    },
  },
  datePickerBoxStyle: {
    display: 'flex',
    alignItems: 'center',
    padding: '0',
    marginRight: '10px',
    '@media only screen and (max-width:1290px)': {
      margin: '0 0 10px',
    },
    '@media only screen and (max-width:900px)': {
      marginLeft: '0',
    },
    '@media only screen and (max-width:750px)': {
      flexDirection: 'column',
    },
  },
  inDatePickerBoxStyle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '40px',
    margin: '15px 0',
    '@media only screen and (max-width:750px)': {
      margin: '10px 0',
    },
  },
  showReportBoxStyle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '3px 10px',
    '@media only screen and (max-width:1290px)': {
      marginLeft: '0',
    },
  },
  showReportBoxBtnStyle: {
    padding: '3px 10px',
    borderRadius: '20px',
    fontFamily: 'Montserrat-Medium',
    fontSize: '0.910rem',
    height: '40px',
    whiteSpace: 'nowrap',
  },
  noQAProjectRowTypographyStyle: {
    fontFamily: 'Montserrat-Regular',
    fontSize: '0.9rem',
    color: '#444444',
    marginLeft: '0.5rem',
  },
  noQAProjectRowTypoStyle: {
    textAlign: 'center',
    borderLeft: '2px solid #EEEEEE',
    borderRight: '2px solid #EEEEEE',
    borderRadius: '5px 0 0 5px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '20px',
  },
  BoxSecondCustomStyle: {
    width: '200px',
    display: 'flex',
    justifyContent: 'space-between',
    float: 'left',
    alignItems: 'center',
  },
  FormSecondControlStyle: {
    m: 1,
    minWidth: '75px',
  },

  typographyEntriesNameSecondStyle: {
    width: '550px',
    color: '#353665',
    fontFamily: 'Montserrat-Medium',
    fontSize: '0.9rem',
  },
  selectReportTypeStyle: {
    width: '200px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    padding: '5px',
    fontFamily: 'Montserrat-Medium',
    height: '40px',
    fontSize: '0.9rem',
    display: 'flex',
    flexWrap: 'nowrap',
    backgroundColor: '#FFFFFF',
    color: '#353665',
    borderRadius: '50px',
    '&:hover': {
      backgroundColor: '#FFFFFF',
      color: '#353665',
    },
  },
  menuItem: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  selectSecondTypeStyle: {
    width: '75px',
    height: '40px',
    borderRadius: '20px',
  },
  exportBtnBoxStyle: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    width: '160px',
    backgroundColor: 'white',
  },
  exportButtonsStyle: {
    fontFamily: 'Montserrat-Regular',
    fontSize: '1rem',
    padding: '0',
    width: '40px',
    height: '40px',
    minWidth: 'fit-content',
    borderRadius: '50%',
    backgroundColor: 'white',
    '&:hover': {
      backgroundColor: 'white',
    },
    '@media only screen and (max-width:780px)': {
      fontSize: '0.9rem',
    },
  },
}
