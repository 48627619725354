import { call, takeLatest } from 'redux-saga/effects'
import { actionTypes } from '../actions'
import * as ApiService from '../services'
import { sendPayload, sendPayloadFailure } from './helper'
import { LOGOUT } from '../actions/actiontypes'
import { toast } from 'react-toastify'
const { REQUEST, LOGIN } = actionTypes

//write sagas function

function* handleLoginUser(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.loginUserEndpoint,
      data.data,
    )
    yield sendPayload(apiResponse, LOGIN)
  } catch (e) {
    yield sendPayloadFailure(e, LOGIN)
  }
}

function* handleLogoutUser(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(ApiService.logoutUserEndpoint)
    yield sendPayload(apiResponse, LOGOUT)
    toast.error('Logout Successfull!')
    setTimeout(function () {
      localStorage.clear()
      window.location.href = '/'
    }, 4000)
  } catch (e) {
    yield sendPayloadFailure(e, LOGOUT)
  }
}

//This saga in for renewing access token
function* handleGetAccessToken(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.getAccessTokenEndPoint,
      data.data,
    )
    // yield sendPayload(apiResponse, GET_ACCESS_TOKEN)
  } catch (e) {
    // yield sendPayloadFailure(e, GET_ACCESS_TOKEN)
    // handleGetAccessToken(data)
  }
}

export const sagas = {
  //watcher come here
  watchLoginUser: takeLatest(actionTypes.LOGIN[REQUEST], handleLoginUser),
  watchHandleAccessToken: takeLatest(actionTypes.GET_ACCESS_TOKEN[REQUEST], handleGetAccessToken),
  watchLogout: takeLatest(actionTypes.LOGOUT[REQUEST], handleLogoutUser),
}
