import { Box, Typography } from '@mui/material'
import styled from 'styled-components'
import { FC } from 'react'

interface PropsType {
  title: string
  items: string[]
}

const StyledBox = styled(Box)(({ theme }) => ({
  marginLeft: '8px',
  width: '19%',
  minWidth: 'fit-content',
  height: '120px',
  background: '#F2F2F2',
  color: '#353665',
  marginTop: '10px',
  borderRadius: '10px',
  '@media only screen and (max-width:900px)': {
    fontSize: '12px',
    margin: '0',
    marginTop: '10px',
  },
}))

const style = {
  cardContainer: {
    padding: '10px 14px',
  },
  cardTitle: {
    fontSize: '1rem',
    textAlign: 'left',
    fontFamily: 'Montserrat-Bold',
  },
}

const Card: FC<PropsType> = (props) => {
  const { title, items } = props
  return (
    <>
      <StyledBox>
        <Box width='90%' height='100%' sx={style.cardContainer}>
          <Box height='30%' display='flex' flexWrap='wrap'>
            <Typography variant='subtitle1' sx={style.cardTitle}>
              {title}
            </Typography>
          </Box>
          <Box>
            {items.map((item) => {
              return (
                <Typography
                  key={item}
                  variant='body2'
                  fontFamily='Montserrat-Regular'
                  fontSize='0.8rem'
                >
                  {item}
                </Typography>
              )
            })}
          </Box>
        </Box>
      </StyledBox>
    </>
  )
}

export default Card
