import { Grid, Card, CardContent, Typography, Box } from '@mui/material'
import { ReactComponent as NoItemFoundIcon } from '../../../assets/images/NoItemIcon.svg'
import { style } from './projectCustomersStyle'

export const NoCards = () => {
  return (
     <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={12} sm={12} md={12}>
          <Card sx={style.noCardStyle}>
            <CardContent sx={style.noCardContentStyle}>
              <Box sx={style.noCardContentBoxStyle}>
                <NoItemFoundIcon />
                <Typography sx={style.noCardContentTypoStyle}>Data Not Found.</Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
  )
}
