import { Box, Button, Collapse } from '@mui/material' 
import DateRangePicker from './DateRangePicker'
import SearchBox from './SearchBox'
import Card from './Cards/Index'
import { ReactComponent as ExpandIcon } from '../../../assets/images/expand_content.svg'
import { ReactComponent as ShrinkIcon } from '../../../assets/images/shrink.svg'
import { ActionBarPropsType } from './ProjectSheetTypes'
import { style } from './SheetStyles'
import ExportButton from './ExportButton'


const ActionBar = (props: ActionBarPropsType) => {

  const {
    expand,
    setExpand,
    searchQuery,
    setSearchQuery,
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    cardData,
    setShowResults
  } = props
  

  return (
    <Box component="div" id='actionBar'>
      <Box sx={style.actionBarConatiner} paddingBottom={expand ? '1rem' : '0'}>
        <Box sx={style.actionBar}>
        <SearchBox 
        searchQuery={searchQuery} 
        setSearchQuery={setSearchQuery}       
        />
          <Box display='flex' alignItems='center' justifyContent='center' padding='0' sx={style.datePickerContainer}>
            <DateRangePicker
              startDate={startDate}
              endDate={endDate}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              setShowResults={setShowResults}
              searchQuery={searchQuery}
            />
          </Box>
          <Box sx={style.actionBarDownloadbtn}>
            <ExportButton />
          </Box>
          <Button size='small' onClick={() => setExpand((prev) => !prev)} sx={style.expandButton}>
            {expand ? <ShrinkIcon /> : <ExpandIcon />}
          </Button>
        </Box>
        <Collapse orientation='vertical' in={expand}>
          <Box component='div' id='card'>
            <Box sx={style.cardContainer}>
              {cardData.length > 0 &&
                cardData.map((item) => {
                  return <Card key={item.title} title={item.title} items={item.items} />
                })}
            </Box>
          </Box>
        </Collapse>
      </Box>
    </Box>
  )
}

export default ActionBar
