import { alpha, Box, Button, CircularProgress, Dialog, FormControl, Grid, IconButton, InputLabel, Menu, MenuItem, MenuProps, Pagination, Paper, Select, Stack, styled, Tab, Table, TableBody, TableContainer, TableHead, TableRow, Tabs, Tooltip, Typography } from '@mui/material';
import { HeaderHeading, loaderProps, StatusBoxCustom, StyledTableCell, StyledTableCellForMyTeam, StyledTableRow } from '../Common/CommonStyles';
import { style } from '../Report/SheetStyles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { useEffect, useState } from 'react';
import { RootState } from '../../configureStore';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { downloadForm16, getFinancialYear, getPayrollForm16, sendMail, uploadForm16 } from '../../actions';
import { financeEntity, financeUI } from '../../reducers';
import { toast } from 'react-toastify';
import { StyledMenuItem } from '../UserDetails/UserDetailsStyle';
import ShowEnteries from './ShowEntries';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';


const getColumnData = [
    { id: "1", label: "EMPLOYEE ID" },
    { id: "2", label: "EMPLOYEE" },
    { id: "3", label: "EMAIL" },
    { id: "4", label: "PAN" },
    { id: "5", label: "MOBILE" },
    { id: "6", label: "ACTIONS" },
];

interface ICancelButton {
    fontSize?: string
    height?: string
}

const CancelButton = styled(Button)(({ fontSize = '13px', height = '42px' }: ICancelButton) => ({
    background: '#E2E2E2',
    color: '#000000',
    fontSize: `${fontSize}`,
    height: `${height}`,
    fontFamily: 'Montserrat-SemiBold',
    width: '100px',
    borderRadius: '20px',
    '&:hover': {
        background: '#E2E2E2',
        color: '#000000',
    },
}))


const Form16Info = (props: any) => {
    const [uploadToggle, setUploadToggle] = useState(false);
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [fileDataEmail, setFileDataEmail] = useState<File | null>(null);
    const [fileError, setFileError] = useState<string | null>(null); // Error state for file validation
    const [yearData, setYearData] = useState(props?.financialYearData[0] ?? '')
    const [id, setId] = useState<Number>();
    let [empStatus, setEmpStatus] = useState(1);
    const [page, setPage] = useState(1)
    const [rowsPerPage, setRowsPerPage] = useState(100)

    useEffect(() => {
        props?.fetchFinancialYear();
    }, []);

    useEffect(() => {
        if (props?.financialYearData) {
            setYearData(props?.financialYearData[0])
        }
    }, [props?.financialYearData])

    useEffect(() => {
        if (yearData)
            props?.fetchForm16Data({ currentYear: yearData, status: empStatus, limit: rowsPerPage, page: page, user: 0 });
    }, [empStatus, yearData, page, rowsPerPage, props?.isUploadedForm16Loader]);

    const setDataForUploadImg = (id: number) => {
        setId(id)
    }

    useEffect(() => {
        setPage(1)
    }, [empStatus])

    useEffect(() => {
        if (props?.downloadForm16Success) {
            const link = document.getElementById("download");
            link?.click();
            toast.success('Form 16 downloaded successfully')
            props?.downloadForm16Reset();
        }
    }, [props?.downloadForm16Success]);

    useEffect(() => {
        if (props?.isSendEmailLoaderSuccess) {
            toast.success('Email sent successfully')
            props?.sendEmailResetRequest();
        }
    }, [props?.isSendEmailLoaderSuccess]);

    useEffect(() => {
        if (props?.isUploadedForm16ToastSuccess) {
            toast.success('Form 16 uploaded successfully')
            props?.uploadForm16Reset();
        }
    }, [props?.isUploadedForm16ToastSuccess])

    const uploadImgCall = () => {
        if (selectedFile) {
            let filedata = selectedFile;
            const reader = new FileReader()
            reader.readAsDataURL(filedata)
            props?.uploadForm16({ id_user: id, file: filedata, currentYear: yearData })
        }
    }

    const sendEmailCall = (id: number) => {
        props?.sendEmailRequest({ userId: id, accountsDepartmentId: 19, currentYear: yearData });
    }

    const setDownloadForm16Data = (id: number) => {
        props?.downloadForm16({ id: id, currentYear: yearData })
    }

    const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files ? e.target.files[0] : null;
        if (file) {
            setFileError(null);
            setSelectedFile(file);
            setFileDataEmail(file)

            if (file.type !== 'application/pdf') {
                setFileError('Please select a PDF file.');
            } else if (file.size > 10 * 1024 * 1024) { // Check if file size exceeds 10 MB
                setFileError('File size must not exceed 10 MB.');
                setSelectedFile(null); // Clear selected file if there's an error
                setFileDataEmail(null)
            } else {
                setFileError(null);  // Clear the error if file is valid
                setFileDataEmail(null)
            }
        } else {
            setSelectedFile(null);
            setFileDataEmail(null)
        }
    };

    const handleCloseUpload = () => {
        setUploadToggle(false);
        setSelectedFile(null);
        setFileError(null);  // Clear the error when dialog is closed
    };

    const handleChangePage = (event: React.ChangeEvent<unknown> | null, newPage: number) => {
        setPage(newPage)
    }

    return (
        <Paper
            sx={{ padding: '10px 25px', margin: '25px auto', width: '90%' }}
        >
            <HeaderHeading>Form 16</HeaderHeading>
            <Box display={'flex'} width={'100%'} height={'40px'} alignItems={'center'} gap={'10px'}>
                <Box sx={{ width: '200px' }}>
                    <FormControl fullWidth>
                        <InputLabel sx={{ marginTop: '-5px' }} id="demo-simple-select-label">Financial Year</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="financialYear"
                            label="Financial Year"
                            sx={{ borderRadius: '30px', height: '42px' }}
                            value={yearData}
                            onChange={(e: any) => {
                                setYearData(e.target.value)
                            }}
                        >
                            {props?.financialYearData?.map((data: string, index: number) => (
                                <MenuItem key={index} value={data}>
                                    {data}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>

                <Box>
                    <StatusBoxCustom
                        size='small'
                        label='Emp Status'
                        value={empStatus}
                        onChange={(e: any) => setEmpStatus(e.target.value)}
                        select
                        required={false}
                    >
                        <StyledMenuItem value={0}>All</StyledMenuItem>
                        <StyledMenuItem value={1}>Active</StyledMenuItem>
                        <StyledMenuItem value={2}>Disabled</StyledMenuItem>
                    </StatusBoxCustom>
                </Box>
            </Box>

            <Box width='100%' margin='15px 0 20px 0px' sx={style.table}>
                <TableContainer component={Paper} id='export' sx={style.tableContainer}>
                    <Table>
                        <TableHead id='head'>
                            <StyledTableRow sx={style.border}>
                                {getColumnData?.map((column: any) => (
                                    <StyledTableCell key={column.id} >
                                        {column.label}
                                    </StyledTableCell>
                                ))}
                            </StyledTableRow>
                        </TableHead>
                        <TableBody>
                            {props?.getAllForm16Data?.data?.map((row: any, idx: any) => (
                                <StyledTableRow key={idx} sx={style.border}>
                                    <StyledTableCellForMyTeam>{row?.employee_id}</StyledTableCellForMyTeam>
                                    <StyledTableCellForMyTeam>{row?.name}</StyledTableCellForMyTeam>
                                    <StyledTableCellForMyTeam>{row?.email}</StyledTableCellForMyTeam>
                                    <StyledTableCellForMyTeam>{row?.pan_card_no}</StyledTableCellForMyTeam>
                                    <StyledTableCellForMyTeam>{row?.mobile}</StyledTableCellForMyTeam>
                                    <StyledTableCellForMyTeam>
                                        <><a id='download' href={props?.downloadForm16LinkData} download>
                                        </a>
                                            <Tooltip title={row?.file !== 'NA' ? 'Download Form 16' : ''} arrow>
                                                <IconButton
                                                    sx={{
                                                        opacity: row?.file === 'NA' ? '0.5' : '1',
                                                        '&:hover': {
                                                            cursor: row?.file === 'NA' ? 'not-allowed' : 'pointer',
                                                        },
                                                    }}
                                                    onClick={(event) => {
                                                        if (row?.file !== 'NA') {
                                                            event.stopPropagation();
                                                            setDownloadForm16Data(row.userId)
                                                        }
                                                    }}
                                                    disableRipple={row?.file === 'NA'}
                                                >
                                                    <CloudDownloadIcon fontSize="medium" sx={{ color: '#8b41f7' }} />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title={row?.status === false ? '' : 'Upload Form 16'} arrow>
                                                <IconButton
                                                    sx={{
                                                        opacity: row?.status === false ? 0.5 : 1,
                                                        '&:hover': {
                                                            cursor: row?.status === false ? 'not-allowed' : 'pointer',
                                                        },
                                                    }}
                                                    disableRipple={row?.status === false}
                                                    onClick={() => {
                                                        if (row?.status !== false) {
                                                            setDataForUploadImg(row.userId);
                                                            return setUploadToggle(true)
                                                        }
                                                    }
                                                    }
                                                >
                                                    <CloudUploadIcon fontSize='medium' sx={{ color: '#3d4ff9' }} />
                                                </IconButton>
                                            </Tooltip>

                                            <Tooltip title={row?.file !== 'Uploaded' ? '' : 'Send Email'} arrow>
                                                <IconButton
                                                    sx={{
                                                        cursor: row?.file === 'Uploaded' ? 'pointer' : 'not-allowed',
                                                        opacity: row?.file === 'Uploaded' ? 1 : 0.5,
                                                        '&:hover': {
                                                            cursor: row?.file === 'Uploaded' ? 'pointer' : 'not-allowed',
                                                        },
                                                    }}
                                                    disableRipple={row?.file !== 'Uploaded'}
                                                    onClick={() => {
                                                        if (row?.file === 'Uploaded') {
                                                            sendEmailCall(row.userId)
                                                        }
                                                    }}
                                                >
                                                    <ForwardToInboxIcon fontSize='medium' sx={{ color: '#cb2e81' }}
                                                    />
                                                </IconButton>
                                            </Tooltip>
                                        </>
                                    </StyledTableCellForMyTeam>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                {
                    props?.getAllForm16Data?.data?.length === 0 && <Box marginTop={'30px'} height={'40px'} width={'100%'} display={'flex'} alignItems={'center'} justifyContent={'center'} border={'1px solid #DDDDDD'}>
                        <Typography variant='h5' fontFamily={'Montserrat-Medium'} fontSize={'14px'} color={'#808080'} fontWeight={500}>No Form 16 Data Available</Typography>
                    </Box>
                }
                <Stack
                    width='100%'
                    spacing={2}
                    direction='row'
                    justifyContent='flex-end'
                    marginTop='1rem'
                >
                    <ShowEnteries
                        setPage={setPage}
                        setRowsPerPage={setRowsPerPage}
                        rowsPerPage={rowsPerPage}
                    />
                    <Pagination
                        count={Math.ceil(props?.getAllForm16Data?.total / rowsPerPage)}
                        page={page}
                        onChange={handleChangePage}
                        color='primary'
                    />
                </Stack>
            </Box>

            {/* Upload Dialog */}
            <Dialog open={uploadToggle} onClose={handleCloseUpload} maxWidth="sm" fullWidth>
                <Box sx={{ padding: '20px', textAlign: 'center' }}>
                    <Typography variant="h6" gutterBottom>
                        Upload File
                    </Typography>

                    <Box sx={{
                        border: '2px dashed #ccc',
                        borderRadius: '10px',
                        padding: '20px',
                        backgroundColor: '#f7f7f7',
                        margin: '20px 0',
                        cursor: 'pointer',
                    }}>
                        <input
                            type="file"
                            accept=".pdf"
                            onChange={handleFileUpload}
                            style={{ display: 'none' }}
                            id="fileUpload"
                        />
                        <label htmlFor="fileUpload">
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                flexDirection: 'column',
                                cursor: 'pointer',
                                height: '150px',
                                color: '#888'
                            }}>
                                <CloudUploadIcon sx={{ fontSize: 50, color: '#888' }} />
                                <Typography variant="body1">
                                    {selectedFile ? selectedFile.name : 'Click to upload'}
                                </Typography>
                                <Typography variant="body2" color="textSecondary">
                                    Note: Maximum file size is 10MB. Supported format: PDF
                                </Typography>


                                {/* Display file type error */}
                                {fileError && (
                                    <Typography color="error" variant="body2">
                                        {fileError}
                                    </Typography>
                                )}
                            </Box>
                        </label>
                    </Box>

                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: '15px', height: '35px' }}>
                        <CancelButton onClick={handleCloseUpload}>
                            Cancel
                        </CancelButton>
                        <Button
                            variant="contained"
                            sx={{
                                borderRadius: '20px',
                                fontSize: '13px',
                                height: '42px',
                                fontFamily: 'Montserrat-SemiBold',
                                width: '100px',

                                '&.Mui-disabled': {
                                    opacity: 0.5,
                                    color: '#ffffff',
                                    cursor: 'not-allowed',
                                },
                            }}
                            onClick={() => {
                                uploadImgCall();
                                handleCloseUpload();
                            }}
                            disabled={!selectedFile || fileError !== null}
                        >
                            Upload
                        </Button>
                    </Box>
                </Box>
            </Dialog>

            {(props?.isUploadedForm16Loader || props?.isDownloadedForm16Loader || props?.isForm16DataLoader || props?.isSendEmailLoader) && (
                <Dialog
                    open={props?.isUploadedForm16Loader || props?.isDownloadedForm16Loader || props?.isForm16DataLoader || props?.isSendEmailLoader}
                    PaperProps={{ style: loaderProps }}
                >
                    <CircularProgress color='secondary' />
                </Dialog>
            )}
        </Paper>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        getAllForm16Data: financeEntity.getFinance(state).getForm16Data,
        isForm16DataLoader: financeUI.getFinance(state).isForm16DataLoader,
        financialYearData: financeEntity.getFinance(state).getFinancialYearData,
        isUploadedForm16Loader: financeUI.getFinance(state).isUploadedForm16,
        isDownloadedForm16Loader: financeUI.getFinance(state).isDownloadedForm16Loader,
        downloadForm16LinkData: financeEntity.getFinance(state).getDownloadForm16Link,
        downloadForm16Success: financeUI.getFinance(state).isDownloadedForm16,
        isDownloadedFormSucesss: financeUI.getFinance(state).isDownloadedFormSucesss,
        isUploadedForm16ToastSuccess: financeUI.getFinance(state).isUploadedForm16ToastSuccess,
        isSendEmailLoader: financeUI.getFinance(state).isSendEmailLoader,
        isSendEmailLoaderSuccess: financeUI.getFinance(state).isSendEmailLoaderSuccess,
    };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        fetchForm16Data: (data: any) => {
            dispatch(getPayrollForm16.request(data));
        },
        uploadForm16: (data: any) => { dispatch(uploadForm16.request(data)); },
        uploadForm16Reset: () => { dispatch(uploadForm16.reset()); },
        fetchFinancialYear: () => { dispatch(getFinancialYear.request()); },
        downloadForm16: (data: any) => { dispatch(downloadForm16.request(data)); },
        downloadForm16Reset: () => { dispatch(downloadForm16.reset()); },
        sendEmailRequest: (data: any) => { dispatch(sendMail.request(data)); },
        sendEmailResetRequest: () => { dispatch(sendMail.reset()); },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Form16Info);
