import { SearchBoxCustom, SearchIconStyle } from '../../Common/CommonStyles'
import styled from '@emotion/styled'

const CustomSearchBox = styled(SearchBoxCustom)(({ theme }) => ({
  width: '200px',
  '&.MuiFormControl-root': {
    margin: '0',
  },
  '& .MuiOutlinedInput-root': {
    borderRadius: '20px',
    height: '40px',
    '& fieldset': {
      borderColor: '#777777',
    },
  },
}))

const SearchBox = ({ searchQuery, setSearchQuery }: any) => {
  return (
    <CustomSearchBox
      id='outlined-basic'
      placeholder='Search Resources'
      variant='outlined'
      size='small'
      value={searchQuery}
      onChange={(e) => setSearchQuery(e.target.value)}
      InputProps={{
        startAdornment: <SearchIconStyle />,
      }}
    />
  )
}
export default SearchBox
