import { Box, TableBody, Typography } from '@mui/material'
import { ReactComponent as NoItemFoundIcon } from '../../../assets/images/NoItemIcon.svg'
import { StyledTableCell, StyledTableRow } from '../../../Common/CommonStyles'
// import { StyledTableCell, StyledTableRow } from '../CommonStyles'

const NoDataFound = () => {
  return (
    <StyledTableRow>
      <StyledTableCell align='center' colSpan={10}>
        <Typography variant='subtitle1' sx={{ color: '#483f3f', marginLeft: 50 }}>
          No matching records found.
        </Typography>
      </StyledTableCell>
    </StyledTableRow>
  )
}

export default NoDataFound
