import { combineReducers } from 'redux'
import { actionTypes } from '../../actions'
import { Actions as actions } from '../../actions/Types'
import { RootState } from '../../configureStore'
import {
  EMPLOYEE_HISTORY,
  FETCH_DESIGNATION,
  FETCH_DESIGNATIONBAND,
  FETCH_EMPINFO,
  FETCH_EMPLOYMENTTYPE,
  FETCH_QUALIFICATION,
  FETCH_QUALIFICATION_SKILL,
  FETCH_SAPERIOD,
  FETCH_TIMING,
  GET_IDSR_RECENT,
  MANAGER_HISTORY,
} from '../../actions/actiontypes'

const { SUCCESS, REQUEST, GET_USER_DATA } = actionTypes

const entity = () => {
  const getEmpInfoData = (state = [], action: actions) => {
    switch (action.type) {
      case FETCH_EMPINFO[SUCCESS]:
        return action.payload
      case FETCH_EMPINFO[REQUEST]:
        return []
      default:
        return state
    }
  }

  const getIdsrRecentData = (state = [], action: actions) => {
    switch (action.type) {
      case GET_IDSR_RECENT[SUCCESS]:
        return action.payload
      case GET_IDSR_RECENT[REQUEST]:
        return []
      default:
        return state
    }
  }

  const getDesignationData = (state = [], action: actions) => {
    switch (action.type) {
      case FETCH_DESIGNATION[SUCCESS]:
        return action.payload
      case FETCH_DESIGNATION[REQUEST]:
        return []
      default:
        return state
    }
  }

  const getDesignationBandData = (state = [], action: actions) => {
    switch (action.type) {
      case FETCH_DESIGNATIONBAND[SUCCESS]:
        return action.payload
      case FETCH_DESIGNATIONBAND[REQUEST]:
        return []
      default:
        return state
    }
  }

  const getEmploymentTypeData = (state = [], action: actions) => {
    switch (action.type) {
      case FETCH_EMPLOYMENTTYPE[SUCCESS]:
        return action.payload
      case FETCH_EMPLOYMENTTYPE[REQUEST]:
        return []
      default:
        return state
    }
  }

  const getSAPeriodData = (state = [], action: actions) => {
    switch (action.type) {
      case FETCH_SAPERIOD[SUCCESS]:
        return action.payload
      case FETCH_SAPERIOD[REQUEST]:
        return []
      default:
        return state
    }
  }

  const getTimingData = (state = [], action: actions) => {
    switch (action.type) {
      case FETCH_TIMING[SUCCESS]:
        return action.payload
      case FETCH_TIMING[REQUEST]:
        return []
      default:
        return state
    }
  }

  const getQualification = (state = [], action: actions) => {
    switch (action.type) {
      case FETCH_QUALIFICATION[SUCCESS]:
        return action.payload
      case FETCH_QUALIFICATION[REQUEST]:
        return []
      default:
        return state
    }
  }

  const getQualificationSkill = (state = [], action: actions) => {
    switch (action.type) {
      case FETCH_QUALIFICATION_SKILL[SUCCESS]:
        return action.payload
      case FETCH_QUALIFICATION_SKILL[REQUEST]:
        return []
      default:
        return state
    }
  }

  const getEmpHistoryData = (state = [], action: actions) => {
    switch (action.type) {
      case EMPLOYEE_HISTORY[SUCCESS]:
        return action.payload
      case EMPLOYEE_HISTORY[REQUEST]:
        return []
      default:
        return state
    }
  }
  const getManagerHistoryData = (state = [], action: actions) => {
    switch (action.type) {
      case MANAGER_HISTORY[SUCCESS]:
        return action.payload
      case MANAGER_HISTORY[REQUEST]:
        return []
      default:
        return state
    }
  }

  return combineReducers({
    getEmpInfoData,
    getDesignationData,
    getDesignationBandData,
    getEmploymentTypeData,
    getSAPeriodData,
    getTimingData,
    getQualification,
    getQualificationSkill,
    getEmpHistoryData,
    getManagerHistoryData,
    getIdsrRecentData
  })
}

export default entity

export const getEmployeePortal = (state: RootState) => state.entities.employeePortal
