import { combineReducers } from 'redux'
import { Actions as actions } from '../../actions/Types'
import { RootState } from '../../configureStore'
import {DEPARTMENT_BASED_ORG_CHART_DATA, DESIGNATION_BASED_ORG_CHART_DATA, PEOPLE_BASED_ORG_CHART_DATA, REQUEST, SUCCESS } from '../../actions/actiontypes'

const entity = () => {

    const fetchPeopleBasedOrgChartState = (state = [], action: actions) => {
        switch (action.type) {
            case PEOPLE_BASED_ORG_CHART_DATA[SUCCESS]:
                return action.payload
            case PEOPLE_BASED_ORG_CHART_DATA[REQUEST]:
                return []
            default:
                return state
        }
    }
    const fetchDepartmentBasedOrgChartState = (state = [], action: actions) => {
        switch (action.type) {
            case DEPARTMENT_BASED_ORG_CHART_DATA[SUCCESS]:
                return action.payload
            case DEPARTMENT_BASED_ORG_CHART_DATA[REQUEST]:
                return []
            default:
                return state
        }
    }

const fetchDesignationBasedOrgChartState =(state= [],action: actions)=>{
      switch(action.type){
       case DESIGNATION_BASED_ORG_CHART_DATA[SUCCESS]:
           return action.payload
        case DESIGNATION_BASED_ORG_CHART_DATA[REQUEST]:
            return []
        default:
            return state
      }
}

    return combineReducers({
        fetchPeopleBasedOrgChartState,
        fetchDepartmentBasedOrgChartState,
        fetchDesignationBasedOrgChartState,
       
    })

}

export default entity

export const getAllOrgChartState = (state: RootState) => state.entities.orgchartstate
