import * as React from 'react'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Typography from '@mui/material/Typography'
import {
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  MenuItem,
  Modal,
  Radio,
  RadioGroup,
  TextField,
  Autocomplete,
} from '@mui/material'
import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import data from './NewRequestData.json'
import { Dispatch } from 'redux'
import { RootState } from '../../configureStore'
import { connect } from 'react-redux'
import { addNewComment, createServiceRequest, getDrsData, getIssueTypeList } from '../../actions'
import { userAuthenticationUI, SRUI, SREntity, dashboardEntity, dashboardUI } from '../../reducers'
import { IRequestData } from '../Types'
import { Box } from '@mui/system'
import { ICreateComment } from '../../models/create-comment.model'
import { loaderProps } from '../Common/CommonStyles'
import moment from 'moment'
import { duration } from '../../utils/date-format'
import { toast } from 'react-toastify'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone'
import { makeStyles } from '@mui/styles'
import { employeeDataRequest } from '../../reducers/entities'

export const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: '10px 40px',
  },
  '& .MuiDialogActions-root': {
    justifyContent: 'center',
    gap: '15px',
    padding: '20px 0',
  },

  '& .MuiButton-root:hover': {},
  '&.MuiButtonBase-root-MuiButton-root:hover': {},
}))

export interface DialogTitleProps {
  id: string
  children?: React.ReactNode
  onClose: () => void
}

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  fontSize: '13px',
  fontFamily: 'Montserrat-Medium',
}))

const Heading = styled(Typography)(({ theme }) => ({
  fontSize: '26px',
  textAlign: 'center',
  fontFamily: 'Montserrat-SemiBold',
  letterSpacing: '0px',
}))

const InputField = styled(TextField)(({ theme }) => ({
  marginTop: '5px',
  marginBottom: '5px',
  '& .MuiOutlinedInput-input': {
    padding: '13.5px 14px', // Adjust the padding here
    fontSize: '13px', // Adjust the font size here
    fontFamily: 'Montserrat-Medium',
  },
  '& .MuiFormLabel-asterisk': {
    color: 'red',
  },
  '& .MuiInputBase-root.MuiOutlinedInput-root': {
    borderRadius: '20px',
  },
  '& .MuiFormLabel-root.MuiInputLabel-root': {
    fontSize: '15px',
    lineHeight: '1.8em',
  },
}))

const DateField = styled(DatePicker)(({ theme }) => ({
  marginTop: '5px',
  marginBottom: '5px',
  '& .MuiOutlinedInput-input': {
    padding: '13.5px 14px',
    fontSize: '13px',
    fontFamily: 'Montserrat-Medium',
  },
  '& .MuiFormLabel-asterisk': {
    color: 'red',
  },
  '& .MuiFormLabel-root, & .MuiInputLabel-root': {
    lineHeight: '1em',
  },
  '& .MuiInputBase-root.MuiOutlinedInput-root': {
    borderRadius: '20px',
  },
}))

const SelectField = styled(TextField)(({ theme }) => ({
  marginTop: '5px',
  marginBottom: '5px',
  '& .MuiOutlinedInput-input': {
    padding: '12px 14px', // Adjust the padding here
    fontSize: '13px', // Adjust the font size here
    fontFamily: 'Montserrat-Medium',
  },
  '& .MuiFormLabel-asterisk': {
    color: 'red',
  },
  '& .MuiInputBase-root.MuiOutlinedInput-root': {
    borderRadius: '20px',
  },
}))

const ActionButton = styled(Button)(({ theme }) => ({
  fontSize: '13px',
  height: '42px',
  fontFamily: 'Montserrat-SemiBold',
  width: '20%',
  borderRadius: '20px',
}))

const ActionButtonForAddComment = styled(Button)(({ theme }) => ({
  fontSize: '13px',
  height: '42px',
  fontFamily: 'Montserrat-SemiBold',
  width: '37%',
  borderRadius: '20px',
}))

const ActionButtonForPostComment = styled(Button)(({ theme }) => ({
  fontSize: '13px',
  height: '42px',
  fontFamily: 'Montserrat-SemiBold',
  width: '60%',
  borderRadius: '20px',
}))

const CancelButton = styled(Button)(({ theme }) => ({
  background: '#E2E2E2',
  color: '#000000',
  fontSize: '13px',
  height: '42px',
  fontFamily: 'Montserrat-SemiBold',
  width: '20%',
  borderRadius: '20px',
  '&:hover': {
    background: '#E2E2E2',
    color: '#000000',
  },
}))

const CancelButtonForPost = styled(Button)(({ theme }) => ({
  background: '#E2E2E2',
  color: '#000000',
  fontSize: '13px',
  height: '42px',
  fontFamily: 'Montserrat-SemiBold',
  width: '30%',
  borderRadius: '20px',
  marginLeft: '20px',
  '&:hover': {
    background: '#E2E2E2',
    color: '#000000',
  },
}))

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #A5C332',
  boxShadow: 24,
  p: 4,
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  )
}

const NewServiceRequestDialog = ({
  state,
  rowData,
  open,
  onClose,
  createServiceRequest,
  DepartmentList,
  GetIssueTypeData,
  isCommentPostDone,
  isCreateServiceRequestSuccess,
  getIssueTypeList,
  userDetails,
  DrsData,
  addNewComment,
  loaderState,
  comment,
  fetchSRData1,
  resetCreateSR,
  resetAddComment,
  fetchDrsData,
  employeeRecords,
}: any) => {
  const useStyles = makeStyles({
    radioButton: {
      display: 'flex',
    },
    active: {
      backgroundColor: '#00bcd4',
      color: 'white',
    },
  })
  const classes = useStyles()
  const { AllPriority, AllStatus } = data
  const [getDepartmentId, setDepartmentId] = useState(
    state?.deptId
      ? state?.deptId
      : rowData?.id && rowData?.department?.id
      ? rowData?.department?.id
      : '2',
  )
  const [getParticularEmployeeUserId, setParticularEmployeeUserId] = useState(
    rowData?.userId ? rowData?.userId : '',
  )

  const [getParticularDepartmentId, setParticularDepartmentId] = useState(
    state?.deptId?.toString() ?? '',
  )
  const [getParticularIssueId, setParticularIssueId] = useState(state?.issueId?.toString() ?? '')

  let isDepartmentId = getParticularDepartmentId == '3'
  let isIssueTypeId = getParticularIssueId == '80'

  let isDepartmentId1 = getParticularDepartmentId == '4'
  let isIssueTypeId1 = getParticularIssueId == '34'

  let isDepartmentId2 = getParticularDepartmentId == '11'
  let isIssueTypeId2 = getParticularIssueId == '89'

  if (rowData?.id) {
    let check1 = rowData?.department?.id
    let check2 = rowData?.issues?.id
    let check3 = rowData?.department?.id
    let check4 = rowData?.issues?.id
    let check5 = rowData?.department?.id
    let check6 = rowData?.issues?.id
    isDepartmentId = check1 == '3'
    isIssueTypeId = check2 == '80'
    isDepartmentId1 = check3 == '4'
    isIssueTypeId1 = check4 == '34'
    isDepartmentId2 = check5 == '11'
    isIssueTypeId2 = check6 == '89'
  }
  const shouldHideGrid = isDepartmentId && isIssueTypeId
  const shouldHideGrid1 = isDepartmentId1 && isIssueTypeId1
  const shouldHideGrid2 = isDepartmentId2 && isIssueTypeId2

  const [selectedPriorityOption, setPriorityOption] = useState(
    rowData?.priority ? rowData?.priority : '',
  )

  const [getLeaveStartDate, setLeaveStartDate] = useState(
    rowData?.leaveDates?.leave_start_date
      ? new Date(rowData?.leaveDates?.leave_start_date)
      : new Date(),
  )
  const [getLeaveEndDate, setLeaveEndDate] = useState(
    rowData?.leaveDates?.leave_end_date
      ? new Date(rowData?.leaveDates?.leave_end_date)
      : new Date(),
  )
  const [getProjectGroup, setProjectGroup] = useState(
    rowData?.project_group ? rowData?.project_group : null,
  )
  const [getAmount, setAmount] = useState(rowData?.amount ? rowData?.amount : null)
  const [TypeError, setTypeError] = useState(false)
  const [TitleError, setTitleError] = useState(false)
  const [empNameError, setEmpNameError] = useState(false)
  const [DescriptionError, setDescriptionError] = useState(false)
  const [PriorityError, setPriorityError] = useState(false)
  const [ProjectGroupError, setProjectGroupError] = useState(false)
  const [AmountError, setAmountError] = useState(false)
  const [workingEmployee, setWorkingEmployee] = useState<number | null>(null)

  const [selectedStatusOption, setStatusOption] = useState(
    rowData?.id && rowData?.status ? rowData?.status : 1,
  )
  const [titleValue, setTitleValue] = React.useState(rowData?.title ? rowData?.title : '')
  const [descriptionValue, setDescriptionValue] = React.useState(
    rowData?.description ? rowData?.description : '',
  )
  const [constructiveValue, setConstructiveValue] = React.useState(
    rowData?.constructive_feedback ? rowData?.constructive_feedback : '',
  )
  const [positiveFeedbackValue, setPossitiveFeedbackValue] = React.useState(
    rowData?.postive_feedback ? rowData?.postive_feedback : '',
  )

  const [getIssueTypeId, setIssueTypeId] = useState(
    state?.issueId ? state?.issueId : rowData?.issues?.id ? rowData?.issues?.id : '',
  )
  const [isRequestId, setIsRequestId] = React.useState(rowData?.id ? true : false)
  const [openCommentModal, setOpenCommentModal] = React.useState(false)
  const [comments, setComments] = React.useState(
    rowData?.comments && rowData?.comments.length > 0 ? rowData?.comments : [],
  )
  const [selectedFile, setSelectedFile] = useState<File | null>(null)
  const [newComment, setNewComment] = React.useState('')
  const isCommentEmpty = newComment.trim() === ''
  useEffect(() => {
    fetchDrsData()
    setTitleValue(rowData?.title ? rowData?.title : '')
    setDescriptionValue(rowData?.description ? rowData?.description : '')
    setConstructiveValue(rowData?.constructive_feedback ? rowData?.constructive_feedback : '')
    setPossitiveFeedbackValue(rowData?.postive_feedback ? rowData?.postive_feedback : '')
    setDepartmentId(
      state?.deptId
        ? state?.deptId
        : rowData?.id && rowData?.department?.id
        ? rowData?.department.id
        : 2,
    )
    setIssueTypeId(state?.issueId ? state?.issueId : rowData?.issues?.id ? rowData?.issues.id : '')
    setPriorityOption(rowData?.priority ? rowData?.priority : '')
    setStatusOption(rowData?.id && rowData?.status ? rowData?.status : 1)
    setIsRequestId(rowData?.id ? true : false)
    setComments(rowData?.comments && rowData?.comments.length > 0 ? rowData?.comments : [])
    setLeaveStartDate(
      rowData?.leaveDates?.leave_start_date
        ? new Date(rowData?.leaveDates?.leave_start_date)
        : new Date(),
    )
    setLeaveEndDate(
      rowData?.leaveDates?.leave_end_date
        ? new Date(rowData?.leaveDates?.leave_end_date)
        : new Date(),
    )
    setProjectGroup(rowData?.project_group ? rowData?.project_group : null)
    setAmount(rowData?.amount ? rowData?.amount : null)

    getIssueTypeList({
      dept_id: state?.deptId
        ? state?.deptId
        : rowData?.id && rowData?.department?.id
        ? rowData?.department.id
        : 2,
    })

    if (rowData?.leaveFor) {
      setSelectedOption(rowData.leaveFor)
      setParticularEmployeeUserId(rowData.userId ? rowData.userId : null)
    }
  }, [rowData, fetchDrsData])

  React.useMemo(() => {
    const commentList = [...comments]
    if (comment?.id) {
      commentList.push(comment)
      setComments(commentList)
    }
  }, [comment])

  const handleClose = () => {
    onClose()
    setTitleValue(rowData?.title ? rowData?.title : '')
    setDescriptionValue(rowData?.description ? rowData?.description : '')
    setConstructiveValue(rowData?.constructive_feedback ? rowData?.constructive_feedback : '')
    setPossitiveFeedbackValue(rowData?.postive_feedback ? rowData?.postive_feedback : '')
    setDepartmentId(
      state?.deptId
        ? state?.deptId
        : rowData?.id && rowData?.department?.id
        ? rowData?.department.id
        : 2,
    )
    setIssueTypeId(state?.issueId ? state?.issueId : rowData?.issues?.id ? rowData?.issues.id : '')
    setPriorityOption(rowData?.priority ? rowData?.priority : '')
    setProjectGroup(rowData?.project_group ? rowData?.project_group : null)
    setAmount(null)
    setTypeError(false)
    setTitleError(false)
    setEmpNameError(false)
    setDescriptionError(false)
    setPriorityError(false)
    setProjectGroupError(false)
    setSelectedOption(1)
    setSelectedFile(null)
    // setSelectedOption(rowData.leaveFor)
    setParticularEmployeeUserId('')
  }

  const inputDateStr = rowData?.created_at
  const dateObject = new Date(inputDateStr)
  const formattedDate = moment(dateObject).format('DD/MM/YYYY')

  const handleDepartmentChange = (event: any) => {
    const selectedDepartmentId = event.target.value
    setParticularDepartmentId(event.target.value)
    setDepartmentId(selectedDepartmentId)
    setIssueTypeId(null)
    getIssueTypeList({ dept_id: selectedDepartmentId })
  }

  const handleSelectedEmployee = (event: any) => {
    const selectedEmployeeUserId = event.target.value
    setParticularEmployeeUserId(selectedEmployeeUserId)
  }

  useEffect(() => {}, [getParticularEmployeeUserId])

  const handleIssueTypeChange = (event: any) => {
    const selectedIssueTypeId = event.target.value
    setParticularIssueId(event.target.value)
    setIssueTypeId(selectedIssueTypeId)
    setTypeError(typeof event.target.value !== 'number')
  }

  const handlePriorityChange = (event: any) => {
    setPriorityOption(event.target.value)
    setPriorityError(typeof event.target.value !== 'number')
  }

  const handleStatusChange = (event: any) => {
    setStatusOption(event.target.value)
  }

  const handleStartDate = (date: any) => {
    if (date) {
      setLeaveStartDate(new Date(date))
    }
  }

  const handleEndDate = (date: any) => {
    if (date) {
      setLeaveEndDate(new Date(date))
    }
  }

  const handleProjectGroup = (e: React.ChangeEvent<HTMLInputElement>) => {
    const emailListRegex = /^[\w\.-]+@[\w\.-]+(?:,\s*[\w\.-]+@[\w\.-]+)*$/
    setProjectGroupError(!emailListRegex.test(e.target.value))
    setProjectGroup(e.target.value)
  }

  const handleAmount = (e: React.ChangeEvent<HTMLInputElement>) => {
    const hasSpecialCharacters = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(e.target.value)
    const isValidNumber = !isNaN(Number(e.target.value)) && Number(e.target.value) >= 1
    setAmountError(hasSpecialCharacters || !isValidNumber)
    setAmount(e.target.value)
  }

  const handleDescriptionChange = (e: any) => {
    setDescriptionValue(e.target.value)
    setDescriptionError(!e.target.value)
  }

  const handleConstructiveChange = (e: any) => {
    setConstructiveValue(e.target.value)
  }

  const handlePositiveFeedbackChange = (e: any) => {
    setPossitiveFeedbackValue(e.target.value)
  }

  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTitleValue(e.target.value)
    setTitleError(!e.target.value)
  }

  const handleCreate = () => {
    if (typeof getIssueTypeId !== 'number' && !getIssueTypeId) {
      setTypeError(true)
    } else {
      setTypeError(false)
    }

    if (workingEmployee === null) {
      setEmpNameError(true)
    } else {
      setEmpNameError(false)
    }

    if (!getAmount) {
      setAmountError(true)
    } else {
      setAmountError(false)
    }

    if (!titleValue) {
      setTitleError(true)
    } else {
      setTitleError(false)
    }

    if (!descriptionValue) {
      setDescriptionError(true)
    } else {
      setDescriptionError(false)
    }

    if (typeof selectedPriorityOption !== 'number') {
      setPriorityError(true)
    } else {
      setPriorityError(false)
    }
    if (!getProjectGroup && getIssueTypeId === 80) {
      setProjectGroupError(true)
    }

    if (
      (state.tabName === 'Feedback Details' ? false : !titleValue || !descriptionValue) ||
      typeof getIssueTypeId !== 'number' ||
      typeof selectedPriorityOption !== 'number' ||
      (!getProjectGroup && getIssueTypeId === 80) ||
      (getIssueTypeId === 80 && ProjectGroupError) ||
      (!getLeaveEndDate && getIssueTypeId === 80) ||
      (!getLeaveStartDate && getIssueTypeId === 80)
    ) {
      return
    }
    if (getParticularDepartmentId == '3' && getParticularIssueId == '80') {
      createServiceRequest({
        id_department: 3,
        priority: selectedPriorityOption,
        status: selectedStatusOption,
        title: titleValue,
        id_issue_type: 80,
        leave_for: selectedOption,
        employeeId: getParticularEmployeeUserId ? getParticularEmployeeUserId : userDetails?.id,
        description: descriptionValue,
        leave_start_date: new Date(moment(getLeaveStartDate).format('YYYY-MM-DD')),
        leave_end_date: new Date(moment(getLeaveEndDate).format('YYYY-MM-DD')),
        project_group: getProjectGroup,
      })
      onClose()
    } else if (
      (getParticularDepartmentId == '4' && getParticularIssueId == '34') ||
      (getParticularDepartmentId == '11' && getParticularIssueId == '89')
    ) {
      createServiceRequest({
        id_department: 4,
        description: descriptionValue,
        priority: selectedPriorityOption,
        status: selectedStatusOption,
        title: titleValue,
        id_issue_type: 34,
        amount:
          getParticularDepartmentId === '4' && getParticularIssueId === '34' ? getAmount : null,
        file: selectedFile,
      })
    } else if (getParticularDepartmentId == '19' && getParticularIssueId == '98') {
      if ((positiveFeedbackValue || constructiveValue) && workingEmployee !== null) {
        createServiceRequest({
          id_department: getDepartmentId,
          constructive_feedback: constructiveValue,
          positive_feedback: positiveFeedbackValue,
          priority: selectedPriorityOption,
          status: selectedStatusOption,
          title: "Feedback",
          id_issue_type: getIssueTypeId,
          id_feedback_user: workingEmployee,
          file: selectedFile,
        })
      }
    } else {
      createServiceRequest({
        id_department: 4,
        description: descriptionValue,
        priority: selectedPriorityOption,
        status: selectedStatusOption,
        title: titleValue,
        id_issue_type: 34,
      })

      onClose()
    }
  }

  const handleOpenCommentModal = () => setOpenCommentModal(true)
  const handleCloseCommentModal = () => {
    setOpenCommentModal(false)
  }
  const handleClosePost = () => {
    onClose()
  }
  const [selectedOption, setSelectedOption] = useState(1)

  const handleOptionChange = (event: any) => {
    setSelectedOption(event.target.value) // Update the selected option when a Radio button is clicked
  }

  useEffect(() => {
    if (isCreateServiceRequestSuccess) {
      toast.success(
        `We'll do our best to respond to your request within 3 business days. This depends heavily on the complexity of your request, and the completeness and accuracy of the information you've provided. In addition, please expect delays during holidays. We appreciate your patience while we process your request.`,
      )
      fetchSRData1()
      resetCreateSR()
    }
  }, [isCreateServiceRequestSuccess])

  useEffect(() => {
    if (isCommentPostDone) {
      toast.success('Comment added successfully')
      fetchSRData1()
      resetAddComment()
    }
  }, [isCommentPostDone])

  useEffect(() => {
    if (state.tabName === 'Feedback Details') {
      setPriorityOption(1)
    }
  }, [open])

  const handlePostComment = () => {
    if (newComment.trim() && rowData?.id) {
      const entity: ICreateComment = {
        id_service_request: rowData.id,
        comment: newComment,
        created_by: userDetails.name,
        id_user: userDetails.id,
        request_status: rowData.status,
      }
      addNewComment(entity)
      handleCloseCommentModal()
      handleClosePost()
      setNewComment('')
    }
  }

  const handleFileChange = (event: any) => {
    const file = event.target.files[0]

    if (file) {
      if (
        file.type === 'video/mp4' ||
        file.type === 'video/mpeg' ||
        file.type === 'video/quicktime' ||
        file.type === 'video/x-matroska'
      ) {
        toast.error('Video files are not allowed')
        event.target.value = ''
        setSelectedFile(null)
      } else {
        setSelectedFile(file)
      }
    }
  }

  return (
    <div>
      <>
        {loaderState && (
          <Dialog open={loaderState} PaperProps={{ style: loaderProps }}>
            <CircularProgress color='secondary' />
          </Dialog>
        )}
      </>
      <BootstrapDialog onClose={handleClose} aria-labelledby='customized-dialog-title' open={open}>
        <BootstrapDialogTitle id='customized-dialog-title' onClose={handleClose}>
          {state?.deptId === 4 ? (
            <Heading>Reimbursement Request</Heading>
          ) : state.tabName === 'Leave Details' ? (
            <Heading> Leave Request</Heading>
          ) : (
            <Heading>
              {state.tabName === 'Feedback Details'
                ? isRequestId
                  ? 'View Feedback'
                  : 'Add Feedback'
                : 'Service Request'}
            </Heading>
          )}
        </BootstrapDialogTitle>
        <DialogContent
          dividers
          sx={{
            paddingBottom: '10px !important',
            paddingTop: state.tabName === 'Leave Details' ? '30px !important' : '',
          }}
        >
          <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 4 }}>
            {/* <Grid item xs={4} sm={6}>
              <SelectField
                required
                id='outlined-required'
                label='Department'
                size='small'
                select
                value={getDepartmentId}
                onChange={handleDepartmentChange}
                variant='outlined'
                disabled
              >
                {DepartmentList?.map((option: any) => (
                  <StyledMenuItem key={option?.id} value={option?.id}>
                    {option.dept_name}
                  </StyledMenuItem>
                ))}
              </SelectField>
            </Grid>
            <Grid item xs={6} sm={6}>
              <SelectField
                error={TypeError}
                required
                id='outlined-required'
                label='Type'
                size='small'
                select
                value={getIssueTypeId}
                onChange={handleIssueTypeChange}
                variant='outlined'
                helperText={TypeError && 'Type required'}
                disabled
              >
                <StyledMenuItem value='' disabled>
                  Select type
                </StyledMenuItem>
                {GetIssueTypeData.map((option: any) => (
                  <StyledMenuItem key={option?.id} value={option?.id}>
                    {option.title}
                  </StyledMenuItem>
                ))}
              </SelectField>
            </Grid> */}

    

            {shouldHideGrid && (
              <>
                <Grid
                  item
                  xs={6}
                  sm={6}
                  sx={{ paddingTop: '5px !important', display: 'flex', alignItems: 'end' }}
                >
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateField
                      label='Leave Start Date'
                      value={dayjs(getLeaveStartDate)}
                      onChange={handleStartDate}
                      format='DD-MM-YYYY'
                      // minDate={dayjs(new Date()).subtract(0, 'day')}
                      disabled={isRequestId}
                    />
                  </LocalizationProvider>
                </Grid>

                <Grid item xs={6} sm={6}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateField
                      label='Leave End Date'
                      value={dayjs(getLeaveEndDate)}
                      onChange={handleEndDate}
                      format='DD-MM-YYYY'
                      minDate={dayjs(getLeaveStartDate).add(0, 'day')}
                      disabled={isRequestId}
                    />
                  </LocalizationProvider>
                </Grid>
              </>
            )}

            {state.tabName === 'Feedback Details' && (
              <>
                <Grid item xs={12} sm={12} sx={{ paddingTop: '25px !important' }}>
                  <Autocomplete
                    autoFocus={false}
                    size='small'
                    disablePortal
                    id='select-working-employees'
                    options={employeeRecords}
                    getOptionLabel={(option: any) => option?.info}
                    disabled={isRequestId}
                    sx={{
                      '.MuiInputBase-root': {
                        padding: '22px 11px',
                        borderRadius: '20px',
                        fontSize: '13px',
                        fontFamily: 'Montserrat-Medium',
                        height: '45px',
                      },
                      '& .MuiFormControl-root': {
                        margin: '0',
                        marginTop: '5px',
                      },
                      '& .MuiFormLabel-root ': {
                        backgroundColor: 'white',
                        width: '165px',
                      },
                      '.MuiFormLabel-asterisk': {
                        color: 'red',
                      },
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label='Select Employee'
                        variant='outlined'
                        // helperText={empNameError && 'Employee name required'}
                        // error={empNameError}
                      />
                    )}
                    defaultValue={
                      employeeRecords.find(
                        (value: any) => value.userId === rowData?.id_user_feedback,
                      ) ?? null
                    }
                    onChange={(event: any, newValue: any) => {
                      setEmpNameError(!newValue?.userId)
                      setWorkingEmployee(newValue?.userId ?? '')
                    }}
                    ListboxProps={{
                      style: {
                        maxHeight: '100px',
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} sx={{ paddingTop: '5px !important' }}>
                  <InputField
                    // error={DescriptionError}
                    required={false}
                    id='outlined-required'
                    label='Positive Feedback'
                    size='small'
                    value={positiveFeedbackValue}
                    maxRows={4}
                    fullWidth={true}
                    // helperText={DescriptionError && 'Description required'}
                    onChange={handlePositiveFeedbackChange}
                    multiline={true}
                    disabled={isRequestId}
                  />
                </Grid>
                <Grid item xs={12} sm={12} sx={{ paddingTop: '5px !important' }}>
                  <InputField
                    // error={DescriptionError}
                    required={false}
                    id='outlined-required'
                    label='Constructive Feedback'
                    size='small'
                    value={constructiveValue}
                    maxRows={4}
                    fullWidth={true}
                    // helperText={DescriptionError && 'Description required'}
                    onChange={handleConstructiveChange}
                    multiline={true}
                    disabled={isRequestId}
                  />
                </Grid>
              </>
            )}

            <Grid
              item
              xs={12}
              sm={12}
              sx={{
                paddingTop:
                  state.tabName === 'Feedback Details'
                    ? '10px !important'
                    : state.tabName === 'Leave Details'
                    ? '10px !important'
                    : ' 25px !important',
              }}
            >
              {state.tabName !== 'Feedback Details' && (
                <InputField
                  error={TitleError}
                  required
                  id='outlined-required'
                  label='Title'
                  size='small'
                  value={titleValue}
                  onChange={handleTitleChange}
                  helperText={TitleError && 'Title required'}
                  disabled={isRequestId}
                  fullWidth
                />
              )}
            </Grid>
            {state.tabName !== 'Feedback Details' && (
              <Grid item xs={12} sm={12} sx={{ paddingTop: '5px !important' }}>
                <InputField
                  error={DescriptionError}
                  required
                  id='outlined-required'
                  label='Description'
                  size='small'
                  value={descriptionValue}
                  maxRows={4}
                  fullWidth={true}
                  helperText={DescriptionError && 'Description required'}
                  onChange={handleDescriptionChange}
                  multiline={true}
                  disabled={isRequestId}
                />
              </Grid>
            )}

            {shouldHideGrid1 && (
              <Grid item xs={12} sm={12} sx={{ paddingTop: '5px !important' }}>
                <>
                  <style>
                    {`
                  input[type='number']::-webkit-inner-spin-button,
                  input[type='number']::-webkit-outer-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                  }

                  input[type='number'] {
                    -moz-appearance: textfield;
                  }
                `}
                  </style>

                  <InputField
                    type='number'
                    inputProps={{ min: 1 }}
                    error={AmountError}
                    id='outlined-required'
                    label='Amount'
                    placeholder='Amount'
                    size='small'
                    value={getAmount}
                    InputLabelProps={{ required: true }}
                    helperText={AmountError && 'Enter amount'}
                    onChange={handleAmount}
                    disabled={isRequestId}
                  />
                </>
              </Grid>
            )}
            {rowData?.id && state.tabName !== 'Feedback Details' && (
              <Grid item xs={12} sm={12} sx={{ paddingTop: '5px !important' }}>
                <InputField
                  required
                  id='outlined-required'
                  label='Created Date'
                  size='small'
                  value={formattedDate}
                  disabled={isRequestId}
                />
              </Grid>
            )}
            {shouldHideGrid && (
              <Grid item xs={12} sm={12} sx={{ paddingTop: '5px !important' }}>
                <InputField
                  error={ProjectGroupError}
                  required
                  type='email'
                  id='outlined-required'
                  label='Project Group'
                  placeholder='Please enter email addresses separated by commas.'
                  size='small'
                  value={getProjectGroup}
                  helperText={ProjectGroupError && 'Please enter email separated by commas.'}
                  onChange={handleProjectGroup}
                  disabled={isRequestId}
                />
              </Grid>
            )}
            {state.tabName !== 'Feedback Details' && (
              <Grid item xs={12} sm={6} sx={{ paddingTop: '5px !important' }}>
                <SelectField
                  error={PriorityError}
                  required
                  id='outlined-required'
                  label='Priority'
                  size='small'
                  select
                  value={selectedPriorityOption}
                  onChange={handlePriorityChange}
                  variant='outlined'
                  helperText={PriorityError && 'Priority required'}
                  disabled={isRequestId}
                >
                  {AllPriority.map((option) => (
                    <StyledMenuItem key={option.value} value={option.value}>
                      {option.label}
                    </StyledMenuItem>
                  ))}
                </SelectField>
              </Grid>
            )}

            {state.tabName !== 'Feedback Details' && (
              <Grid item xs={12} sm={6} sx={{ paddingTop: '5px !important' }}>
                <SelectField
                  required
                  id='outlined-required'
                  label='Status'
                  size='small'
                  select
                  value={Number(selectedStatusOption)}
                  onChange={handleStatusChange}
                  variant='outlined'
                  disabled
                >
                  {AllStatus.map((option) => (
                    <StyledMenuItem key={option.value} value={option.value}>
                      {option.label}
                    </StyledMenuItem>
                  ))}
                </SelectField>
              </Grid>
            )}

            {(shouldHideGrid2 || shouldHideGrid1) && (
              <>
                <input
                  type='file'
                  id={'id'}
                  style={{ display: 'none' }}
                  disabled={isRequestId}
                  onChange={handleFileChange}
                  accept='image/pjpeg,
                  image/jpeg,
                  image/png,
                  application/pdf,
                  application/msword,
                  application/vnd.openxmlformats-officedocument.wordprocessingml.document,
                  application/vnd.oasis.opendocument.text,
                  application/vnd.ms-excel,
                  application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                />
                <label htmlFor={'id'}>
                  <Button
                    disabled={isRequestId}
                    sx={{
                      fontSize: '15px',
                      padding: '6px 22px',
                      borderRadius: '24px',
                      fontFamily: 'Montserrat-Medium',
                      marginLeft: '36px',
                      marginBottom: '5px',
                      marginTop: '10px',
                    }}
                    variant='contained'
                    component='span'
                  >
                    Upload File
                  </Button>
                </label>
                <Typography
                  sx={{
                    fontSize: '13px',
                    marginLeft: '10px',
                    marginTop: '15px',
                    fontFamily: 'Montserrat-Medium',
                    maxWidth: '200px',
                  }}
                  variant='body1'
                  color='textSecondary'
                >
                  {selectedFile ? selectedFile.name : isRequestId ? '...' : 'No file selected'}
                </Typography>
              </>
            )}

            {rowData?.id && (
              <>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    marginTop: '12px',
                  }}
                >
                  <ActionButtonForAddComment
                    variant='contained'
                    startIcon={<AddTwoToneIcon sx={{ width: 20, height: 24 }} />}
                    size='small'
                    onClick={handleOpenCommentModal}
                    sx={{ fontSize: '14px', fontWeight: '100', textTransform: 'uppercase' }}
                  >
                    Add Comment
                  </ActionButtonForAddComment>
                  <CancelButtonForPost onClick={handleClose}>CANCEL</CancelButtonForPost>
                </Grid>

                <div>
                  <Modal
                    open={openCommentModal}
                    onClose={handleCloseCommentModal}
                    aria-labelledby='modal-comment'
                    aria-describedby='modal-comment-desc'
                  >
                    <Box sx={style}>
                      <Typography
                        id='modal-comment'
                        variant='h6'
                        component='h6'
                        sx={{ textTransform: 'uppercase' }}
                      >
                        Add Comment
                      </Typography>
                      <TextField
                        type='text'
                        id='comments'
                        name='comments'
                        fullWidth
                        multiline
                        rows={2}
                        maxRows={4}
                        value={newComment}
                        onChange={(e) => setNewComment(e.target.value)}
                      />
                      <Box textAlign='left'>
                        <ActionButtonForPostComment
                          variant='contained'
                          size='small'
                          sx={{
                            fontSize: '14px',
                            fontWeight: '100',
                            width: '50%',
                            textTransform: 'uppercase',
                          }}
                          onClick={handlePostComment}
                          disabled={isCommentEmpty}
                        >
                          Post Comment
                        </ActionButtonForPostComment>
                      </Box>
                      <Box textAlign='right'>
                        <CancelButton
                          variant='contained'
                          size='small'
                          sx={{
                            fontSize: '14px',
                            fontWeight: '100',
                            width: '32%',
                            textTransform: 'uppercase',
                            bottom: '43px',
                          }}
                          onClick={handleCloseCommentModal}
                        >
                          Cancel
                        </CancelButton>
                      </Box>
                    </Box>
                  </Modal>
                </div>
                {comments && comments.length > 0 && (
                  <Grid item xs={12} sm={12}>
                    <Typography component='h5' variant='h5' align='center'>
                      Comments
                    </Typography>
                    <Box
                      sx={{
                        maxHeight: '400px',
                        overflowY: 'scroll',
                        margin: '0 auto',
                        padding: '5px',
                        border: '2px solid lightgrey',
                      }}
                    >
                      {comments?.map((data: any, index: number) => (
                        <>
                          <Typography
                            component='span'
                            sx={{ fontSize: 'smaller', color: '#A82E2F' }}
                          >{`${data?.created_by} @${duration(data?.created_at)} ago`}</Typography>
                          <InputField
                            type='text'
                            // rows={2}
                            fullWidth={true}
                            multiline={true}
                            id='comments'
                            name='comments'
                            InputProps={{ readOnly: true }}
                            value={data.comment}
                          />
                        </>
                      ))}
                    </Box>
                  </Grid>
                )}
              </>
            )}
          </Grid>
        </DialogContent>
        {!rowData?.id && (
          <DialogActions>
            <ActionButton
              // disabled={
              //   (positiveFeedbackValue === '' && constructiveValue === '') ||
              //   workingEmployee === null
              // }
              style={{
                cursor:
                  state.tabName === 'Feedback Details' &&
                  workingEmployee !== null &&
                  getDepartmentId &&
                  getIssueTypeId &&
                  selectedStatusOption &&
                  (positiveFeedbackValue || constructiveValue)
                    ? 'pointer'
                    : state.tabName !== 'Feedback Details'
                    ? 'pointer'
                    : 'not-allowed',
                color: '#fff',
                // opacity:
                //   (positiveFeedbackValue === '' && constructiveValue === '') ||
                //   workingEmployee === null
                //     ? 0.5
                //     : 1,
              }}
              onClick={handleCreate}
              autoFocus
            >
              CREATE
            </ActionButton>

            <CancelButton autoFocus onClick={handleClose}>
              CANCEL
            </CancelButton>
          </DialogActions>
        )}
      </BootstrapDialog>
    </div>
  )
}

NewServiceRequestDialog.propTypes = {
  state: PropTypes.object,
  open: PropTypes.bool,
  SRData: PropTypes.array,
  onClose: PropTypes.func,
  createServiceRequest: PropTypes.func.isRequired,
  DepartmentList: PropTypes.array,
  GetIssueTypeData: PropTypes.array,
  getIssueTypeList: PropTypes.func.isRequired,
  rowData: PropTypes.object,
  userDetails: PropTypes.object,
  addNewComment: PropTypes.func.isRequired,
  comment: PropTypes.object,
  loaderState: PropTypes.bool,
  fetchSRData1: PropTypes.func.isRequired,
  resetCreateSR: PropTypes.func.isRequired,
  isCommentPostDone: PropTypes.bool,
  isCreateServiceRequestSuccess: PropTypes.bool,
  isCreateServiceRequest: PropTypes.bool,
}

const mapStateToProps = (state: RootState) => {
  return {
    isCreateServiceRequestSuccess:
      SRUI.getServiceRequestCreate(state).isCreateServiceRequestSuccess,
    isCreateServiceRequest: SRUI.getServiceRequestCreate(state).isCreateServiceRequest,
    isCommentPostDone: SRUI.getServicePostState(state).isCommentPostDone,
    SRStatus: userAuthenticationUI.getUserAuthentication(state).isUserAuthenticationDone,
    SRData: SREntity.createServiceRequestData(state).createServiceRequest,
    GetIssueTypeData: dashboardEntity.getDashboard(state).getIssueTypeList,
    userDetails: dashboardEntity.getDashboard(state).getUserDetails,
    comment: SREntity.createServiceRequestData(state).getComments,
    loaderState: dashboardUI.getDashboard(state).isCommentData,
    DrsData: dashboardEntity.getDashboard(state).getDrsData,
    employeeRecords: employeeDataRequest.getAllEmployeesList(state).getAllEmployeesDetails,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    createServiceRequest: (data: IRequestData) => {
      dispatch(createServiceRequest.request(data))
    },
    getIssueTypeList: (data: any) => {
      dispatch(getIssueTypeList.request(data))
    },
    fetchDrsData: () => dispatch(getDrsData.request()),
    addNewComment: (data: ICreateComment) => dispatch(addNewComment.request(data)),
    resetCreateSR: () => dispatch(createServiceRequest.reset()),
    resetAddComment: () => dispatch(addNewComment.reset()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewServiceRequestDialog)
