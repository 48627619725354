import { Table, TableBody, TableHead, Tooltip } from '@mui/material'
import React from 'react'
// import { StyledEditableTableCell, StyledTableRow } from '../CommonStyles'
// import { style } from '../../Report/SheetStyles'
import NoDataFound from './NoDataFound'
import { StyledEditableTableCell, StyledTableRow } from '../../../Common/CommonStyles'
import { style } from '../../SheetStyles'
import moment from 'moment'

const CardMidText = {
  lineHeight: '1.8',
  fontSize: '11px',
  fontFamily: 'Montserrat-Medium',
  letterSpacing: '0px',
  opacity: '1',
}

const CommonTableContainer = (props: any) => {
  const { tableData, width } = props
  const handleRowData = (rowData: any) => {}

  function htmlToText(html: string) {
    const temporaryElement = document.createElement('div')
    temporaryElement.innerHTML = html
    return temporaryElement.textContent || temporaryElement.innerText || ''
  }

  const tableHeaderForAssignedTask = [
    'PROJECT NAME',
    'TASK STATUS',
    'TASK DESCRIPTION',
    'EMP NAME',
    'COLLABORATE WITH',
    'DATE',
  ]

  return (
    <>
      <Table sx={{ marginTop: '20px' }}>
        <TableHead id='head'>
          <StyledTableRow sx={style.border}>
            {tableHeaderForAssignedTask?.length &&
              tableHeaderForAssignedTask?.map((header: any, index: any) => (
                <StyledEditableTableCell key={index} width={width ? width : '100px'}>
                  {header}
                </StyledEditableTableCell>
              ))}
          </StyledTableRow>
        </TableHead>
        <TableBody>
          {tableData?.length > 0 ? (
            tableData?.map((row: any, index: any) => (
              <StyledTableRow
                id='body'
                key={index}
                sx={style.border}
                onClick={() => handleRowData(row)}
              >
                <StyledEditableTableCell width='150px'>{row?.projectName}</StyledEditableTableCell>
                <StyledEditableTableCell width='150px'>{row?.status}</StyledEditableTableCell>
                <StyledEditableTableCell width='150px'>
                  <Tooltip
                    title={row.taskDescription ? htmlToText(row.taskDescription) : 'NA'}
                    arrow
                  >
                    <span style={CardMidText}>
                      {row.taskDescription
                        ? htmlToText(row.taskDescription).length > 35
                          ? `${htmlToText(row.taskDescription).substring(0, 35)}...`
                          : htmlToText(row.taskDescription)
                        : 'NA'}
                    </span>
                  </Tooltip>
                </StyledEditableTableCell>
                <StyledEditableTableCell width='150px'>{row?.collabWith}</StyledEditableTableCell>
                <StyledEditableTableCell width='150px'>
                  {row.withManager === 'true'
                    ? row?.managerName
                    : row.withSuperManager === 'true'
                    ? 'Priyanka Chaudhari'
                    : 'NA'}
                </StyledEditableTableCell>
                <StyledEditableTableCell width='150px'>
                  {row.submission_time
                    ? new Date(row.submission_time)
                        .toLocaleDateString('en-US', {
                          month: '2-digit',
                          day: '2-digit',
                          year: 'numeric',
                        })
                        .replace(/\//g, '-')
                    : 'NA'}
                </StyledEditableTableCell>
              </StyledTableRow>
            ))
          ) : (
            <NoDataFound />
          )}
        </TableBody>
      </Table>
    </>
  )
}

export default CommonTableContainer
