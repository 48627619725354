import { Tab, Tabs } from "@mui/material";
import React, { useState } from "react";
import PeopleBasedCharts from "./PeopleBasedChart";
import DesignationBasedCharts from "./DesignationBasedChart";
import DepartmentBasedCharts from "./DepartmentbasedChart";
import { IndexStyledPaper } from "./OrgStyles";


const OrgCharts = () => {

    const [selectedTab, setSelectedTab] = useState(0)

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setSelectedTab(newValue)
      }

    return(
         <>
         <IndexStyledPaper>
         <Tabs value={selectedTab} onChange={handleTabChange} aria-label='Tabs for different charts'>
          <Tab label='PEOPLE BASED ORG CHART' onClick={() => setSelectedTab(0)} />
          {/* <Tab label='DESIGNATION BASED ORG CHART' onClick={() => setSelectedTab(1)} /> */}
          {/* <Tab label='DEPARTMENT BASED ORG CHART' onClick={() => setSelectedTab(2)} /> */}
         </Tabs>
         {selectedTab === 0 &&
         <PeopleBasedCharts/>
         }
         {selectedTab === 1 && 
         <DesignationBasedCharts/>
         }
         {/* {selectedTab === 2 && 
         <DepartmentBasedCharts/>
         } */}
         </IndexStyledPaper>   
         </>
    )
}

export default OrgCharts