import { combineReducers } from 'redux'
import { actionTypes } from '../../actions'
import { Actions as actions } from '../../actions/Types'
import { RootState } from '../../configureStore'
import { FAILURE } from '../../actions/actiontypes'

const { SUCCESS, REQUEST, LOGIN } = actionTypes

const entity = () => {
  const getUserAuthentication = (state = {}, action: actions) => {
    switch (action.type) {
      case LOGIN[SUCCESS]:
        return action.payload
      case LOGIN[REQUEST]:
        return {}
      case LOGIN[FAILURE]:
        return action.payload
      default:
        return state
    }
  }

  return combineReducers({
    getUserAuthentication,
  })
}

export default entity

export const getUserAuthentication = (state: RootState) => state.entities.userAuthentication
