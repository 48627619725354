import { combineReducers } from 'redux'
import { actionTypes } from '../../actions'
import { Actions as actions } from '../../actions/Types'
import { RootState } from '../../configureStore'
import {
  FETCH_ACCOUNTTYPE,
  FETCH_BANKINFO,
  FETCH_COUNTRY,
  FETCH_STATE,
  FETCH_COMPENSATION_DETAILS,
  GET_TIMESHEET,
  GET_PAY_SLIP,
  PAYROLL_FORM16,
  UPLOAD_FORM16,
  GET_FINANCIAL_YEAR,
  DOWNLOAD_FORM16,
} from '../../actions/actiontypes'

const { SUCCESS, REQUEST, GET_USER_DATA } = actionTypes

const entity = () => {
  const getBankInfoData = (state = [], action: actions) => {
    switch (action.type) {
      case FETCH_BANKINFO[SUCCESS]:
        return action.payload
      case FETCH_BANKINFO[REQUEST]:
        return []
      default:
        return state
    }
  }

  const getCountryData = (state = [], action: actions) => {
    switch (action.type) {
      case FETCH_COUNTRY[SUCCESS]:
        return action.payload
      case FETCH_COUNTRY[REQUEST]:
        return []
      default:
        return state
    }
  }

  const getStateData = (state = [], action: actions) => {
    switch (action.type) {
      case FETCH_STATE[SUCCESS]:
        return action.payload
      case FETCH_STATE[REQUEST]:
        return []
      default:
        return state
    }
  }

  const getCompensationDetails = (state = [], action: actions) => {
    switch (action.type) {
      case FETCH_COMPENSATION_DETAILS[SUCCESS]:
        return action.payload
      case FETCH_COMPENSATION_DETAILS[REQUEST]:
        return []
      default:
        return state
    }
  }

  const getAccountTypeData = (state = [], action: actions) => {
    switch (action.type) {
      case FETCH_ACCOUNTTYPE[SUCCESS]:
        return action.payload
      case FETCH_ACCOUNTTYPE[REQUEST]:
        return []
      default:
        return state
    }
  }

  const getTimesheetData = (state = [], action: actions) => {
    switch (action.type) {
      case GET_TIMESHEET[SUCCESS]:
        return action.payload
      case GET_TIMESHEET[REQUEST]:
        return []
      default:
        return state
    }
  }

  const getPaySlipData = (state = [], action: actions) => {
    switch (action.type) {
      case GET_PAY_SLIP[SUCCESS]:
        return action.payload
      case GET_PAY_SLIP[REQUEST]:
        return []
      default:
        return state
    }
  }

  const getForm16Data = (state = [], action: actions) => {
    switch (action.type) {
      case PAYROLL_FORM16[SUCCESS]:
        return action.payload
        case PAYROLL_FORM16[REQUEST]:
        return []
      default:
        return state
    }
  }


  const getFinancialYearData = (state = [], action: actions) => {
    switch (action.type) {
      case GET_FINANCIAL_YEAR[SUCCESS]:
        return action.payload
        case GET_FINANCIAL_YEAR[REQUEST]:
        return []
      default:
        return state
    }
  }

  const getDownloadForm16Link = (state = [], action: actions) => {
    switch (action.type) {
      case DOWNLOAD_FORM16[SUCCESS]:
        return action.payload
        case DOWNLOAD_FORM16[REQUEST]:
        return []
      default:
        return state
    }
  }


  return combineReducers({
    getBankInfoData,
    getStateData,
    getCountryData,
    getAccountTypeData,
    getCompensationDetails,
    getTimesheetData,
    getPaySlipData,
    getForm16Data,
    getFinancialYearData,
    getDownloadForm16Link,
  })
}

export default entity

export const getFinance = (state: RootState) => state.entities.finance
