import { call, takeLatest } from 'redux-saga/effects'
import { actionTypes } from '../actions'
import * as ApiService from '../services'
import { sendPayload, sendPayloadFailure } from './helper'
import { DEPARTMENT_BASED_ORG_CHART_DATA, DESIGNATION_BASED_ORG_CHART_DATA, PEOPLE_BASED_ORG_CHART_DATA, REQUEST } from '../actions/actiontypes'



export interface NodeProps {
  level: any
  type:string
  data:{
  name: string;
  emp_id: string;
  designation: string;
  imageURL: string
  drs?: NodeProps[];
  }
}
function* getpeopleBasedOrgChartData(data: NodeProps) {
    try {
      const apiResponse: Generator<string, number, string> = yield call(
        ApiService.getpeopleBasedOrgChart,
        data,
      )
      yield sendPayload(apiResponse,PEOPLE_BASED_ORG_CHART_DATA)
    } catch (e) {
      yield sendPayloadFailure(e, PEOPLE_BASED_ORG_CHART_DATA)
    }
  }

  function* DepartmentBasedOrgChartData(data: any) {
    try {
      const apiResponse: Generator<string, number, string> = yield call(
        ApiService.geteDepartmentBasedOrgChart,
        data,
      )
      yield sendPayload(apiResponse,DEPARTMENT_BASED_ORG_CHART_DATA)
    } catch (e) {
      yield sendPayloadFailure(e, DEPARTMENT_BASED_ORG_CHART_DATA)
    }
  }

  function* DesignationBasedOrgChartData(data: NodeProps) {
    try{
      const apiResponse: Generator<string,number,string> = yield call(
        ApiService.getDesignationBasedOrgChart,
        data,
      )
      yield sendPayload(apiResponse,DESIGNATION_BASED_ORG_CHART_DATA)
    } catch (e){
      yield sendPayloadFailure(e, DESIGNATION_BASED_ORG_CHART_DATA)
    }
  }
  
  export const sagas = {
    watchGetpeopleBasedOrgChartData: takeLatest(actionTypes.PEOPLE_BASED_ORG_CHART_DATA[REQUEST], getpeopleBasedOrgChartData),
    watchGetdepartmentBasedOrgChartData: takeLatest(actionTypes.DEPARTMENT_BASED_ORG_CHART_DATA[REQUEST], DepartmentBasedOrgChartData),
    watchGetdesignationBasedOrgChartData: takeLatest(actionTypes.DESIGNATION_BASED_ORG_CHART_DATA[REQUEST],DesignationBasedOrgChartData)

  }