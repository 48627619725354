import { combineReducers } from 'redux'
import { actionTypes } from '../../actions'
import { Actions as actions } from '../../actions/Types'
import { RootState } from '../../configureStore'
import { DELETE_USER, FAILURE, FETCH_ALL_CLIENT_LOCATION, FETCH_ALL_COUNTRY, FETCH_ALL_FLOOR, FETCH_ALL_LOCATION, FETCH_ALL_STATE, FETCH_ALL_USER_LIST, FETCH_ALL_WORKSTATION, GET_USER_IMAGE, RESET, UPDATE_USER, UPLOAD_USER_IMG } from '../../actions/actiontypes'
import { toast } from 'react-toastify'

const { SUCCESS, REQUEST, CREATE_USER, FETCH_ALL_ROLE } = actionTypes

const entity = () => {
  const fetchUser = (state = [], action: actions) => {
    switch (action.type) {
      case CREATE_USER[SUCCESS]:
        return action.payload
      case CREATE_USER[REQUEST]:
        return []
      case CREATE_USER[FAILURE]:
        return action.payload.data
      case CREATE_USER[RESET]:
        return {}
      default:
        return state
    }
  }

  const fetchAllRole = (state = [], action: actions) => {
    switch (action.type) {
      case FETCH_ALL_ROLE[SUCCESS]:
        return action.payload
      case FETCH_ALL_ROLE[REQUEST]:
        return []
      case FETCH_ALL_ROLE[FAILURE]:
        return action.payload.data
      case FETCH_ALL_ROLE[RESET]:
        return {}
      default:
        return state
    }
  }

  const fetchAllState = (state = [], action: actions) => {
    switch (action.type) {
      case FETCH_ALL_STATE[SUCCESS]:
        return action.payload
      case FETCH_ALL_STATE[REQUEST]:
        return []
      case FETCH_ALL_STATE[FAILURE]:
        return action.payload.data
      case FETCH_ALL_STATE[RESET]:
        return {}
      default:
        return state
    }
  }

  const fetchAllCountry = (state = [], action: actions) => {
    switch (action.type) {
      case FETCH_ALL_COUNTRY[SUCCESS]:
        return action.payload
      case FETCH_ALL_COUNTRY[REQUEST]:
        return []
      case FETCH_ALL_COUNTRY[FAILURE]:
        return action.payload.data
      case FETCH_ALL_COUNTRY[RESET]:
        return {}
      default:
        return state
    }
  }

  const fetchAllFloor = (state = [], action: actions) => {
    switch (action.type) {
      case FETCH_ALL_FLOOR[SUCCESS]:
        return action.payload
      case FETCH_ALL_FLOOR[REQUEST]:
        return []
      case FETCH_ALL_FLOOR[FAILURE]:
        return action.payload.data
      case FETCH_ALL_FLOOR[RESET]:
        return {}
      default:
        return state
    }
  }

  const fetchAllWorkstation = (state = [], action: actions) => {
    switch (action.type) {
      case FETCH_ALL_WORKSTATION[SUCCESS]:
        return action.payload
      case FETCH_ALL_WORKSTATION[REQUEST]:
        return []
      case FETCH_ALL_WORKSTATION[FAILURE]:
        return action.payload.data
      case FETCH_ALL_WORKSTATION[RESET]:
        return {}
      default:
        return state
    }
  }

  const fetchAllLocation = (state = [], action: actions) => {
    switch (action.type) {
      case FETCH_ALL_LOCATION[SUCCESS]:
        return action.payload
      case FETCH_ALL_LOCATION[REQUEST]:
        return []
      case FETCH_ALL_LOCATION[FAILURE]:
        return action.payload.data
      case FETCH_ALL_LOCATION[RESET]:
        return {}
      default:
        return state
    }
  }

  const fetchAllClientLocation = (state = [], action: actions) => {
    switch (action.type) {
      case FETCH_ALL_CLIENT_LOCATION[SUCCESS]:
        return action.payload
      case FETCH_ALL_CLIENT_LOCATION[REQUEST]:
        return []
      case FETCH_ALL_CLIENT_LOCATION[FAILURE]:
        return action.payload.data
      case FETCH_ALL_CLIENT_LOCATION[RESET]:
        return {}
      default:
        return state
    }
  }

  const fetchAllUserList = (state = [], action: actions) => {
    switch (action.type) {
      case FETCH_ALL_USER_LIST[SUCCESS]:
        return action.payload
      case FETCH_ALL_USER_LIST[REQUEST]:
        return []
      case FETCH_ALL_USER_LIST[FAILURE]:
        return action.payload.data
      case FETCH_ALL_USER_LIST[RESET]:
        return {}
      default:
        return state
    }
  }

  const updateUser = (state = [], action: actions) => {
    switch (action.type) {
      case UPDATE_USER[SUCCESS]:
        return action.payload
      case UPDATE_USER[REQUEST]:
        return []
      case UPDATE_USER[FAILURE]:
        return action.payload.data
      case UPDATE_USER[RESET]:
        return {}
      default:
        return state
    }
  }

  const uploadUser = (state = [], action: actions) => {
    switch (action.type) {
      case UPLOAD_USER_IMG[SUCCESS]:
        return action.payload
      case UPLOAD_USER_IMG[REQUEST]:
        return []
      case UPLOAD_USER_IMG[FAILURE]:
        return action.payload.data
      case UPLOAD_USER_IMG[RESET]:
        return {}
      default:
        return state
    }
  }

  const getUserImage = (state = [], action: actions) => {
    switch (action.type) {
      case GET_USER_IMAGE[SUCCESS]:
        return action.payload
      case GET_USER_IMAGE[REQUEST]:
        return []
      case GET_USER_IMAGE[FAILURE]:
        return action.payload.data
      case GET_USER_IMAGE[RESET]:
        return {}
      default:
        return state
    }
  }

  const deleleUser = (state = [], action: actions) => {
    switch (action.type) {
      case DELETE_USER[SUCCESS]:
        return true
      case DELETE_USER[REQUEST]:
        return []
      case DELETE_USER[FAILURE]:
        return action.payload.data
      case DELETE_USER[RESET]:
        return {}
      default:
        return state
    }
  }

  return combineReducers({
    fetchUser, fetchAllRole, fetchAllState, fetchAllUserList, fetchAllCountry, fetchAllFloor, fetchAllWorkstation, fetchAllLocation, updateUser, fetchAllClientLocation, uploadUser, getUserImage, deleleUser
  })
}

export default entity

export const fetchUserData = (state: RootState) => state.entities.user
