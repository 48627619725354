import React, { useEffect, useRef, useState } from 'react'
import { Box, Button, CircularProgress, Dialog, Typography } from '@mui/material'
import { Tree, TreeNode } from 'react-organizational-chart'
import { RootState } from '../../configureStore'
import { orgChartEntity, orgChartUI } from '../../reducers'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { peopleBasedOrgChart } from '../../actions'
import { loaderProps } from '../Common/CommonStyles'
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'
import {
  DrsCount,
  Label,
  Name,
  NodeProps,
  PeopleBasedChartsProps,
  StyledImage,
  StyledNode,
  StyledPaper,
  styles,
} from './OrgStyles'
import colors from './Colors'

const Node: React.FC<NodeProps> = ({ name, designation, imageURL, count, bgColor }) => (
  <StyledNode bgColor={bgColor}>
    <StyledImage src={imageURL} alt='image' />
    <Name>{name}</Name>
    <Label>{designation}</Label>
    {count ? <DrsCount>Total Reportees : {count}</DrsCount> : ''}
  </StyledNode>
)
const renderTreeNodes = (node: NodeProps, colorMap: { [key: string]: string }) => (
  <TreeNode key={node.emp_id} label={<Node {...node} bgColor={colorMap[node.emp_id]} />}>
    {node.drs?.map((child) => renderTreeNodes(child, colorMap))}
  </TreeNode>
)

const generateColorMap = (
  nodes: NodeProps[],
  colorMap: { [key: string]: string },
  colorList: string[],
) => {
  const colorQueue = [...colorList]
  const assignColor = (node: NodeProps, parentColor?: string) => {
    if (!colorMap[node.emp_id]) {
      colorMap[node.emp_id] = parentColor || colorQueue.shift() || ''
    }

    if (colorQueue.length === 0) {
      colorQueue.push(...colorList)
    }

    node.drs?.forEach((sub) => assignColor(sub, colorMap[node.emp_id]))
  }

  nodes.forEach((node) => assignColor(node))
  return colorMap
}

const PeopleBasedCharts: React.FC<PeopleBasedChartsProps> = (props) => {
  const { peopleBasedOrgChartAPI, peopleBasedOrgChartData, isGettingPeopleBasedOrgData } = props
  const [colorMap, setColorMap] = useState<{ [key: string]: string }>({})

  const chartRef = useRef<HTMLDivElement>(null)

  const predefinedColors = Object.values(colors)

  useEffect(() => {
    if (peopleBasedOrgChartData && peopleBasedOrgChartData.drs) {
      const initialColorMap = {}
      generateColorMap(peopleBasedOrgChartData.drs, initialColorMap, predefinedColors)
      setColorMap(initialColorMap)
    }
  }, [peopleBasedOrgChartData])

  useEffect(() => {
    peopleBasedOrgChartAPI()
  }, [])

  const exportToPDF = () => {
    if (chartRef.current) {
      const chart = chartRef.current
      const originalWidth = chart.scrollWidth
      const originalHeight = chart.clientHeight
      const originalOverflow = chart.style.overflow

      chart.style.width = `${originalWidth}px`
      chart.style.overflow = 'visible'

      html2canvas(chart, {
        scrollX: 0,
        scrollY: 0,
        useCORS: true,
        width: originalWidth,
        height: originalHeight,
        scale: 1,
      }).then((canvas) => {
        const imgData = canvas.toDataURL('image/jpeg', 0.9)

        const pdf = new jsPDF({
          orientation: 'landscape',
          unit: 'px',
          format: [canvas.width, canvas.height],
        })

        const pdfWidth = pdf.internal.pageSize.getWidth()
        const pdfHeight = (canvas.height * pdfWidth) / canvas.width

        pdf.addImage(imgData, 'JPEG', 0, 0, pdfWidth, pdfHeight, '', 'FAST')
        pdf.save('org-chart.pdf')

        chart.style.width = ''
        chart.style.overflow = originalOverflow
      })
    }
  }

  return (
    <>
      <Box sx={styles.selectBox}>
        <Button onClick={exportToPDF} sx={styles.buttonStyles} variant='contained' color='primary'>
          Export as PDF
        </Button>
      </Box>
      <StyledPaper>
        {isGettingPeopleBasedOrgData ? (
          <Dialog open={isGettingPeopleBasedOrgData} PaperProps={{ style: loaderProps }}>
            <CircularProgress color='secondary' />
          </Dialog>
        ) : (
          <div ref={chartRef}>
            <Tree
              lineWidth={'var(--line-width)'}
              lineColor={'var(--line-color)'}
              lineBorderRadius={'var(--line-border-radius)'}
              label={<Node {...peopleBasedOrgChartData} bgColor='' />}
            >
              {peopleBasedOrgChartData?.drs?.map((child) => renderTreeNodes(child, colorMap))}
            </Tree>
          </div>
        )}
      </StyledPaper>
    </>
  )
}
const mapStateToProps = (state: RootState) => {
  return {
    peopleBasedOrgChartData:
      orgChartEntity.getAllOrgChartState(state).fetchPeopleBasedOrgChartState,
    isGettingPeopleBasedOrgData: orgChartUI.getAllOrgChartUI(state).isGettingPeopleBasedOrgData,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    peopleBasedOrgChartAPI: (data: NodeProps) => dispatch(peopleBasedOrgChart.request(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PeopleBasedCharts)
