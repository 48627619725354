import { Box, Typography } from '@mui/material'
import { style } from './ projectQAReportStyles'

export const CollapseData = ({ projectQAReportData }: any) => {
  return (
    <>
      <Box id='card' sx={style.collapseBoxStyle}>
        <Box className='tile'>
          <Box sx={style.cardSection}>
            <Typography sx={style.cardText}>
              Total Projects: {projectQAReportData.total_projects}
            </Typography>
          </Box>
        </Box>
        <Box className='tile'>
          <Box sx={style.cardSection}>
            <Typography sx={style.cardText}>
              Total Projects With QA: {projectQAReportData.total_projects_with_qa}
            </Typography>
          </Box>
        </Box>
        <Box className='tile'>
          <Box sx={style.cardSection}>
            <Typography sx={style.cardText}>
              Total Projects Without QA: {projectQAReportData.total_projects_without_qa}
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  )
}
export default CollapseData
