import { Box, Pagination, Paper, Stack, Table, TableBody, TableContainer } from '@mui/material'
import TableHeadColumns from './TableHeadColumns'
import { style } from './ProjectManagementReportStyle'
import TableBodyRow from './TableBodyRow'
import NoProjectRow from './NoProjectRow'
import ShowEnteries from './ShowEnteries'

const TableLayout = (props: any) => {
  const {
    rowsToDisplay,
    page,
    handleChangePage,
    rowsPerPage,
    setRowsPerPage,
    setPage,
    tableData,
    tabView,
  } = props

  const sortedRowData = rowsToDisplay?.sort((a: any, b:any) => a.emp_id - b.emp_id);

  return (
    <Box>
      <div id='export'>
        <Box overflow='scroll' margin='0 15px 0' sx={style.tableContainer}>
          <TableContainer component={Paper}>
            <Table>
              <TableHeadColumns
              tabView={tabView}
              />
              <TableBody>
                {sortedRowData?.map((row: any) => (
                  <TableBodyRow row={row} key={row.emp_id} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </div>
      {!rowsToDisplay?.length && <NoProjectRow />}     

      {rowsToDisplay?.length > 0 && (
        <Stack width='95%' spacing={2} direction='row' justifyContent='flex-end' margin='1rem 32px'>
          <ShowEnteries
            setPage={setPage}
            setRowsPerPage={setRowsPerPage}
            rowsPerPage={rowsPerPage}
          />

          <Pagination
            count={Math.ceil(tableData?.count / rowsPerPage)}
            page={page}
            onChange={handleChangePage}
            color='primary' 
          />
        </Stack>
      )}
    </Box>
  )
}
export default TableLayout
