import { Box, Button, styled } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs, { Dayjs } from 'dayjs'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'

type PropType = {
  startDate: Dayjs
  endDate: Dayjs
  setStartDate: Dispatch<SetStateAction<Dayjs>>
  setEndDate: Dispatch<SetStateAction<Dayjs>>
  setShowResults: Dispatch<SetStateAction<boolean>>
  searchQuery: string
  selectedTab: any
}

const style = {
  showResults: {
    padding: '3px 14px',
    borderRadius: '25px',
    fontFamily: 'Montserrat-Medium',
    fontSize: '0.9rem',
    height: '40px',
    '@media only screen and (max-width:650px)': {
      width: '7rem',
      fontSize: '0.75rem',
    },
    '&.Mui-disabled': {
      color: '#BEBEBE',
      backgroundColor: 'transparent',
    },
  },
}

const StyledDatePicker = styled(DatePicker)(({ theme }) => ({
  '*': {
    paddingLeft: '0',
    marginLeft: '0',
  },
  '&': {
    width: '15.3rem',
    padding: '0',
    marginLeft: '10px',
    '@media only screen and (max-width:460px)': {
      margin: '7px',
    },
  },
  '& .MuiInputBase-root': {
    height: '40px',
  },
  '& .MuiFormLabel-asterisk': {
    display: 'none',
  },
  '& .MuiDateCalendar-root': {
    width: '200px',
  },
  '& .MuiInputBase-root.MuiOutlinedInput-root': {
    borderRadius: '20px',
  },
  input: {
    fontSize: '14px',
    padding: '11.5px 14px',
    color: 'primary',
    fontFamily: 'Montserrat-Medium',
  },
  '@media only screen and (max-width:650px)': {
    '&': {
      width: '6rem',
    },
    input: {
      fontSize: '12px',
      padding: '9px 10px',
    },
  },
}))

const DateRangePicker = (props: any) => {
  const {
    setStartDate,
    setEndDate,
    startDate,
    endDate,
    setShowResults,
    fetchDelayedTable,
    delayTable,
  } = props
  const [startDateOpen, setStartDateOpen] = useState(false)
  const [endDateOpen, setEndDateOpen] = useState(false)
  const [fromDate, setFromDate] = useState(startDate)
  const [toDate, setToDate] = useState(endDate)

  const handlerDateResult = () => {
    setStartDate(fromDate)
    setEndDate(toDate)
    setShowResults((prev: boolean) => !prev)
  }

  useEffect(() => {
    setStartDate(fromDate)
    setEndDate(toDate)
  }, [fromDate, toDate])

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box display='flex' alignItems='center' justifyContent='right' margin='0' padding='0'>
        <StyledDatePicker
          label='Start Date'
          value={fromDate}
          onAccept={(newValue: any) => {
            // If the start date is greater than the end date, update the end date
            if (newValue.isAfter(toDate)) {
              setToDate(newValue as Dayjs)
            }
            setFromDate(newValue as any)
          }}
          disableFuture
          open={startDateOpen}
          onClose={() => setStartDateOpen(false)}
          slotProps={{
            textField: {
              onClick: () => setStartDateOpen(true),
            },
          }}
        />
        <Box
          display='flex'
          alignItems='center'
          justifyContent='center'
          marginLeft='0.5rem'
          paddingRight='10px'
        >
          <StyledDatePicker
            label='End Date'
            value={toDate}
            onAccept={(newValue: any) => {
              // Ensure the end date is not earlier than the start date
              if (!newValue.isBefore(fromDate)) {
                setToDate(newValue as Dayjs)
              } else {
                alert('End Date cannot be earlier than Start Date')
              }
            }}
            minDate={fromDate}
            open={endDateOpen}
            onClose={() => setEndDateOpen(false)}
            slotProps={{
              textField: {
                onClick: () => setEndDateOpen(true),
              },
            }}
          />
        </Box>

        <Button onClick={handlerDateResult} variant='outlined' sx={style.showResults}>
          Show Result
        </Button>
      </Box>
    </LocalizationProvider>
  )
}

export default DateRangePicker
