import { combineReducers } from 'redux'
import { actionTypes } from '../../actions'
import { Actions } from '../../actions/Types'
import { RootState } from '../../configureStore'
import { FETCH_EMPLOYEE_DETAILS } from '../../actions/actiontypes'

const { SUCCESS, REQUEST, FAILURE } = actionTypes

const ui = () => {
  const isGetAllEmployeeList = (state = false, action: Actions) => {
    switch (action.type) {
      case FETCH_EMPLOYEE_DETAILS[SUCCESS]:
        return true
      case FETCH_EMPLOYEE_DETAILS[FAILURE]:
      case FETCH_EMPLOYEE_DETAILS[REQUEST]:
        return false
      default:
        return state
    }
  }

  const isGetAllEmployeeListFailed = (state = false, action: Actions) => {
    switch (action.type) {
      case FETCH_EMPLOYEE_DETAILS[SUCCESS]:
      case FETCH_EMPLOYEE_DETAILS[REQUEST]:
        return false
      case FETCH_EMPLOYEE_DETAILS[FAILURE]:
        return true
      default:
        return state
    }
  }

  return combineReducers({
    isGetAllEmployeeList,
    isGetAllEmployeeListFailed,
  })
}

export default ui

export const getAllEmpList = (state: RootState) => state.ui.employeeList
