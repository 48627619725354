import { combineReducers } from 'redux'
import * as dashboardState from './dashboard'
import * as userAuthenticationState from './userAuthentication'
import * as loginUserState from './loginUser'
import * as employeePortalState from './employeePortal'
import * as financeState from './finance'
import * as ServiceRequest from './ServiceRequest'
import * as loanRequest from './LoanRequest'
import * as employeeDataRequest from './employeeList'
import * as statuSummary from './statusSummary'
import * as attendanceState from "./attendance"
import * as fetchRCAs from './fetchRCAs'
import * as fetchIDSRs from './fetchRCAs'
import * as fetchPlans from './fetchRCAs'
import * as fetchStatusType from './fetchRCAs'
import * as getAssignedRequest from './ServiceRequest'
import * as createUpdatedServiceRequestData from './ServiceRequest'
import * as getDownloadableURL from './ServiceRequest'
import * as getManagerViewData from './dashboard'
import * as getAllProjectManagement from './projectGraph'
import * as getAllOrgChartState from './orgChart'
import * as fetchAllUsers from './user'
import * as fetchAllRole from './user'
import * as fetchAllState from './user'
import * as fetchAllUserList from './user'
import * as fetchAllCountry from './user'
import * as fetchAllFloor from './user'
import * as fetchAllWorkstation from './user'
import * as fetchAllLocation from './user'
import * as updateUser from './user'
import * as fetchAllClientLocation from './user'
import * as uploadUser from './user'
import * as getUserImage from './user'
import * as deleleUser from './user'

export {
  dashboardState,
  userAuthenticationState,
  loginUserState,
  employeePortalState,
  financeState,
  ServiceRequest,
  loanRequest,
  employeeDataRequest,
  statuSummary,
  attendanceState,
  fetchRCAs,
  fetchIDSRs,
  fetchPlans,
  fetchStatusType,
  getAssignedRequest,
  createUpdatedServiceRequestData,
  getDownloadableURL,
  getManagerViewData,
  getAllProjectManagement,
  getAllOrgChartState,
  fetchAllUsers,
  fetchAllRole,
  fetchAllState,
  fetchAllUserList,
  fetchAllCountry,
  fetchAllFloor,
  fetchAllWorkstation,
  fetchAllLocation,
  updateUser,
  fetchAllClientLocation,
  uploadUser,
  getUserImage,
  deleleUser
}

export default combineReducers({
  dashboard: dashboardState.default(),
  userAuthentication: userAuthenticationState.default(),
  ServiceRequest: ServiceRequest.default(),
  loginUser: loginUserState.default(),
  employeePortal: employeePortalState.default(),
  finance: financeState.default(),
  LoanRequest: loanRequest.default(),
  EmployeeDataRequest: employeeDataRequest.default(),
  statuSummary: statuSummary.default(),
  EmployeeAttendanceRequest: attendanceState.default(),
  fetchRCAs: fetchRCAs.default(),
  fetchIDSRs: fetchIDSRs.default(),
  fetchPlans: fetchPlans.default(),
  fetchStatusType: fetchStatusType.default(),
  getAssignedRequest: getAssignedRequest.default(),
  createUpdatedServiceRequestData:createUpdatedServiceRequestData.default(),
  getDownloadableURL: getDownloadableURL.default(),
  getManagerViewData: getManagerViewData.default(),
  projectManagement: getAllProjectManagement.default(),
  orgchartstate: getAllOrgChartState.default(),
  user: fetchAllUsers.default(),
  allRole: fetchAllRole.default(),
  allState: fetchAllState.default(),
  allUserList: fetchAllUserList.default(),
  allContry: fetchAllCountry.default(),
  allFloor: fetchAllFloor.default(),
  allWorkstation: fetchAllWorkstation.default(),
  allLocation: fetchAllLocation.default(),
  updateUser: updateUser.default(),
  allClientLocation: fetchAllClientLocation.default(),
  uploadUser: uploadUser.default(),
  getUserImage: getUserImage.default(),
  deleleUser: deleleUser.default() 
})
