import { call, takeLatest } from 'redux-saga/effects'
import { actionTypes } from '../actions'
import * as ApiService from '../services'
import { sendPayload, sendPayloadFailure } from './helper'
import { STATUS_SUMMARY } from '../actions/actiontypes'
const { REQUEST } = actionTypes

//write sagas function


function* getStatusSummary(data: any) {
    try {
        const apiResponse: Generator<string, number, string> = yield call(
            ApiService.getStatusSummary,
            data.data,
        )
        yield sendPayload(apiResponse, STATUS_SUMMARY)
    } catch (e) {
        yield sendPayloadFailure(e, STATUS_SUMMARY)
    }
}

export const sagas = {
    //watcher come here
    watchStatusSummary: takeLatest(actionTypes.STATUS_SUMMARY[REQUEST], getStatusSummary)
}
