import { Box, Button, Collapse } from '@mui/material'
import { Dayjs } from 'dayjs'
import { Dispatch, SetStateAction } from 'react'
import DateRangePicker from '../DateRangePicker/DateRangePicker'
import { ReactComponent as ExpandIcon } from '../../../../assets/images/expand_content.svg'
import { ReactComponent as ShrinkIcon } from '../../../../assets/images/shrink.svg'
import ExportButton from '../ExportButtonFunctionality'
import { style } from '../ResourceReportStyle'
import ProjectsSelectTab from '../ProjectSelectedTab'
import { projectReportCards } from '../CommontStyleForResource'

interface ActionBarPropsType {
  expand: boolean
  setExpand: Dispatch<SetStateAction<boolean>>
  setRowsPerPage: Dispatch<SetStateAction<number>>
  setPage: Dispatch<SetStateAction<number>>
  startDate: Dayjs
  endDate: Dayjs
  setStartDate: Dispatch<SetStateAction<Dayjs>>
  setEndDate: Dispatch<SetStateAction<Dayjs>>
  setProjectState: any
  setLocation: any
  setEmploymentType: any
  selectData: any
  selectForSource: string
  setSelectForSource: any
  projectState: string
  location: string
  employmentType: string
  fetchResourceReport: any
}
const ActionBar = (props: ActionBarPropsType) => {
  const {
    expand,
    setExpand,
    selectData,
    setProjectState,
    setLocation,
    setSelectForSource,
    selectForSource,
    setEmploymentType,
    projectState,
    location,
    employmentType,
    setPage,
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    fetchResourceReport,
  } = props

  return (
    <Box sx={style.actionBarConatiner}>
      <Box sx={style.actionBar}>
        <Box display='flex' alignItems='center' justifyContent='center' padding='0'>
          <DateRangePicker
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
          />
        </Box>
        <Box sx={style.entriesAndDownloadbtn}>
          <ExportButton />
        </Box>
        <Button size='small' onClick={() => setExpand((prev) => !prev)} sx={style.expandButton}>
          {' '}
          {expand ? <ShrinkIcon /> : <ExpandIcon />}
        </Button>
      </Box>
      <Collapse orientation='vertical' in={expand} sx={{ display: 'flex' }}>
        <ProjectsSelectTab
          setPage={setPage}
          projectState={projectState}
          location={location}
          employmentType={employmentType}
          setSelectForSource={setSelectForSource}
          selectForSource={selectForSource}
          selectData={selectData}
          setProjectState={setProjectState}
          setLocation={setLocation}
          setEmploymentType={setEmploymentType}
        />
        <Box component='div' id='card' display='flex' justifyContent='space-between'>
          <Box
            flexWrap='wrap'
            display='flex'
            margin='0 0 0 12px'
            gap='2.5%'
            padding='10px 2px'
            width='100%'
            sx={projectReportCards}
          >
            <Box sx={{ ...projectReportCards.tabs }}>
              {`Total Projects: ${
                fetchResourceReport?.totalProjects === undefined
                  ? ''
                  : fetchResourceReport?.totalProjects
              } `}
            </Box>
            <Box sx={{ ...projectReportCards.tabs }}>
              {`Total Fixed Billable: ${
                fetchResourceReport?.total_fixed_billable === undefined
                  ? ''
                  : fetchResourceReport?.total_fixed_billable
              } `}
            </Box>
            <Box sx={{ ...projectReportCards.tabs }}>
              {`Total Monthly Billable: ${
                fetchResourceReport?.total_monthly_billable === undefined
                  ? ''
                  : fetchResourceReport?.total_monthly_billable
              } `}
            </Box>
            <Box sx={{ ...projectReportCards.tabs }}>
              {`Total Hours Billable: ${
                fetchResourceReport?.total_hourly_billable === undefined
                  ? ''
                  : fetchResourceReport?.total_hourly_billable
              } `}
            </Box>
          </Box>
        </Box>
      </Collapse>
    </Box>
  )
}

export default ActionBar
