import {  TableHead, TableRow } from '@mui/material'
import { TableHeadColumnsPropType } from './projectQAReportTypes'
import { StyledTableCell } from '../../Common/CommonStyles'

export const style = {
  tableHeadCell: {
    color: 'white',
    width: '300px',
  },
  typographyStyle: {
    textAlign: 'center',
    width: '100%',
  },
  border: {
    borderLeft: '1px solid #E9E9E9',
    borderRight: '1px solid #E9E9E9',
  },
}

const TableHeadColumns = ({ selectType }: TableHeadColumnsPropType) => {
  const styles ={
    tableHeadStyles:{
      Padding: '2px 6px'
    }
  }
  return (
    <TableHead id='head'>
      <TableRow>
        {selectType === 'project-wise' ? (
          <>
            <StyledTableCell sx={styles.tableHeadStyles}>PROJECT</StyledTableCell>
            <StyledTableCell sx={styles.tableHeadStyles}>STATUS</StyledTableCell>
            <StyledTableCell sx={styles.tableHeadStyles}>QA</StyledTableCell>
            <StyledTableCell sx={styles.tableHeadStyles}>IS BILLABLE</StyledTableCell>
            <StyledTableCell sx={styles.tableHeadStyles}>EMPLOYMENT TYPE</StyledTableCell>
          </>
        ) : (
          <>
            <StyledTableCell sx={styles.tableHeadStyles}>EMPLOYEE ID</StyledTableCell>
            <StyledTableCell sx={styles.tableHeadStyles}>EMPLOYEE NAME</StyledTableCell>
            <StyledTableCell sx={styles.tableHeadStyles}>PROJECTS</StyledTableCell>
            <StyledTableCell sx={styles.tableHeadStyles}>TOTAL ASSIGNED PROJECTS</StyledTableCell>
          </>
        )}
      </TableRow>
    </TableHead>
  )
}

export default TableHeadColumns
