import { combineReducers } from 'redux'
import { actionTypes } from '../../actions'
import { Actions as actions } from '../../actions/Types'
import { RootState } from '../../configureStore'
import { FAILURE, RESET } from '../../actions/actiontypes'

const { SUCCESS, REQUEST, LOGIN_USER } = actionTypes

const entity = () => {
  const getLoginUser = (state = [], action: actions) => {
    switch (action.type) {
      case LOGIN_USER[SUCCESS]:
        return action.payload
      case LOGIN_USER[REQUEST]:
        return []
      case LOGIN_USER[FAILURE]:
        return action.payload.data
      case LOGIN_USER[RESET]:
        return {}
      default:
        return state
    }
  }

  return combineReducers({
    getLoginUser,
  })
}

export default entity

export const getLoginUserData = (state: RootState) => state.entities.loginUser
