import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogTitleProps,
  Grid,
  Grow,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import styled from 'styled-components'
import CommentIcon from '@mui/icons-material/Comment'
import EditNoteIcon from '@mui/icons-material/EditNote'
import AddTaskIcon from '@mui/icons-material/AddTask'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import {
  StyledTableRow,
  StyledTableCell,
  loaderProps,
  StyledTableCellForMyTeam,
} from '../Common/CommonStyles'
import CloseIcon from '@mui/icons-material/Close'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { attendanceEntity, attendanceUI, dashboardEntity } from '../../reducers'
import { RootState } from '../../configureStore'
import { useEffect, useState } from 'react'
import { Heading } from '../Pages/Styles'
import CheckIcon from '@mui/icons-material/Check'
import PendingIcon from '@mui/icons-material/Pending'
import { makeStyles } from '@mui/styles'
import React from 'react'
import { InputField } from '../Common/ComponentCommonStyles'
import priority from '../IDSR/constant'
import { ReactComponent as EditIcon } from '../../assets/images/editIconTimesheet.svg'
import { ReactComponent as ApproveIcon } from '../../assets/images/approveIconTimesheet.svg'
import { ReactComponent as CheckedApprovedIcon } from '../../assets/images/checkApprovedIcon.svg'
import { APPROVE_PLANFORTHEDAY } from '../../actions/actiontypes'
import {
  approvePlanForTheDay,
  editPlanForTheDay,
  getCommentPLanForTheDay,
  getTaskStatus,
} from '../../actions'
import { toast } from 'react-toastify'
// import { ReactComponent as CheckedApprovedIcon } from "../../assets/images/checkApprovedIcon.svg";
import './IDSRs.css'
import EditPlanForTheDay from '../Report/EditPlanForTheDay'
import dayjs from 'dayjs'

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  height: 'auto',
  bgcolor: 'background.paper',
  border: '2px solid #A5C332',
  boxShadow: 24,
  p: 4,
}

const StyledPaper = styled(Paper)(() => ({
  width: '93%',
  padding: '1px  25px 1px 25px',
  background: '#FFFFFF',
  opacity: '1',
  marginTop: '10px',
  marginLeft: 'auto',
  marginRight: 'auto',
  marginBottom: '70px',
  border: '1px solid #DDDDDD',
}))

const PlanForTheDayList = (props: any) => {
  const useStyles = makeStyles({
    checkIcon: {
      color: 'green',
      // marginLeft: 12,
    },
    crossIcon: {
      color: 'red',
      marginLeft: 12,
    },
    pendingIcon: {
      marginLeft: 12,
      color: 'brown',
    },

    heading: {
      marginLeft: 80,
    },
    headingFinancial: {
      marginTop: 50,
    },
    textField: {
      borderRadius: '4px',
    },
    dialogTitle: {
      fontWeight: 'normal',
    },
    dialogTitleName: {
      fontWeight: 'bold',
    },
    boxContainer: {
      textAlign: 'left',
    },
    boxSubContainer: {
      float: 'right',
      marginRight: '8px',
      marginTop: '0px',
    },
    noCursor: {
      cursor: 'auto !important',
    },
    cursor: {
      cursor: 'pointer !important',
    },
  })
  const classes = useStyles()

  const { ManagerViewData, fetchTaskStatus, approvePlanForTheDayDispatchCall, mandateTypeData } =
    props
  const [selectedPlanForTheDay, setSelectedPlanForTheDay] = useState<any | null>(null)
  const [isEditPlanDialogOpen, setEditPlanDialog] = useState(false)
  const [getPfpId, setPfpId] = useState()
  const [getpfpIdForMngEditId, setPfpIdForMngEdit] = useState({ sender: '', cc: '' })

  let statusType = new Map<number, string>()

  for (const key in mandateTypeData?.data) {
    statusType.set(mandateTypeData.data[key]?.id, mandateTypeData.data[key]?.mandate_name)
  }
  const [newComment, setNewComment] = React.useState('')
  const isCommentEmpty = newComment.trim() === ''

  const [openCommentModal, setOpenCommentModal] = React.useState(false)

  const [isNewPlanDialogOpen, setNewPlanDialog] = useState(false)

  const [planForTheDayData, setPlanForTheDayData] = useState()

  const handleNewPlanDialog = () => {
    setNewPlanDialog(true)
  }

  const handleCloseNewPlanDialog = () => {
    setNewPlanDialog(false)
  }

  const handleCloseEditPlanDialog = () => {
    setEditPlanDialog(false)
  }

  const [formValues, setFormValues] = useState('')

  const handleTaskDescriptionChange = (content: string) => {
    setFormValues(content)
  }

  interface DialogTitleProps {
    id: string
    children?: React.ReactNode
    onClose: () => void
  }

  function BootstrapDialogTitle(props: DialogTitleProps) {
    const { children, onClose, ...other } = props

    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label='close'
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    )
  }

  const handleRowClickData = (data: any) => {
    setSelectedPlanForTheDay(data)
  }

  const handleCloseModal = () => {
    setSelectedPlanForTheDay(null)
  }

  const handlePostComment = () => {
    if (formValues) {
      let finalData = JSON.stringify({ comment: formValues })
      props.createCommentPlanForTheDay({ id: planForTheDayData, data: finalData })
      handleCloseNewPlanDialog()
      setFormValues('')
    }
  }

  function convertTextToHTML(text: string) {
    let htmlText = text.replace(/\\n/g, '<br>')

    htmlText = htmlText.replace(/\*([^*]+)\*/g, '<strong>$1</strong>')

    return <div dangerouslySetInnerHTML={{ __html: htmlText }} />
  }

  useEffect(() => {
    if (!props.getLoaderState && props.getCommentSuccess) {
      toast.success('Comment added successfully')
    }
    props.resetPlanForTheDayComment()
  }, [props.getCommentSuccess])

  useEffect(() => {
    fetchTaskStatus()
  }, [])

  useEffect(() => {
    if (!props.getApproveLoader && props.getApproveSuccessStatus) {
      toast.success('Plan for the day approved successfully')
    }
    props.resetPlanForTheDayApprove()
    setSelectedPlanForTheDay(null)
  }, [props.getApproveSuccessStatus])

  const convertMinutesToHours = (minutes: number) => {
    const hours = (minutes / 60).toFixed(1)
    return `${hours} hrs`
  }

  function htmlToText(html: string) {
    const temporaryElement = document.createElement('div')
    temporaryElement.innerHTML = html
    return temporaryElement.textContent || temporaryElement.innerText || ''
  }

  const leadTooltipContent = (data: any) => {
    return (
      <div style={{ padding: '8px', width: '150px' }}>
        <Typography fontWeight={500} fontSize={'13px'}>
          Comment:{' '}
        </Typography>
        <Typography marginTop={'5px'} fontSize={'12px'}>
          {data}
        </Typography>
      </div>
    )
  }

  const handleEditPlanForTheDay = (event: any, data: any) => {
    setPfpIdForMngEdit({ sender: data.sender_mail, cc: data.cc })
    const dataPfp = data.id
    event.preventDefault()
    event.stopPropagation()
    setPfpId(dataPfp)
    if (data.approve_status !== 1) {
      setEditPlanDialog(true)
    }
  }

  return (
    <StyledPaper>
      <Dialog
        open={props.getLoaderState || props.getApproveLoader}
        PaperProps={{ style: loaderProps }}
      >
        <CircularProgress color='secondary' />
      </Dialog>
      <TableContainer sx={{ margin: '36px 0' }} component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label='customized table'>
          <TableHead>
            <StyledTableRow>
              <StyledTableCell>DATE (DAY)</StyledTableCell>
              <StyledTableCell>SUBJECT</StyledTableCell>
              <StyledTableCell>PROJECT NAME</StyledTableCell>
              <StyledTableCell>TYPE</StyledTableCell>
              <StyledTableCell sx={{ width: '110px' }}>EST TIME (MINS)</StyledTableCell>
              <StyledTableCell>STATUS</StyledTableCell>
              <StyledTableCell sx={{ width: '110px' }}>SUBMITTED AT</StyledTableCell>
              <StyledTableCell>COMMENT</StyledTableCell>
              <StyledTableCell>EDIT</StyledTableCell>
            </StyledTableRow>
          </TableHead>
          {ManagerViewData?.Planfortheday?.length ? (
            <TableBody>
              {ManagerViewData?.Planfortheday?.map((data: any) => (
                <StyledTableRow
                  key={`${data?.subject}${data?.time_spent}`}
                  onClick={() => handleRowClickData(data)}
                  sx={{ cursor: 'pointer' }}
                >
                  <StyledTableCellForMyTeam component='th' scope='row'>
                    {`${new Date(data.PLANFORDAY_date).toLocaleDateString('en-US', {
                      year: 'numeric',
                      month: '2-digit',
                      day: '2-digit',
                    })}`}{' '}
                    (
                    {`${new Date(data.PLANFORDAY_date).toLocaleDateString('en-GB', {
                      weekday: 'long',
                    })}`}
                    )
                  </StyledTableCellForMyTeam>
                  <StyledTableCellForMyTeam align='left' sx={{ width: '110px' }}>
                    {data.subject}
                  </StyledTableCellForMyTeam>
                  <StyledTableCellForMyTeam align='left' sx={{ width: '130px' }}>
                    {data.project_name === 'Other' ? data.other_project_name : data.project_name}
                  </StyledTableCellForMyTeam>
                  <StyledTableCellForMyTeam align='left'>
                    {data.mandate_type_id ? statusType.get(data.mandate_type_id) : '-'}
                  </StyledTableCellForMyTeam>{' '}
                  <StyledTableCellForMyTeam align='left'>
                    {`${data.time_spent} mins (${convertMinutesToHours(data.time_spent)})`}
                  </StyledTableCellForMyTeam>
                  <StyledTableCellForMyTeam align='left'>
                    <Box sx={{ marginTop: '9px', marginLeft: '10px' }}>
                      {data.approve_status === 1 ? (
                        <Tooltip title={'Approved'}>
                          <CheckedApprovedIcon
                            fontSize='small'
                            style={{ width: '27px', height: '27px', textAlign: 'center' }}
                          />
                        </Tooltip>
                      ) : (
                        <Tooltip title={'Pending'}>
                          <PendingIcon
                            style={{ color: 'orange', fontSize: '30px', marginLeft: '-2px' }}
                          />
                        </Tooltip>
                      )}
                    </Box>
                  </StyledTableCellForMyTeam>
                  <StyledTableCellForMyTeam align='left'>
                    {dayjs(data.submission_time).format('hh:mm A')}
                  </StyledTableCellForMyTeam>
                  <StyledTableCellForMyTeam align='left'>
                    <Tooltip
                      placement='left'
                      classes={{
                        tooltip: 'custom-tooltip',
                        arrow: 'custom-tooltip-arrow',
                      }}
                      title={data.comment ? leadTooltipContent(htmlToText(data.comment)) : ''}
                      arrow
                    >
                      <IconButton
                        sx={{
                          width: '40px',
                          opacity: data?.comment ? 1 : 0.5,
                          color: '#483F3F',
                          cursor: data?.comment ? 'pointer' : 'not-allowed',
                          marginTop: '6px',
                        }}
                        disableRipple={data?.comment}
                      >
                        {' '}
                        <CommentIcon />
                      </IconButton>
                    </Tooltip>
                  </StyledTableCellForMyTeam>
                  <StyledTableCellForMyTeam align='left'>
                    <Tooltip title={'Edit'} arrow>
                      <EditIcon
                        style={{
                          opacity: data.approve_status === 1 ? '0.5' : '1',
                          cursor: data.approve_status === 1 ? 'not-allowed' : 'pointer',
                        }}
                        onClick={(event) => handleEditPlanForTheDay(event, data)}
                        width={'27px'}
                        height={'27px'}
                      />
                    </Tooltip>
                  </StyledTableCellForMyTeam>
                </StyledTableRow>
              ))}
            </TableBody>
          ) : (
            <TableBody>
              <StyledTableRow>
                <StyledTableCell align='center' colSpan={10}>
                  <Typography variant='subtitle1' sx={{ color: '#483f3f', marginLeft: 50 }}>
                    No matching records found.
                  </Typography>
                </StyledTableCell>
              </StyledTableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <EditPlanForTheDay
        setPfpId={setPfpId}
        getPfpId={getPfpId}
        isNewPlanDialogOpen={isEditPlanDialogOpen}
        setNewPlanDialog={setEditPlanDialog}
        onClose={handleCloseEditPlanDialog}
        getpfpIdForMngEdit={getpfpIdForMngEditId}
      />
      <Modal open={!!selectedPlanForTheDay} onClose={handleCloseModal}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '70vw',
            maxWidth: '75%',
            bgcolor: 'background.paper',
            p: 4,
            maxHeight: '80vh',
            overflowY: 'auto',
            borderRadius: '10px',
          }}
        >
          {/* <Heading>Delayed Task Description</Heading> */}
          <IconButton
            aria-label='close'
            onClick={handleCloseModal}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[600],
              marginTop: '15px',
            }}
          >
            <CloseIcon />
          </IconButton>

          <Box display={'flex'} justifyContent={'center'} width='100%' height={'70px'}>
            <Box width='65%' justifyContent='flex-end' display='flex'>
              <Heading
                style={{
                  fontSize: '18px',
                  textAlign: 'center',
                  fontFamily: 'sans-serif',
                  letterSpacing: '0px',
                  position: 'relative',
                }}
                dangerouslySetInnerHTML={{
                  __html: selectedPlanForTheDay?.subject || '',
                }}
              />
            </Box>
            <Box width={'40%'} display={'flex'} justifyContent={'flex-end'}>
              <Tooltip title={selectedPlanForTheDay?.approve_status === 1 ? '' : 'Approve'} arrow>
                <IconButton
                  sx={{
                    position: 'absolute',
                    left: '63%',
                    top: 8,
                    marginTop: '11px',
                    width: '50px',
                    // opacity: selectedPlanForTheDay?.approve_status === 1 ? 0.5 : 1,
                    cursor: selectedPlanForTheDay?.approve_status === 1 ? 'not-allowed' : 'pointer',
                  }}
                  disableRipple={selectedPlanForTheDay?.approve_status === 1}
                >
                  {selectedPlanForTheDay?.approve_status === 1 ? (
                    <CheckedApprovedIcon />
                  ) : (
                    <ApproveIcon
                      onClick={() => {
                        if (selectedPlanForTheDay?.approve_status !== 1) {
                          props.approvePlanForTheDayDispatchCall({ id: selectedPlanForTheDay?.id })
                        }
                      }}
                    />
                  )}
                </IconButton>
              </Tooltip>

              <Tooltip
                title={selectedPlanForTheDay?.approve_status === 1 ? '' : 'Add Comment'}
                arrow
              >
                <IconButton
                  sx={{
                    position: 'absolute',
                    left: '68%',
                    top: 8,
                    color: 'black',
                    marginTop: '11px',
                    // opacity: selectedPlanForTheDay?.approve_status === 1 ? 0.5 : 1,
                    // cursor: selectedPlanForTheDay?.approve_status === 1 ? 'not-allowed' : 'pointer',
                  }}
                  // disableRipple={selectedPlanForTheDay?.approve_status === 1}
                >
                  {selectedPlanForTheDay?.approve_status === 1 ? (
                    ''
                  ) : (
                    <EditIcon
                      onClick={() => {
                        if (selectedPlanForTheDay?.approve_status !== 1) {
                          setPlanForTheDayData(selectedPlanForTheDay.id)
                          setSelectedPlanForTheDay(null)
                          return handleNewPlanDialog()
                        }
                      }}
                    />
                  )}
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
          <Typography variant='body1' style={{ fontSize: '12px', marginTop: '-25px' }}>
            <div>{convertTextToHTML(selectedPlanForTheDay?.body || '')}</div>
          </Typography>
          {selectedPlanForTheDay?.approve_status === 0
            ? selectedPlanForTheDay?.comment && (
                <Box
                  width={'98%'}
                  padding={'15px 0 0 0'}
                  display={'flex'}
                  flexDirection={'column'}
                  flexWrap={'wrap'}
                >
                  {/* <Typography fontFamily={'Montserrat-Semibold'} fontSize={'15px'} marginBottom={'5px'}>Comment: </Typography> */}
                  <Box
                    width={'100%'}
                    height={'30px'}
                    display={'flex'}
                    justifyContent={'flex-start'}
                    alignItems={'center'}
                  >
                    <Typography fontFamily={'Montserrat-Semibold'} fontSize={'15px'}>
                      Comment{' '}
                    </Typography>
                    <Typography
                      marginLeft={'3px'}
                      fontFamily={'Montserrat-Semibold'}
                      fontSize={'13px'}
                    >
                      {' '}
                      {` ${dayjs(selectedPlanForTheDay?.comment_submission_time).format(
                        'MM/DD/YYYY hh:mm A',
                      )})`}
                      :
                    </Typography>
                  </Box>
                  <Typography
                    width={'100%'}
                    border={'1px solid #D3D3D3'}
                    padding={'0 10px'}
                    sx={{
                      wordWrap: 'break-word',
                      fontSize: '12px',
                      borderBottomColor: 'coral',
                    }}
                  >
                    {convertTextToHTML(selectedPlanForTheDay?.comment || '')}
                  </Typography>
                </Box>
              )
            : ''}
        </Box>
      </Modal>
      <Dialog open={isNewPlanDialogOpen}>
        <Box className='PFTD_Wrapper'>
          <BootstrapDialogTitle id='customized-dialog-title' onClose={handleCloseNewPlanDialog}>
            <Typography
              padding={'5px 0'}
              variant='h5'
              sx={{ textAlign: 'center', marginTop: '-10px' }}
              fontSize={'22px'}
              fontFamily={'Montserrat-Medium'}
            >
              Add Comment
            </Typography>
          </BootstrapDialogTitle>

          <Box sx={{ padding: '0 16px', marginTop: '-8px' }}>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
              <Grid item xs={12} sm={12} className='PFTD_Editor'>
                <CKEditor
                  editor={ClassicEditor}
                  data={''}
                  onReady={() => {}}
                  onChange={(event, editor) => {
                    const data = editor.getData()
                    handleTaskDescriptionChange(data)
                  }}
                />
              </Grid>
            </Grid>
          </Box>

          <DialogActions className='button_wrapper' sx={{ padding: '15px 10px' }}>
            <Button
              sx={{
                background: '#E2E2E2',
                color: '#000000',
                fontSize: `13px`,
                height: `42px`,
                fontFamily: 'Montserrat-SemiBold',
                width: '100px',
                borderRadius: '20px',
                '&:hover': {
                  background: '#E2E2E2',
                  color: '#000000',
                },
              }}
              onClick={handleCloseNewPlanDialog}
            >
              CANCEL
            </Button>
            <Button
              sx={{
                borderRadius: '20px',
                fontSize: '13px',
                height: '42px',
                fontFamily: 'Montserrat-SemiBold',
                width: '100px',

                '&.Mui-disabled': {
                  opacity: 0.5,
                  color: '#ffffff',
                  cursor: 'not-allowed',
                },
              }}
              onClick={handlePostComment}
              disabled={!formValues}
            >
              Submit
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </StyledPaper>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    ManagerViewData: dashboardEntity.getDashboard(state).getManagerViewData,
    // approvePlanForTheDay : attendanceUI.getEmpAttendanceList(state).
    getComment: attendanceEntity.getAllAttendance(state).getCommentPlanForTheDayReducer,
    getLoaderState: attendanceUI.getEmpAttendanceList(state).isCreateCommentForPlanForTheDayLoader,
    getCommentSuccess:
      attendanceUI.getEmpAttendanceList(state).isCreateCommentForPlanForTheDaySuccess,
    getApproveLoader: attendanceUI.getEmpAttendanceList(state).isApprovingPlanForTheDay,
    getApproveSuccessStatus:
      attendanceUI.getEmpAttendanceList(state).isApprovingPlanForTheDaySuccess,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    approvePlanForTheDayDispatchCall: (data: any) => dispatch(approvePlanForTheDay.request(data)),
    getCommentPlanForTheDayCall: (data: any) => dispatch(getCommentPLanForTheDay.request(data)),
    resetPlanForTheDayApprove: () => dispatch(approvePlanForTheDay.reset()),
    fetchTaskStatus: () => dispatch(getTaskStatus.request()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PlanForTheDayList)
