import {
  Box,
  IconButton,
  Modal,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  Typography,
  styled,
} from '@mui/material'
import { useState } from 'react'
import { StyledTableCell, StyledTableRow } from '../Common/CommonStyles'
import { RootState } from '../../configureStore'
import CloseIcon from '@mui/icons-material/Close'
import { attendanceEntity, dashboardEntity } from '../../reducers'
import { Dispatch } from 'redux'
import { getAttendanceDetail, getAttendanceTimesheet, managerViewData } from '../../actions'
import { connect } from 'react-redux'
import { Heading } from '../Pages/Styles'

const StyledPaper = styled(Paper)(() => ({
  width: '93%',
  padding: '2px  25px 2px 25px',
  background: '#FFFFFF',
  opacity: '1',
  marginTop: '10px',
  marginLeft: 'auto',
  marginRight: 'auto',
  marginBottom: '70px',
  border: '1px solid #DDDDDD',
}))

const DrsRcaTable = (props: any) => {
  const { ManagerViewData } = props

  return (
    <StyledPaper>
      <TableContainer sx={{ margin: '20px 0' }} component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label='customized table'>
          <TableHead>
            <StyledTableRow>
              <StyledTableCell>DATE</StyledTableCell>
              <StyledTableCell>SUBJECT</StyledTableCell>
              <StyledTableCell>MANAGER</StyledTableCell>
              <StyledTableCell>PROJECT</StyledTableCell>
            </StyledTableRow>
          </TableHead>
          {ManagerViewData?.rcaData?.length ? (
            <TableBody>
              {ManagerViewData?.rcaData?.map((data: any, index: number) => (
                <StyledTableRow
                  key={`${data?.subject}`}
                  onClick={() => props.onRowClick(data)}
                  sx={{ cursor: 'pointer' }}
                >
                  <StyledTableCell component='th' scope='row'>
                  {`${new Date(data.created_at).toLocaleDateString('en-US', {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                      })}`}
                  </StyledTableCell>
                  <StyledTableCell align='left'>{data.subject}</StyledTableCell>
                  <StyledTableCell align='left'>{data.manager_name}</StyledTableCell>
                  <StyledTableCell align='left'>
                    {data.project_name === 'Other' ? data.other_project_name : data.project_name}
                  </StyledTableCell>{' '}
                </StyledTableRow>
              ))}
            </TableBody>
          ) : (
            <TableBody>
              <StyledTableRow>
                <StyledTableCell align='center' colSpan={10}>
                  <Typography align='center' variant='subtitle1' sx={{ color: '#483f3f' }}>
                    No RCA found
                  </Typography>
                </StyledTableCell>
              </StyledTableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </StyledPaper>
  )
}

const DRsRCAList = (props: any) => {
  const { ManagerViewData } = props

  const [selectedRCA, setSelectedRCA] = useState<any | null>(null)
  const handleRowClick = (data: any) => {
    setSelectedRCA(data)
  }

  const handleCloseModal = () => {
    setSelectedRCA(null)
  }

  return (
    <>
      <DrsRcaTable ManagerViewData={ManagerViewData} onRowClick={handleRowClick} />
      <Modal open={!!selectedRCA} onClose={handleCloseModal}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '70vw',
            maxWidth: '75%',
            bgcolor: 'background.paper',
            p: 4,
            maxHeight: '80vh',
            overflowY: 'auto',
            borderRadius: '10px',
          }}
        >
          <IconButton
            aria-label='close'
            onClick={handleCloseModal}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[600],
              marginTop: '15px',
            }}
          >
            <CloseIcon />
          </IconButton>
          <Heading
            style={{
              fontSize: '18px',
              textAlign: 'center',
              fontFamily: 'sans-serif',
              letterSpacing: '0px',
              position: 'relative',
            }}
          >
            {selectedRCA?.subject}
          </Heading>
          <Typography variant='body1' style={{ fontSize: '12px' }}>
            <div dangerouslySetInnerHTML={{ __html: selectedRCA?.body }}></div>
          </Typography>
        </Box>
      </Modal>
    </>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    ManagerViewData: dashboardEntity.getDashboard(state).getManagerViewData,
    AttendanceTimesheet: attendanceEntity.getAllAttendance(state).getAttendanceTimesheet,
    getUserById: dashboardEntity.getDashboard(state).getUserDetails,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    fetchManagerViewData: (data: any) => dispatch(managerViewData.request(data)),
    fetchAttendanceTimesheet: (userId: any) => dispatch(getAttendanceTimesheet.request({ userId })),
    fetchAttendanceData: (data: any) => dispatch(getAttendanceDetail.request(data)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(DRsRCAList)
