import { call, takeLatest } from 'redux-saga/effects'
import { actionTypes } from '../actions'
import * as ApiService from '../services'
import { sendPayload, sendPayloadFailure } from './helper'
import {CREATE_PLAN_FOR_THE_DAY, STATUS_TYPE,TASK_STATUS, FETCH_PLANS_FOR_THE_DAY, FETCH_RCA, CREATE_RCA, FETCH_IDSR, CREATE_IDSR, FETCH_PROJECTS_NAME } from '../actions/actiontypes'
const { REQUEST } = actionTypes

//write sagas function

function* fetchRCAs(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.getRCAsList,
      data.userId,
    )
    yield sendPayload(apiResponse, FETCH_RCA)
  } catch (e) {
    yield sendPayloadFailure(e, FETCH_RCA)
  }
}

function* createRCA(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.createRCA,
      data.data,
    )
    yield sendPayload(apiResponse, CREATE_RCA)
  } catch (e) {
    yield sendPayloadFailure(e, CREATE_RCA)
  }
}

function* fetchIDSRs(data: any) {  
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.getIDSRsList,
      data,      
    )
    yield sendPayload(apiResponse, FETCH_IDSR)
  } catch (e) {
    yield sendPayloadFailure(e, FETCH_IDSR)
  }
}

function* createIDSR(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.createIDSR,
      data.data,
    )
    yield sendPayload(apiResponse, CREATE_IDSR)
  } catch (e) {
    yield sendPayloadFailure(e, CREATE_IDSR)
  }
}

function* createPlanForTheDay(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.createPlanForTheDay,
      data.data,
    )
    yield sendPayload(apiResponse, CREATE_PLAN_FOR_THE_DAY)
  } catch (e) {
    yield sendPayloadFailure(e, CREATE_PLAN_FOR_THE_DAY)
  }
}

function* fetchPlans(data: any) {  
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.getPlansList,
      data,      
    )
    yield sendPayload(apiResponse, FETCH_PLANS_FOR_THE_DAY)
  } catch (e) {
    yield sendPayloadFailure(e, FETCH_PLANS_FOR_THE_DAY)
  }
}

function* fetchStatusType(data: any) {  
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.getStatusType    
    )
    yield sendPayload(apiResponse, STATUS_TYPE)
  } catch (e) {
    yield sendPayloadFailure(e, STATUS_TYPE)
  }
}

function* fetchTaskStatus(data: any) {  
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.getTaskStatus    
    )
    yield sendPayload(apiResponse, TASK_STATUS)
  } catch (e) {
    yield sendPayloadFailure(e, TASK_STATUS)
  }
}

function* getProjectsName(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.getAllProjects,
      data,
    )
    yield sendPayload(apiResponse,FETCH_PROJECTS_NAME)
  } catch (e) {
    yield sendPayloadFailure(e, FETCH_PROJECTS_NAME)
  }
}



export const sagas = {
  watchFetchRCAs: takeLatest(actionTypes.FETCH_RCA[REQUEST], fetchRCAs),
  watchCreateRCA: takeLatest(actionTypes.CREATE_RCA[REQUEST], createRCA),
  watchFetchIDSRs: takeLatest(actionTypes.FETCH_IDSR[REQUEST], fetchIDSRs),
  watchcreateIDSR: takeLatest(actionTypes.CREATE_IDSR[REQUEST], createIDSR),
  watchcGetProjectsName: takeLatest(actionTypes.FETCH_PROJECTS_NAME[REQUEST],getProjectsName),
  watchFetchPlans: takeLatest(actionTypes.FETCH_PLANS_FOR_THE_DAY[REQUEST], fetchPlans),
  watchFetchStatusType : takeLatest(actionTypes.STATUS_TYPE[REQUEST],fetchStatusType),
  watchFetchTaskStatus : takeLatest(actionTypes.TASK_STATUS[REQUEST],fetchTaskStatus),
  watchcreatePlanForTheDay: takeLatest(actionTypes.CREATE_PLAN_FOR_THE_DAY[REQUEST], createPlanForTheDay),
}
