import React, { useState } from 'react'
import { Dialog, DialogTitle, IconButton, Tooltip } from '@mui/material'
import { HeaderHeadingDelayedTask } from '../Common/CommonStyles'
import CloseIcon from '@mui/icons-material/Close'
import dayjs from 'dayjs'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'

const DelayedTaskRowData = (props: any) => {
  const { open, rowData, setOpen } = props
  const [copyTooltipOpen, setCopyTooltipOpen] = useState(false)
  const [hoverTooltipOpen, setHoverTooltipOpen] = useState(false)

  const handleOnClose = () => {
    setOpen(false)
  }

  const handleCopyContent = (data: any) => {
    const stripHTML = (html: any) => {
      let temp = html.replace(/<p>/g, '\n')
      temp = temp.replace(/<\/p>/g, '\n')
      temp = temp.replace(/<br\s*\/?>/gi, '\n')
      temp = temp.replace(/<strong>/g, '*')
      temp = temp.replace(/\*\*(.*?)\*\*/g, '<b>$1</b>')
      temp = temp.replace(/<\/strong>/g, '*')
      temp = temp.replace(/<em>/g, '*')
      temp = temp.replace(/<\/em>/g, '*')
      temp = temp.replace(/<i>/g, '*').replace(/<\/i>/g, '*')
      temp = temp.replace(/<li>/g, '\n ').replace(/<\/li>/g, '')

      temp = temp.replace(/<ol>/g, '\n').replace(/<\/ol>/g, '\n')
      temp = temp.replace(/<ul>/g, '\n').replace(/<\/ul>/g, '\n')
      temp = temp.replace(/<u>/g, '__')
      temp = temp.replace(/<\/u>/g, '__')
      temp = temp.replace(/&nbsp;/g, ' ')
      temp = temp.replace(/<[^>]+>/g, '')
      temp = temp.replace(/&amp;/g, '&')
      temp = temp.replace(/&\$/g, '$')

      return temp
    }

    // Extract values from the data
    const empName = data.empName || ''
    const projectName = data.projectName || ''
    const otherProjectName = data.otherProjectName || ''
    const mandateName = data.mandateName || ''
    const priority = data.priority || ''
    const timeSpend = data.timeSpend || ''
    const taskDescription = stripHTML(data.taskDescription || '')

    // Combine the content into a formatted string
    const content = `
      Employee Name: ${empName}
      Project Name: ${projectName}
      Other Project Name: ${otherProjectName}
      Mandate Name: ${mandateName}
      Priority: ${priority}
      Time Spent: ${timeSpend} minutes
      Task Description: ${taskDescription}`

    // Copy to clipboard
    navigator.clipboard.writeText(content).then(() => {
      setCopyTooltipOpen(true)
      setTimeout(() => {
        setCopyTooltipOpen(false)
      }, 2000)
    })
  }

  // Function to strip HTML tags
  const stripHtmlTags = (html: any) => {
    // Create a temporary div element to safely manipulate the HTML content
    const tempDiv = document.createElement('div')
    tempDiv.innerHTML = html

    // Extract and return the text content, which preserves list formatting
    return tempDiv.textContent || tempDiv.innerText || ''
  }

  return (
    <Dialog
      open={open}
      onClose={handleOnClose}
      sx={{
        '& .MuiDialog-paper': {
          width: '80%', // Custom width as a percentage or pixels
          maxWidth: '900px', // Optional: max width in pixels
          padding: '10px 25px 25px 25px',
        },
      }}
    >
      <DialogTitle sx={{ m: 0, p: 2 }}>
        <HeaderHeadingDelayedTask>{`Delayed Task Descripton (${dayjs(rowData?.Date)
          .format('DD/MM/YYYY')
          .toString()})`}</HeaderHeadingDelayedTask>

        <Tooltip
          title={copyTooltipOpen ? 'Copied!' : 'Copy to Clipboard'}
          open={hoverTooltipOpen || copyTooltipOpen}
          arrow
        >
          <IconButton
            aria-label='copy'
            onClick={() => handleCopyContent(rowData)}
            onMouseEnter={() => setHoverTooltipOpen(true)}
            onMouseLeave={() => setHoverTooltipOpen(false)}
            sx={{
              position: 'absolute',
              right: 50,
              top: 22,
              zIndex: 999,
            }}
          >
            <ContentCopyIcon />
          </IconButton>
        </Tooltip>
        <IconButton
          aria-label='close'
          onClick={handleOnClose}
          sx={{
            position: 'absolute',
            right: 15,
            top: 22,
            zIndex: 999,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <table
        style={{
          width: '100%',
          borderCollapse: 'collapse',
          textAlign: 'left',
          padding: '10px',
        }}
      >
        <thead>
          <tr>
            <th
              style={{
                border: '1px solid #dddddd',
                padding: '8px',
                backgroundColor: '#f2f2f2',
                whiteSpace: 'nowrap',
                fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
                fontSize: '12px',
              }}
            >
              <strong>Emp Name</strong>
            </th>
            <th
              style={{
                border: '1px solid #dddddd',
                padding: '8px',
                backgroundColor: '#f2f2f2',
                whiteSpace: 'nowrap',
                fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
                fontSize: '12px',
              }}
            >
              <strong>Project Name</strong>
            </th>
            <th
              style={{
                border: '1px solid #dddddd',
                padding: '8px',
                backgroundColor: '#f2f2f2',
                whiteSpace: 'nowrap',
                fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
                fontSize: '12px',
              }}
            >
              <strong>Mandate Type</strong>
            </th>
            <th
              style={{
                border: '1px solid #dddddd',
                padding: '8px',
                backgroundColor: '#f2f2f2',
                whiteSpace: 'nowrap',
                fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
                fontSize: '12px',
              }}
            >
              <strong>Task Description</strong>
            </th>
            <th
              style={{
                border: '1px solid #dddddd',
                padding: '8px',
                backgroundColor: '#f2f2f2',
                whiteSpace: 'nowrap',
                fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
                fontSize: '12px',
              }}
            >
              <strong>Priority</strong>
            </th>
            <th
              style={{
                border: '1px solid #dddddd',
                padding: '8px',
                backgroundColor: '#f2f2f2',
                whiteSpace: 'nowrap',
                fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
                fontSize: '12px',
              }}
            >
              <strong>Time Spent (mins)</strong>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td
              style={{
                border: '1px solid #dddddd',
                padding: '8px',
                fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
                fontSize: '12px',
              }}
            >
              {rowData?.empName || 'NA'}
            </td>
            <td
              style={{
                border: '1px solid #dddddd',
                padding: '8px',
                fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
                fontSize: '12px',
              }}
            >
              {rowData?.projectName || 'NA'}
            </td>
            <td
              style={{
                border: '1px solid #dddddd',
                padding: '8px',
                fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
                fontSize: '12px',
              }}
            >
              {rowData?.mandateName || 'NA'}
            </td>
            <td
              style={{
                border: '1px solid #dddddd',
                padding: '8px',
                fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
                fontSize: '12px',
              }}
            >
              {stripHtmlTags(rowData?.taskDescription) || 'NA'}
            </td>
            <td
              style={{
                border: '1px solid #dddddd',
                padding: '8px',
                fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
                fontSize: '12px',
              }}
            >
              {rowData?.priority || 'NA'}
            </td>
            <td
              style={{
                border: '1px solid #dddddd',
                padding: '8px',
                fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
                fontSize: '12px',
              }}
            >
              {rowData?.timeSpend || 'NA'}
            </td>
          </tr>
        </tbody>
      </table>
    </Dialog>
  )
}

export default DelayedTaskRowData
