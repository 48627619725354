import { Box, Typography } from "@mui/material"
import { ReactComponent as NoItemFoundIcon } from "../../../../assets/images/NoItemIcon.svg";
const style = {
    typographyText: {
        fontFamily: 'Montserrat-Medium',
        fontSize: '0.9rem',
        color: '#444444',
        marginLeft: '0.5rem',
    }
}
const NoProjectRow = (props: { value: string }) => {
    return (
        <Box
            border='1px solid #EEEEEE'
            width='100%'
            height='60px'
            display='flex'
            alignItems='center'
            justifyContent='center'
            margin= '10px 0'
            boxSizing={'border-box'}
        >
            <NoItemFoundIcon />
            <Typography sx={style.typographyText}
            >
               No project found for selected date range
            </Typography>
        </Box>
    )
}
export default NoProjectRow;
