import React, { useState, useEffect } from 'react'
import { styled } from '@mui/material/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import Paper from '@mui/material/Paper'
import { Box, CircularProgress, Dialog, TextField, Typography } from '@mui/material'
import {
  HeaderHeading,
  SearchBoxCustom,
  SearchIconStyle,
  StyledMenuItem,
  StyledTableCell,
  StyledTableRow,
  loaderProps,
} from '../Common/CommonStyles'
import { Dispatch } from 'redux'
import { RootState } from '../../configureStore'
import { connect } from 'react-redux'
import { fetchHolidays } from '../../actions'
import { dashboardEntity, dashboardUI } from '../../reducers'
import { useNavigate } from 'react-router-dom'
import { ArrowBack } from '@mui/icons-material'

const StyledPaper = styled(Paper)(({ theme }) => ({
  width: '93%',
  padding: '25px 25px',
  background: '#FFFFFF',
  opacity: '1',
  margin: '20px',
  border: '1px solid #DDDDDD',
}))

const MainContainer = {
  backgroundColor: 'rgb(231, 235, 240)',
  width: '100%',
}

const HolidayListTableData = ({ data }: { data: any[] }) => {
  const showYears = data
    .map((value, i) => new Date(value.date).getFullYear())
    .filter((val, id, array) => {
      return array.indexOf(val) == id
    })
  const [searchQuery, setSearchQuery] = useState('')
  const [selectedYear, setSelectedYear] = useState<string>(
    showYears[0] ? showYears[0].toString() : '',
  )
  const [holidayList, setHolidayList] = useState<any[]>(
    data?.length
      ? data.filter((row) =>
          String(new Date(row.date).getFullYear()).toLowerCase().includes(showYears[0].toString()),
        )
      : [],
  )

  useEffect(() => {
    if (searchQuery.trim()) {
      const filtered = holidayList.filter((row) =>
        Object.values(row).some((value) =>
          String(value).toLowerCase().includes(searchQuery.toLowerCase()),
        ),
      )
      setHolidayList(filtered)
    } else {
      const filtered = data.filter((row) =>
        String(new Date(row.date).getFullYear()).toLowerCase().includes(selectedYear),
      )
      setHolidayList(filtered)
    }
  }, [searchQuery, holidayList, selectedYear])

  const navigate = useNavigate()

  const sortedHolidayList = holidayList.slice().sort((a, b) => {
    const dateA = new Date(a.date);
    const dateB = new Date(b.date);
  
    if (dateA < dateB) return -1;
    if (dateA > dateB) return 1;
    return 0;
  });

  return (
    <>
      <Box sx={{ textAlign: 'left' }}>
        <Box
          onClick={() => navigate(-1)}
          sx={{ float: 'right', pr: '30px', mt: '0px', cursor: 'pointer' }}
        >
          <ArrowBack />
        </Box>
      </Box>
      <HeaderHeading>Holiday List</HeaderHeading>

      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <SearchBoxCustom
          id='outlined-basic'
          placeholder='Search'
          variant='outlined'
          size='small'
          fullWidth
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          InputProps={{
            startAdornment: <SearchIconStyle />,
          }}
        />

        <Box sx={{ width: '150px' }}>
          <TextField
            select
            id='year'
            name='year'
            size='small'
            variant='outlined'
            label='Year'
            InputLabelProps={{ required: false }}
            value={selectedYear}
            onChange={(e) => setSelectedYear(e.target.value)}
          >
            {showYears.map((year, i) => (
              <StyledMenuItem key={year} value={year}>
                {year}
              </StyledMenuItem>
            ))}
          </TextField>
        </Box>
      </Box>

      <TableContainer component={Paper} sx={{}}>
        <Table
          sx={{
            minWidth: 700,
          }}
          aria-label='customized table'
        >
          <TableHead>
            <StyledTableRow>
              <StyledTableCell>S.NO.</StyledTableCell>
              <StyledTableCell>HOLIDAY NAME</StyledTableCell>
              <StyledTableCell>DESCRIPTION</StyledTableCell>
              <StyledTableCell>DAY</StyledTableCell>
              <StyledTableCell>DATE</StyledTableCell>
            </StyledTableRow>
          </TableHead>
          {sortedHolidayList && sortedHolidayList?.length > 0 ? (
            <TableBody>
              {sortedHolidayList.map((holiday, index: number) => (
                <StyledTableRow key={holiday?.id}>
                  <StyledTableCell>{index + 1}</StyledTableCell>
                  <StyledTableCell>{holiday.name}</StyledTableCell>
                  <StyledTableCell>{holiday.desc}</StyledTableCell>
                  <StyledTableCell>
                    {new Date(holiday.date).toLocaleDateString('en-GB', {
                      weekday: 'long',
                    })}
                  </StyledTableCell>
                  <StyledTableCell>
                    {new Date(holiday.date).toLocaleDateString('en-GB')}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          ) : (
            <TableBody>
              <StyledTableRow>
                <StyledTableCell align='center' colSpan={10}>
                  <Typography variant='subtitle1' sx={{ color: '#483f3f', textAlign: 'center' }}>
                    No matching records found
                  </Typography>
                </StyledTableCell>
              </StyledTableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </>
  )
}

const HolidayList = (props: any) => {
  const { fetchHolidaysData, HolidayData, loaderState } = props

  useEffect(() => {
    fetchHolidaysData()
  }, [])

  return (
    <>
      {loaderState && (
        <Dialog open={loaderState} PaperProps={{ style: loaderProps }}>
          <CircularProgress color='secondary' />
        </Dialog>
      )}
      {!loaderState && HolidayData && HolidayData?.length && (
        <div style={MainContainer}>
          <StyledPaper style={{ minHeight: '70vh' }}>
            <HolidayListTableData data={HolidayData} />
          </StyledPaper>
        </div>
      )}
    </>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    HolidayData: dashboardEntity.getDashboard(state).getHolidaysData,
    loaderState: dashboardUI.getDashboard(state).isHolidaysData,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    fetchHolidaysData: () => dispatch(fetchHolidays.request()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HolidayList)
