import {
  Box,
  Paper,
  styled,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import TableRowContainer from './TableRowContainer'
import { style } from './SheetStyles'
import { StyledTableCell } from '../../Common/CommonStyles'


const StyledTableRow = styled(TableRow)(({ theme }) => ({
  left: '160px',
  width: '1719px',
  height: '60px',
  boxShadow: '0px 10px 3px #6c6c6c10',
  opacity: '1',
}))

const getColumnData = [
  { id: "1", label: "PROJECT NAME" },
  { id: "2", label: "PROJECT CUSTOMER" },
  { id: "3", label: "PROJECT SOURCE" },
  { id: "4", label: "PROJECT DOMAIN" },
  { id: "5", label: "PROJECT LOCATION" },
  { id: "6", label: "PROJECT TYPE" },
  { id: "7", label: "PROJECT STATUS" },
  { id: "8", label: "START DATE" },
  { id: "9", label: "END DATE" },
  { id: "10", label: "MEMBER" },
  { id: "11", label: "REVIEWER 1" },
  { id: "12", label: "REVIEWER 2" },
  // { id: "13", label: "BILLABLE DESIGNER" },
  // { id: "14", label: "BILLABLE LEAD" },
  // { id: "15", label: "BILLABLE REVIEWER" },
  { id: "16", label: "TOTAL BILLABLE" }
];

const styles = {
  backNavigate: {
    position: 'absolute',
    top: 80,
    right: 25,
    margin: '20px',
    cursor: 'pointer',
  },
  styledCells: {
    padding: '6px 6px',
  },
  typoIntablehead: {
    margin: '0',
    padding: '6px 2px',
    width: '160px'
  },
}


const getWidth = (index: number) => {
  switch(index) {
    case 3:
    case 4:
    case 7:
    case 10:
    case 12:
      return '50px';
    case 6:
    case 9:
      return '50px';
    case 8:
      return '50px';
    case 11:
      return '60px';
    default:
      return '140px';
  }
};


const TableLayout = (props: { rowsToDisplay: Object[] }) => {
  const { rowsToDisplay } = props

  return (
    <Box overflow='scroll' width= '100%' margin='20px  0px' sx={style.table}>
      <TableContainer component={Paper} id='export' sx={style.tableContainer}>
        <Table>
          <TableHead id='head'>
            <StyledTableRow sx={style.border}>
              {/* {getColumnData?.map((column: any) => (
                <StyledTableCell key={column.id} sx={style.tableHeadWidth}>
                  {column.label}
                </StyledTableCell>
              ))} */}
              {getColumnData?.map((data: any, index: any) => (
                <StyledTableCell sx={styles.styledCells}>
                  <Typography
                    width={getWidth(index)}
                    fontSize={'0.800rem'}
                    fontFamily={'Montserrat-Medium'}
                    sx={styles.typoIntablehead}
                  >
                    {data.label}
                  </Typography>
                </StyledTableCell>
              ))}
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {rowsToDisplay?.map((row, idx) => (
              <TableRowContainer key={idx} row={row} idx={idx} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

export default TableLayout
