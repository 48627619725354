import { ACCTypes } from './Types'
export const REQUEST = 'REQUEST'
export const SUCCESS = 'SUCCESS'
export const FAILURE = 'FAILURE'
export const RESET = 'RESET'

// This function will return Action Type object
export const createRequestTypes = (base: string) => {
  return [REQUEST, SUCCESS, FAILURE, RESET].reduce((acc: ACCTypes, type) => {
    acc[type] = `${base}_${type}`
    return acc
  }, {})
}

export const action = (type: string, payload = {}) => {
  return { type, ...payload }
}

export const GET_USER_DATA = createRequestTypes('GET_USER_DATA')

export const LOGIN = createRequestTypes('LOGIN')

export const LOGOUT = createRequestTypes('LOGOUT')

export const GET_ACCESS_TOKEN = createRequestTypes('GET_ACCESS_TOKEN')

export const LOGIN_USER = createRequestTypes('LOGIN_USER')

export const FETCH_USERS = createRequestTypes('FETCH_USERS')

export const FETCH_BACKINFO = createRequestTypes('FETCH_BACKINFO')

export const FETCH_HOLIDAYS = createRequestTypes('FETCH_HOLIDAYS')

export const FETCH_ASSET = createRequestTypes('FETCH_ASSET')

export const FETCH_LOAN_INSTALLMENT_DATA = createRequestTypes('FETCH_LOAN_INSTALLMENT_DATA')

export const FETCH_DRS_DATA = createRequestTypes('FETCH_DRS_DATA')

export const FETCH_SUB_DRS_DATA = createRequestTypes('FETCH_SUB_DRS_DATA')

export const FETCH_ASSIGNED_TASK_DRS = createRequestTypes('FETCH_ASSIGNED_TASK_DRS')

export const FETCH_EMPINFO = createRequestTypes('FETCH_EMPINFO')

export const FETCH_DESIGNATION = createRequestTypes('FETCH_DESIGNATION')

export const FETCH_DESIGNATIONBAND = createRequestTypes('FETCH_DESIGNATIONBAND')

export const FETCH_EMPLOYMENTTYPE = createRequestTypes('FETCH_EMPLOYMENTTYPE')

export const FETCH_SAPERIOD = createRequestTypes('FETCH_SAPERIOD')

export const FETCH_TIMING = createRequestTypes('FETCH_TIMING')

export const FETCH_BASICINFO = createRequestTypes('FETCH_BASICINFO')

export const FETCH_SR = createRequestTypes('FETCH_SR')

export const FETCH_ASSIGNED_SR = createRequestTypes('FETCH_ASSIGNED_SR')


export const FETCH_BANKINFO = createRequestTypes('FETCH_BANKINFO')

export const FETCH_STATE = createRequestTypes('FETCH_STATE')

export const FETCH_COUNTRY = createRequestTypes('FETCH_COUNTRY')

export const FETCH_ACCOUNTTYPE = createRequestTypes('FETCH_ACCOUNTTYPE')

export const FETCH_MARITIAL_STATUS = createRequestTypes('FETCH_MARITIAL_STATUS')

export const FETCH_LEAVE_DETAILS = createRequestTypes('FETCH_LEAVE_DETAILS')

export const FETCH_QUALIFICATION = createRequestTypes('FETCH_QUALIFICATION')

export const FETCH_QUALIFICATION_SKILL = createRequestTypes('FETCH_QUALIFICATION_SKILL')

export const CREATE_SERVICE_REQUEST = createRequestTypes('CREATE_SERVICE_REQUEST')

export const FETCH_ISSUE_TYPE_LIST = createRequestTypes('FETCH_ISSUE_TYPE_LIST')

export const FETCH_USER_BY_ID = createRequestTypes('FETCH_USER_BY_ID')

export const FETCH_REF_BY_ID = createRequestTypes('FETCH_REF_BY_ID')

export const FETCH_COMPENSATION_DETAILS = createRequestTypes('FETCH_COMPENSATION_DETAILS')

export const FETCH_LOAN_DETAILS = createRequestTypes('FETCH_LOAN_DETAILS')

export const FETCH_DEPARTMENT_LIST = createRequestTypes('FETCH_DEPARTMENT_LIST')

export const SEND_LOAN_REQUEST = createRequestTypes('SEND_LOAN_REQUEST')

// export const SERVICE_REQUEST = createRequestTypes('SERVICE_REQUEST')

export const STATUS_SUMMARY = createRequestTypes('STATUS_SUMMARY')

export const USER_INFO = createRequestTypes('USER_INFO')

export const HOME_PAGE_INFO = createRequestTypes('HOME_PAGE_INFO')

export const FETCH_EMPLOYEE_DETAILS = createRequestTypes('FETCH_EMPLOYEE_DETAILS')

export const ADD_NEW_COMMENT = createRequestTypes('ADD_NEW_COMMENT')

export const GET_TIMESHEET = createRequestTypes('GET_TIMESHEET')

export const GET_LEAVE_TIMESHEET = createRequestTypes('GET_LEAVE_TIMESHEET')

export const GET_PAY_SLIP = createRequestTypes('GET_PAY_SLIP')

export const GET_LEAVE_DATA = createRequestTypes('GET_LEAVE_DATA')

export const ACCEPT_LEAVE_DATA = createRequestTypes('ACCEPT_LEAVE_DATA')

export const REJECT_LEAVE_DATA = createRequestTypes('REJECT_LEAVE_DATA')

export const FETCH_PROJECT_DATA = createRequestTypes('FETCH_PROJECT_DATA')

export const FETCH_HIGHLIGHTS = createRequestTypes('FETCH_HIGHLIGHTS')

export const FETCH_ATTENDANCE = createRequestTypes('FETCH_ATTENDANCE')

export const FETCH_ATTENDANCE_TIMESHEET = createRequestTypes('FETCH_ATTENDANCE_TIMESHEET')

export const FETCH_RCA = createRequestTypes('FETCH_RCA')

export const FETCH_IDSR = createRequestTypes('FETCH_IDSR')

export const FETCH_PLANS_FOR_THE_DAY = createRequestTypes('FETCH_PLANS_FOR_THE_DAY')

export const STATUS_TYPE = createRequestTypes('STATUS_TYPE')

export const TASK_STATUS = createRequestTypes('TASK_STATUS')

export const CREATE_RCA = createRequestTypes('CREATE_RCA')

export const CREATE_IDSR = createRequestTypes('CREATE_IDSR')

export const CREATE_PLAN_FOR_THE_DAY = createRequestTypes('CREATE_PLAN_FOR_THE_DAY')

export const FETCH_ASSIGNED_REQUEST = createRequestTypes('FETCH_ASSIGNED_REQUEST')

export const FETCH_UPDATED_SERVICE_REQUEST = createRequestTypes('FETCH_UPDATED_SERVICE_REQUEST')

export const FETCH_DOWNLOADABLE_URL = createRequestTypes('FETCH_DOWNLOADABLE_URL')

export const MANAGER_VIEW_DATA = createRequestTypes('MANAGER_VIEW_DATA')

export const FETCH_DOC_URL = createRequestTypes('FETCH_DOC_URL')

export const FETCH_PROJECTS_NAME = createRequestTypes ('FETCH_PROJECTS_NAME')

export const PROJECT_GRAPH_DATA = createRequestTypes ('PROJECT_GRAPH_DATA')

export const DESIGNATION_GRAPH_DATA = createRequestTypes('DESIGNATION_GRAPH_DATA')

export const DESIGNATION_LIST = createRequestTypes('DESIGNATION_LIST')

export const FETCH_PROJECT_TYPES = createRequestTypes ('FETCH_PROJECT_TYPES')

export const FETCH_PROJECT_DOMAIN = createRequestTypes ('FETCH_PROJECT_DOMAIN')

export const FETCH_PROJECT_SOURCE = createRequestTypes('FETCH_PROJECT_SOURCE')

export const FETCH_TECHNOLOGIES  = createRequestTypes ('FETCH_TECHNOLOGIES')

export const FETCH_PROJECT_LOCATION = createRequestTypes ('FETCH_PROJECT_LOCATION')

export const ADD_NEW_PROJECT = createRequestTypes ('ADD_NEW_PROJECT')

export const DELETE_PROJECT = createRequestTypes ('DELETE_PROJECT')

export const FETCH_PROJECT_CUSTOMERS = createRequestTypes ('FETCH_PROJECT_CUSTOMERS')

export const FETCH_MANDATE_TYPE = createRequestTypes ('FETCH_MANDATE_TYPE')

export const RESTART_PROJECT = createRequestTypes ('RESTART_PROJECT')

export const FETCH_PROJECT_DETAILS = createRequestTypes ('FETCH_PROJECT_DETAILS')

export const FETCH_WORKING_EMP =createRequestTypes ('FETCH_WORKING_EMP')

export const ADD_EMP_BASED_ON_ROLE = createRequestTypes ('ADD_EMP_BASED_ON_ROLE')

export const UPDATE_PROJECT = createRequestTypes ('UPDATE_PROJECT')

export const DELETE_EMP_BASED_ON_ROLE = createRequestTypes ('DELETE_EMP_BASED_ON_ROLE')

export const EDIT_PROJECT = createRequestTypes ('EDIT_PROJECT')

export const ORGANIZATION_DESIGNATION = createRequestTypes ('ORGANIZATION_DESIGNATION')

export const ORGANISATION_DESIGNATION_DATA = createRequestTypes ('ORGANISATION_DESIGNATION_DATA')

export const FETCH_PROJECT_REPORTS = createRequestTypes('FETCH_PROJECT_REPORTS')

export const FETCH_REPORTS = createRequestTypes('FETCH_REPORTS')

export const FETCH_ALL_PROJECT_RESOURCE_REPORT = createRequestTypes('FETCH_ALL_PROJECT_RESOURCE_REPORT')

export const PROJECT_RESOURCE_REPORT_DROPDOWN = createRequestTypes ('PROJECT_RESOURCE_REPORT_DROPDOWN')

export const FETCH_PROJECT_QA_REPORT = createRequestTypes('FETCH_PROJECT_QA_REPORT')

export const FETCH_PROJECT_CUSTOMERS_LIST=createRequestTypes('FETCH_PROJECT_CUSTOMERS_LIST')

export const CREATE_PROJECT_CUSTOMERS  = createRequestTypes('CREATE_PROJECT_CUSTOMERS')

export const CREATE_MANDATE_TYPE  = createRequestTypes('CREATE_MANDATE_TYPE')

export const UPDATE_MANDATE_TYPE = createRequestTypes('UPDATE_MANDATE_TYPE')

export const DELETE_MANDATE_TYPE = createRequestTypes('DELETE_MANDATE_TYPE')

export const DELETE_PROJECT_CUSTOMER = createRequestTypes('DELETE_PROJECT_CUSTOMER')

export const UPDATE_PROJECT_CUSTOMER = createRequestTypes('UPDATE_PROJECT_CUSTOMER')

export const FETCH_NON_BILLABLE_RESOURCES = createRequestTypes('FETCH_NON_BILLABLE_RESOURCES')

export const UPDATE_NON_BILLABLE_RESOURCES = createRequestTypes('UPDATE_NON_BILLABLE_RESOURCES')

export const FETCH_PROJECT_MANAGEMENT_REPORT = createRequestTypes('FETCH_PROJECT_MANAGEMENT_REPORT')

export const PEOPLE_BASED_ORG_CHART_DATA =  createRequestTypes('PEOPLE_BASED_ORG_CHART_DATA')

export const DEPARTMENT_BASED_ORG_CHART_DATA = createRequestTypes('DEPARTMENT_BASED_ORG_CHART_DATA')

export const DESIGNATION_BASED_ORG_CHART_DATA = createRequestTypes('DESIGNATION_BASED_ORG_CHART_DATA')

export const EMPLOYEE_HISTORY = createRequestTypes('EMP_HISTORY')

export const MANAGER_HISTORY = createRequestTypes('MANAGER_HISTORY')

export const  COMPANY_LOGO = createRequestTypes('COMPANY_LOGO')

export const  COMPANY_ID = createRequestTypes('COMPANY_ID')

export const UPLOAD_USER_IMG = createRequestTypes('UPLOAD_USER_IMG')

export const CREATE_USER = createRequestTypes('CREATE_USER')

export const UPDATE_USER = createRequestTypes('UPDATE_USER')

export const FETCH_ALL_ROLE = createRequestTypes('FETCH_ALL_ROLE')

export const FETCH_ALL_STATE = createRequestTypes('FETCH_ALL_STATE')

export const FETCH_ALL_COUNTRY = createRequestTypes('FETCH_ALL_COUNTRY')

export const FETCH_ALL_FLOOR = createRequestTypes('FETCH_ALL_FLOOR')

export const FETCH_ALL_WORKSTATION = createRequestTypes('FETCH_ALL_WORKSTATION')

export const FETCH_ALL_LOCATION = createRequestTypes('FETCH_ALL_LOCATION')

export const FETCH_ALL_CLIENT_LOCATION = createRequestTypes('FETCH_ALL_CLIENT_LOCATION')

export const FETCH_ALL_USER_LIST = createRequestTypes('FETCH_ALL_USER_LIST')
 
export const GET_USER_IMAGE = createRequestTypes('GET_USER_IMAGE')

export const DELETE_USER = createRequestTypes('DELETE_USER')

export const APPROVE_PLANFORTHEDAY = createRequestTypes('APPROVE_TIMESHEET')

export const ADD_COMMENT_TIMESHEET = createRequestTypes('ADD_COMMENT_TIMESHEET')

export const ADD_COMMENT_PLANFORTHEDAY = createRequestTypes('ADD_COMMENT_PLANFORTHEDAY')

export const GET_COMMENT_PLANFORTHEDAY = createRequestTypes('GET_COMMENT_PLANFORTHEDAY')

export const GET_COMMENT_TIMESHEET = createRequestTypes('GET_COMMENT_TIMESHEET')

export const GET_PLANFORTHEDAY = createRequestTypes('GET_PLANFORTHEDAY')

export const EDIT_PLANFORTHEDAY = createRequestTypes('EDIT_PLANFORTHEDAY')

export const DOWNLOAD_CSV_FOR_MY_TEAM = createRequestTypes('DOWNLOAD_CSV_FOR_MY_TEAM')

export const GET_SINGLE_IDSR = createRequestTypes('GET_SINGLE_IDSR')

export const EDIT_SINGLE_IDSR = createRequestTypes('EDIT_SINGLE_IDSR')

export const PAYROLL_FORM16 = createRequestTypes('PAYROLL_FORM16')

export const UPLOAD_FORM16 = createRequestTypes('UPLOAD_FORM16')

export const DOWNLOAD_FORM16 = createRequestTypes('DOWNLOAD_FORM16')

export const GET_FINANCIAL_YEAR = createRequestTypes('GET_FINANCIAL_YEAR')

export const SEND_EMAIL = createRequestTypes('SEND_EMAIL')

export const GET_CLIENT_MEMBER = createRequestTypes('GET_CLIENT_MEMBER')

export const GET_TEAM_MANDATES = createRequestTypes('GET_TEAM_MANDATES')

export const GET_DELAYED_GRAPH = createRequestTypes('GET_DELAYED_GRAPH')

export const GET_DELAYED_TABLE = createRequestTypes('GET_DELAYED_TABLE')

export const GET_IDSR_RECENT = createRequestTypes('GET_IDSR_RECENT')

export const GET_TASK_STATUS = createRequestTypes('GET_TASK_STATUS')

export const GET_TODAY_TASK = createRequestTypes('GET_TODAY_TASK')

export const MARK_TO_REVIEWS = createRequestTypes('MARK_TO_REVIEWS')

export const MARK_AS_RESOLVED = createRequestTypes('MARK_AS_RESOLVED')

export const GET_FOLLOW_UP_TASK = createRequestTypes('GET_FOLLOW_UP_TASK')

export const ASSIGNED_TASK = createRequestTypes('ASSIGNED_TASK')

export const ASSIGNED_TASK_MY_TEAM = createRequestTypes('ASSIGNED_TASK_MY_TEAM')

export const EMP_TENURE = createRequestTypes('EMP_TENURE')
