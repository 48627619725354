import { Table, TableBody, TableHead, Tooltip } from '@mui/material'
import React from 'react'
import NoDataFound from './NoDataFound'
import { StyledEditableTableCell, StyledTableRow } from '../../Common/CommonStyles'
import { style } from '../../Report/SheetStyles'
import { htmlToText } from '../../Common/HtmlToText'

const MyTaskTableContainer = (props: any) => {
  const { tableData, tableHeader } = props
  const handleRowData = (rowData: any) => {}
  return (
    <>
      <Table>
        <TableHead id='head'>
          <StyledTableRow sx={style.border}>
            {tableHeader?.length &&
              tableHeader?.map((header: any, index: any) => (
                <StyledEditableTableCell key={index}> {header}</StyledEditableTableCell>
              ))}
          </StyledTableRow>
        </TableHead>
        <TableBody>
          {tableData?.length > 0 ? (
            tableData?.map((row: any, index: any) => (
              <StyledTableRow
                id='body'
                key={index}
                sx={style.border}
                onClick={() => handleRowData(row)}
              >
                <StyledEditableTableCell width='150px'>
                  {' '}
                  {`${new Date(row.submission_time).toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                  })}`}{' '}
                  (
                  {`${new Date(row.submission_time).toLocaleDateString('en-GB', {
                    weekday: 'long',
                  })}`}
                  )
                </StyledEditableTableCell>
                <StyledEditableTableCell width='150px'>demo</StyledEditableTableCell>

                <StyledEditableTableCell width='150px'>
                  {row.projectName ? row.projectName : row.otherProjectName}
                </StyledEditableTableCell>

                <StyledEditableTableCell width='150px'>
                  {' '}
                  <Tooltip
                    title={row.taskDescription ? htmlToText(row.taskDescription) : 'NA'}
                    arrow
                  >
                    <span >
                      {row.taskDescription
                        ? htmlToText(row.taskDescription).length > 35
                          ? `${htmlToText(row.taskDescription).substring(0, 35)}...`
                          : htmlToText(row.taskDescription)
                        : 'NA'}
                    </span>
                  </Tooltip>
                </StyledEditableTableCell>
              </StyledTableRow>
            ))
          ) : (
            <NoDataFound />
          )}
        </TableBody>
      </Table>
    </>
  )
}

export default MyTaskTableContainer
