import { combineReducers } from 'redux'
import { actionTypes } from '../../actions'
import { Actions } from '../../actions/Types'
import { RootState } from '../../configureStore'

const { SUCCESS, REQUEST, FAILURE, LOGIN } = actionTypes

const ui = () => {
  const isUserAuthenticationDone = (state = false, action: Actions) => {
    switch (action.type) {
      case LOGIN[SUCCESS]:
        return true
      case LOGIN[FAILURE]:
      case LOGIN[REQUEST]:
        return false
      default:
        return state
    }
  }

  const isUserAuthenticationFailed = (state = false, action: Actions) => {
    switch (action.type) {
      case LOGIN[SUCCESS]:
      case LOGIN[REQUEST]:
        return false
      case LOGIN[FAILURE]:
        return true
      default:
        return state
    }
  }

  return combineReducers({
    isUserAuthenticationDone,
    isUserAuthenticationFailed,
  })
}

export default ui

export const getUserAuthentication = (state: RootState) => state.ui.userAuthentication
