import React, { useState, useEffect } from 'react'
import FilteredItemList from './FilteredItemList'
import { Button, Grid, Box, CircularProgress } from '@mui/material'
import { RootState } from '../../../configureStore'
import { projectManagementEntity, projectManagementUI } from '../../../reducers'
import { Dispatch } from '@reduxjs/toolkit'
import { updateNonBillableResource, fetchNonBillableResources } from '../../../actions'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import { style } from './NonBillabelStyle'
import Alphabet from './Alphabet'
import { NonBillableDataType, NonBillableProps } from './NonBillableResourcesTypes'
import SearchBox from './SearchBox'

const NonBillableResources = ({
  fetchNonBillableResources,
  updateNonBillableResources,
  nonBillableResourceData,
  isGetNonBillableData,
  isUpdatedNonBillableResources,
}: NonBillableProps) => {
  const [nonBillableData, setNonBillableData] = useState<NonBillableDataType[]>(
    nonBillableResourceData.response ?? [],
  )
  const [filteredData, setFilteredData] = useState<NonBillableDataType[]>(
    nonBillableResourceData?.response ?? [],
  )
  const [selectedLetter, setSelectedLetter] = useState<string>('All')
  const [hasChanges, setHasChanges] = useState<boolean>(false)
  const [searchQuery, setSearchQuery] = useState('')
  const [searchedData, setSearchedData] = useState<NonBillableDataType[]>([])

  useEffect(() => {
    fetchNonBillableResources()
  }, [])

  useEffect(()=>{
    setSelectedLetter('All')
  },[searchQuery])

  useEffect(() => {
    if (isUpdatedNonBillableResources) {
      fetchNonBillableResources()
    }
  }, [isUpdatedNonBillableResources])

  useEffect(() => {
    setNonBillableData(nonBillableResourceData.response)
    if (nonBillableResourceData && nonBillableResourceData.response) {
      let filtered: NonBillableDataType[] = []
      if (selectedLetter === 'All') {
        filtered = nonBillableResourceData?.response
      } else {
        filtered = nonBillableResourceData?.response?.filter((item: NonBillableDataType) =>
          item?.name?.toLowerCase().startsWith(selectedLetter?.toLowerCase()),
        )
      }
      setFilteredData(filtered)
    }
  }, [nonBillableResourceData])

  const checkForChanges = (updatedData: NonBillableDataType[]) => {
    return updatedData.some((item) => {
      const originalItem = nonBillableResourceData.response.find((orig) => orig.id === item.id)
      return originalItem && originalItem.checked !== item.checked
    })
  }

  const handleCheckboxChange = (id: number) => {
    const updatedFilteredItems = nonBillableData?.map((item: NonBillableDataType) => {
      if (item.id === id) {
        return { ...item, checked: !item.checked }
      }
      return item
    })
    setNonBillableData(updatedFilteredItems)
    const updatedFilteredItems1 = filteredData?.map((item: NonBillableDataType) => {
      if (item.id === id) {
        return { ...item, checked: !item.checked }
      }
      return item
    })
    setFilteredData(updatedFilteredItems1)
    const updatedFilteredItems2 = searchedData?.map((item: NonBillableDataType) => {
      if (item.id === id) {
        return { ...item, checked: !item.checked }
      }
      return item
    })
    setSearchedData(updatedFilteredItems2)

    const hasChanges = checkForChanges(updatedFilteredItems)
    setHasChanges(hasChanges)
  }

  const handleSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (selectedLetter === 'All') {
      const checked = !(
        nonBillableData?.length > 0 && nonBillableData.every((item) => item.checked)
      )
      const selectedAllParentData = nonBillableData?.map((item: NonBillableDataType) => ({
        ...item,
        checked: checked,
      }))
      setNonBillableData(selectedAllParentData)
      const hasChanges = checkForChanges(selectedAllParentData)
      setHasChanges(hasChanges)
    } else {
      if (searchQuery === '') {
        const checked = !(filteredData.length > 0 && filteredData.every((item) => item.checked))
        const selectedAllFilteredData = filteredData?.map((item) => ({
          ...item,
          checked: checked,
        }))
        setFilteredData(selectedAllFilteredData)
        const updatedParent = nonBillableData?.map((parentItem) => {
          const filteredItem = filteredData?.find(
            (filteredItem) => filteredItem.id === parentItem.id,
          )
          if (filteredItem) {
            return { ...parentItem, checked: checked }
          }
          return parentItem
        })
        setNonBillableData(updatedParent)
        const hasChanges = checkForChanges(updatedParent)
        setHasChanges(hasChanges)
      } else {
        const checked = !(searchedData.length > 0 && searchedData.every((item) => item.checked))
        const selectedAllSearchedData = searchedData?.map((item) => ({
          ...item,
          checked: checked,
        }))
        setSearchedData(selectedAllSearchedData)
        const updatedParent = nonBillableData?.map((parentItem) => {
          const filteredItem = searchedData.find(
            (filteredItem) => filteredItem.id === parentItem.id,
          )
          if (filteredItem) {
            return { ...parentItem, checked: checked }
          }
          return parentItem
        })
        setNonBillableData(updatedParent)
        const hasChanges = checkForChanges(updatedParent)
        setHasChanges(hasChanges)
      }
    }
  }

  const handleFilterByLetter = (letter: string) => {
    setSearchQuery('')
    setSelectedLetter(letter)
    let filtered: NonBillableDataType[] = []
    if (letter === 'All') {
      filtered = nonBillableData
    } else {
      filtered = nonBillableData?.filter((item: NonBillableDataType) =>
        item?.name?.toLowerCase().startsWith(letter?.toLowerCase()),
      )
    }
    setFilteredData(filtered)
  }

  const handleSave = () => {
    const changedItems = nonBillableData
      .filter((parentItem) => {
        const copyParentItem = nonBillableResourceData.response.find(
          (copyItem) => copyItem.id === parentItem.id,
        )
        return copyParentItem && parentItem.checked !== copyParentItem.checked
      })
      ?.map((item) => item.id)
    const data = { Employee_id: changedItems }
    if (data.Employee_id.length === 0) {
      toast.error('Please select field')
    } else {
      updateNonBillableResources(data)
      setHasChanges(false)
    }
  }
  const rowData = selectedLetter === 'All' ? nonBillableData : filteredData

  useEffect(() => {
    const filtered = rowData?.filter((row: any) =>
      Object.values(row).some((value) =>
        String(value).toLowerCase().includes(searchQuery.toLowerCase()),
      ),
    )
    setSearchedData(filtered)
  }, [searchQuery, rowData])

  return (
    <>
      <Grid container>
        <SearchBox searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
        <Alphabet selectedLetter={selectedLetter} handleFilterByLetter={handleFilterByLetter} />
        {isGetNonBillableData || rowData === undefined ? (
          <Box sx={style.loader}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            <FilteredItemList
              items={searchQuery === '' ? rowData : searchedData}
              handleCheckboxChange={handleCheckboxChange}
              handleSelectAll={handleSelectAll}
            />
            <Grid container justifyContent={'center'}>
              <Button
                variant='contained'
                onClick={handleSave}
                sx={style.saveButton}
                disabled={!hasChanges}
              >
                Save
              </Button>
            </Grid>
          </>
        )}
      </Grid>
    </>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    nonBillableResourceData:
      projectManagementEntity.getAllProjectManagement(state).getNonBillableResourcesData,
    isGetNonBillableData: projectManagementUI.getProjectManagemet(state).isGetNonBillableResources,
    isUpdatedNonBillableResources:
      projectManagementUI.getProjectManagemet(state).isUpdatedNonBillableResources,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    fetchNonBillableResources: () => dispatch(fetchNonBillableResources.request()),
    updateNonBillableResources: (data: { Employee_id: number[] }) =>
      dispatch(updateNonBillableResource.request(data)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(NonBillableResources)
