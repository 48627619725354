import { Box, IconButton, InputAdornment, styled } from '@mui/material'
import { Dispatch, SetStateAction, useState } from 'react'
import { style } from './SheetStyles'
import { SearchBoxCustom, SearchIconStyle } from '../Common/CommonStyles'
import ClearIcon from '@mui/icons-material/Clear'

type SearchBoxType = {
  searchQuery: string
  setSearchQuery: Dispatch<SetStateAction<string>>
}

const CustomSearchBox = styled(SearchBoxCustom)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: '20px',
    '& fieldset': {
      borderColor: '#777777',
    },
  },
}))

const SearchBox = (props: SearchBoxType) => {
  const { setSearchQuery } = props
  const [value, setValue] = useState('')
  const [debounceTimeout, setDebounceTimeout] = useState<NodeJS.Timeout | null>(null);

  const handleClear = () => {
    setValue('')
    setSearchQuery('')
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    const DEBOUNCE_DELAY = 700;
    setValue(newValue);

    // Clear the previous debounce timer
    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }

    // Set a new debounce timer
    const newTimeout = setTimeout(() => {
      setSearchQuery(newValue);
    }, DEBOUNCE_DELAY);

    setDebounceTimeout(newTimeout);
  };


  return (
    <Box width='240px' sx={style.serachBoxContainer}>
    <CustomSearchBox
      id='outlined-basic'
      placeholder='Search by Emp Name'
      variant='outlined'
      size='small'
      value={value}
      onChange={handleInputChange}
      width='100%'
      InputProps={{
        startAdornment: <SearchIconStyle />,
        endAdornment: (
          <InputAdornment position='end'>
            <IconButton aria-label='clear-icon' onClick={handleClear} edge='end'>
              {value && <ClearIcon />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />

    </Box>
  )
}

export default SearchBox
