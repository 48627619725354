import * as React from 'react'
import { makeStyles } from '@mui/styles'
import {
  Card,
  CardActions,
  CardContent,
  Paper,
  Typography,
  Dialog,
  CircularProgress,
} from '@mui/material'
import { dashboardEntity, dashboardUI } from '../../reducers'
import { connect } from 'react-redux'
import { getProjectData } from '../../actions'
import { HeaderHeading, loaderProps } from '../Common/CommonStyles'
import { RootState } from '../../configureStore'

function ProjectDetails({ ProjectData, fetchProjectData, loaderState }: any) {
  React.useEffect(() => {
    fetchProjectData()
  }, [])

  const CommonCardStyle: React.CSSProperties = {
    border: '1px solid lightgrey',
    borderRadius: '4px',
    justifyContent: 'normal',
    marginTop: '15px',
    marginBottom: '15px',
    gridTemplateColumns: 'auto auto auto',
    gap: '10px',
    textAlign: 'justify',
    left: '30px',
  }

  const CardHeadingText: React.CSSProperties = {
    position: 'relative',
    bottom: '10px',
    fontFamily: 'Montserrat-SemiBold',
  }

  const CardMidText = {
    lineHeight: '1.8',
    fontSize: '11px',
    fontFamily: 'Montserrat-Medium',
    letterSpacing: '0px',
    color: '#00000080',
    opacity: '1',
  }
  const CardStyle = {
    padding: '16px 13px 16px 16px',
  }
  const cardStyled = {
    width: '70%',
    justifyContent: 'normal',
    position: 'relative',
    border: 'none',
    marginTop: '15px',
    marginBottom: '15px',
    display: 'grid',
    gridTemplateColumns: 'auto auto auto',
    gap: '10px',
    textAlign: 'justify',
    left: '30px',
  }

  const useStyles = makeStyles({
    image: {
      width: '35px',
      borderRadius: '50%',
      height: '35px',
      cursor: 'pointer',
    },
    container: {},
    subContainer: {
      padding: '12px',
    },
    paperCard: {
      width: '100%',
      height: '100%',
      padding: '10px 0px 0px 0px',
      background: '#FFFFFF 0% 0% no-repeat padding-box',
      boxShadow: '0px 3px 6px #00000029',
      opacity: '1',
      marginTop: '10px',
      overflow: 'hidden',
    },
    drsHeader: {
      marginTop: '10px',
      marginBottom: '20px',
    },
  })
  const classes = useStyles()

  const ProjectDetailsCardOngoing = (
    <React.Fragment>
      {ProjectData.filter((project: any) => project.projectStatus === 0).map(
        (filteredProject: any) => (
          <div style={CommonCardStyle} key={filteredProject.projectStatus}>
            <CardContent sx={CardStyle}>
              <Typography gutterBottom>
                <span style={CardHeadingText}>Ongoing Projects</span>
              </Typography>
              <Typography sx={CardMidText} color='text.secondary' gutterBottom>
                Project Name:
                <span> {filteredProject.projectName}</span>
              </Typography>
              <Typography sx={CardMidText} color='text.secondary' gutterBottom>
                Project Status:
                <span> {filteredProject.projectStatus}</span>
              </Typography>
              <Typography sx={CardMidText} color='text.secondary' gutterBottom>
                Member Type:
                <span> {filteredProject.memberType}</span>
              </Typography>
              <Typography sx={CardMidText} color='text.secondary' gutterBottom>
                Bill Status:
                <span> {filteredProject.billStatus} </span>
              </Typography>
              <Typography sx={CardMidText} color='text.secondary' gutterBottom>
                Start Date:
                <span> {filteredProject.startDate} </span>
              </Typography>
              <Typography sx={CardMidText} color='text.secondary' gutterBottom>
                End Date:
                <span> {filteredProject.endDate} </span>
              </Typography>
            </CardContent>
            <div style={{ flexGrow: 1 }} />
            <CardActions></CardActions>
          </div>
        ),
      )}
    </React.Fragment>
  )

  const ProjectDetailsCardCompleted = (
    <React.Fragment>
      {ProjectData.filter((project: any) => project.projectStatus === 1).map(
        (filteredProject: any) => (
          <div style={CommonCardStyle} key={filteredProject.projectStatus}>
            <CardContent sx={CardStyle}>
              <Typography gutterBottom>
                <span style={CardHeadingText}>Completed Projects</span>
              </Typography>
              <Typography sx={CardMidText} color='text.secondary' gutterBottom>
                Project Name:
                <span> {filteredProject.projectName}</span>
              </Typography>
              <Typography sx={CardMidText} color='text.secondary' gutterBottom>
                Project Status:
                <span> {filteredProject.projectStatus}</span>
              </Typography>
              <Typography sx={CardMidText} color='text.secondary' gutterBottom>
                Member Type:
                <span> {filteredProject.memberType}</span>
              </Typography>
              <Typography sx={CardMidText} color='text.secondary' gutterBottom>
                Bill Status:
                <span> {filteredProject.billStatus} </span>
              </Typography>
              <Typography sx={CardMidText} color='text.secondary' gutterBottom>
                Start Date:
                <span> {filteredProject.startDate} </span>
              </Typography>
              <Typography sx={CardMidText} color='text.secondary' gutterBottom>
                End Date:
                <span> {filteredProject.endDate} </span>
              </Typography>
            </CardContent>
            <div style={{ flexGrow: 1 }} />
            <CardActions></CardActions>
          </div>
        ),
      )}
    </React.Fragment>
  )

  const ProjectDetailsCardDragging = (
    <React.Fragment>
      {ProjectData.filter((project: any) => project.projectStatus === 2).map(
        (filteredProject: any) => (
          <div style={CommonCardStyle} key={filteredProject.projectStatus}>
            <CardContent sx={CardStyle}>
              <Typography gutterBottom>
                <span style={CardHeadingText}>Dragging Projects</span>
              </Typography>
              <Typography sx={CardMidText} color='text.secondary' gutterBottom>
                Project Name:
                <span> {filteredProject.projectName}</span>
              </Typography>
              <Typography sx={CardMidText} color='text.secondary' gutterBottom>
                Project Status:
                <span> {filteredProject.projectStatus}</span>
              </Typography>
              <Typography sx={CardMidText} color='text.secondary' gutterBottom>
                Member Type:
                <span> {filteredProject.memberType}</span>
              </Typography>
              <Typography sx={CardMidText} color='text.secondary' gutterBottom>
                Bill Status:
                <span> {filteredProject.billStatus} </span>
              </Typography>
              <Typography sx={CardMidText} color='text.secondary' gutterBottom>
                Start Date:
                <span> {filteredProject.startDate} </span>
              </Typography>
              <Typography sx={CardMidText} color='text.secondary' gutterBottom>
                End Date:
                <span> {filteredProject.endDate} </span>
              </Typography>
            </CardContent>
            <div style={{ flexGrow: 1 }} />
            <CardActions></CardActions>
          </div>
        ),
      )}
    </React.Fragment>
  )

  const ProjectDetailsCardPaused = (
    <React.Fragment>
      {ProjectData.filter((project: any) => project.projectStatus === 3).map(
        (filteredProject: any) => (
          <div style={CommonCardStyle} key={filteredProject.projectStatus}>
            <CardContent sx={CardStyle}>
              <Typography gutterBottom>
                <span style={CardHeadingText}>Paused Projects</span>
              </Typography>
              <Typography sx={CardMidText} color='text.secondary' gutterBottom>
                Project Name:
                <span> {filteredProject.projectName}</span>
              </Typography>
              <Typography sx={CardMidText} color='text.secondary' gutterBottom>
                Project Status:
                <span> {filteredProject.projectStatus}</span>
              </Typography>
              <Typography sx={CardMidText} color='text.secondary' gutterBottom>
                Member Type:
                <span> {filteredProject.memberType}</span>
              </Typography>
              <Typography sx={CardMidText} color='text.secondary' gutterBottom>
                Bill Status:
                <span> {filteredProject.billStatus} </span>
              </Typography>
              <Typography sx={CardMidText} color='text.secondary' gutterBottom>
                Start Date:
                <span> {filteredProject.startDate} </span>
              </Typography>
              <Typography sx={CardMidText} color='text.secondary' gutterBottom>
                End Date:
                <span> {filteredProject.endDate} </span>
              </Typography>
            </CardContent>
            <div style={{ flexGrow: 1 }} />
            <CardActions></CardActions>
          </div>
        ),
      )}
    </React.Fragment>
  )

  return (
    <>
      {loaderState ? (
        <Dialog open={loaderState} PaperProps={{ style: loaderProps }}>
          <CircularProgress color='secondary' />
        </Dialog>
      ) : (
        <Paper className={classes.paperCard} elevation={3}>
          <div style={{}}>
            <HeaderHeading className={classes.drsHeader}>Project Details</HeaderHeading>
          </div>
          <div>
            <Card sx={cardStyled} variant='outlined'>
              {ProjectDetailsCardOngoing}
            </Card>
            <Card sx={cardStyled} variant='outlined'>
              {ProjectDetailsCardCompleted}
            </Card>
            <Card sx={cardStyled} variant='outlined'>
              {ProjectDetailsCardDragging}
            </Card>
            <Card sx={cardStyled} variant='outlined'>
              {ProjectDetailsCardPaused}
            </Card>
          </div>
        </Paper>
      )}
    </>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    homePageDetails: dashboardEntity.getDashboard(state).getHomePageDetails,
    loaderState: dashboardUI.getDashboard(state).isHomePageData,
    ProjectData: dashboardEntity.getDashboard(state).getProjectData,
  }
}

const mapDispatchToProps = (dispatch: (arg: { type: string }) => void) => {
  return {
    fetchProjectData: () => dispatch(getProjectData.request()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectDetails)
