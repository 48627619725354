import { Box, Typography } from '@mui/material'
import { style } from './ProjectManagementReportStyle'
import { TableDataType } from './ProjectManagementReportType'

type CardSectionPropsType = {
  tabView: string
  tableData: TableDataType
}

const CardSection = (props: any) => {
  const { tabView, tableData } = props
  return (
    <Box sx={style.cardSection}>
    {tabView === 'billable' && (
      <Typography sx={style.cardText} className={'tile'}>
        Total Billable Resources:{' '}
        {tableData?.count ? tableData?.count : 0}
      </Typography>
    )}
    {tabView === 'additional' && (
      <Typography sx={style.cardText} className='tile'>
        Total Additional Resources:{' '}
        {tableData?.count ? tableData?.count : 0}
      </Typography>
    )}
    {tabView === 'nonbillable' && (
      <Typography sx={style.cardText} className='tile'>
        Total Non-Billable Resources:{' '}
        {tableData?.count? tableData?.count : 0}
      </Typography>
    )}
    {tabView === 'free' && (
      <Typography sx={style.cardText} className='tile'>
        Total Free Resources: {tableData?.count? tableData?.count : 0}
      </Typography>
    )}
  </Box>
  )
}
export default CardSection
