import { Grid, Box, Button, Typography, styled } from '@mui/material'
import { style } from './NonBillabelStyle'
import { AlphabetPropType } from './NonBillableResourcesTypes'

const CustomButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#F6F6F6',
  color: '#777777',
  borderRadius: '10px',
  padding: '10px 20px',
  marginRight: '5px',
  minWidth: '0',
}));

const Alphabet = ({ selectedLetter, handleFilterByLetter }: AlphabetPropType) => {
  const alphabet = Array.from({ length: 26 }, (_, i) => String.fromCharCode(65 + i))

  return (
    <>
      <Grid container >
        <Box sx={style.alphabetContainer}>
          <CustomButton
            key='All'
            sx={{
              ...style.buttonMargin,
              ...(selectedLetter === 'All' && style.activeButton),
            }}
            onClick={() => handleFilterByLetter('All')}
          >
            <Typography sx={style.alphabetTypoStyle}>All</Typography>
          </CustomButton>
          {alphabet.map((letter: string) => (
            <CustomButton
              key={letter}
              sx={{
                ...style.buttonMargin,
                ...(selectedLetter === letter && style.activeButton),
              }}
              onClick={() => handleFilterByLetter(letter)}
            >
              <Typography sx={style.alphabetTypoStyle}>{letter}</Typography>
            </CustomButton>
          ))}
        </Box>
      </Grid>
    </>
  )
}

export default Alphabet