import { Formik, Form, Field, FieldProps } from 'formik';
import {
  Box,
  Button,
  DialogContent,
  Grid,
  Typography,
  styled,
} from '@mui/material';
import { style } from './projectCustomersStyle';
import { InputField } from '../../Common/ComponentCommonStyles';
import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { FormikFormPropType } from './ProjectCustomersTypes';

export const validationSchema = Yup.object({
  mandate_name: Yup.string()
    .required('Mandate is required')
    .test('is-empty-or-whitespace', 'Customer Name cannot be empty or whitespace', (value:any) => {
      return value?.trim().length > 0;
    }),
});

const ActionButton = styled(Button)(({ theme }) => ({
  fontSize: '13px',
  height: '32px',
  fontFamily: 'Montserrat-SemiBold',
  width: '90px',
  borderRadius: '20px',
  '&.Mui-disabled': {
    opacity: 0.5,
    color: '#ffffff',
  },
}));

const CancelButton = styled(Button)(({ theme }) => ({
  background: '#E2E2E2',
  color: '#000000',
  fontSize: '13px',
  height: '32px',
  fontFamily: 'Montserrat-SemiBold',
  width: '90px',
  borderRadius: '20px',
  '&:hover': {
    background: '#E2E2E2',
    color: '#000000',
  },
}));

const FormikForm = ({
  initialValues,
  onSubmit,
  handleClickCloseDiaglogForm,
  openCustomerEditForm,
}: FormikFormPropType) => {

  const [isDisabled,setIsDisabled]=useState(true);

  useEffect(() => {
    if (openCustomerEditForm && initialValues.mandate_name?.trim()) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [openCustomerEditForm, initialValues.mandate_name]);

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit} validate={values => {
      const errors = {};
      if (!values.mandate_name || !values.mandate_name.trim()) {
        setIsDisabled(true);
      } else {
        setIsDisabled(false);
      }
      return errors;
    }}>
      {({ values, errors, touched }) => (
        <Form>
          <DialogContent dividers>
            <Grid container sx={style.formGridContainerStyle}>
              <Box sx={style.fieldBoxStyle}>
                <Field name='mandate_name'>
                  {({ field }: FieldProps<string>) => (
                    <InputField
                      {...field}
                      label='Mandate Name'
                      variant='outlined'
                      placeholder='Enter Mandate Name'
                      size='small'
                      error={touched.mandate_name && !!errors.mandate_name}
                      helperText={
                        touched.mandate_name && typeof errors.mandate_name === 'string'
                          ? errors.mandate_name
                          : ''
                      }
                      inputProps={{
                        autoComplete: 'new-password',
                        form: { autoComplete: 'off' },
                      }}
                    />
                  )}
                </Field>
              </Box>
            </Grid>
          </DialogContent>
          <Box sx={style.diaglogActionBoxStyle}>
            <CancelButton onClick={handleClickCloseDiaglogForm} autoFocus>CANCEL</CancelButton>
            <ActionButton
              
              autoFocus
              type='submit'
              disabled={isDisabled}
            >
              {openCustomerEditForm ? (
                <Typography>Update</Typography>
              ) : (
                <Typography>Create</Typography>
              )}
            </ActionButton>
          </Box>
        </Form>
      )}
    </Formik>
  )
}

export default FormikForm;
